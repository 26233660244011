import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	CreateAttraction,
	UpdateAttraction,
	viewAttraction,
} from "services/attraction";
import { uploadFile } from "services/auth";
import { fetchCities } from "services/lookups";
import Locale from "translations";
import { DeleteIcon } from "../Icons/deleteIcon";
import { IncludedServiceIcon } from "../Icons/includedServiceIcon";
import { UploadImage } from "../Icons/uploadImage";
import { TimeField } from "../models/TimeField";

export default function AddEditAttraction() {
	const { backOffice, requests } = Locale;
	const { status, id } = useParams();
	const history = useHistory();
	const { allCountries, locale } = useGlobalState();
	const [attractionState, setAttractionState] = useState({
		tour_name: "",
		country: null,
		city: null,
		duration: "",
		description: "",
		included_services: [],
		photos: [],
		gallery: [],
		notes: "",
		terms: "",
		cancelation_police: "",
		cancelation_fees: "",
	});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const imageUrl = process.env.REACT_APP_API_URL + "/api/files/fetch";

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);
	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (attractionState.country?.value) {
				const res = await fetchCities(attractionState.country?.value);
				const formatted = res?.data?.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [attractionState.country]);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "tour_name", value: attractionState.tour_name },
				{ required: true }
			),
			...validate(
				{ name: "country", value: attractionState.country },
				{ required: true }
			),
			...validate(
				{ name: "city", value: attractionState.city },
				{ required: true }
			),
			...validate(
				{ name: "duration", value: attractionState.duration },
				{ required: true }
			),
			// ...validate(
			// 	{ name: "cancelation_fees", value: attractionState.cancelation_fees },
			// 	{ required: true }
			// ),
			...validate(
				{
					name: "photos",
					value: attractionState.photos?.length > 0 ? true : false,
				},
				{ required: true }
			),
		});
	};
	const handleChange = (e) => {
		const value = e.value;
		const name = e.name;
		if (name === "country") {
			setCities([]);
			setAttractionState({ ...attractionState, city: "", [name]: value });
		} else setAttractionState({ ...attractionState, [name]: value });
	};
	const addIncludedServices = () => {
		const cloneServices = attractionState.included_services;
		cloneServices.push("");
		setAttractionState({
			...attractionState,
			included_services: [...cloneServices],
		});
	};
	const handleIncludedService = (e) => {
		const value = e.value;
		const index = e.index;
		const clone = attractionState.included_services;
		clone[index] = value;
		setAttractionState({ ...attractionState, included_services: clone });
	};
	const removeIncludedService = (index) => {
		const clone = attractionState.included_services;
		clone.splice(index, 1);
		setAttractionState({ ...attractionState, included_services: clone });
	};
	const removeImage = (index) => {
		const clone = attractionState.photos;
		clone.splice(index, 1);
		setAttractionState({ ...attractionState, photos: clone });
	};
	const handleUpload = async (e) => {
		const files = e.target.files[0];
		let formData = new FormData();
		formData.append("file", files);
		const isImgSelected =
			e.target.files.length > 0 && formData !== undefined
				? await uploadFile(formData)
				: null;
		if (isImgSelected?.status === 200) {
			setAttractionState({
				...attractionState,
				photos: [...attractionState.photos, isImgSelected.data.uuid],
				gallery: [...attractionState.gallery, isImgSelected.data.uuid],
			});
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			const AddEditAttraction = async () => {
				var res = null;
				let date = {
					name: attractionState?.tour_name,
					country_id: attractionState?.country?.value,
					city_id: attractionState?.city?.value,
					duration: attractionState?.duration,
					description: attractionState?.description,
					services: attractionState?.included_services?.map(
						(service) => service
					),
					photos: attractionState?.photos?.map((photo) => photo),
					notes: attractionState?.notes,
					terms: attractionState?.terms,
				};
				if (status == "add") {
					res = await CreateAttraction(date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Create New Attraction Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push("/attractions");
							},
						});
					}
				} else {
					res = await UpdateAttraction(id, date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Edit Attraction Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push("/attractions");
							},
						});
					}
				}
			};
			AddEditAttraction();
		}
	}, [isErrorLoaded]);
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	//View attraction
	useEffect(() => {
		const viewAttractionFn = async () => {
			debugger;
			const res = await viewAttraction(id);
			if (res.status === 200) {
				setAttractionState({
					...res.data.data,
					tour_name: res.data.data.name,
					country: {
						value: res.data.data.country.id,
						label: res.data.data.country.name,
					},
					city: {
						value: res.data.data.city.id,
						label: res.data.data.city.name,
					},
					duration: res.data.data.duration,
					description: res.data.data.description,
					included_services: [...res.data.data.services],
					photos: [...res.data.data.photos],
					gallery: [...res.data.data.photos],
					notes: res.data.data.notes,
					terms: res.data.data.terms,
					cancelation_police: "",
					cancelation_fees: "",
				});
			}
		};

		if (status === "edit") {
			viewAttractionFn();
		}
	}, []);

	const handleDurationChange = (hours, minutes) => {
		const value = +hours || +minutes ? `${hours}:${minutes}` : "";
		setAttractionState({
			...attractionState,
			duration: value,
		});
		setErrors({
			...errors,
			...validate(
				{ name: "duration", value },
				{
					required: true,
				}
			),
		});
	};
	const disabledBtn = () => {
		const clone = attractionState?.included_services;
		return clone.some((item) => item === "");
	};
	console.log("errors1111", disabledBtn());

	return (
		<div className="add-edit-attraction">
			<div className="row">
				{/**Tour Name */}
				<div className="col-md-4">
					<TextField
						type="text"
						label={backOffice.TourName}
						placeholder={backOffice.TourName}
						value={attractionState.tour_name}
						name="tour_name"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setAttractionState({
								...attractionState,
								tour_name: e.target.value,
							});
						}}
						color={errors?.tour_name?.required ? "danger" : ""}
						errors={errors?.tour_name}
					/>
				</div>
				{/* Country */}
				<div className="col-md-4">
					<SelectField
						label={backOffice.country}
						placeholder={backOffice.country}
						errors={errors.country}
						color={errors.country?.required ? "danger" : ""}
						options={countries}
						onChange={(e) => {
							handleChange({ name: "country", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "country", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={attractionState.country}
					/>
				</div>
				{/* City */}
				<div className="col-md-4">
					<SelectField
						label={backOffice.city}
						placeholder={backOffice.city}
						errors={errors.city}
						color={errors.city?.required ? "danger" : ""}
						options={cities}
						onChange={(e) => {
							handleChange({ name: "city", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "city", value: e },
									{
										required: true,
									}
								),
							});
						}}
						value={attractionState.city}
					/>
				</div>
				{/**Duration */}
				<div className="col-md-12 px-3">
					{/* <TimePickerField
						label={"Duration"}
						fromValue={attractionState.duration}
						// amPmAriaLabel={"A"}
						onFromChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "duration", value: e },
									{
										required: true,
									}
								),
							});
							setAttractionState({
								...attractionState,
								duration: e,
							});
						}}
						name="duration"
						color={errors?.duration?.required ? "danger" : ""}
						errors={errors?.duration}
						hourAriaLabel="Hour"
						hourPlaceholder="HH"
						minutePlaceholder="mm"
						format="hh:mm"
					/> */}
					<TimeField
						changeValue={handleDurationChange}
						label={backOffice.Duration}
						value={{
							hours: attractionState.duration?.split(":")[0],
							minutes: attractionState.duration?.split(":")[1],
						}}
						errorMsg={errors?.duration?.required ? "Duration required" : false}
					/>
				</div>
				{/**Description */}
				<div className="col-md-12">
					<TextAreaField
						label={backOffice.description}
						customLabel={"Description"}
						placeholder={backOffice.description}
						value={attractionState.description}
						rows="3"
						className="control-field__textarea_input"
						name="description"
						onChange={(e) => {
							setAttractionState({
								...attractionState,
								description: e.target.value,
							});
						}}
					/>
				</div>
				{/** Here included Services */}
				<div className="col-md-12 included-services">
					<div className="row">
						<div
							className={`col-md-6 p-0 ${disabledBtn() ? "danger-border" : ""}`}
						>
							<p>
								<IncludedServiceIcon />
								{backOffice.IncludedServices}
							</p>
							{attractionState.included_services.map((service, index) => (
								<div className="d-flex">
									<div className="w-90">
										<TextField
											type="text"
											hasLabel={false}
											placeholder={backOffice.service}
											value={service}
											onChange={(e) => {
												handleIncludedService({
													value: e.target.value,
													index: index,
												});
											}}
										/>
									</div>

									{/* {index !== 0 && ( */}
									<button
										className="btn delete-btn"
										onClick={() => removeIncludedService(index)}
									>
										<DeleteIcon />
									</button>
									{/** )}*/}
								</div>
							))}
							<button className="btn mx-1" onClick={addIncludedServices}>
								<i class="fas fa-plus"></i> {backOffice.AddNew}
							</button>
						</div>
						<div className="col-md-6 p-0">
							<div
								className={`p-3 d-flex justify-content-between align-items-center  flex-wrap position-relative upload-images ${
									errors?.photos?.required ? "danger-border" : ""
								}`}
								style={{ borderRadius: "5px" }}
							>
								<div className="d-flex justify-content-center w-100">
									<label
										//onChange={handleUpload}
										htmlFor="uploadFile"
										className=" text-black px-4 d-flex flex-column align-items-center"
									>
										<UploadImage />
										{backOffice.UploadPhoto}
									</label>
									<input
										multiple="multiple"
										onChange={(e) => {
											handleUpload(e);
											setErrors({
												...errors,
												...validate(
													{ name: "photos", value: e },
													{
														required: true,
													}
												),
											});
										}}
										type="file"
										id="uploadFile"
										accept="image/*"
										name="photos"
									/>
								</div>
								<div className="images-gallery w-100">
									{attractionState.photos.length > 0 &&
										attractionState.photos.map((gallery, index) => (
											<div className="image-gallery">
												<img
													src={`${imageUrl}/${gallery}`}
													alt=""
													width={110}
													height={80}
												/>
												<div className="gallery-remove">
													<button
														className="btn delete-btn"
														onClick={() => removeImage(index)}
													>
														<DeleteIcon />
													</button>
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/**Notes */}
				<div className="col-md-12">
					<TextAreaField
						label={backOffice.Notes}
						placeholder={backOffice.Notes}
						value={attractionState.notes}
						rows="3"
						className="control-field__textarea_input"
						name="notes"
						onChange={(e) => {
							setAttractionState({
								...attractionState,
								notes: e.target.value,
							});
						}}
					/>
				</div>
				{/**Terms & conditions  */}
				<div className="col-md-12">
					<TextAreaField
						label={requests.TermsConditions}
						placeholder={requests.TermsConditions}
						value={attractionState.terms}
						rows="3"
						className="control-field__textarea_input"
						name="terms"
						onChange={(e) => {
							setAttractionState({
								...attractionState,
								terms: e.target.value,
							});
						}}
					/>
				</div>

				{/**cancellation policy  */}
				{/* <div className="col-md-12">
					<TextAreaField
						label={"cancellation policy "}
						placeholder={"cancellation policy "}
						value={attractionState.cancelation_police}
						rows="3"
						className="control-field__textarea_input"
						name="cancelation_police"
						onChange={(e) => {
							setAttractionState({
								...attractionState,
								cancelation_police: e.target.value,
							});
						}}
					/>
				</div>
				<div className="col-md-12 px-3">
					<DatePickerField
						date={attractionState.cancelation_fees}
						placeholder={"DD/MM/YYYY"}
						label={"Cancelation fees "}
						name="cancelation_fees "
						onChangeDate={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "cancelation_fees", value: e },
									{ required: true }
								),
							});
							setAttractionState({
								...attractionState,
								cancelation_fees: e,
							});
						}}
						// isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
						color={errors?.cancelation_fees?.required ? "danger" : ""}
						errors={errors?.cancelation_fees}
					/>
				</div> */}
			</div>
			<div className="action-buttons mx-3">
				<button className="btn btn-danger px-4">{backOffice.delete}</button>
				<button
					className="btn btn-primary px-4"
					onClick={submit}
					disabled={disabledBtn() ? true : false}
				>
					{status == "add" ? backOffice.SaveChanges : backOffice.edit}
				</button>
			</div>
		</div>
	);
}
