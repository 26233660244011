import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import { addExchangeRate, viewExchangeRate } from "services/exchangeRate";
import Locale from "translations";
import ExchangeDetails from "./exchangeDetails";

export default function ExchangeRate() {
	const { backOffice } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const [rateRow, setRateRow] = useState([]);
	const [errors, setErrors] = useState([]);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const fetchExchangeRateList = async () => {
		const res = await viewExchangeRate();
		setRateRow(res?.data?.data);
		if (res?.status == 200) {
			setRateRow(res?.data?.data);
		}
	};
	useEffect(() => {
		fetchExchangeRateList();
	}, []);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate({ name: "from", value: rateRow.from }, { required: true }),
			...validate({ name: "to", value: rateRow.to }, { required: true }),
			...validate({ name: "rate", value: rateRow.rate }, { required: true }),
		});
	};
	const handleSubmit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const addAll = async () => {
		let handleData = [];
		handleSubmit();
		handleData = rateRow.map((item, index) => {
			return {
				from: typeof item.from === "object" ? item.from?.label : item.from,
				to: typeof item.to === "object" ? item.to?.label : item.to,
				rate: +item.rate,
			};
		});
		const res = await addExchangeRate({ exchange_rates: handleData });
		if (res.status == 200) {
			getNotification(res.data.message);
			fetchExchangeRateList();
		}
	};

	return (
		<div className="edit-supplier container-fluid ">
			<div className="">
				<div className="text-black">
					<div className="d-flex ">
						<h4 className="main-title">{backOffice.configurations}</h4>
						{/* <Nav tabs>
							<NavItem>
								<NavLink
									className={`h4 text-black ${
										activeTab === "1" ? "is-active" : ""
									}`}
									onClick={() => {
										toggle("1");
									}}
								>
									Configuration
								</NavLink>
							</NavItem>
						</Nav> */}
					</div>
				</div>
			</div>
			<div className="mt-4 our-border p-4">
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<ExchangeDetails
							rateRow={rateRow}
							setRateRow={setRateRow}
							errors={errors}
							setErrors={setErrors}
						/>
					</TabPane>
				</TabContent>

				<ShowForPermission permission="Exchange-Rate-Manage">
					{rateRow.length > 0 ? (
						<div className="d-flex  justify-content-end mt-5">
							<button className="btn companies-btn" onClick={() => addAll()}>
								{backOffice.save}
							</button>
						</div>
					) : null}
				</ShowForPermission>
			</div>
		</div>
	);
}
