import TextField from "components/shared/TextField";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function ConfirmPendingRequestModal({
	isOpen,
	toggle,
	submitAmount,
}) {
	const { backOffice } = Locale;
	const [fees, setFees] = useState("");
	const [selectedCurrency, setSelectedCurrency] = useState(null);

	return (
		<Modal isOpen={isOpen} centered={true}>
			<ModalHeader toggle={toggle}>
				<span>{backOffice.confirmRequest}</span>
			</ModalHeader>

			<ModalBody className="pt-0">
				<div className="px-3 text-center">
					<div className="position-relative">
						<TextField
							type={"number"}
							name="fees"
							id="fees"
							label={backOffice.fees}
							value={fees}
							onChange={(e) => {
								setFees(+e.target.value);
							}}
						/>
					</div>
					<button
						className="accept-btn w-100"
						onClick={() => {
							submitAmount({ amount: fees, currency: selectedCurrency });
						}}
						disabled={fees === ""}
					>
						{backOffice.confirm}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
