import { useCallback, useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// React-notifications
// Components
import Pagination from 'components/shared/Pagination';
// Module Components
import QuickFilter from '../../shared/QuickFilter';
import TargetCampaignsFilter from './TargetCampaignsFilter';
import TargetCampaignsList from './TargetCampaignsList';
// Helper
import ShowForPermission from 'helpers/showForPermission';
// Moment
import moment from 'moment';
// Services
import { exportExcelCampaignList, getCampaignList } from 'services/safaVisa';
import { fetchCompaniesLookups } from 'services/sbsReservations';
// Icons
import { ExportIcon } from 'components/Icons';
// Custom Hooks
import useDebounce from 'hooks/useDebounce';
import useSafaVisaData from '../../shared/useSafaVisaData';
//-------------------------------------------------------------------------

export default function TargetedCampaigns() {
  // *** Hooks
  let history = useHistory();

  const { safaVisa } = Locale;

  // *** Custom Hook
  const { initialQuickFilter, exportExcel } = useSafaVisaData();

  const initialFilter = {
    country_ids: '',
    pricing_type: '',
    company_ids: '',
    calendar_type: { value: 'gregorian', label: safaVisa.gregorian }, // default calendar type,
    date_from: '',
    date_to: '',
  }

  // ** State
  const [quickfilters, setQuickFilter] = useState(initialQuickFilter);
  const [filters, setFilters] = useState(initialFilter);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [companies, setCompanies] = useState();
  const [reload, setReload] = useState(false);

  const [targetedCampaignsData, setTargetedCampaignsData] = useState({
    list: [],
    meta: {}
  });

  const allFilterParams = {
    ...quickfilters,
    title: quickfilters?.title || null,
    country_ids: filters?.country_ids?.value ? [Number(filters?.country_ids?.value)] : null,

    is_default_pricing: (
      (filters?.pricing_type?.value === 'default_price') ||
      (filters?.pricing_type?.value === 'all')
    ) ? 1 : null,

    is_pricing_package: (
      (filters?.pricing_type?.value === 'package')
      || (filters?.pricing_type?.value === 'all')
    ) ? 1 : null,

    company_ids: filters?.company_ids ? [filters?.company_ids?.value] : null,
    date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
    date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,

  }

  //** Function
  const goTo = (page) => {
    setQuickFilter({ ...quickfilters, page: page });
  };

  const fetctCampaignList = async (params) => {
    setTargetedCampaignsData({
      list: [],
      meta: {}
    })
    const response = await getCampaignList(params)
    if (response?.status === 200) {
      setTargetedCampaignsData({
        list: response?.data?.data,
        meta: response?.data?.meta
      })
    }
  }

  const getCompaniesLookups = async (params) => {
    const response = await fetchCompaniesLookups(params)
    if (response?.status === 200) {
      const transformedCompanies = response?.data?.data.map((t) => ({
        value: t.id,
        label: t.name,
        ...t,
      }));
      setCompanies(transformedCompanies)
    }
  }

  const depounceSearchByTitle = useDebounce((params) =>
    fetctCampaignList({ ...params })
    , 300)

  const handleQuickFilter = useCallback(({ action, key, payload }) => {
    if (action === "reset") { setQuickFilter(initialQuickFilter) }
    if (action === "filter") {
      setQuickFilter((prevFilter) => ({ ...prevFilter, [key]: payload }));
    }
  }, []);

  const handleFilter = useCallback(({ action, key, payload }) => {
    if (action === "reset") {
      setFilters({ ...initialFilter });
      setQuickFilter({ ...initialQuickFilter, title: '' })
    }
    if (action === "filter") {
      setFilters((prevFilter) => ({ ...prevFilter, [key]: payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyFilter = () => fetctCampaignList({ ...allFilterParams })

  const handleToggleFilter = () => setFilterIsOpen(!filterIsOpen)

  const exportDataToExcel = () => exportExcel({
    apiFunctionService: exportExcelCampaignList({ ...allFilterParams }),
    fileName: 'Targeted Campaigns List'
  })

  useEffect(() => {
    if (quickfilters?.title) {
      depounceSearchByTitle({ ...allFilterParams })
    } else {
      fetctCampaignList({ ...allFilterParams })
    }
  }, [reload, quickfilters])

  useEffect(() => {
    if (filterIsOpen) { getCompaniesLookups() }
  }, [filterIsOpen])

  // console.log(filters)

  // ------- JSX Code -------
  return (
    <div className='safaVisa-wrapper'>
      <div>
        {/* Target Campaigns Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.targetedCampaigns}</h4>

          <div className='d-flex gap-2 align-items-center'>
            <ShowForPermission permission="Tourism-Company-Manage">
              <button
                className="btn export-btn py-2"
                onClick={exportDataToExcel}
              >
                <ExportIcon color='#57A866' width='24' height='24' />
                {safaVisa.exportExcel}
              </button>
            </ShowForPermission>
            <ShowForPermission permission="Tourism-Company-Manage">
              <button
                className="btn  add-btn py-2"
                onClick={() => history.push("/safaVisa-targetedCampaigns/add")}
              >
                <i className="fas fa-plus text-white fa-md"></i>{" "}
                {safaVisa.add}
              </button>
            </ShowForPermission>
          </div>
        </div>

        <hr />

        {/* Target Campaigns Filter */}
        <div className='safa-visa-filter'>
          <QuickFilter
            quickfilters={quickfilters}
            onQuickFilter={handleQuickFilter}
            toggleFilter={handleToggleFilter}
            filterIsOpen={filterIsOpen}
          />

          {filterIsOpen &&
            <TargetCampaignsFilter
              filters={filters}
              companiesOptions={companies}
              onFilter={handleFilter}
              onApplyFilter={handleApplyFilter}
            />
          }

        </div>

        {/* Target Campaigns List */}
        <TargetCampaignsList
          targetCampaignsList={targetedCampaignsData?.list}
          reload={reload}
          onReload={setReload}
        />

        {/* Pagination */}
        <Pagination info={targetedCampaignsData?.meta} goTo={goTo} items={10} />
      </div>
    </div>
  )
}

