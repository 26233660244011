import TextAreaField from "components/shared/TextAreaField";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
// import TextAreaField from "components/Form/TextAreaField/TextAreaField";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { addRemarks } from "services/sbsReservations";
import Locale from "translations";

function Remarks(props) {
	const { backOffice } = Locale;
	const { details, handleReservationState } = props;
	const { remarks } = details;
	const { id } = useParams();

	const [remarkState, setRemarkState] = useState("");
	const sudanManualReservationsManagePermission = useShowPermission({
		permission: "Sudan-Manual-Reservations-Manage",
	});
	const handleRemarksList = (response) => {
		let updatedRemarksState = response.data.data.remarks;

		handleReservationState({ name: "remarks", value: updatedRemarksState });
		setRemarkState("");
	};

	const addNewRemark = async () => {
		const response = await addRemarks(id, {
			content: remarkState,
		});

		if (response.status == 200) {
			handleRemarksList(response);
		}
	};

	return (
		<div id="reserv-remarks">
			{remarks.map((i) => (
				<div key={i.id} className="w-100 border p-2 mt-2 remarks_content">
					<p>{`${i.user_name} | ${i.created_at}`}</p>
					<p>{i.content}</p>
				</div>
			))}

			<TextAreaField
				hasLabel={false}
				placeholder={backOffice.AddRemarks}
				value={remarkState}
				disabled={!sudanManualReservationsManagePermission}
				rows={5}
				name="reasons"
				onChange={(e) => {
					setRemarkState(e.target.value);
				}}
			/>
			<ShowForPermission permission="Sudan-Manual-Reservations-Manage">
				<div className="w-100 text-end">
					<button
						className="btn btn-info p-2 my-2 rounded-0"
						onClick={addNewRemark}
						disabled={details?.status?.id == 5}
					>
						{backOffice.AddRemarks}
					</button>
				</div>
			</ShowForPermission>
		</div>
	);
}

export default Remarks;
