import DatePickerField from "components/shared/DatePickerField";
import TextField from "components/shared/TextField";
import Locale from "translations";
import { ClearIcon } from "./Icons/clearIcon";
import { SearchIcon } from "./Icons/searchIcon";
export default function Filter({ filter, setFilter }) {
	// const handleChange = (e) => {
	// 	if ("target" in e) {
	// 		const value = e.target.value;
	// 		const name = e.target.name;
	// 		setFilterState({ ...filterState, [name]: value });
	// 	} else {
	// 		const value = e.value;
	// 		const name = e.name;
	// 		setFilterState({ ...filterState, [name]: value });
	// 	}
	// };

	const { backOffice, requests } = Locale;
	const resetFilters = () => {
		setFilter({
			search: "",
			date: null,
			page: 1,
		});
	};

	return (
		<>
			<>
				<div className="attraction-filter">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-md-3 p-0">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									label={backOffice.search}
									value={filter.search}
									extraTextPosition="prepend"
									extraText={<SearchIcon />}
									color="transparent"
									onChange={(e) => {
										setFilter({
											...filter,
											search: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md-3 p-0 date-input">
							<DatePickerField
								date={filter.date}
								placeholder={"DD/MM/YYYY"}
								label={backOffice.date}
								onChangeDate={(filterDate) => {
									setFilter({
										...filter,
										date: filterDate,
									});
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>

						<div className="col-md-1 p-0">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary  pointer mt-4 clear-filter mx-2"
							>
								<ClearIcon />
								<p className="text-caption mx-2">{requests.clear}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
