// Translation
import Locale from 'translations';
// Components
import RadioGroup from 'components/shared/RadioGroup';
// Module Component
import DefaultPricingVisasList from './DefaultPricingVisasList';
import PackageQuotasList from './PackageQuotasList';
import PackageSubscriptionsTypesList from './PackageSubscriptionsTypesList';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Helper
import validate from 'helpers/validate';
// ---------------------------------------------------------------------
export default function DiscountPricingTypes(props) {
  const {
    discountData,
    setDiscountData,
    errors,
    setErrors,
  } = props

  // *** Hooks
  const { safaVisa } = Locale;

  // *** Custom Hook
  const {
    packageSubscriptionOptions,
    offerTypesOptions,
    discountTypesOptions,
  } = useSafaVisaData();


  const subscriptionTypesInSelectedPackage = discountData?.package_pricing?.filter(el => el?.price !== null).map(e => e?.name.toLowerCase())
  const subscriptionOptions = packageSubscriptionOptions?.filter(e => e?.value === subscriptionTypesInSelectedPackage?.find(el => el === e?.value))

  const subscriptionOptionsToShow = subscriptionOptions.length >= 2 ? packageSubscriptionOptions : subscriptionOptions
  // console.log(subscriptionOptionsToShow)
  // console.log(subscriptionOptionsToShow)
  // *** Functions
  const handleChangeInputs = ({ key, value }) => {
    const packageDataClone = { ...discountData }
    packageDataClone[`${key}`] = value
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setDiscountData(packageDataClone)
  }

  // ------- JSX Code --------
  return (
    <form
      // onSubmit={submit} 
      className="mt-4"
    >

      <div className='d-flex gap-2 align-items-center mb-2'>

        {/* Offer Type  */}
        {discountData?.pricing_type === 'package' &&
          <div className="col-4" >
            <RadioGroup
              label={safaVisa?.offerType}
              hasLabel={true}
              name='offer_type'
              groupOptions={offerTypesOptions}
              onChange={(e) => handleChangeInputs({ key: 'offer_type', value: e?.target?.value })}
              value={discountData?.offer_type}
            />
          </div>
        }

        {/* Subscription Type  */}
        {(discountData?.pricing_type === 'package' &&
          (discountData?.offer_type === 'price' || discountData?.offer_type === 'offers_all'))
          &&
          <div className="col-4" >
            <RadioGroup
              label={safaVisa?.subscriptionType}
              hasLabel={true}
              name='subscription_type'
              groupOptions={subscriptionOptionsToShow}
              onChange={(e) => handleChangeInputs({ key: 'subscription_type', value: e?.target?.value })}
              value={discountData?.subscription_type}
            />
          </div>
        }

        {/* Discount Type  */}
        {(discountData?.pricing_type === 'default_price' ||
          (discountData?.pricing_type === 'package' && discountData?.offer_type !== 'units'))
          &&
          <div className="col-4" >
            <RadioGroup
              label={safaVisa?.discountType}
              hasLabel={true}
              name='discount_type'
              groupOptions={discountTypesOptions}
              onChange={(e) => handleChangeInputs({ key: 'discount_type', value: e?.target?.value })}
              value={discountData?.discount_type}
              color={errors?.discount_type?.required ? "danger" : ""}
              errors={errors?.discount_type}
            />
          </div>
        }
      </div>

      <div>
        {(discountData?.pricing_type === 'package' &&
          (discountData?.offer_type === 'price' || discountData?.offer_type === 'offers_all'))
          &&
          < PackageSubscriptionsTypesList
            discountData={discountData}
            setDiscountData={setDiscountData}
            errors={errors}
            setErrors={setErrors}
          />
        }

        {(discountData?.pricing_type === 'package' &&
          (discountData?.offer_type === 'units' || discountData?.offer_type === 'offers_all'))
          &&
          <PackageQuotasList
            discountData={discountData}
            setDiscountData={setDiscountData}
            errors={errors}
            setErrors={setErrors}
          />
        }

        {discountData?.pricing_type === 'default_price' &&
          <DefaultPricingVisasList
            discountData={discountData}
            setDiscountData={setDiscountData}
            errors={errors}
            setErrors={setErrors}
          />
        }

      </div>

    </form >
  )
}

