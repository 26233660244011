import Locale from "translations";
function FlightBookingSummary({ flightData, togglePriceBreakdown }) {
	const adultsChildrenCount = +flightData?.adults + +flightData?.children;
	// adults
	const fareForAllAdults = +flightData?.adtFare * +flightData?.adults || 0;
	const taxForAllAdults = +flightData?.adtTax * +flightData?.adults || 0;
	const adultsTotalPrice =
		fareForAllAdults + taxForAllAdults + +flightData?.tktFee || 0;
	// children
	const fareForAllChildren = +flightData?.chdFare * +flightData?.children || 0;
	const taxForAllChildren = +flightData?.chdTax * +flightData?.children || 0;
	const childrenTotalPrice =
		fareForAllChildren + taxForAllChildren + +flightData?.tktFee || 0;
	const { backOffice } = Locale;
	return (
		<>
			<div className="bg-white p-2 text-capitalize border-light">
				<span
					className="text-right text-capitalize mb-2 pointer d-flex justify-content-end"
					onClick={togglePriceBreakdown}
					style={{
						color: "#D29D4D",
						fontSize: "small",
						fontWeight: "bold",
						display: "inline-block",
					}}
				>
					{backOffice.PriceBreakdown}
				</span>
				{/* adults price summary */}
				<div className="adults-price-summary">
					<div className="d-flex justify-content-between ">
						<p className="fw-bold">
							{flightData?.adults} X {backOffice.adult}
						</p>
						<p className="fw-bold">
							{(fareForAllAdults + taxForAllAdults)?.toFixed(2)}{" "}
							{flightData?.currency}
						</p>
					</div>
					<div className="d-flex justify-content-between ms-4 price-color">
						<div>
							<p>{backOffice.FarePerAdult}</p>
							<p>{backOffice.TaxesPerAdult}</p>
						</div>
						<div>
							<p>
								{flightData?.adtFare?.toFixed(2)} {flightData?.currency}
							</p>
							<p>
								{flightData?.adtTax?.toFixed(2)} {flightData?.currency}
							</p>
						</div>
					</div>
				</div>
				{/* child price summary */}
				{flightData?.children > 0 ? (
					<div className="children-price-summary my-2">
						<div className="d-flex justify-content-between">
							<h5>
								{flightData?.children} X {backOffice.child}
							</h5>
							<h5>
								{(fareForAllChildren + taxForAllChildren).toFixed(2)}
								{flightData?.currency}
							</h5>
						</div>
						<div className="d-flex justify-content-between ms-4 price-color">
							<div>
								<p className="mb-0">{backOffice.FarePerChild}</p>
								<p className="mb-0">{backOffice.TaxesPerChild}</p>
							</div>
							<div>
								<p>
									{flightData?.chdFare?.toFixed(2)} {flightData?.currency}
								</p>
								<p>
									{flightData?.chdTax?.toFixed(2)} {flightData?.currency}
								</p>
							</div>
						</div>
					</div>
				) : null}

				{/* tickets fees */}
				{/* <div className='d-flex justify-content-between price-color'>
          <h5 className='bold'>{adultsChildrenCount} X Ticketing Fee</h5>
          <h5 className='bold'>
            {(+flightData?.tktFee).toFixed(2)}
            {flightData?.currency}
          </h5>
        </div> */}
			</div>
			{/* total price and penalties */}
			<div className="d-flex justify-content-between total-price">
				<p>{backOffice.TotalPrice}</p>
				<p
					style={{
						fontSize: "16px",
						color: "#28A745",
						fontWeight: "bold",
						lineHeight: "1",
					}}
				>
					{flightData?.totalPrice?.toFixed(2)} {flightData?.currency}
				</p>
			</div>
		</>
	);
}

export default FlightBookingSummary;
