import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ShareAttraction, viewAttraction } from "services/attraction";
import Locale from "translations";
import Share from "../models/share";

export default function ViewAttraction() {
	const { backOffice, booking, requests } = Locale;
	const { id } = useParams();
	const history = useHistory();
	const [AttractionData, setAttractionData] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [shareState, setShareState] = useState({
		to: "",
		from: "",
		payment_type: "",
	});
	const toggle = () => {
		setIsOpen((prev) => !prev);
	};
	const slides = useRef(null);
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 7,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 6,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const imageUrl = process.env.REACT_APP_API_URL + "/api/files/fetch";

	useEffect(() => {
		const getAttractionData = async () => {
			const res = await viewAttraction(id);
			if (res.status === 200 || res.status === 200) {
				debugger;
				setAttractionData(res.data.data);
				setShareState({
					to: res.data.data?.share_to
						? moment(res.data.data?.share_to).format("YYYY-MM-DD")
						: "",
					from: res.data.data?.share_from
						? moment(res.data.data?.share_from).format("YYYY-MM-DD")
						: "",
					payment_type: res.data.data?.book_type ?? "",
				});
			}
		};
		getAttractionData();
	}, []);

	const share = async () => {
		const data = {
			share_from: moment(shareState.from).format("YYYY-MM-DD"),
			share_to: moment(shareState.to).format("YYYY-MM-DD"),
			book_type: shareState.payment_type,
		};
		const response = await ShareAttraction(id, data);
		if (response.status === 200 || response.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "Share Attraction Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/attractions");
				},
			});
		}
	};

	const arrowStyleLeft = {
		border: 0,
		background: "none",
		position: "absolute",
		left: "5px",
	};
	const arrowStyleRight = {
		border: 0,
		background: "none",
		position: "absolute",
		right: "5px",
	};

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		const {
			carouselState: { currentSlide, slidesToShow, totalItems },
		} = rest;
		debugger;
		console.log(rest);
		return (
			<div className="carousel-button-group-new">
				<button
					className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right  ${
						currentSlide + slidesToShow >= totalItems ? "disable" : ""
					}`}
					onClick={() => next()}
				></button>
				<button
					className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left  ${
						currentSlide === 0 ? "disable" : ""
					}`}
					onClick={() => previous()}
				>
					{" "}
				</button>
			</div>
		);
	};
	return (
		<div className="view-attraction">
			<div className="attractions-info-container">
				<AttractionInfoData
					label={backOffice.TourName}
					data={AttractionData.name}
				/>
				<AttractionInfoData
					label={backOffice.country}
					data={AttractionData?.country?.name}
				/>
				<AttractionInfoData
					label={backOffice.city}
					data={AttractionData?.city?.name}
				/>
				{/* <AttractionInfoData label={"No of pax"} data={"-"} /> */}
				<AttractionInfoData
					label={backOffice.date}
					data={AttractionData?.operation_day?.day ?? "-"}
				/>
				<AttractionInfoData
					label={backOffice.Duration}
					data={`${AttractionData?.duration?.slice(
						0,
						2
					)}h ${AttractionData?.duration?.slice(3, 5)}min`}
				/>
				<AttractionInfoData
					label={booking.Duration}
					data={AttractionData.is_shared}
					status={true}
				/>
			</div>
			<AttractionDetails
				title={backOffice.description}
				data={AttractionData?.description}
			/>

			<div className="photos">
				<h6>{backOffice.Photos}</h6>

				<Carousel
					//ref={slides}
					//responsive={responsive}
					arrows={false}
					customButtonGroup={<ButtonGroup />}
					renderButtonGroupOutside={true}
					responsive={responsive}
					itemClass={"package-slide"}
					slidesToSlide={1}
					keyBoardControl={true}
					className="new-market-packages-countries"
					//rtl={locale === "ar"}
				>
					{AttractionData?.photos?.length > 0 &&
						AttractionData?.photos.map((photo, index) => (
							<div className="images">
								<img src={`${imageUrl}/${photo}`} alt="" />
							</div>
						))}
				</Carousel>
			</div>
			{AttractionData?.services?.length > 0 ? (
				<div className="included-services">
					<h6>{backOffice.IncludedServices}</h6>
					<ul>
						{AttractionData?.services?.length > 0 &&
							AttractionData?.services?.map((service, index) => (
								<li>{service}</li>
							))}
					</ul>
				</div>
			) : null}

			<AttractionDetails
				title={backOffice.Notes}
				data={AttractionData?.notes}
			/>
			<AttractionDetails
				title={requests.TermsConditions}
				data={AttractionData?.terms}
			/>
			{/* <AttractionDetails
				title="cancellation policy"
				data="Ideally located next to Al-Masjid an-Nabawi Square in the central
    southern district, Al-Andalus Palace Hotel offers rooms with air
    conditioning and satellite TV. The Prophets Biography Museum is just a
    4-minute walk away. All rooms in Al-Andalus Palace Hotel are equipped
    with the best and latest equipment for a high-level hotel stay and a
    unique experience. Guests can benefit from the convenient 24-hour
    front desk. We wish you a happy"
			/>
			<div className="cancleation-fees">
				<div>
					<h6>Cancelation fees </h6>
					<span>From : 23/11/2023 - 02:00 AM</span>
				</div>
				<p>50 SAR</p>
			</div> */}
			<div className="action-buttons ">
				<ShowForPermission permission="Manage-Attraction">
					<button className="btn btn-secondary px-4" onClick={toggle}>
						{backOffice.Share}
					</button>
					<button
						className="btn btn-primary px-4"
						onClick={() =>
							history.push(`/attractions/edit/${AttractionData.id}`)
						}
					>
						{backOffice.edit}
					</button>
				</ShowForPermission>
			</div>

			<Share
				isOpen={isOpen}
				toggle={toggle}
				state={shareState}
				setState={setShareState}
				shareAttraction={share}
				selectAttraction={AttractionData}
			/>
		</div>
	);
}
export function AttractionInfoData({ label, data, status = false }) {
	return (
		<div className="attraction-info">
			<p>{label} :</p>
			<span
				className={`${
					status ? (data ? "status-active" : "status-inactive") : ""
				}`}
			>
				{status === true ? (data ? "Active" : "Inactive") : data}
			</span>
		</div>
	);
}
export function AttractionDetails({ title, data }) {
	return data ? (
		<div className="details-box">
			<h6>{title}</h6>
			<p>{data}</p>
		</div>
	) : null;
}
