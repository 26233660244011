import { useEffect, useRef, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// Transaltion
import Locale from 'translations';
// React-notifications
import { store } from "react-notifications-component";
// Helper
import ShowForPermission from 'helpers/showForPermission';
// Icons
import { ExportIcon, LeftArrowIcon, PrintIcon } from 'components/Icons';
// Services
import { exportExcelDiscountHistory, getDiscountHistoryById } from 'services/safaVisa';
// Moment
import moment from 'moment';
// -----------------------------------------------------------------
export default function DiscountHistory() {

  // *** Hooks
  let history = useHistory();

  let { id } = useParams();

  const printRef = useRef()

  const { backOffice, safaVisa } = Locale;

  // // ** State
  const [discountHistory, setDiscountHistory] = useState({})

  // *** Function
  const fetchDiscountHistory = async () => {
    const response = await getDiscountHistoryById(id)
    if (response?.status === 200) {
      setDiscountHistory(response?.data)
    }
  }

  const exportExcel = async () => {
    const res = await exportExcelDiscountHistory(id);
    if (res?.status === 200 || res?.status === 201) {
      if (res.data) {
        const blob = new Blob([res.data], {
          type: 'xlsx',
        });
        const objectUrl = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `discount History-${moment().format("DD-MM-YYYY")}.xlsx`
        );
        anchor.click();
        // window.open(objectUrl);
      }
      store.addNotification({
        title: "Info!",
        message: `Export To Excel Successful`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  const handlePrint = () => window.print();

  useEffect(() => {
    fetchDiscountHistory()
  }, [])

  // --------- JSX Code --------
  return (
    <div className='safaVisa-wrapper ' ref={printRef}>

      {/*Discount History Header */}
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="main-title mb-0">{safaVisa.history}</h4>

        <div className='d-flex gap-2 align-items-center'>
          <ShowForPermission permission="Tourism-Company-Manage">
            <button
              className="btn export-btn py-2"
              onClick={exportExcel}
            >
              <ExportIcon color='#57A866' width='24' height='24' />
              {safaVisa.exportExcel}
            </button>
          </ShowForPermission>
          <ShowForPermission permission="Tourism-Company-Manage">
            <button
              className="btn  add-btn py-2"
              onClick={handlePrint}
            >
              <PrintIcon color='white' width='24' height='24' />
              {safaVisa.print}
            </button>
          </ShowForPermission>
        </div>
      </div>

      <hr />

      {/* Disacount History Info */}
      <div className='pricing-info-holder'>

        <div>
          <p className='mb-2 title'>{safaVisa?.package} :</p>
          <p className='subTitle'>{discountHistory?.title}</p>
        </div>


        <div id="code">
          <p className='mb-2 title '>{safaVisa?.code}</p>
          <p className='subTitle' style={{ color: '#1e85ff' }}>#{discountHistory?.id}</p>
        </div>

      </div>

      {/* Discount History Table */}
      <div className="our-border mt-5">

        <table className="custom-table back-office-table table table-striped mb-0">

          <thead>
            <tr>
              <th>{safaVisa.id}</th>
              <th> {safaVisa.date}</th>
              <th>{safaVisa.user}</th>
              <th>{safaVisa.action}</th>
            </tr>
          </thead>

          <tbody>
            {discountHistory?.data?.length > 0 ? (
              discountHistory?.data?.map((row) =>
                <tr key={row?.id}>
                  <td><p className='id-text'> {row?.id} </p></td>
                  <td>{row?.created_at}</td>
                  <td> <span > {row?.causer?.username}</span></td>
                  <td > {safaVisa[row?.action]}</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="15">
                  <div className="product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div >

      <button
        className="btn back-btn py-2 mt-5"
        onClick={() => history.push("/safaVisa-discount")}
      >
        <span className='mdi-rotate-180'>
          <LeftArrowIcon color='#1e85ff' width='24' height='24' />
        </span>
        {safaVisa.back}
      </button>

    </div >
  )
}

