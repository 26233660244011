import { Box, Button, Collapse, Typography } from "@material-ui/core";
import { useState } from "react";
import Locale from "translations";
import imagePlaceHolder from "../../../../../../assets/images/image_placeholder.png";
import { BedIcon } from "../../Icons";
import CancellationPolicies from "./CancellationPolicies";
import ShowRoomDetails from "./ShowRoomDetails";

function RoomDetails({ room, onSelectRoom, viewSelcetedRoom, hotelType }) {
	const [showDetailsCollapse, setShowDetailsCollapse] = useState(false);
	const [cancelationCollapse, setCancelationCollapse] = useState(false);
	const handelCancelationCollapse = (index) => {
		setCancelationCollapse((prev) => !prev);
	};

	const handelShowDetailsCollapse = () => {
		setShowDetailsCollapse((current) => !current);
	};
	const { backOffice } = Locale;
	return (
		<div className={`${viewSelcetedRoom ? "" : "mt-4"}`}>
			<div className={`room_details_container`}>
				<Box
					style={{
						marginRight: 16,
						"@media(min-width: 320px )and (max-width: 480px)": {
							display: "none !important",
						},
					}}
				>
					<img
						className="rounded-left"
						src={room.images[0]?.url || imagePlaceHolder?.src}
						alt={room.name}
						width={72}
						height={53}
					/>
				</Box>

				<div className={`room_details_container flex-wrap`}>
					<div className={`room_title`}>
						<h5 className="mb-0 fw-bold">{room.name}</h5>
						<p className={`room_Sub_title`}>
							{backOffice.KidUnderYearsStaysFree}
						</p>
					</div>

					<Button
						size="small"
						className="text-info"
						endIcon={<i class="fas fa-chevron-down text-info" />}
						onClick={handelShowDetailsCollapse}
					>
						{backOffice.ShowDetails}
					</Button>

					<Collapse in={showDetailsCollapse} timeout="auto" className="w-100">
						<ShowRoomDetails room={room} />
					</Collapse>

					<div key={room.roomConfigurationId} className={`select_room mb-3`}>
						<Typography variant="caption" className="d-flex align-items-center">
							<BedIcon />
							<p className="m-0 p-1">{room.boardTypes[0]?.type}</p>
						</Typography>
						<Button
							size="small"
							style={{ fontSize: 12, color: "#018128" }}
							endIcon={<i class="fas fa-chevron-down" />}
							onClick={() => handelCancelationCollapse()}
						>
							{backOffice.CancelationPolicies}
						</Button>

						<Collapse
							in={cancelationCollapse}
							timeout="auto"
							className="w-100"
							unmountOnExit
						>
							<CancellationPolicies
								cancellationPolicy={room.cancellationPolicy}
							/>
						</Collapse>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RoomDetails;
