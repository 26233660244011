import { useState } from 'react';
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Icons
import { TrashIcon } from 'components/Icons';
// Module Components
import ConfirmModal from '../../shared/ConfirmModal';
// Custom Hook
import AddPackageModal from './AddPackageModal';
// --------------------------------------------------------------------
export default function TargetCampaignsPackages(props) {
  const {
    targetCampaignData,
    setTargetCampaignData,
    packagesPriceOptions,
  } = props

  // *** Hooks
  const { backOffice, safaVisa } = Locale;


  // *** State
  const [packageInfo, setPackageInfo] = useState({
    package_id: null
  });

  const [isOpen, setIsOpen] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);


  // *** Function
  const toggle = (data) => {
    setIsOpen((prev) => !prev);
    setPackageInfo({ ...data });
  };

  const handleSavePackage = () => {
    let dataClone = { ...targetCampaignData }
    // to handle if selected any package from DropDown
    const existedItem = dataClone?.pricing_packages_id?.find(item => item?.id === packageInfo?.package_id?.id)
    if (existedItem) {
      store.addNotification({
        message: safaVisa?.itemAlreadyExist,
        title: safaVisa?.itemAlreadyExist,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    if (!existedItem) {
      dataClone['pricing_packages_id'].push(packageInfo.package_id)
      setTargetCampaignData(dataClone)
    }
    toggle(null)
  }

  const handleToggleConfirmModal = (data) => {
    setIsOpenConfirm(!isOpenConfirm)
    setPackageInfo({ ...data })
  }

  const handleConfirmAction = () => {
    let pricingDataClone = { ...targetCampaignData }
    setTargetCampaignData({
      ...pricingDataClone,
      pricing_packages_id: pricingDataClone['pricing_packages_id']?.filter(item => item?.id !== packageInfo?.id)
    })
    handleToggleConfirmModal(null)
  }

  const handleCancelAction = () => handleToggleConfirmModal()

  // -------- JSX Code --------
  return (
    <>
      <div className="col-12 d-flex justify-content-between align-items-center gap-10 mt-5">
        <h4 className="main-title mb-0">{safaVisa.packages}</h4>
        <button
          disabled={!targetCampaignData?.countries_id?.length}
          className="btn  add-btn py-2"
          onClick={() => setIsOpen(true)}
        >
          <i className="fas fa-plus text-white fa-md"></i>{" "}
          {safaVisa.add}
        </button>
      </div>

      {/* Table */}
      <div className="our-border table-responsive  mt-4">
        <table className="custom-table back-office-table table table-striped mb-0">

          <thead>
            <tr>
              <th>{safaVisa.id}</th>
              <th> {safaVisa.title}</th>
              <th>{safaVisa.currency}</th>
              <th>{safaVisa.audience}</th>
              <th>{safaVisa.visasCount}</th>
              <th>{safaVisa.actions}</th>
            </tr>
          </thead>

          <tbody>
            {targetCampaignData?.pricing_packages_id?.length > 0 ? (
              targetCampaignData?.pricing_packages_id?.map((row) =>
                <tr>

                  <td><p className='id-text'> {row?.id} </p></td>

                  <td> {row?.title}  </td>

                  <td>{row?.currency}</td>

                  <td> {safaVisa[row?.audience_type]} </td>

                  <td> {row?.visa_issued} </td>

                  <td >
                    <div className='d-flex justify-content-start gap-2 align-items-center'>
                      <span className='pointer' onClick={() => handleToggleConfirmModal(row)} >
                        <TrashIcon color='#EA5455' />
                      </span>
                    </div>
                  </td>

                </tr>
              )
            ) : (
              <tr>
                <td colSpan="15">
                  <div className="product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div >

      <AddPackageModal
        isOpen={isOpen}
        toggle={toggle}
        packagesPriceOptions={packagesPriceOptions}
        packageInfo={packageInfo}
        setPackageInfo={setPackageInfo}
        onSave={handleSavePackage}
      />

      <ConfirmModal
        isOpen={isOpenConfirm}
        toggle={handleToggleConfirmModal}
        confirmBtnText={safaVisa?.confirm}
        messageText={safaVisa?.cannotGoBack}
        icon={<TrashIcon color='#EA5455' />}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </>
  )
}

