import moment from "moment";
import { useEffect, useState } from "react";
import { listB2cReservations } from "services/b2c";
import Locale from "translations";
import ReservationsList from "../ReservationsList";
export default function AllBookings() {
	const { backOffice } = Locale;
	const [bookings, setBookings] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filters, setFilter] = useState({
		search: "",
		trip_type: null,
		visa_status: null,
		booking_date: null,
		departure_date: null,
	});
	useEffect(() => {
		const fetchList = async () => {
			const res = await listB2cReservations({
				...filters,
				trip_type: filters.trip_type?.value,
				visa_status: filters.visa_status?.value,
				booking_date: filters.booking_date
					? moment(filters.booking_date).format("YYYY-MM-DD")
					: null,
				departure_date: filters.departure_date
					? moment(filters.departure_date).format("YYYY-MM-DD")
					: null,
			});
			if (res?.status === 200) {
				setBookings(res?.data?.data);
				setMeta(res?.data?.meta);
			}
		};
		fetchList();
	}, [filters]);

	return (
		<>
			<h5>{backOffice.AllBookings}</h5>
			<ReservationsList
				filters={filters}
				meta={meta}
				reservations={bookings}
				setFilter={setFilter}
			/>
		</>
	);
}
