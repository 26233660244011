import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function CommercialDistAirLineFilters({ filters, setFilters }) {
	const { backOffice, vendor } = Locale;

	function resetFilters() {
		setFilters({
			search: "",
			booking_date: "",
			travel_date: "",
			status: "",
			page: 1,
		});
	}

	const STATUS = [
		{ value: 1, label: "pending" },
		// { value: 2, label: "rejected" },
		// { value: 4, label: "canceled" },
		// { value: 6, label: "confirmed" },
		// { value: 7, label: "expired" },
		{ value: 8, label: "issued" },
	];

	return (
		<div className="main-filter bg-white-blue py-2 my-2">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col-3">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						hasLabel={true}
						value={filters.search}
						onChange={(e) => {
							setFilters({
								...filters,
								search: e.target.value,
							});
						}}
					/>
				</div>
				{/* booking date */}
				<div className="col-2">
					<DatePickerField
						hasLabel={true}
						label={backOffice.bookingDate}
						date={filters.booking_date}
						placeholder={backOffice.date}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								booking_date: filterDate,
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>
				{/* travel date */}
				<div className="col-2">
					<DatePickerField
						hasLabel={true}
						label={backOffice.travelDate}
						date={filters.travel_date}
						placeholder={backOffice.date}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								travel_date: filterDate,
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>
				{/* status */}
				<div className="col-2">
					<SelectField
						hasLabel={true}
						label={backOffice.status}
						value={filters.status}
						placeholder={backOffice.status}
						name="status"
						options={STATUS}
						onChange={(e) => {
							setFilters({
								...filters,
								status: e,
							});
						}}
					/>
				</div>

				<div className="col-md">
					<div
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
						onClick={resetFilters}
					>
						<img src={resetIcon} alt="reset filters" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
