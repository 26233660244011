import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useState } from "react";
import Locale from "translations";
const CommissionModelAdd = () => {
	const { backOffice } = Locale;

	const [isOpen, setIsOpen] = useState(false);
	const Selectors = [
		{
			value: 1,
			label: "Country",
		},
		{
			value: 2,
			label: "Season",
		},
		{
			value: 3,
			label: "Hotel Level",
		},
	];

	const SelectorsWithoutHotel = [
		{
			value: 1,
			label: "Country",
		},
		{
			value: 2,
			label: "Season",
		},
	];

	const HotelLevelCommission = [
		{
			value: 0,
			label: "All",
		},
		{
			value: 1,
			label: "1",
		},
		{
			value: 2,
			label: "2",
		},
		{
			value: 3,
			label: "3",
		},
		{
			value: 4,
			label: "4",
		},
		{
			value: 5,
			label: "5",
		},
	];

	const applyCommission = [
		{
			value: "add",
			label: "Add",
		},
		{
			value: "deduct",
			label: "Deduct",
		},
	];

	const reservationType = [
		{
			value: "Umrah Planner",
			label: "Umrah Planner",
		},
	];

	const ItemTypes = [
		{
			value: "Hotel",
			label: "Hotel",
		},
		{
			value: "Transportation",
			label: "Transportation",
		},
		{
			value: "Ground Service",
			label: "Ground Service",
		},
	];

	const [selectedReservationType, setSelectedReservationType] = useState(false);

	return (
		<>
			<div className="w-100">
				<div className="row custom--row">
					<div className="col-md-4">
						<TextField
							type="text"
							label={backOffice.commissionName}
							placeholder={backOffice.commissionName}
							name="commission_name"
							/* 						value={commissionState.platformName}
							 */ onChange={(e) => {
								/* 	setCommissionState({
								...commissionState,
								platformName: e.target.value,
							}); */
							}}
						/>
					</div>

					<div className="col-md-4">
						<SelectField
							label={backOffice.reservationType}
							placeholder={backOffice.select + " " + backOffice.reservationType}
							options={reservationType}
							name="reservation_type"
							/* 						value={ReservationType}
						onChange={(e) => {
							handleChange({ name: "reservation_type", value: e });
							setCommissionState({
								...commissionState,
								reservationType: e,
							});
							setReservationType(e);
							setSelectedReservationType(e.value === "trans" ? true : false);
						}} */
						/>
					</div>
					<div className="col-md-4">
						<SelectField
							label={"ItemType"}
							placeholder={"Item Type"}
							options={ItemTypes}
							name="Item Type"
							/* 						value={ReservationType}
						onChange={(e) => {
							handleChange({ name: "reservation_type", value: e });
							setCommissionState({
								...commissionState,
								reservationType: e,
							});
							setReservationType(e);
							setSelectedReservationType(e.value === "trans" ? true : false);
						}} */
						/>
					</div>
				</div>
				<div className="row custom--row">
					<div className="col-md-12">
						<SelectField
							multi
							label={backOffice.selectors}
							placeholder={backOffice.select + " " + backOffice.selectors}
							options={
								selectedReservationType ? SelectorsWithoutHotel : Selectors
							}
							name="selectors"
							/* 	value={commissionState.selectors}
						onChange={(e) => {
							setIsOpen(true);
							setCommissionState({
								...commissionState,
								selectors: e,
							});
							setSelectedOptions(e);
							//selectedOptions.push(e)
						}} */
						/>
					</div>
				</div>
				{/* 
			{isOpen == true && selectedOptions?.length > 0 ? (
				<div
					className="row custom--row p-2"
					id="selectorsConfig"
					style={{ backgroundColor: "#EDF2F7" }}
				>
					<div className="col-12 text-center gray h5 mb-0">
						{backOffice.selectorsConfiguration}
					</div>
					{selectedOptions.find((item) => item.label === "Country") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.country}
								placeholder={backOffice.select + " " + backOffice.country}
								options={CountriesWithALL}
								name="countries"
								value={Countries}
								onChange={(e) => {
									handleChange({ name: "country", value: e });
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Season") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.season}
								placeholder={backOffice.select + " " + backOffice.season}
								options={season}
								value={Seasons}
								name="season"
								onChange={(e) => {
									handleChange({ name: "season", value: e });
									//setSeasons(e.value)
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setSeasons(arr);
										} else {
											setSeasons(e);
										}
									} else {
										setSeasons(e);
									}
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Hotel Level") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.hotelLevel}
								placeholder={backOffice.select + " " + backOffice.hotelLevel}
								options={HotelLevelCommission}
								name="hotelLevel"
								value={HotelLevel}
								onChange={(e) => {
									handleChange({ name: "hotelLevel", value: e });
									//setHotelLevel(e.value);
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setHotelLevel(arr);
										} else {
											setHotelLevel(e);
										}
									} else {
										setHotelLevel(e);
									}
								}}
							/>
						</div>
					) : null}
					{selectedOptions.find((item) => item.label === "Company") ? (
						<div className="col-12">
							<SelectField
								multi
								label={backOffice.company}
								placeholder={backOffice.select + " " + backOffice.company}
								options={Company}
								name="company"
								value={Companies}
								onChange={(e) => {
									handleChange({ name: "company", value: e });
									//setHotelLevel(e.value);
									if (e !== null) {
										let arr = [];
										if (e.find((item) => item.value === 0)) {
											arr.push(e.find((item) => item.value === 0));
											setCompanies(arr);
										} else {
											setCompanies(e);
										}
									} else {
										setCompanies(e);
									}
								}}
							/>
						</div>
					) : null}
				</div>
			) : null} */}
				<div className="row custom--row">
					<div className="col-md-4">
						<SelectField
							label={backOffice.applyCommission}
							placeholder={backOffice.select + " " + backOffice.applyCommission}
							options={applyCommission}
							name="apply_commission"
							/* 	value={ApplyCommission}
						onChange={(e) => {
							handleChange({ name: "apply_commission", value: e });
							setCommissionState({
								...commissionState,
								applyCommission: e,
							});
							setApplyCommission(e);
						}} */
						/>
					</div>
				</div>

				<div className="row custom--row">
					<div className="col-md-4">
						<TextField
							type="number"
							label={backOffice.baseCommission}
							placeholder={backOffice.baseCommission}
							name="base_commission"
							/* value={commissionState.baseCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								baseCommission: e.target.value,
							});
						}} */
							extraTextPosition="append"
							/* 	extraText={selectedCommissionType}
						color={commissionState.baseCommission > 100 ? "danger" : ""} */
						/>
						{/* 	{commissionState.baseCommission > 100 ? (
						<p className="text-danger">*Must be equal or less than 100</p>
					) : null} */}
					</div>

					<div className="col-md-4">
						<TextField
							type="number"
							label={backOffice.safaCommission}
							placeholder={backOffice.safaCommission}
							name="safa_commission"
							/* 						value={commissionState.safaCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								safaCommission: e.target.value,
							});
						}}
						color={PercentageError ? "danger" : ""} */
							extraTextPosition="append"
							extraText="%"
						/>
					</div>

					<div className="col-md-4">
						<TextField
							type="number"
							label={backOffice.supplierCommission}
							placeholder={backOffice.supplierCommission}
							name="Supplier Commission"
							/* 	value={commissionState.platformCommission}
						onChange={(e) => {
							setCommissionState({
								...commissionState,
								platformCommission: e.target.value,
							});
						}}
						color={PercentageError ? "danger" : ""} */
							extraTextPosition="append"
							extraText="%"
						/>
					</div>
				</div>
				{/* 			{PercentageError ? (
				<p className="text-danger">
					SAFA COMMISSION + PLATFORM COMMISSION Must equal 100
				</p>
			) : null} */}
				<div className="row custom--row mt-2 flex-row-reverse">
					<div className="col-md-3">
						{/* <button
					
							className="btn btn-sbs-primary w-100 mb-4"
						
						>
							{backOffice.add}
						</button> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default CommissionModelAdd;
