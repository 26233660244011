import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function CommercialDistributionFilter({
	filters,
	handleFiltersInputs,
	resetFilters,
}) {
	const { backOffice, teamManagement } = Locale;
	const { allCountries, locale } = useGlobalState();
	const [countries, setCountries] = useState([]);

	const servicesLookup = [
		{
			value: 2,
			label: "hotels",
		},
		{
			value: 3,
			label: "flight",
		},
		{
			value: 4,
			label: "transfers",
		},
		{
			value: 5,
			label: "tours",
		},
	];

	useEffect(() => {
		function countriesLookups() {
			const format = allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries, locale]);

	return (
		<div className="main-filter bg-white-blue py-2 mb-2">
			<div className="row align-items-center w-100 no-gutter m-0">
				{/* search text */}
				<div className="col-2">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						hasLabel={true}
						value={filters?.search}
						onChange={(e) => handleFiltersInputs(e.target.value, "search")}
					/>
				</div>
				{/* country */}
				<div className="col-2">
					<SelectField
						placeholder={backOffice.country}
						label={backOffice.country}
						name="country"
						options={countries}
						value={filters?.country}
						onChange={(e) => handleFiltersInputs(e, "country")}
					/>
				</div>
				{/* contact from  */}
				<div className="col-2">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={teamManagement.ContractFrom}
						name="contractFrom"
						onOutsideClick={false}
						date={filters?.contract_start}
						onChangeDate={(e) => handleFiltersInputs(e, "contract_start")}
					/>
				</div>
				{/* contact to  */}
				<div className="col-2">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={teamManagement.ContractTo}
						name="contractTo"
						onOutsideClick={false}
						date={filters?.contract_end}
						onChangeDate={(e) => handleFiltersInputs(e, "contract_end")}
					/>
				</div>
				{/* servises  */}
				<div className="col-2">
					<SelectField
						placeholder={backOffice.select}
						label={backOffice.services}
						name="services"
						options={servicesLookup}
						value={filters?.services}
						onChange={(e) => handleFiltersInputs(e, "services")}
					/>
				</div>

				<div className="col-md">
					<button
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn bg-transparent"
						onClick={resetFilters}
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</button>
				</div>
			</div>
		</div>
	);
}
