import moment from "moment/moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	exportToExcelManualSupplier,
	fetchreservations,
} from "services/manualSupplier";
import Locale from "translations";
import FilterReservation from "./FilterReserviton";
import ReservationTable from "./ReservationTable";
function ListReservation() {
	const [Reservation, setReservation] = useState([]);
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [meta, setmeta] = useState([]);
	const { name, id } = useParams();
	const { backOffice } = Locale;
	const [filters, setFilters] = useState({
		hotel_name: "",
		// checkin: "",
		date_from_start: undefined,
		date_from_end: undefined,
		destination_id: "",
		status: "",
	});
	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				hotel_name: filters.hotel_name,
				destination_id: filters.destination_id?.value,
				date_from_start: filters?.date_from_start
					? moment(filters?.date_from_start).format("YYYY-MM-DD")
					: "",
				date_from_end: filters?.date_from_end
					? moment(filters?.date_from_end).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await fetchreservations(id, data, pages);
			setReservation(response?.data.data);
			setmeta(response?.data?.meta);
		}
		fetchReservationsFilterdData();
	}, [
		filters.hotel_name,
		filters.date_from_start,
		filters.date_from_end,
		filters.destination_id,
		filters.status,
		pages,
	]);

	const goto = (page) => {
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};
	const exportToFile = async () => {
		const res = await exportToExcelManualSupplier(id, {
			hotel_name: filters.hotel_name,
			destination_id: filters.destination_id?.value,
			date_from_start: filters?.date_from_start
				? moment(filters?.date_from_start).format("YYYY-MM-DD")
				: "",
			date_from_end: filters?.date_from_end
				? moment(filters?.date_from_end).format("YYYY-MM-DD")
				: "",
			status: filters.status?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`manual-suppliers-reservations-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="main-title">
					{backOffice.reservations}{" "}
					<span className="tentative">{`(${name})`}</span>
				</h6>
				<button className="btn btn-primary" onClick={() => exportToFile()}>
					{backOffice.ExportToExcel}
				</button>
			</div>

			<FilterReservation setFilters={setFilters} filters={filters} />
			<ReservationTable
				Reservation={Reservation}
				setReservation={setReservation}
				meta={meta}
				goto={goto}
			/>
		</>
	);
}
export default ListReservation;
