import Locale from "translations";
import Branch from "./Branch";
export default function ListBranches({ branches, toggleEdit, removeBranch }) {
	const { backOffice } = Locale;
	const allBranches =
		branches.length > 0 ? (
			branches.map((branch) => (
				<Branch
					branch={branch}
					key={branch.id}
					toggleEdit={toggleEdit}
					removeBranch={removeBranch}
				/>
			))
		) : (
			<h2>{backOffice.NoBranches}</h2>
		);

	return <div>{allBranches}</div>;
}
