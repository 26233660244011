import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchOfflineSuppler } from "services/manualSupplier";
import Locale from "translations";
import OfflineFilter from "./OfflineFilter";
import OfflineTable from "./offlineSupplierTable";
const ManualSuppliers = () => {
	const { backOffice } = Locale;
	const history = useHistory();
	const [properties, setProperties] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filterState, setFilterState] = useState({
		search: "",
		country: "",
		city: [],
		page: 1,
	});

	async function fetchData() {
		const data = {
			name: filterState.search,
			country_id: filterState.country?.value,
			city_id: filterState.city?.value,
		};

		const res = await fetchOfflineSuppler(data, filterState?.page);
		setProperties(res?.data.data);
		setMeta(res.data.meta);
	}
	useEffect(() => {
		fetchData();
	}, [filterState]);

	const goTo = (pagenum) => {
		setFilterState({ ...filterState, page: pagenum });
	};

	return (
		<div className="manual-suppliers">
			<div className="d-flex justify-content-between">
				<h6>{backOffice.ManualSuppliers}</h6>
				<ShowForPermission permission="Manual-Suppliers-Manage">
					<button
						className="btn btn-primary"
						onClick={() => history.push("/manual-suppliers/add")}
					>
						{backOffice.AddNewSupplier}
					</button>
				</ShowForPermission>
			</div>
			<div className="container-fluid">
				<OfflineFilter
					filterState={filterState}
					setFilterState={setFilterState}
				/>
				<OfflineTable
					properties={properties}
					setProperties={setProperties}
					goTo={goTo}
					meta={meta}
				/>
			</div>
		</div>
	);
};

export default ManualSuppliers;
