import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
import { UploadImage } from "modules/backOffice/Components/Attractions/Icons/uploadImage";
import moment from "moment";
import { useEffect } from "react";
import { isInclusivelyBeforeDay } from "react-dates";
import { MultiSelect } from "react-multi-select-component";
import { uploadFile } from "services/auth";
import Locale from "translations";
import WarningModal from "../../share/warningModal";

const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
export default function TransportationRow({
	index,
	country: countryBase,
	visa,
	handleDelete,
	destination,
	setDestination,
	indexConfiguration,
	setviewConfiguration,
	viewConfiguration,
	setIndexConfiguration,
	inputsState,
	setInputsState,
	arrayCountry,
	setArrayCountry,
	configData,
	allConfigData,
	visaLabel,
	setVisaRowState,
	VisaRowState,
	visaIndexState,
	countries,
	currencies,
	CycleTypes,
	vehicleType,
	vehicleModel,
	alertToggle,
	setAlertToggle,
	addToggle,
	listSuppliers,
}) {
	const { backOffice, booking } = Locale;

	// const add = () => {
	// 	setInputsStates([...inputsStates, {}]);
	// };
	const addEdit = (ind) => {
		let newData = viewConfiguration;

		let counrtyX = newData.filter((res) =>
			res.country
				? res.country == countryBase
				: res.destination?.name == countryBase
		)[0];

		counrtyX.residences.push({
			residence: "",
			currency: "",
		});
		setviewConfiguration([...newData]);
	};

	const handleChenageinArray = (name, value, index) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) =>
			res.country
				? res.country == countryBase
				: res.destination?.name == countryBase
		)[0];
		counrtyX.residences[index][name] = value;
		setviewConfiguration([...newData]);
	};

	//Fetch currencies

	useEffect(() => {
		if (!arrayCountry.includes(countryBase?.value)) {
			setArrayCountry([...arrayCountry, countryBase?.value]);
		}
	}, []);

	const deleteVise = (ind, indexs) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) =>
			res.country
				? res.country == countryBase
				: res.destination?.name == countryBase
		)[0];
		counrtyX.residences = counrtyX.residences.filter(
			(res, index) => index != ind
		);
		if (counrtyX.residences.length == 0) {
			newData = newData.filter((res) => res.destination?.name != countryBase);
		}
		setviewConfiguration([...newData]);
	};
	const handleCopy = (index, ind) => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) =>
				res.destination?.name == countryBase || res.country == countryBase
		)[0];
		let configureRowCopied = selectedConfig?.residences;

		configureRowCopied?.push({
			...configureRowCopied[ind],
			id: configureRowCopied?.length + 1,
		});
		setviewConfiguration([...allConfigurationClone]);
	};
	console.log("viewConfigurationTransportation", viewConfiguration);

	useEffect(() => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) =>
				res.country == countryBase || res?.destination?.name == countryBase
		);
		console.log(selectedConfig);

		if (selectedConfig?.length > 1) {
			setAlertToggle(true);
			selectedConfig = selectedConfig[0];
			allConfigurationClone.pop(selectedConfig[1]);
			console.log(allConfigurationClone);
		}
		setviewConfiguration([...allConfigurationClone]);
	}, [addToggle]);

	const valueRenderer = (selected) => {
		if (!selected.length) {
			return "No Item Selected";
		}
		return "Item Selected  " + selected.length;
	};

	const handleUpload = async (e, index) => {
		const files = e.target.files[0];
		let formData = new FormData();
		formData.append("file", files);
		const uploadedPhotoRes =
			e.target.files.length > 0 && formData !== undefined
				? await uploadFile(formData)
				: null;
		if (uploadedPhotoRes?.status === 200) {
			handleChenageinArray("photo", uploadedPhotoRes.data.uuid, index);
		}
	};

	const transportationPermissionManage = useShowPermission({
		permission: "Transportation-Configuration-Manage",
	});
	return (
		<>
			{!visa && inputsState && inputsState?.length > 0
				? inputsState.map((inputsStateData, ind) => {
						return (
							<div className="visa-row">
								<div className="col-md-12 m-auto">
									<div className="row visa-row-content flex-wrap bg-gray-white-100 px-3">
										<>
											<div className="col-md-2 mt-3">
												<span className="px-2">{backOffice.residence}</span>
												<div className="w-100 px-2">
													<MultiSelect
														isDisabled={!transportationPermissionManage}
														label={backOffice.residence}
														placeholder={backOffice.residence}
														options={countries.slice(1)}
														id="residences"
														name="residences"
														isMulti
														valueRenderer={valueRenderer}
														onChange={(e) => {
															if (e == null || e.length === 0) {
																handleChenageinArray("residences", null, ind);
																return;
															}
															if (
																e &&
																e.length > 0 &&
																e.filter((res) => res.label === "All").length >
																	0
															) {
																handleChenageinArray(
																	"residences",
																	countries.slice(1),
																	ind
																);
															} else {
																handleChenageinArray("residences", e, ind);
															}
														}}
														value={
															inputsStateData?.residences == null
																? []
																: inputsStateData?.residences?.length === 0
																? { label: "All", value: 0 }
																: inputsStateData?.residences?.length > 0 &&
																  inputsStateData?.residences[0]?.country_id
																? inputsStateData?.residences?.map((item) => {
																		return {
																			value: item.id,
																			label: item.name,
																		};
																  })
																: inputsStateData?.residences?.map((item) => {
																		return {
																			value: item?.id || item?.value,
																			label: item?.label,
																		};
																  })
														}
													/>
												</div>
											</div>

											<div className="col-md-2">
												<TextField
													disabled={!transportationPermissionManage}
													type="text"
													label={backOffice.price}
													placeholder={backOffice.price}
													name="price"
													onChange={(e) => {
														handleChenageinArray(
															"price",
															e.target.value,
															ind,
															index
														);
													}}
													value={inputsStateData?.price}
												/>
											</div>
											<div className="col-md-2">
												<SelectField
													disabled={!transportationPermissionManage}
													label={backOffice.currency}
													placeholder={backOffice.currency}
													options={currencies}
													id="basic-countries"
													name="currency"
													onChange={(e) => {
														handleChenageinArray(
															"currency",
															e,
															ind,
															index,
															"id",
															"currency_code"
														);
													}}
													value={
														!inputsStateData?.currency?.hasOwnProperty("label")
															? {
																	value: inputsStateData?.currency_code,
																	label: inputsStateData?.currency_code,
															  }
															: inputsStateData?.currency
													}
												/>
											</div>

											<div className="col-md-2">
												<SelectField
													disabled={!transportationPermissionManage}
													label={backOffice.CycleTypes}
													placeholder={backOffice.CycleTypes}
													options={CycleTypes}
													id="basic-countries"
													name="cycle_type"
													onChange={(e) => {
														handleChenageinArray(
															"cycle_type",
															e,
															ind,
															index,
															"id",
															"cycle_type_id"
														);
													}}
													// value={
													// 	!inputsStateData?.cycleTypes
													// 		? {
													// 				value: inputsStateData?.cycle_type?.id,
													// 				label: inputsStateData?.cycle_type?.name,
													// 		  }
													// 		: inputsStateData.cycleTypes
													// }
													value={
														inputsStateData?.cycle_type?.hasOwnProperty("id")
															? {
																	value: inputsStateData?.cycle_type?.id,
																	label: inputsStateData?.cycle_type?.name,
															  }
															: inputsStateData?.cycle_type
													}
												/>
											</div>
											{/*******Start New Fields *********** */}
											<div className="col-md-2">
												<TextField
													disabled={!transportationPermissionManage}
													type="number"
													label={backOffice.SeatsCount}
													placeholder={"Seats Count"}
													name="seats_count"
													onChange={(e) => {
														handleChenageinArray(
															"seats_count",
															e.target.value,
															ind,
															index
														);
													}}
													value={inputsStateData?.seats_count}
												/>
											</div>

											<div className=" col-md-2">
												<SelectField
													disabled={!transportationPermissionManage}
													label={backOffice.VehicleType}
													placeholder={backOffice.VehicleType}
													options={vehicleType}
													name="vehicle_type"
													onChange={(e) => {
														handleChenageinArray("vehicle_type", e, ind, index);
													}}
													value={
														inputsStateData?.vehicle_type?.hasOwnProperty("id")
															? {
																	value: inputsStateData?.vehicle_type?.id,
																	label: inputsStateData?.vehicle_type?.name,
															  }
															: inputsStateData?.vehicle_type
													}
												/>
											</div>
											<div className=" col-md-2">
												<SelectField
													disabled={!transportationPermissionManage}
													label={backOffice.VehicleModel}
													placeholder={backOffice.VehicleModel}
													options={vehicleModel}
													name="vehicle_model"
													onChange={(e) => {
														handleChenageinArray(
															"vehicle_model",
															e,
															ind,
															index
														);
													}}
													value={
														inputsStateData?.vehicle_model?.hasOwnProperty("id")
															? {
																	value: inputsStateData?.vehicle_model?.id,
																	label: inputsStateData?.vehicle_model?.name,
															  }
															: inputsStateData?.vehicle_model
													}
												/>
											</div>
											<div className="col-md-2  dataPicker-configRow">
												<DatePickerField
													disabled={!transportationPermissionManage}
													hasLabel={true}
													label={booking.StartDate}
													placeholder={booking.StartDate}
													date={
														inputsStateData?.start_date
															? moment(inputsStateData?.start_date)
															: inputsStateData?.start_date
													}
													name="start_date"
													onChangeDate={(e) => {
														handleChenageinArray("start_date", e, ind, index);
													}}
													isOutsideRange={(day) =>
														day.isBefore(moment().add(-1, "days"))
													}
												/>
											</div>
											<div className="col-md-2 dataPicker-configRow">
												<DatePickerField
													disabled={!transportationPermissionManage}
													hasLabel={true}
													label={booking.EndDate}
													placeholder={booking.EndDate}
													date={
														inputsStateData?.end_date
															? moment(inputsStateData?.end_date)
															: inputsStateData?.end_date
													}
													name="end_date"
													onChangeDate={(e) => {
														handleChenageinArray("end_date", e, ind, index);
													}}
													isOutsideRange={(day) =>
														isInclusivelyBeforeDay(day, moment())
													}
												/>
											</div>
											<div className="col-md-2">
												<TextField
													disabled={!transportationPermissionManage}
													type="number"
													label={booking.ExpireDays}
													placeholder={booking.ExpireDays}
													name="expiration_days"
													onChange={(e) => {
														handleChenageinArray(
															"expiration_days",
															e.target.value,
															ind,
															index
														);
													}}
													value={inputsStateData?.expiration_days}
													color={
														inputsStateData?.expiration_days <= 0 &&
														inputsStateData?.expiration_days != ""
															? "danger"
															: ""
													}
												/>
												{inputsStateData?.expiration_days <= 0 &&
												inputsStateData?.expiration_days != "" ? (
													<span className="text-danger">
														{backOffice.ExpireDaysMustBePositiveNumber}
													</span>
												) : (
													""
												)}
											</div>

											<div className="col-md-2">
												<SelectField
													disabled={!transportationPermissionManage}
													label={backOffice.supplier}
													placeholder={backOffice.supplier}
													options={listSuppliers}
													id="supplier"
													name="supplier"
													onChange={(e) => {
														handleChenageinArray("supplier", e, ind);
													}}
													value={
														!inputsStateData?.supplier?.hasOwnProperty("label")
															? {
																	value: inputsStateData?.supplier?.id,
																	label: inputsStateData?.supplier?.name,
															  }
															: inputsStateData?.supplier
													}
												/>
											</div>

											<div className="col-md-2 mt-5 ">
												<input
													disabled={!transportationPermissionManage}
													className="form-check-input  visa-include-ground-checkbox"
													type="checkbox"
													id="include_ground"
													//name="include_ground"
													onChange={(e) => {
														handleChenageinArray(
															"include_ground",
															e.target.checked,
															ind,
															index
														);
													}}
													checked={
														inputsStateData.include_ground == 1 ? true : false
													}
													style={{ fontSize: "21px" }}
												/>
												<label
													class={`form-check-label pointer mx-2 mt-1 ${
														inputsStateData.include_ground != 1
															? "txt-gray"
															: ""
													}`}
													//for="include_ground"
												>
													{backOffice.IncludeGroundService}
												</label>
											</div>

											<div className="upload-images col-12">
												<label
													//onChange={handleUpload}
													htmlFor="uploadFile"
													className=" text-black px-4 d-flex flex-column align-items-center"
													style={{ width: "fit-content" }}
												>
													{!inputsStateData?.photo && <UploadImage />}
													{inputsStateData?.photo ? (
														<img
															src={fetchFilesURL + "/" + inputsStateData?.photo}
															alt=""
															width="200px"
														/>
													) : (
														"Upload Photo"
													)}
												</label>
												<input
													multiple="multiple"
													onChange={(e) => {
														handleUpload(e, ind);
													}}
													type="file"
													id="uploadFile"
													accept="image/*"
													name="photos"
													style={{ width: "fit-content" }}
												/>
											</div>

											{/*******End New Fields *********** */}
											<ShowForPermission permission="Transportation-Configuration-Manage">
												<div className="col-md-2 d-flex justify-content-end align-items-center mt-4">
													<button
														className="btn copyIcon-configRow"
														onClick={() => handleCopy(index, ind)}
													>
														<i class="fas fa-copy"></i> {backOffice.Duplicate}
													</button>
													<a
														className="btn-link text-danger mx-1 "
														onClick={() => {
															deleteVise(ind, index);
														}}
														href={() => false}
													>
														<i class="fas fa-trash"></i> {backOffice.Remove}
													</a>
												</div>
											</ShowForPermission>
										</>
									</div>
								</div>
								{inputsState.length == ind + 1 ? (
									<ShowForPermission permission="Transportation-Configuration-Manage">
										<button
											className="btn-link text-success  btn-add-ground-input mt-1"
											onClick={() => addEdit(ind)}
											style={{ fontSize: "16px" }}
										>
											<i class="fas fa-plus-circle text-success"></i>
											{booking.messages.add}
										</button>
									</ShowForPermission>
								) : (
									""
								)}
							</div>
						);
				  })
				: ""}

			{alertToggle ? (
				<WarningModal isOpen={alertToggle} toggle={setAlertToggle} />
			) : (
				""
			)}
		</>
	);
}
