import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import useShowPermission from "hooks/useShowPermission";
import Locale from "translations";

const SupplierDetails = ({
	setSupplier,
	supplier,
	setErrors,
	errors,
	countries,
	cities,
}) => {
	const ManualReservationsManagePermission = useShowPermission({
		permission: "Manual-Suppliers-Manage",
	});
	const { backOffice } = Locale;
	return (
		<div className="supplier-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{backOffice.SupplierDetails}</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={backOffice.supplierName}
						placeholder={backOffice.EnterSupplierName}
						value={supplier.supplierName}
						disabled={!ManualReservationsManagePermission}
						name="supplierName"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setSupplier({
								...supplier,
								supplierName: e.target.value,
							});
						}}
						color={errors?.supplierName?.required ? "danger" : ""}
						errors={errors?.supplierName}
					/>
				</div>

				<div className="col-4">
					<SelectField
						label={backOffice.country}
						value={backOffice.country}
						name="supplierCountry"
						disabled={!ManualReservationsManagePermission}
						options={countries}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "supplierCountry", value: e.value },
									{
										required: true,
									}
								),
							});
							setSupplier({
								...supplier,
								supplierCountry: e,
								supplierCity: null,
							});
						}}
						color={errors?.supplierCountry?.required ? "danger" : ""}
						errors={errors?.supplierCountry}
					/>
				</div>
				<div className="col-4">
					<SelectField
						label={backOffice.city}
						value={backOffice.city}
						name="supplierCity"
						disabled={!ManualReservationsManagePermission}
						options={cities}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "supplierCity", value: e.value },
									{
										required: true,
									}
								),
							});
							setSupplier({
								...supplier,
								supplierCity: e,
							});
						}}
						color={errors?.supplierCity?.required ? "danger" : ""}
						errors={errors?.supplierCity}
					/>
				</div>
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={backOffice.CompanyEmailAddress}
						placeholder={backOffice.CompanyEmailAddress}
						value={supplier.supplierEmail}
						disabled={!ManualReservationsManagePermission}
						name="supplierEmail"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setSupplier({
								...supplier,
								supplierEmail: e.target.value,
							});
						}}
						color={
							errors?.supplierEmail?.required || errors?.supplierEmail?.email
								? "danger"
								: ""
						}
						errors={errors?.supplierEmail}
					/>
				</div>
			</div>
		</div>
	);
};

export default SupplierDetails;
