import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import { fetchCommericialDistById } from "services/commercialDistribution";
import { fetchCities } from "services/lookups";
import { providerservices } from "services/vendor";
import Locale from "translations";
import CommercialDistributionDetails from "./ViewDetails";
import CommercialDistributionWallet from "./Wallet";
import {
	formatCommercialDist,
	generateServicesTabs,
} from "./helpers/commercialDistHelper";

export default function ManageCommercialDistribution() {
	const { allCurrencies, allCountries, locale } = useGlobalState();
	const { path, url } = useRouteMatch();
	const { push, location } = useHistory();
	const { pathname } = location;
	const { id } = useParams();
	const { backOffice, translate_companies } = Locale;

	const isManage = url.includes("manage");
	const [commercialDistData, setCommercialDistData] = useState({
		details: {
			name: "",
			country: null,
			city: null,
			currency: null,
			date_from: null,
			date_to: null,
			link: "",
		},
		servicesList: [],
		editMode: false,
	});
	const [servicesListTabs, setServicesListTabs] = useState([]);
	const [walletList, setWalletList] = useState([]);
	const [reload, setReload] = useState(false);
	const [lookups, setLookups] = useState();

	// get cities based on country id
	async function fetchCitiesBasedOnCountry(countryId) {
		const citiesRes = await fetchCities(countryId);
		const formatCities = citiesRes?.data?.map((city) => ({
			id: city?.id,
			value: city.id,
			label: city.names[locale],
		}));
		setLookups({ ...lookups, cities: formatCities });
	}

	useEffect(() => {
		if (isManage) {
			// inital fetch of ommercial distribution data
			async function fetchCommercialDistribution() {
				const res = await fetchCommericialDistById(id);
				if (res?.status === 200) {
					const formatData = formatCommercialDist(res);
					setWalletList([...res?.data?.data?.wallets]);
					const servicesTabs = generateServicesTabs(res?.data?.data?.services);
					setCommercialDistData(formatData);
					setServicesListTabs(servicesTabs);
				}
			}
			fetchCommercialDistribution();
		}
	}, [isManage, reload]);

	// format countries and currencies
	useEffect(() => {
		async function formatLookup() {
			let servicesLookups;
			let servicesRes = isManage && (await providerservices());
			if (servicesRes?.status === 200) {
				servicesLookups = servicesRes?.data?.data?.services?.map((service) => ({
					name: service,
					providers: [],
				}));
			}
			const formatCurrencies = allCurrencies?.map((currency) => ({
				id: currency.id,
				value: currency.id,
				label: currency.currency_code,
			}));
			const formatCountries = allCountries?.map((country) => ({
				id: country?.id,
				value: country.id,
				label: country.names[locale],
				code: country.country_code,
			}));
			setLookups({
				currencies: formatCurrencies,
				countries: formatCountries,
				services: servicesLookups,
			});
		}
		formatLookup();
	}, [isManage]);

	// in inital get list of cities by country id
	useEffect(() => {
		if (commercialDistData?.details?.country?.id && lookups) {
			fetchCitiesBasedOnCountry(commercialDistData?.details?.country?.id);
		}
	}, [commercialDistData?.details?.country?.id]);

	return (
		<div className="d-flex flex-column">
			<div className="">
				{isManage ? (
					<div
						className=" tabs d-flex align-items-center all-tabs"
						style={{ borderBottom: "1px solid #d9dde0" }}
					>
						<div className="d-flex align-items-center w-100">
							<NavLink
								className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps ${
									pathname.includes("details") ? " is-active" : ""
								}`}
								exact={true}
								activeClassName="is-active"
								onClick={() => {
									push(`${url}/details`);
								}}
							>
								{backOffice.details}
							</NavLink>
							{servicesListTabs?.map((serviceTab) => {
								return (
									<NavLink
										key={serviceTab?.id}
										className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps ${
											pathname.includes(serviceTab.route) ? " is-active" : ""
										}`}
										exact={true}
										activeClassName="is-active"
										onClick={() => {
											push(`${url}/${serviceTab.route}`);
										}}
									>
										{serviceTab?.label}
									</NavLink>
								);
							})}
							{walletList.map((wallet, index) => (
								<NavLink
									className={`btn mx-1 bg-subNav text-bold px-3 font-weight-bold our-taps ${
										pathname.includes("wallet") ? " is-active" : ""
									}`}
									exact={true}
									activeClassName="is-active"
									onClick={() => {
										push(`${url}/wallet`);
									}}
								>
									{translate_companies.wallet}
								</NavLink>
							))}
						</div>
					</div>
				) : (
					<CommercialDistributionDetails
						isManage={isManage}
						commercialDistData={commercialDistData}
						setCommercialDistData={setCommercialDistData}
						setReload={setReload}
						lookups={lookups}
						setLookups={setLookups}
						fetchCitiesBasedOnCountry={fetchCitiesBasedOnCountry}
						setServicesListTabs={setServicesListTabs}
					/>
				)}
			</div>

			<Switch>
				<Route path={`${path}/details`}>
					<CommercialDistributionDetails
						isManage={isManage}
						commercialDistData={commercialDistData}
						setCommercialDistData={setCommercialDistData}
						setReload={setReload}
						lookups={lookups}
						setLookups={setLookups}
						fetchCitiesBasedOnCountry={fetchCitiesBasedOnCountry}
						setServicesListTabs={setServicesListTabs}
					/>
				</Route>
				{walletList.map((wallet, index) => (
					<Route path={`${path}/wallet`}>
						<CommercialDistributionWallet walletData={wallet} />
					</Route>
				))}
				{servicesListTabs.map((serviceTab) => {
					return (
						<Route path={`${path}/${serviceTab.route}`} key={serviceTab?.route}>
							{serviceTab.component}
						</Route>
					);
				})}
			</Switch>
		</div>
	);
}
