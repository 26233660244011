// import { useParams } from "react-router-dom";
import walletBlue from "assets/images/onlineVisa/walletBlue.svg";
import moment from "moment";
import Locale from "translations";

export default function ViewRequestHeader({ header, downloadFileWithUUID }) {
	const { requests, backOffice } = Locale;

	// const { status, id } = useParams();
	async function downloadAgreement() {
		const aggreementText = `test agreement`;

		let aggreement = `${aggreementText} \n \n
${header?.ip_address ? `IP Address: ${header?.ip_address}` : "-"}
${
	header?.request_date
		? `Request Date: ${moment(header?.request_date).format("DD-MM-YYYY")}`
		: ""
}
${header?.residence_name ? `Country: ${header?.residence_name}` : ""}
${header?.company ? `Company Name: ${header?.company}` : ""}
		`;
		// aggreement = aggreement.split("\n").map(s => s.trim()).filter(Boolean).join("\n");
		const agreementBLOB = new Blob([aggreement], {
			type: "application/msword",
		});

		const objectUrl = window.URL.createObjectURL(agreementBLOB);
		const anchor = document.createElement("a");
		anchor.target = "_blank";
		anchor.href = objectUrl;
		anchor.setAttribute(
			"download",
			`agreement-${moment().format("DD-MM-YYYY")}`
		);
		anchor.click();
	}

	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-12  justify-content-between    p-0">
					<div className="col-xl-2 col-12  py-5 bg-gray-100 text-center">
						<p className="m-0 font-weight-bold">{requests.VISA}</p>
						<p className="m-0 font-weight-bold">{requests.DETAILS}</p>
					</div>

					<div className="row col-6 col-xl-4 py-2 px-4">
						<div className="col-6 col-xl-5 text-visa-header font-weight-bold">
							<p className="m-0 ">{backOffice.TravelAgent}</p>
							<p className="m-0 ">{requests.Destination}</p>
							<p className="m-0 ">{requests.VisaType}</p>
							<p className="m-0 ">{requests.TransportType}</p>
							<p className="m-0 ">{requests.Pax}</p>
							<p className="m-0 ">{requests.VisaPriceOne}</p>
						</div>

						<div className="col-6 col-xl-7 ">
							<p className="m-0">{header?.company ? header?.company : "-"}</p>

							<p className="m-0">{header?.destination_name}</p>
							<p className="m-0">{header?.visa_type_name}</p>
							<p className="m-0">
								{header?.transportation_type
									? header?.transportation_type
									: "-"}
							</p>
							<p className="m-0">{header?.pax}</p>
							<p className="m-0">
								{header?.price_per_one} {header?.currency}
							</p>
						</div>
					</div>

					<div className="row col-6 col-xl-4 py-2 ">
						<div className="col-6 col-xl-5  text-visa-header font-weight-bold">
							<p className="m-0 ">{requests.Departure}</p>
							<p className="m-0 ">{requests.Return}</p>
							<p className="m-0 ">{requests.RequestDate}</p>
							<p className="m-0 ">{requests.status}</p>
							{header?.entry_stamp ? (
								<p className="m-0 ">{requests.contractAgreement}</p>
							) : null}
						</div>

						<div className="col-6 col-xl-7">
							<p className="m-0">{header?.departure_date || "-"}</p>
							<p className="m-0">{header?.return_date || "-"}</p>
							<p className="m-0">{header?.request_date || "-"}</p>
							<p
								className={`m-0 ${
									header?.status === "issued" ? "text-success" : "text-warning"
								}`}
							>
								{header?.status}
							</p>
							{header?.entry_stamp ? (
								<p className="m-0 waiting pointer" onClick={downloadAgreement}>
									<i class="fas fa-download"></i>
									<span className="mx-1">{backOffice.download}</span>
								</p>
							) : null}
						</div>
					</div>

					<div className="d-flex align-items-end ">
						<div className="d-flex  bg-gray-100 px-4 text-center ">
							<img src={walletBlue} alt="" className="online-vise-img" />
							<div className="mx-3">
								<p className="m-0 txt-blue">{requests.TotalPrice}</p>
								<p className="m-0 text-success font-weight-bold">
									{header?.total} {header?.currency?.toLocaleUpperCase()}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
