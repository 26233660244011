import Pagination from "components/shared/Pagination";
import Locale from "translations";
const statusClass = {
	rejected: "canceled",
	canceled: "canceled",
	expired: "canceled",
	pending: "text-warning",
	paid: "text-definite",
	completed: "text-definite",
	approved: "text-definite",
};
function ReservationTable({ Reservation, setReservation, meta, goto }) {
	const { booking, backOffice } = Locale;
	const AllReservation = Reservation?.map((res) => {
		return (
			<tr key={res?.id}>
				<td>{res?.id}</td>
				<td>{res?.company_name}</td>
				<td>{res?.property_name}</td>
				<td>{res?.destination?.names?.en} </td>
				<td>{res?.rooms?.length}</td>
				<td>{res?.check_in}</td>
				<td>{res?.nights}</td>
				<td>{res?.created_at}</td>
				<td>{res?.price}</td>
				<td>{res?.currency}</td>

				<td className={`${statusClass[res?.status]} text-capitalize`}>
					{res?.status}
				</td>
			</tr>
		);
	});
	return (
		<>
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.ref}</th>
							<th>{backOffice.agency}</th>
							<th>{backOffice.hotelName}</th>
							<th>{backOffice.destination}</th>
							<th>{backOffice.noOfRooms}</th>
							<th>{backOffice.ChecKin}</th>
							<th>{backOffice.Nights}</th>
							<th>{backOffice.RequestDate}</th>
							<th>{backOffice.Price}</th>
							<th>{backOffice.currency}</th>
							<th>{booking.Status}</th>
						</tr>
					</thead>
					<tbody>
						{Reservation?.length > 0 ? (
							AllReservation
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{Reservation?.length > 0 ? (
					<Pagination info={meta} goTo={goto} />
				) : null}
			</div>
		</>
	);
}

export default ReservationTable;
