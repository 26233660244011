import closeButtonImage from "assets/images/offline-reservation-close-btn.svg";
import TextField from "components/shared/TextField";
import { Modal } from "reactstrap";
import Locale from "translations";

export default function ConfirmationNumberModal({
	confirmationNumberIsOpen,
	setConfirmationNumberIsOpen,
	confirmNumber,
	setConfirmNumber,
	handleConfirmationNumber,
}) {
	const { backOffice } = Locale;
	return (
		<Modal isOpen={confirmationNumberIsOpen} centered={true}>
			<div className="py-3">
				<div className="d-flex flex-row align-items-center justify-content-between px-3 w-100">
					<h4 className="font-weight-bolder m-0">
						{backOffice.ConfirmationNumber}
					</h4>
					<button
						type="button"
						className="btn modal_btn-close p-0"
						onClick={() => {
							setConfirmationNumberIsOpen(false);
						}}
					>
						<img src={closeButtonImage} alt="" />
					</button>
				</div>
				<div className="w-75 my-0 pt-3 mx-auto">
					<TextField
						type="text"
						placeholder={backOffice.ConfirmationNumber}
						label={backOffice.ConfirmationNumber}
						hasLabel={true}
						value={confirmNumber}
						onChange={(e) => {
							setConfirmNumber(e.target.value);
						}}
					/>

					<div className="w-100 px-2 py-0 my-0">
						<button
							type="button"
							className={`btn btn-success my-2 w-100 ${
								confirmNumber ? "" : "disabled text-muted"
							}`}
							disabled={confirmNumber ? false : true}
							onClick={handleConfirmationNumber}
						>
							{backOffice.Send}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
