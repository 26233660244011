
import { useEffect, useState } from 'react';
// React-Router-dom
import { useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Module Component
import PackageDetailsForm from './PackageDetailsForm';
import PackageSubscriptionTypes from './PackageSubscriptionTypes';
import PackagesVisaList from './PackagesVisaList';
// Shared Component
import Tabs from '../../shared/Tabs';
// Helper
import validate from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// Moment
import moment from 'moment/moment';
// Services
import { getPackagePricingById } from 'services/safaVisa';

// --------------------------------------------------------------------------------
export default function AddEditPackage() {
  // Hooks
  const { safaVisa } = Locale;

  let { id, status } = useParams();

  // *** Custom Hook
  const { packagesPricingTabs, requiredPackagePricingInputs, initialPackageData } = useSafaVisaData();

  // *** State
  const [packageData, setPackageData] = useState(initialPackageData)
  const [tabs, setTabs] = useState(packagesPricingTabs)
  const [activeTab, setActiveTab] = useState(packagesPricingTabs[0])
  const [allRequiredInputs, setAllRequiredInputs] = useState({
    requiredDetailsInputs: requiredPackagePricingInputs,
    requiredPricingInputs: []
  })
  const [errors, setErrors] = useState();

  // ** function 
  const checkFormErrors = () => {
    let submitError = {};
    allRequiredInputs?.requiredDetailsInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    setErrors((prev) => ({ ...prev, ...submitError }));
    // console.log(submitError)
    return submitError;
  }

  const handleCheckValidation = () => {
    checkFormErrors()
  }

  const tranformDataToShow = (data) => {
    return {
      title_ar: data?.title?.ar,
      title_en: data?.title?.en,
      description_ar: data?.description?.ar,
      description_en: data?.description?.en,
      countries_id: data?.countries?.map(item => ({
        value: item.id,
        label: item.name,
      })),
      currency: { value: data?.currency, label: data?.currency },
      audience_type: { value: data?.audience_type, label: safaVisa?.[`${data?.audience_type}`] },
      with_contracts: data?.contract_count > 0 ? 'contract-determined' : 'contarct-indeterminate',
      contract_count: data?.contract_count,
      with_Agents: data?.agents_count > 0 ? 'agents-determined' : 'agents-indeterminate',
      agents_count: data?.agents_count,
      quotas: data?.quotas?.map((item, index) => ({
        ...item,
        visa_type: { label: safaVisa?.[`${item?.visa_type}`], value: item?.visa_type },
        id: index + 1,
        amount: item?.amount
      })),
      date_type: data?.date_type,
      share_start_date: data.share_start_date,
      share_end_date: data?.share_end_date,
      package_type: { value: data?.duration_type, label: safaVisa?.[`${data?.duration_type}`] },
      duration_count: data?.duration_count,
      season_start_date: data?.season_start_date,
      season_end_date: data?.season_end_date,
      season_days: moment(data?.season_end_date).diff(moment(data?.season_start_date), "days"),
      start_date: data?.start_date,
      end_date: data?.end_date,
      repurchase: data?.repurchase === 1 ? 'repurchase-available' : 'repurchase-notAvailable',
      postpaid_price: data?.postpaid_price,
      prepaid_price: data?.prepaid_price,
      visible_share_end_date: data?.share_end_date ? 'end-date-determined' : 'end-date-indeterminate',
      subscription_type: (data?.postpaid_price && data?.prepaid_price) ?
        'all'
        :
        data?.postpaid_price ? 'postpaid'
          :
          'prepaid',

    }
  }

  const fetchPackagePriceDetails = async () => {
    const response = await getPackagePricingById(id)
    if (response?.status === 200) {
      const transformedData = tranformDataToShow(response?.data?.data)
      setPackageData(transformedData)
    }
  }

  useEffect(() => {
    if ((status === 'edit' || status === 'duplicate') && id) {
      fetchPackagePriceDetails()
    }
  }, [])

  // console.log(allRequiredInputs)
  // console.log(errors)
  // console.log(packageData)

  // -------- JSX Code -------
  return (
    <div className='safaVisa-wrapper'>

      <div className='safa-visa-container'>

        {/* Header */}
        <div >
          <h4 className="main-title mb-0">{safaVisa.addPackage}</h4>
          <hr />
        </div>


        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onClickTab={handleCheckValidation}
        />

        {activeTab?.value === 'package-details' &&
          <PackageDetailsForm
            packageData={packageData}
            setPackageData={setPackageData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setTabs={setTabs}
            errors={errors}
            setErrors={setErrors}
            allRequiredInputs={allRequiredInputs}
            setAllRequiredInputs={setAllRequiredInputs}
          />
        }


        {activeTab?.value === 'subscription-types' &&
          <PackageSubscriptionTypes
            packageData={packageData}
            setPackageData={setPackageData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setTabs={setTabs}
            errors={errors}
            setErrors={setErrors}
            allRequiredInputs={allRequiredInputs}
            setAllRequiredInputs={setAllRequiredInputs}
          />
        }
        {activeTab?.value === 'package-visas' &&
          <PackagesVisaList
            packageData={packageData}
            setPackageData={setPackageData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setTabs={setTabs}
            setErrors={setErrors}
            allRequiredInputs={allRequiredInputs}
            setAllRequiredInputs={setAllRequiredInputs}
          />
        }

      </div>

    </div>
  )
}

