import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// ReactStrap
import { UncontrolledTooltip } from 'reactstrap';
// Translation
import Locale from 'translations';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// Services
import { getCampaignDetailsById } from 'services/safaVisa';
// Context
import { useGlobalState } from 'context/global';
// ------------------------------------------------------------------------
export default function TargetCampaignsDetails() {

  // *** Hooks
  let history = useHistory();

  let { id } = useParams();

  const { locale } = useGlobalState();

  const { safaVisa, backOffice } = Locale;

  // ** State
  const [targetCampaignsDetails, setTargetCampaignsDetails] = useState({})

  // *** Function
  const defineIsActive = (status) => {
    return status === 1 ? true : false
  }

  const fetchTargetCampaignsDetails = async () => {
    const response = await getCampaignDetailsById(id)
    if (response?.status === 200) {
      setTargetCampaignsDetails(response?.data?.data)
    }
  }

  useEffect(() => {
    fetchTargetCampaignsDetails()
  }, [])


  // --------- JSX Code --------
  return (
    <div className='safaVisa-wrapper'>

      <div className='holder mb-3'>

        {/* Target Campaigns Details Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.campaignsDetails}</h4>
          <div className='d-flex gap-2 align-items-center'>
            <p className={`${defineIsActive(targetCampaignsDetails?.is_active) ? 'active-status ' : 'notActivated-status'}`}>
              {targetCampaignsDetails?.is_active === 1 ? safaVisa.active : safaVisa?.notActive}
            </p>
          </div>
        </div>

        <hr />

        {/* Target Campaigns Info */}
        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.campaignName} :</p>
            <p className='subTitle'> {targetCampaignsDetails?.title?.[locale]}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.packageCode} :</p>
            <p className='subTitle' >
              {targetCampaignsDetails?.code}
            </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.country} :</p>
            <p className='subTitle d-flex' >
              {targetCampaignsDetails?.countries?.[0]?.name}
              {
                targetCampaignsDetails?.countries?.length > 1 ?
                  <>
                    <span
                      href="#"
                      className='more-info-toolTip'
                      id="UncontrolledTooltipExample"
                    >
                      +{targetCampaignsDetails?.countries?.length - 1}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="UncontrolledTooltipExample"
                    >
                      {targetCampaignsDetails?.countries?.slice(1).map(item =>
                        <>
                          {item?.name} {' '}-
                        </>
                      )}
                    </UncontrolledTooltip>
                  </>
                  :
                  null
              }
            </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.targetDefaultPricing} :</p>
            <p className='subTitle'>
              {targetCampaignsDetails?.default_pricing?.title || safaVisa?.notFound}
            </p>
          </div>

        </div>

        <div className='pricing-info-holder mb-5'>

          <div className=''>
            <p className='mb-2 title '>{safaVisa.campaignsCreactionDate} :</p>
            <p className='subTitle' >{targetCampaignsDetails?.created_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.campaignsUpdatedDate} :</p>
            <p className='subTitle' >{targetCampaignsDetails?.updated_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '> {safaVisa.campaignsStartDate} :</p>
            <p className='subTitle' >{targetCampaignsDetails?.start_date}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '> {safaVisa.campaignsEndDate} :</p>
            <p className='subTitle' >{targetCampaignsDetails?.end_date || safaVisa?.indeterminate}</p>
          </div>

        </div>

        <div className='pricing-info-holder'>
          <div>
            <p className='mb-2 title'>{safaVisa.numberOfpurchased}</p>
            <p className='subTitle'> {targetCampaignsDetails?.exported_visas}</p>
          </div>
          <div>
            <p className='mb-2 title'>{safaVisa.campaignsNotification}</p>
            <p className='subTitle'> {targetCampaignsDetails?.notification_text?.[locale]}</p>
          </div>
        </div>

      </div>

      {/* Target Campaigns Packages List  Table */}
      {(targetCampaignsDetails?.pricing_packages?.length > 0) &&
        <div className='holder mb-3'>
          <h4 className="main-title mb-4 ">{safaVisa.campaignPackages}</h4>
          <div className="our-border">
            <table className="custom-table back-office-table table table-striped mb-0">

              <thead>
                <tr>
                  <th>{safaVisa.id}</th>
                  <th> {safaVisa.title}</th>
                  <th>{safaVisa.currency}</th>
                  <th> {safaVisa.audience}</th>
                  <th> {safaVisa.packageType}</th>
                  <th> {safaVisa.purchased}</th>
                </tr>
              </thead>

              <tbody>
                {targetCampaignsDetails?.pricing_packages?.map((row, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row?.title}</td>
                    <td>{row?.currency}</td>
                    <td>{safaVisa[row?.audience_type]}</td>
                    <td>{safaVisa[row?.duration_type]}</td>
                  </tr>
                )}
              </tbody>

            </table>
          </div >
        </div>
      }

      {/* Target Campaigns Companies  List  Table */}
      {(targetCampaignsDetails?.companies?.length > 0) &&
        < div className='holder'>
          <h4 className="main-title mb-4 ">{safaVisa.companiesEntitledToDiscount}</h4>
          <div className="our-border">
            <table className="custom-table back-office-table table table-striped mb-0">

              <thead>
                <tr>
                  <th>{safaVisa.id}</th>
                  <th> {safaVisa.title}</th>
                </tr>
              </thead>

              <tbody>
                {targetCampaignsDetails?.companies?.length > 0 ? (
                  targetCampaignsDetails?.companies?.map((row, index) =>
                    <tr>
                      <td>{index + 1}</td>
                      <td> <span >{row?.name}</span></td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="product-no-data">
                        <i className="fas fa-info-circle fa-lg"></i>{" "}
                        <h4>{backOffice.noResult}</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
          </div >
        </div>
      }

      <button
        className="btn back-btn py-2 mt-5"
        onClick={() => history.push("/safaVisa-targetedCampaigns")}
      >
        <span  >
          <LeftArrowIcon color='#1e85ff' width='24' height='24' />
        </span>
        {safaVisa.back}
      </button>

    </div >
  )
}

