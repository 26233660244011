import { InvoicePrint } from "components/Printing/InvoicePrint";
import { useEffect, useState } from "react";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { getInvouce, viewBookingPassinger } from "services/Request";
import Locale from "translations";
import safaImg from "../../../../../assets/images/backOffice/safa_logo.png";
import invoice from "../../../../../assets/images/onlineVisa/Layer2.svg";

import ShowForPermission from "helpers/showForPermission";
import Booking from "./Booking";
import Financial from "./Financial";
import Passangers from "./Passangers";
import Payments from "./Payments";
import History from "./history";
export default function BookingView() {
	const { path, url } = useRouteMatch();
	const { id } = useParams();
	const { push, location } = useHistory();
	const { pathname } = location;
	// const location = useLocation();
	const BookingInfo = location.state?.BookingInfo;

	const { backOffice, booking } = Locale;
	const [bookingPassengerData, setBookingPassengerData] = useState();
	const [invoiceData, setInvoiceData] = useState();
	const [Reload, setReload] = useState(true);
	useEffect(() => {
		const fetchBookingPassingerTab = async () => {
			const res = await viewBookingPassinger(id);
			setBookingPassengerData(res?.data?.data);
		};
		fetchBookingPassingerTab();
	}, [Reload]);
	//   useEffect(() => {

	//     const fetchInvoice = async () => {
	//         const res = await getInvouce(id)
	//         setInvoiceData(res?.data?.data)
	//       }
	//       fetchInvoice()
	//   }, [])

	const daweloadInvoice = async (brn) => {
		//history.push(`/Print/invoice/${details.brn}`);
		const invoice = await getInvouce(brn);
		const invoiceData = invoice?.data?.data;

		const allRooms =
			invoiceData?.rooms?.length > 0
				? invoiceData?.rooms.map((room, index) => {
						return `		<tr>
							<td
								class=${
									+index + 1 === +invoiceData?.rooms.length
										? "last-td-border"
										: "remove-last-td-border"
								}
							>
								${+index === 0 ? invoiceData?.hotel_name : ""}
							</td>
							<td className="f-12">${room.name}</td>
							<td>
						
								${
									room?.passengers?.length > 0
										? room?.passengers.map((passenger) => {
												return `<p className="m-0 f-12">
												${passenger?.first_name} ${passenger?.last_name}
											</p>`;
										  })
										: "-"
								}
							</td>
							<td className="f-12">${invoiceData?.rooms?.length}</td>
							<td className="f-12">${room.adults}</td>
							<td className="f-12">${room.children}</td>
							<td className="f-12">${room.nights}</td>
							<td className="f-12">${room.from_date}</td>
							<td className="f-12">${room.to_date}</td>
							<td className="f-12">
								${room.paid_price ?? room.price} ${invoiceData?.currency}
							</td>
						</tr>`;
				  })
				: [];

		if (invoice) {
			let respos = InvoicePrint;
			console.log(respos);
			respos = respos.replace("[allRooms]", allRooms);

			respos = respos.replace("[invoiceNumber]", invoiceData?.invoiceNumber);
			console.log(invoice);
			respos = respos.replace(
				"[confirmationNumber]",
				invoiceData?.confirmationNumber
			);
			respos = respos.replace("[company_name]", invoiceData?.company?.name);
			respos = respos.replace("[company_name_sec]", invoiceData?.company?.name);
			respos = respos.replace(
				"[company_address]",
				invoiceData?.company?.address ? invoiceData?.company?.address : "-"
			);

			respos = respos.replace("[company_phone]", invoiceData?.company?.phone);
			respos = respos.replace(
				"[remark]",
				invoiceData?.remark
					? `<div class="border-section">
				<div class="row justify-content-start align-items-center  p-4 ">
					<div class=" col-12 d-flex flex-column">
						<p class="font-weight-bold mb-1">Agent Remarks</p>
						<p class="m-0">${invoiceData?.remark}</p>
					</div>
				</div>
			</div>`
					: ""
			);

			respos = respos.replace("[total_price]", invoiceData?.total_price);

			respos = respos.replace("[invoiceDate]", invoiceData?.invoiceDate);
			respos = respos.replace("[currency]", invoiceData?.currency);

			respos = respos.replace("[SafaLogo]", safaImg);
			respos = respos.replace(
				"[company_image]",
				invoiceData?.companyImage
					? `<img src=${invoiceData?.companyImage} alt="hotal-logo" class="img-fluid w-50" />`
					: `<div class="company-logo-font letter-logo p-5 ">
										${invoiceData?.company?.name[0]}
									</div>`
			);

			var popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	const tabsContent = [
		{
			id: 1,
			route: "bookings",
			component: (
				<Booking bookingInfo={bookingPassengerData} setReload={setReload} />
			),
		},
		{
			id: 2,
			route: "passengers",
			component: (
				<Passangers
					passangerInfo={bookingPassengerData}
					setReload={setReload}
				/>
			),
		},
		{
			id: 3,
			route: "financial",
			component: <Financial finacial={bookingPassengerData} />,
		},
		{
			id: 3,
			route: "payments",
			component: (
				<Payments payment={bookingPassengerData} setReload={setReload} />
			),
		},
		{ id: 4, route: "history", component: <History /> },
	];

	return (
		<div>
			<h3 className="txt-blue font-weight-bold col">
				{booking.singleBooking}: {BookingInfo?.hotel_name}
			</h3>
			<div className="booking-view-header all-tabs mt-3 d-flex  justify-content-between  ">
				<div className="d-flex ">
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
                         ${pathname.includes("bookings") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/bookings`);
						}}
					>
						{booking.booking}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
						${pathname.includes("passengers") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/passengers`);
						}}
					>
						{booking.Passengers}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
						${pathname.includes("financial") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/financial`);
						}}
					>
						{booking.Financial}
					</button>

					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
						${pathname.includes("payments") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/payments`);
						}}
					>
						{booking.Payments}
					</button>
					<button
						className={`btn mx-1 our-tabs  text-bold px-3 font-weight-bold
						${pathname.includes("history") ? "is-active" : ""}`}
						onClick={() => {
							push(`${url}/history`);
						}}
					>
						{backOffice.History}
					</button>
				</div>

				{pathname.includes("bookings") ? (
					<div>
						<ShowForPermission permission="Hotels-Bookings-Manage">
							<button
								type="button"
								class="btn btn-success font-weight-bold"
								onClick={() => daweloadInvoice(bookingPassengerData?.brn)}
							>
								<img src={invoice} alt="" className="" />
								{booking.downloadInvoice}
							</button>
						</ShowForPermission>
					</div>
				) : null}
			</div>
			<Switch>
				{tabsContent.map((pane) => (
					<Route path={`${path}/${pane.route}`}>{pane.component}</Route>
				))}
			</Switch>
		</div>
	);
}
