import ShowForPermission from "helpers/showForPermission";
import { useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import KeyValue from "./KeyValue";

function PassengersDetails({
	passanger,
	passengerType,
	index,
	children,
	downloadItinerary,
}) {
	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	const { backOffice } = Locale;
	return (
		<>
			<div className="form-box radius bg-light mb-2">
				<div className="form-head">
					<p className="fw-bold mb-0">
						{passanger.psgType === "ADT" ? "Adult" : "Child"}
						{index + 1}
					</p>
					{isOpen && index !== 0 ? (
						<i class="fas fa-angle-up pointer" onClick={toggle} />
					) : (
						<i class="fas fa-angle-down pointer" onClick={toggle} />
					)}
					{/* <i class="fas fa-angle-down pointer" onClick={toggle}></i> */}
				</div>
				<Collapse
					isOpen={index === 0 && passengerType === "adults" ? true : isOpen}
					toggle={toggle}
					className="border-light"
				>
					<div className="d-flex justify-content-between px-3 py-1 bg-white ">
						<div>
							<p className="text-black-50 mb-0">
								{backOffice.NameGivennameLastname}
							</p>
							<p className="mb-0">{`${passanger.firstName} / ${passanger.lastName}`}</p>
						</div>
						<ShowForPermission
							permission={[
								"View-Airlines-Reservations",
								"Manage-Airlines-Reservations",
							]}
						>
							<button
								className="btn bold text-custome"
								onClick={() => downloadItinerary(passanger)}
							>
								{backOffice.Itinerary} <i className="fas fa-download ms-1"></i>
							</button>
						</ShowForPermission>
					</div>
					{
						<KeyValue
							data={[
								{
									key: backOffice.gender,
									value: passanger.sex === "M" ? "Male" : "Female",
								},
								{ key: backOffice.dateOfBirth, value: passanger.birthday },
								{ key: backOffice.idNumber, value: passanger.cardNum },
								{
									key: backOffice.idExpirydate,
									value: passanger.cardExpiredDate,
								},
								{ key: backOffice.nationality, value: passanger.nationality },
								{ key: backOffice.TicketNo, value: passanger?.ticketNumber },
							]}
							classStyle={"bg-white"}
						/>
					}

					{children}
				</Collapse>
			</div>
		</>
	);
}

export default PassengersDetails;
