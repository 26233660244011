import { useEffect, useState } from 'react';
// React-Router-dom
import { useHistory, useParams } from 'react-router-dom';
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Module Component
import CompaniesList from '../../shared/CompaniesList';
import TargetCampaignsDetailsForm from './TargetCampaignsDetailsForm';
import TargetCampaignsPackages from './TargetCampaignsPackages';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// Moment
import moment from 'moment/moment';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// Services
import {
  addCampaign,
  editCampaign,
  getCampaignDetailsById,
  getDefaultPricingLookups,
  getPackagesLookups
} from 'services/safaVisa';
import { fetchCompaniesLookups } from 'services/sbsReservations';
// ------------------------------------------------------------------------------
export default function AddEditTargetCampaigns() {

  // *** Hook
  let history = useHistory();

  const { safaVisa } = Locale;

  let { id, status } = useParams();

  // *** Custom Hook
  const { requiredCampaignInputs, initialCampaignData } = useSafaVisaData();

  // *** State
  const [targetCampaignData, setTargetCampaignData] = useState(initialCampaignData)
  const [allRequiredInputs, setAllRequiredInputs] = useState({
    requiredDetailsInputs: requiredCampaignInputs,
    requiredPricingInputs: []
  })
  const [lookups, setLookups] = useState({
    defaultPriceOptions: [],
    packagesPriceOptions: [],
    companiesOptions: []
  });
  const [errors, setErrors] = useState();

  // ** function 
  const checkFormErrors = () => {
    let submitError = {};

    // check required inputs of details form
    allRequiredInputs?.requiredDetailsInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: targetCampaignData[key] },
          { required: true }
        )
      }
    })
    setErrors({ ...submitError });
    return submitError;
  }

  // const checkIsBtnDisabled = () => {
  //   if (targetCampaignData?.pricing_type === 'default_price') {
  //     // eslint-disable-next-line no-unused-expressions
  //     targetCampaignData?.companies_id?.length ? true : false
  //   }
  //   else {
  //     if ((targetCampaignData?.companies_id?.length < 1)
  //       || (targetCampaignData?.pricing_packages_id?.length < 1)
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }

  // }

  const tranformDataToShow = (data) => {
    return {
      title_ar: data?.title?.ar,
      title_en: data?.title?.en,
      countries_id: data?.countries?.map(item => ({
        value: item?.id,
        label: item?.name,
        ...item
      })),
      pricing_type: data?.is_default_pricing === 1 ?
        'default_price'
        :
        data?.is_pricing_package === 1 ?
          'package'
          :
          'all'
      ,
      date_type: data?.date_type,
      start_date: data?.start_date,
      visible_end_date: data?.end_date ? 'end-date-determined' : 'end-date-indeterminate',
      end_date: data?.end_date ? data?.end_date : '',
      notification_text_ar: data?.notification_text?.ar,
      notification_text_en: data?.notification_text?.en,
      companies_id: data?.companies?.map((company) => ({
        ...company
      })),
      pricing_packages_id: data?.pricing_packages?.map((item) => ({
        ...item
      })),
      default_pricing_id: {
        value: data?.default_pricing?.id,
        label: data?.default_pricing?.title,
        ...data?.default_pricing
      },
    }

  }

  const handleSubmitPricing = async () => {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    if (targetCampaignData?.companies_id?.length < 1) {
      store.addNotification({
        message: safaVisa?.chooseCompanyIsRequired,
        title: safaVisa?.canNotSubmit,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      if (targetCampaignData?.pricing_packages_id?.length < 1) {
        store.addNotification({
          message: safaVisa?.choosePackageIsRequired,
          title: safaVisa?.canNotSubmit,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      return
    }

    let requestBody = {
      title: {
        ar: targetCampaignData?.title_ar,
        en: targetCampaignData?.title_en
      },
      countries_id: targetCampaignData?.countries_id?.map(item => item?.value),
      date_type: targetCampaignData?.date_type,
      start_date: moment(targetCampaignData?.start_date).format('YYYY-MM-DD'),
      end_date: (targetCampaignData?.visible_end_date === 'end-date-determined' && targetCampaignData?.end_date) ?
        moment(targetCampaignData?.end_date).format('YYYY-MM-DD') : null
      ,
      notification_text: {
        ar: targetCampaignData?.notification_text_ar,
        en: targetCampaignData?.notification_text_en,
      },
      is_default_pricing: (targetCampaignData?.pricing_type === 'default_price' || targetCampaignData?.pricing_type === 'all')
        ?
        true
        :
        false
      ,
      is_pricing_package: (targetCampaignData?.pricing_type === 'package' || targetCampaignData?.pricing_type === 'all')
        ?
        true
        :
        false,
      default_pricing_id: targetCampaignData?.default_pricing_id?.id,
      pricing_packages_id: targetCampaignData?.pricing_packages_id?.map(item => item?.id),
      companies_id: targetCampaignData?.companies_id?.map(item => item?.id)
    }
    let response;

    if (status === 'add' || status === 'duplicate') {
      response = await addCampaign(requestBody);
    }
    if (status === 'edit' && id) {
      response = await editCampaign(id, requestBody);
    }

    if ((response?.status === 200 || response?.status === 201)) {
      store.addNotification({
        message: safaVisa?.campaignAddedSuccessfully,
        title: safaVisa?.success,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push('/safaVisa-targetedCampaigns')
    }
  }

  const fetchCampaignDetails = async () => {
    const response = await getCampaignDetailsById(id)
    if (response?.status === 200) {
      const transformedData = tranformDataToShow(response?.data?.data)
      setTargetCampaignData(transformedData)
    }
  }

  const getCompaniesLookups = async (params) => {
    const response = await fetchCompaniesLookups(params)
    if (response?.status === 200) {
      const transformedCompanies = response?.data?.data.map((t) => ({
        value: t.id,
        label: t.name,
        ...t,
      }));
      setLookups((prevStatus) => ({
        ...prevStatus,
        companiesOptions: transformedCompanies
      }))
    }
  }

  const fetctDefaultPricingLookups = async (params) => {
    const response = await getDefaultPricingLookups(params)
    if (response?.status === 200) {
      const dropDownOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
        ...item
      }))
      setLookups((prevStatus) => ({
        ...prevStatus,
        defaultPriceOptions: dropDownOption
      }))
    }
  }

  const fetctPackagesLookups = async (params) => {
    const response = await getPackagesLookups(params)
    if (response?.status === 200) {
      const dropDownOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
        ...item
      }))
      setLookups((prevStatus) => ({
        ...prevStatus,
        packagesPriceOptions: dropDownOption
      }))
    }
  }

  useEffect(() => {
    if (targetCampaignData?.countries_id?.length) {
      if (targetCampaignData?.pricing_type === 'default_price' || targetCampaignData?.pricing_type === 'all') {
        fetctDefaultPricingLookups({
          audience_type: 'custom',
          country_ids: targetCampaignData?.countries_id?.map(item => item?.value),
          is_active: 1,
        })
      }
      if (targetCampaignData?.pricing_type === 'package' || targetCampaignData?.pricing_type === 'all') {
        fetctPackagesLookups({
          audience_type: 'direct',
          country_ids: targetCampaignData?.countries_id?.map(item => item?.value),
          is_active: 1,
        })
      }
      getCompaniesLookups({
        countries: targetCampaignData?.countries_id?.map(item => item?.value)
      })
    }

  }, [
    targetCampaignData?.pricing_type,
    targetCampaignData?.countries_id
  ]);

  // get Campaign details in edit mode
  useEffect(() => {
    if ((status === 'edit' || status === 'duplicate') && id) {
      fetchCampaignDetails()
    }
  }, [])



  // ------- JSX Code -------
  return (
    <div className='safaVisa-wrapper'>

      <div className='safa-visa-container'>

        {/* Header */}
        <div >
          <h4 className="main-title mb-0">
            {
              status === 'edit' ?
                safaVisa.editCampaign
                :
                safaVisa.addCampaign
            }
          </h4>
          <hr />
        </div>


        <TargetCampaignsDetailsForm
          targetCampaignData={targetCampaignData}
          setTargetCampaignData={setTargetCampaignData}
          defaultPriceOptions={lookups?.defaultPriceOptions}
          errors={errors}
          setErrors={setErrors}
          setAllRequiredInputs={setAllRequiredInputs}
        />

        {(targetCampaignData?.pricing_type === 'package' ||
          targetCampaignData?.pricing_type === 'all') &&

          <TargetCampaignsPackages
            targetCampaignData={targetCampaignData}
            setTargetCampaignData={setTargetCampaignData}
            packagesPriceOptions={lookups?.packagesPriceOptions}
            errors={errors}
            setErrors={setErrors}
            setAllRequiredInputs={setAllRequiredInputs}
          />
        }

        <CompaniesList
          data={targetCampaignData}
          setData={setTargetCampaignData}
          companiesOptions={lookups?.companiesOptions}
          errors={errors}
          setErrors={setErrors}
          setAllRequiredInputs={setAllRequiredInputs}
        />


        {/* Actions Button */}
        <div className="col-12 d-flex justify-content-between gap-10 mt-5">

          <button
            className="btn back-btn py-2 "
            onClick={() => history.push("/safaVisa-targetedCampaigns")}
          >
            <span >
              <LeftArrowIcon color='#1e85ff' width='24' height='24' />
            </span>
            {safaVisa.back}
          </button>
          <button
            // disabled={checkIsBtnDisabled()}
            type='button'
            className="btn add-btn py-2"
            onClick={handleSubmitPricing}
          >
            {safaVisa.save}
          </button>
        </div>

      </div>
    </div>
  )
}

