import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function FinancialPaymentFilter({ filter, setFilter }) {
	const { backOffice } = Locale;
	const typeOptions = [
		{
			value: "add",
			label: "add",
		},
		{
			value: "deduct",
			label: "deduct",
		},
	];
	const transTypeOptions = [
		{
			value: "cash",
			label: "cash",
		},
		{
			value: "cheque",
			label: "cheque",
		},
		{
			value: "transfer",
			label: "transfer",
		},
	];
	const resetFilters = () => {
		setFilter({
			...filter,
			search: "",
			date: "",
			type: null,
			transaction_type: null,
			page: 1,
		});
	};
	return (
		<>
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<div className="main-label">
						<TextField
							type="text"
							placeholder={backOffice.Typetosearch}
							label={backOffice.search}
							hasLabel={true}
							value={filter.search}
							onChange={(e) => {
								setFilter({
									...filter,
									search: e.target.value,
								});
							}}
						/>
					</div>
				</div>

				<div className="col">
					<DatePickerField
						hasLabel={true}
						date={filter.date}
						placeholder={backOffice.date}
						label={backOffice.date}
						onChangeDate={(filterDate) => {
							setFilter({
								...filter,
								date: filterDate,
							});
						}}
						//isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
					/>
				</div>
				<div className="col">
					<SelectField
						hasLabel={true}
						value={filter.type}
						placeholder={backOffice.type}
						label={backOffice.type}
						name="type"
						options={typeOptions}
						onChange={(e) => {
							setFilter({
								...filter,
								type: e,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						hasLabel={true}
						value={filter.transaction_type}
						placeholder={backOffice.transctionType}
						label={backOffice.transctionType}
						name="transaction_type"
						options={transTypeOptions}
						onChange={(e) => {
							setFilter({
								...filter,
								transaction_type: e,
							});
						}}
					/>
				</div>

				<div className="col-md">
					<div
						onClick={resetFilters}
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</>
	);
}
