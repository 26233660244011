import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	OfflineReservationsList,
	exportToExcelOfflineReservation,
} from "services/sbsReservations";
import Locale from "translations";
import OfflineReservationFilter from "../listOfflineReservation/Filter";
import OfflineReservationTable from "../listOfflineReservation/Table";

export default function ListOfflineReservations() {
	const { backOffice } = Locale;
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [reservations, setReservations] = useState([]);
	const [metaData, setMetaData] = useState(null);

	const [filters, setFilters] = useState({
		search: "",
		date_from_start: undefined,
		date_from_end: undefined,

		created_at_start: undefined,
		created_at_end: undefined,

		destination_id: "",
		status: "",
	});
	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				search: filters.search,
				destination_id: filters.destination_id?.value,
				date_from_start: filters?.date_from_start
					? moment(filters?.date_from_start).format("YYYY-MM-DD")
					: "",
				date_from_end: filters?.date_from_end
					? moment(filters?.date_from_end).format("YYYY-MM-DD")
					: "",
				created_at_start: filters?.created_at_start
					? moment(filters?.created_at_start).format("YYYY-MM-DD")
					: "",
				created_at_end: filters?.created_at_end
					? moment(filters?.created_at_end).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await OfflineReservationsList(pages, { ...data });
			setReservations(response?.data.data);
			setMetaData(response?.data?.meta);
		}
		fetchReservationsFilterdData();
	}, [
		filters.search,
		filters.destination_id,
		filters.date_from_start,
		filters.date_from_end,
		filters.status,
		filters.created_at_start,
		filters.created_at_end,
		pages,
	]);

	const fetchReservationsData = async (page = pages, filters = {}) => {
		const response = await OfflineReservationsList(page, filters);
		if (response?.status === 200) {
			setReservations(response?.data?.data);
			setMetaData(response?.data?.meta);
		}
	};

	const goTo = (page) => {
		fetchReservationsData(page, null);
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};
	const exportToFile = async () => {
		const res = await exportToExcelOfflineReservation({
			...filters,
			destination_id: filters.destination_id?.value,
			date_from_start: filters?.date_from_start
				? moment(filters?.date_from_start).format("YYYY-MM-DD")
				: "",
			date_from_end: filters?.date_from_end
				? moment(filters?.date_from_end).format("YYYY-MM-DD")
				: "",
			created_at_start: filters?.created_at_start
				? moment(filters?.created_at_start).format("YYYY-MM-DD")
				: "",
			created_at_end: filters?.created_at_end
				? moment(filters?.created_at_end).format("YYYY-MM-DD")
				: "",
			status: filters.status?.value,
			page: pages != 1 ? pages : "",
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`manual-offline-reservations-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="main-title">{backOffice.OfflineReservations}</h6>
				<div class="d-flex" style={{ gap: "10px" }}>
					<button className="btn btn-primary" onClick={() => exportToFile()}>
						{backOffice.ExportToExcel}
					</button>
					<ShowForPermission permission="Offline-Manual-Reservations-Manage">
						<Link
							type="button"
							class="btn  btn-success px-4"
							to="/manual-reservations/offline-reservations/add"
						>
							{backOffice.AddOfflineReservations}
						</Link>
					</ShowForPermission>
				</div>
			</div>
			<OfflineReservationFilter
				reservations={reservations}
				setReservations={setReservations}
				goTo={goTo}
				setMetaData={setMetaData}
				filters={filters}
				setFilters={setFilters}
				exportToFile={exportToFile}
			/>
			<OfflineReservationTable
				reservations={reservations}
				setReservations={setReservations}
				goTo={goTo}
				metaData={metaData}
			/>
		</>
	);
}
