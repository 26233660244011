import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FetchAdsLookups, createAds, updateAds, viewOneAd } from "services/ads";
import { uploadFile } from "services/auth";
import Locale from "translations";
import AdsDetails from "./adsDetails";
import ItemsDetails from "./itemsDetails";
const AddEditPopup = () => {
	const { id, status, id_ads } = useParams();
	const [popupAds, setPopupAds] = useState({
		adName: "",
		type: status == "add" ? { value: 1, label: "destinations" } : "",
		adTitle: "",
		from: "",
		to: "",
		expiryDate: "",

		items_destination: [
			{
				image: "",
				destinationName: "",
				destination_name_ar: "",
				priceStartFrom: "",
				adsLink: "",
			},
		],

		items_hotels: [
			{
				image: "",
				hotelName: "",
				hotel_name_ar: "",
				destination: "",
				dateFrom: "",
				dateTo: "",
				priceStartFrom: "",
				adsLink: "",
				rating: "",
				special_offer: false,
			},
		],
	});
	const { ads, backOffice, hotelLayout } = Locale;
	const history = useHistory();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [AdsLink, setAdsLink] = useState([]);
	const [AdSelected, setAdSelected] = useState({});
	const location = useLocation();
	const data = status == "edit" ? location.state?.detail : null;
	const SBSApi = process.env.REACT_APP_SBS_FRONT_URL + "/market-view?token=";

	function innerObject(arr) {
		let all = {};
		arr.map((item, index) => {
			all = { ...all, ...item };
		});
		return all;
	}
	const checkFormErrors = () => {
		let Error = [];

		if (popupAds.type?.label == "destinations") {
			popupAds.items_destination.map((res, index) => {
				Object.entries(res).forEach(([key, value]) => {
					let newError = {
						...validate(
							{
								name: `${key}${index}`,
								value: `${value}`,
							},
							{
								required: key === "destination_name_ar" ? false : true,
							}
						),
					};
					Error.push(newError);
				});
			});

			Error.push(
				{
					...validate(
						{ name: "from", value: popupAds.from },
						{ required: true }
					),
				},
				{ ...validate({ name: "to", value: popupAds.to }, { required: true }) }
			);
		} else {
			popupAds.items_hotels.map((res, index) => {
				Object.entries(res).forEach(([key, value]) => {
					let newError = {
						...validate(
							{
								name: `${key}${index}`,
								value: `${value}`,
							},
							{
								required: key === "hotel_name_ar" ? false : true,
							}
						),
					};
					Error.push(newError);
				});
			});
			// Error.push(
			// 	{
			// 		...validate(
			// 			{ name: "from", value: popupAds.from },
			// 			{ required: true }
			// 		),
			// 	},
			// 	{ ...validate({ name: "to", value: popupAds.to }, { required: false }) }
			// );
		}
		setErrors({
			...errors,
			...validate(
				{ name: "adName", value: popupAds.adName },
				{ required: true }
			),
			...validate({ name: "type", value: popupAds.type }, { required: true }),
			...validate(
				{ name: "adTitle", value: popupAds.adTitle },
				{ required: true }
			),
			// ...validate({ name: "from", value: popupAds.from }, { required: true }),
			// ...validate({ name: "to", value: popupAds.to }, { required: true }),
			...validate(
				{ name: "expiryDate", value: popupAds.expiryDate },
				{ required: false }
			),

			...innerObject(Error),
		});
	};

	const addOne = () => {
		let clone;
		if (popupAds.type?.label == "destinations") {
			clone = popupAds.items_destination;
			clone.push({
				image: "",
				destinationName: "",
				destination_name_ar: "",
				priceStartFrom: "",
				adsLink: "",
			});
		} else {
			clone = popupAds.items_hotels;
			clone.push({
				image: "",
				hotelName: "",
				hotel_name_ar: "",
				destination: "",
				dateFrom: "",
				dateTo: "",
				priceStartFrom: "",
				adsLink: "",
				rating: "",
				special_offer: false,
			});
		}

		setPopupAds({ ...popupAds });
	};
	const handleItems = (value, index, name) => {
		let clone;
		if (popupAds.type?.label == "destinations") {
			clone = popupAds.items_destination;
			if (name == `adsLink`) {
				clone[index][name] = value;
				//clone[index][`priceStartFrom`] = value?.data?.price;

				clone[index]["link_id"] = value?.id;
				clone[index]["token"] = value?.token;
			} else {
				clone[index][name] = value;
				clone[index]["token"] = value?.token;
			}
			// clone[index][name] = value;
			setPopupAds({ ...popupAds, items_destination: clone });
		} else {
			clone = popupAds.items_hotels;
			// clone[index][name] = value;
			if (name == `adsLink`) {
				clone[index][name] = value;
				clone[index]["dateFrom"] = value?.date_from;
				clone[index]["dateTo"] = value?.date_to;
				clone[index]["destination"] = value?.destination;
				clone[index]["token"] = value?.token;
				clone[index]["link_id"] = value?.id;
			} else {
				clone[index][name] = value;
			}
			setPopupAds({ ...popupAds, items_hotels: clone });
		}
	};
	const deleteOne = (index) => {
		let clone;
		if (popupAds.type?.label == "destinations") {
			clone = popupAds.items_destination;
			if (clone.length > 1) {
				clone.splice(index, 1);
			}
		} else {
			clone = popupAds.items_hotels;
			if (clone.length > 1) {
				clone.splice(index, 1);
			}
		}
		setPopupAds({ ...popupAds });
	};
	const handleImageItem = async (value, index, name) => {
		let clone;
		let formData = new FormData();
		formData.append("file", value);
		formData.append("name", value.name);
		formData.append("bucket", "user");
		const resFile = await uploadFile(formData);
		if (resFile.status === 200) {
			if (popupAds?.type?.label == "destinations") {
				clone = popupAds.items_destination;
				clone[index][name] = resFile.data?.uuid;
			} else {
				clone = popupAds.items_hotels;
				clone[index][name] = resFile.data?.uuid;
			}
		}
	};

	const deleteImageItem = (index, name) => {
		let clone;
		if (popupAds.type?.label == "destinations") {
			clone = popupAds.items_destination[index];
			clone[name] = null;
			clone["displayImg"] = "";
		} else {
			clone = popupAds.items_hotels[index];
			clone[name] = null;
			clone["displayImg"] = "";
		}
		setPopupAds({ ...popupAds });
	};

	useEffect(() => {
		const fetchAds = async () => {
			if (status == "edit") {
				const res = await viewOneAd(id_ads);
				let items_destination = [
					{
						image: "",
						destinationName: "",
						destination_name_ar: "",
						priceStartFrom: "",
						adsLink: "",
					},
				];
				let items_hotels = [
					{
						image: "",
						hotelName: "",
						hotel_name_ar: "",
						destination: "",
						dateFrom: "",
						dateTo: "",
						priceStartFrom: "",
						adsLink: "",
						rating: "",
						special_offer: false,
					},
				];
				debugger;
				if (res?.data?.data?.type == "destinations") {
					let data = res?.data?.data?.item_info.map((item) => {
						return {
							adsLink: { value: item?.value_ads, label: item?.title_ads },
							destinationName: item?.destination_name,
							destination_name_ar: item?.destination_name_ar,
							priceStartFrom: item?.price_start_from,
							image: item?.image,
							token: item?.token,
							link_id: item?.link_id,
						};
					});
					items_destination = data;
				} else {
					let data = res.data?.data?.item_info.map((item) => {
						return {
							adsLink: { value: item?.value_ads, label: item?.title_ads },
							hotelName: item?.hotel_name,
							hotel_name_ar: item?.hotel_name_ar,
							destination: item?.destination,
							priceStartFrom: item?.price_start_from,
							image: item?.image,
							dateFrom: item?.date_from,
							dateTo: item?.date_to,
							rating: item?.rating,
							special_offer: item?.special_offer == 1 ? true : false,
							token: item?.token,
							link_id: item?.link_id,
						};
					});
					items_hotels = data;
				}

				setPopupAds({
					type: { value: res?.data?.data?.type, label: res?.data?.data?.type },
					adName: res?.data?.data?.name,
					adTitle: res?.data?.data?.title,
					from: res?.data?.data?.from_date,
					to: res?.data?.data?.to_date,
					expiryDate: res?.data?.data?.expiry_date,
					publish: res?.data?.data?.publish,

					items_destination,
					items_hotels,
				});
			}
		};
		fetchAds();
	}, []);

	useEffect(() => {
		if (isFormValid(errors)) {
			const CreateEditAds = async () => {
				var res = null;
				var itemslData = null;
				if (popupAds?.type?.label == "destinations") {
					debugger;

					itemslData = popupAds.items_destination.map((item, index) => {
						const token = item?.adsLink.token
							? item?.adsLink.token
							: item?.token;
						return {
							// link: item?.adsLink,
							destination_name: item?.destinationName,
							destination_name_ar: item?.destination_name_ar,
							price_start_from: item?.priceStartFrom,
							image: item?.image,
							expiration_date: item?.expiration_date,
							link: SBSApi + token,
							title_ads: item?.adsLink.label,
							value_ads: item?.adsLink?.value,
							token_ads: item?.adsLink?.token,
							token: item?.adsLink.token ? item?.adsLink.token : item?.token,
							link_id: item?.link_id,
						};
					});
				} else {
					debugger;
					itemslData = popupAds.items_hotels.map((item, index) => {
						const token = item?.adsLink.token
							? item?.adsLink.token
							: item?.token;
						return {
							// link: item?.adsLink,
							hotel_name: item?.hotelName,
							hotel_name_ar: item?.hotel_name_ar,
							// destination: item?.destination,
							price_start_from: item?.priceStartFrom,
							image: item?.image,
							// date_from: item?.dateFrom
							// 	? moment(item?.dateFrom).format("YYYY-MM-DD")
							// 	: "",
							// date_to: item?.dateTo
							// 	? moment(item?.dateTo).format("YYYY-MM-DD")
							// 	: "",
							rating: item?.rating?.value ? item?.rating?.value : item?.rating,
							special_offer: item?.special_offer ? 1 : 0,

							expiration_date: AdSelected?.expiration_date,
							link: SBSApi + token,
							destination: item?.adsLink?.destination
								? item?.adsLink?.destination
								: item?.destination,
							date_from: item?.adsLink?.date_from
								? item?.adsLink?.date_from
								: item?.dateFrom,
							date_to: item?.adsLink?.date_to
								? item?.adsLink?.date_to
								: item?.dateTo,
							title_ads: item?.adsLink?.label, //AdSelected?.title,
							value_ads: item?.adsLink?.value, //AdSelected?.id,
							token_ads: item?.adsLink?.token,
							token: item?.adsLink.token ? item?.adsLink.token : item?.token,
							link_id: item?.link_id,
						};
					});
				}

				let date = {
					country_ad_id: id,
					type: popupAds?.type?.label,
					name: popupAds.adName,
					title: popupAds.adTitle,
					from_date: popupAds.from
						? moment(popupAds.from).format("YYYY-MM-DD")
						: "",
					to_date: popupAds.to ? moment(popupAds.to).format("YYYY-MM-DD") : "",
					expiry_date: popupAds.expiryDate
						? moment(popupAds?.expiryDate).format("YYYY-MM-DD")
						: "",
					publish: status == "add" ? 0 : popupAds.publish,
					item_info: itemslData,
				};
				if (status == "add") {
					res = await createAds(date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Create New Ads Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push(`/view/popup-ads/${id}`);
							},
						});
					}
				} else {
					res = await updateAds(id_ads, date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Edit Ads Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push(`/view/popup-ads/${id}`);
							},
						});
					}
				}
			};
			CreateEditAds();
		}
	}, [isErrorLoaded]);
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	/****** Add Ads Link dropdown ******/

	useEffect(() => {
		const fetchAdLinkLookups = async () => {
			const response = await FetchAdsLookups();
			if (response.status == 200) {
				const data = response?.data?.data?.map((item) => ({
					label: item.title,
					value: item.id,
					...item,
				}));
				setAdsLink(data);
			}
		};
		fetchAdLinkLookups();
	}, [id]);

	return (
		<div className="px-3 popup-ads">
			<h3>{status == "add" ? ads.AddNewPopupAds : ads.EditPopupAds}</h3>

			<AdsDetails
				setPopupAds={setPopupAds}
				popupAds={popupAds}
				setErrors={setErrors}
				errors={errors}
			/>
			<ItemsDetails
				setPopupAds={setPopupAds}
				popupAds={popupAds}
				setErrors={setErrors}
				errors={errors}
				handleItems={handleItems}
				addOne={addOne}
				deleteOne={deleteOne}
				handleImageItem={handleImageItem}
				deleteImageItem={deleteImageItem}
				AdsLink={AdsLink}
				AdSelected={AdSelected}
				setAdSelected={setAdSelected}
			/>
			<div className="d-flex justify-content-end my-2">
				<button
					className="btn btn-gray bg-gray-100 mx-2"
					onClick={() => history.goBack()}
				>
					{backOffice.back}
				</button>

				<button className="btn btn-success" onClick={submit}>
					{status == "add" ? (
						<>{hotelLayout.calendar.saveChanges}</>
					) : (
						<>{backOffice.EditAds}</>
					)}
				</button>
			</div>
		</div>
	);
};

export default AddEditPopup;
