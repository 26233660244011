import Group from "./Group";

export default function ListGroups({ groups, setdeleting }) {
	console.log(groups, "test group");
	const allGroups = groups?.map((group) => (
		<Group group={group} key={group.id} setdeleting={setdeleting} />
	));

	return <div>{allGroups}</div>;
}
