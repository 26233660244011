import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { listRecommendations } from "services/umrahForum";
import Locale from "translations";

export default function Recommendations() {
	const { backOffice } = Locale;
	const [meta, setMeta] = useState(null);
	const [page, setpage] = useState(null);
	const [Recommendations, setRecommendations] = useState([]);
	useEffect(() => {
		async function fetchRecommendations() {
			const res = await listRecommendations({ page });
			if (res.status === 200) {
				setRecommendations(res.data?.data);
				setMeta(res?.data?.meta);
			}
		}
		fetchRecommendations();
	}, [page]);
	const goTo = (page) => {
		setpage(+page);
	};
	return (
		<div className=" table-responsive  our-border  px-3">
			<table className="table table-striped">
				<thead>
					<tr>
						<th>{backOffice.senderName}</th>
						<th>{backOffice.senderEmail}</th>
						<th>{backOffice.senderPhone}</th>
						<th>{backOffice.Userscount}</th>
						<th colSpan={2}>{backOffice.ticketname}</th>
					</tr>
				</thead>
				<tbody>
					{Recommendations.length > 0 ? (
						<>
							{Recommendations.map((data, index) => (
								<tr key={index}>
									<td>{data.sender_name}</td>
									<td>{data.sender_email}</td>
									<td>
										{data.sender_phone_code} {data.sender_phone_number}
									</td>
									<td>{data.users_count}</td>
									<td>{data.ticket_name}</td>
									<td>
										<Link
											to={{
												pathname: `/umrah-forum-recommendations/view/${data.id}`,
											}}>
											<i class="fas fa-chevron-right" />
										</Link>
									</td>
								</tr>
							))}
						</>
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i> <h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<Pagination info={meta} goTo={goTo} items={10} />
		</div>
	);
}
