// Translation
import Locale from 'translations';
// -----------------------------------------------------------------------

export default function RadioGroup(props) {
  // Hooks
  const { commons } = Locale;

  const { label, hasLabel = true, name, value, onChange, groupOptions } = props

  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(commons.isRequired);
        } else if (k === "min" && v) {
          message.push(` should be at least ${props.min} chars`);
        } else if (k === "max" && v) {
          message.push(` should be equal ${props.min} chars`);
        } else if (k === "maxNumber" && v) {
          message.push(` should not exceed ${props.max}`);
        } else if (k === "minNumber" && v) {
          message.push(` should be at least ${props.min}`);
        } else if (k === "email" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "phone" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "number" && v) {
          message.push(commons.shouldBeNumber);
        } else if (k === "notNumber" && v) {
          message.push(commons.shouldBeText);
        }
      }
      if (message.length === 1) {
        return `${props.customLabel || props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.customLabel || props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  // ------- JSX Code --------
  return (
    <div className={`w-100 ${props?.color && 'control-field--danger'} radio-group-holder my-1`}>

      {(label && hasLabel) &&
        <label htmlFor="radio-group" className='radio-group-label'>
          {label}
        </label>
      }

      <div className='inputs-holder'>
        {groupOptions?.map((option) =>
          <label key={option?.value} htmlFor={option?.value}>
            <input
              name={name}
              id={option?.value}
              type='radio'
              value={option?.value}
              onChange={onChange}
              checked={value === option?.value}
              disabled={props?.disabled}
            />
            {' '}
            {option?.name}
          </label>
        )}


      </div>

      <small
        className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
      >
        {generateErrorsMsgs(props.errors)}
      </small>
    </div >
  )

}
