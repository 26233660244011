import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { b2cReservationsView } from "services/b2c";
import Locale from "translations";
import { CalendarIcon, TravelerIcon } from "../../SideBar/Icons";
import B2CDetails from "./BookingDetails";
import Travelers from "./Travelers";

export default function B2CBookingDetails() {
	const [tabStatus, setTabStatus] = useState(1);
	const [reservation, setReservation] = useState();
	const { reservationId } = useParams();
	const history = useHistory();
	const { backOffice } = Locale;

	useEffect(() => {
		const userReservations = async () => {
			const res = await b2cReservationsView(reservationId);
			if (res?.status === 200) {
				setReservation(res?.data?.data);
			}
		};
		userReservations();

		return () => {};
	}, []);

	const tabObj = {
		1: <B2CDetails bookingDetails={reservation} />,
		2: <Travelers travelers={reservation?.travellers} />,
	};
	return (
		<div className="b2c_booking_details">
			<div>
				<h5> {reservation?.user_info?.name}</h5>
				<button className="btn px-0" onClick={() => history.goBack()}>
					<i className="fas fa-arrow-left text-info" />
					<span className="ms-2 text-info">
						{backOffice.BackToReservations}
					</span>
				</button>
			</div>
			<div className="booking_tab_container">
				<button
					className={`booking_tab p-3 ${tabStatus === 1 ? "active" : ""}`}
					onClick={() => setTabStatus(1)}
				>
					<CalendarIcon />
					<h6 className="px-2 m-0">{backOffice.bookingDetails}</h6>
				</button>
				<button
					className={`booking_tab p-3 ${tabStatus === 2 ? "active" : ""}`}
					onClick={() => setTabStatus(2)}
				>
					<TravelerIcon />
					<h6 className="px-2 m-0">{backOffice.travelers}</h6>
				</button>
			</div>
			{reservation && tabObj[tabStatus]}
		</div>
	);
}
