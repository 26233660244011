import classnames from "classnames";
import { useGlobalState } from "context/global";
import { formatFetchReservationView } from "data/sbsREservaiton";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from "react-router";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import {
	acceptResrvationRequests,
	definiteConfirmation,
	fetchGroup,
	fetchGroupReservationPDF,
	fetchGroupReservationVoucher,
	updateReservation,
} from "services/sbsReservations";
// import CancelModal from "../Property/CancelModal";
import { DefinitVoucher } from "components/Printing/DefinitVoucher";
import { TentativePDF } from "components/Printing/TentativePDF";
import Locale from "translations";
import safaImg from "../../../../assets/images/backOffice/safa_logo.png";
import Details from "./Details";
import History from "./History";
import { CancelModal } from "./Main/CancelModal";
import { SendTentativeModal } from "./Main/SendTentativeModal";
import { UpdateTentativeModal } from "./Main/UpdateTentativeModal";
import { ViewTransactionsModal } from "./Main/ViewTransactionsModal";
import { paymentsTotals, roomsTotals } from "./Main/helper";
import Notes from "./Notes";
import Payments from "./Payments";
import Remarks from "./Remarks";
import TermsConditions from "./TermsConditions";

const SBSReservationView = (props) => {
	const { backOffice, booking, requests } = Locale;
	const { allCountries } = useGlobalState();
	const [reservationDetails, setReservationDetails] = useState({
		hotel_name: "",
		supplier_hotel: "",
		destination: "",
		rooms_count: null,
		date_from: moment(new Date()).add(2, "d"),
		date_to: moment(new Date()).add(3, "d"),
		nights: 1,
		created_at: "",
		status: "",
		rooms: [],
		notes: [],
		residence: "",
		nationality: "",
		currency: "",
		terms: "",
		payments: [],
		remarks: [],
	});
	const [edit, setEdit] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState({
		rooms: [],
		info: {},
	});

	let { path, url } = useRouteMatch();
	const { id } = useParams();
	const { push, location } = useHistory();
	const { pathname } = location;
	const paymentsRoute = pathname.includes("payments");

	const groupStatusId = reservationDetails?.status?.id;
	const newRequestStatus = groupStatusId === 1;
	const pendingStatus = groupStatusId === 2;
	const tentativeStatus = groupStatusId === 3 || groupStatusId === 4;
	const reservationStatusCondition = pendingStatus || tentativeStatus;
	const cancelStatus = reservationStatusCondition || groupStatusId === 5;
	const [Reload, setReload] = useState(false);
	useEffect(() => {
		const fetchGroupDetails = async () => {
			const groupReservationsDetails = await fetchGroup(id);
			const data = formatFetchReservationView(
				groupReservationsDetails.data.data
			);
			setReservationDetails(data);
		};
		fetchGroupDetails();
		return () => {};
	}, [Reload]);

	const handleReservationState = ({ name, value, multiValue }) => {
		setReservationDetails((current) => ({
			...current,
			...(multiValue ? value : { [name]: value }),
		}));
	};

	const [cancelGroupModel, setCancelGroupModel] = useState(false);
	const [tentativeModal, setTentativeModal] = useState(false);
	const [updateTentativeModal, setUpdateTentativeModal] = useState(false);

	const cancelGroupModelHandler = () => {
		setCancelGroupModel((current) => !current);
	};
	const tentativeModalHandler = () => {
		setTentativeModal((current) => !current);
	};

	const updateTentativeModalHandler = () => {
		setUpdateTentativeModal((current) => !current);
	};

	const status = reservationDetails["status"]?.name;

	const accept = async () => {
		const res = await acceptResrvationRequests(id);

		if (res.status == 200) {
			const data = formatFetchReservationView(res.data.data);
			setReservationDetails(data);
		}
	};

	const acceptRequests = () => {
		accept();
	};

	// const tentativeRequest = () => {};

	const tabsHeader = [
		{ id: 1, route: "details", name: backOffice.details, hasPermission: true },
		{ id: 2, route: "remarks", name: backOffice.remarks, hasPermission: true },
		{
			id: 3,
			route: "terms-conditions",
			name: requests.TermsConditions,
			hasPermission: cancelStatus,
		},
		{
			id: 4,
			route: "history",
			name: backOffice.History,
			hasPermission: cancelStatus,
		},
		{
			id: 5,
			route: "payments",
			name: booking.Payments,
			hasPermission: cancelStatus,
		},
		{ id: 6, route: "notes", name: booking.Notes, hasPermission: cancelStatus },
	];

	const tabsContent = [
		{
			id: 1,
			route: "details",
			component: (
				<Details
					details={reservationDetails}
					handleReservationState={handleReservationState}
					errors={errors}
					setErrors={setErrors}
					edit={edit}
					Reload={Reload}
					setReload={setReload}
				/>
			),
			hasPermission: true,
		},
		{
			id: 2,
			route: "remarks",
			component: (
				<Remarks
					details={reservationDetails}
					handleReservationState={handleReservationState}
				/>
			),
			hasPermission: true,
		},
		{
			id: 3,
			route: "terms-conditions",
			component: (
				<TermsConditions
					details={reservationDetails}
					handleReservationState={handleReservationState}
				/>
			),
			hasPermission: cancelStatus,
		},
		{
			id: 4,
			route: "history",
			component: <History />,
			hasPermission: cancelStatus,
		},
		{
			id: 5,
			route: "payments",
			component: (
				<Payments
					details={reservationDetails}
					edit={edit}
					handleReservationState={handleReservationState}
				/>
			),
			hasPermission: cancelStatus,
		},
		{
			id: 6,
			route: "notes",
			component: (
				<Notes
					details={reservationDetails}
					handleReservationState={handleReservationState}
				/>
			),
			hasPermission: cancelStatus,
		},
	];

	const checkFormErrors = () => {
		let roomError = [];
		reservationDetails.rooms.map((res) => {
			let newError = {
				...validate(
					{
						name: "net_rate",
						value: res.net_rate,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "selling_rate",
						value: res.selling_rate,
					},
					{
						required: true,
					}
				),
			};

			roomError.push(newError);
		});

		setErrors({
			...errors,
			rooms: roomError,
			info: {
				...validate(
					{
						name: "supplier_hotel",
						value: reservationDetails.supplier_hotel?.value,
					},
					{ required: true }
				),
				...validate(
					{ name: "supplier", value: reservationDetails.supplier },
					{ required: true }
				),
			},
		});
	};

	const checkEditFormErrors = () => {
		let roomError = [];
		reservationDetails.rooms.map((res) => {
			let newError = {
				...validate(
					{
						name: "net_rate",
						value: res.net_rate,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "selling_rate",
						value: res.selling_rate,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "room_count",
						value: res.room_count,
					},
					{
						required: true,
						minNumber: 1,
					}
				),
				...validate(
					{
						name: "room_type",
						value: res.room_type,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "room_view",
						value: res.room_view,
					},
					{
						required: true,
					}
				),
				...validate(
					{
						name: "number_of_adult",
						value: res.number_of_adult,
					},
					{
						required: true,
						minNumber: 1,
					}
				),
			};

			roomError.push(newError);
		});

		setErrors({
			...errors,
			rooms: roomError,
			info: {
				...validate(
					{
						name: "supplier_hotel",
						value: reservationDetails.supplier_hotel?.value,
					},
					{ required: true }
				),
				...validate(
					{ name: "supplier", value: reservationDetails.supplier },
					{ required: true }
				),
				...validate(
					{ name: "hotel_name", value: reservationDetails.hotel_name },
					{ required: true }
				),
				...validate(
					{ name: "date_from", value: reservationDetails.date_from },
					{ required: true }
				),
				...validate(
					{ name: "date_to", value: reservationDetails.date_to },
					{ required: true }
				),
				...validate(
					{ name: "destination", value: reservationDetails.destination },
					{ required: true }
				),
				...validate(
					{ name: "meal_plan", value: reservationDetails.meal_plan },
					{ required: false }
				),
				...validate(
					{ name: "nationality", value: reservationDetails.nationality },
					{ required: true }
				),
				...validate(
					{ name: "residence", value: reservationDetails.residence },
					{ required: true }
				),
			},
		});
	};

	const onTentativeConfirmation = () => {
		push(`${url}/details`);
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const onEditForm = () => {
		!paymentsRoute && push(`${url}/details`);
		checkEditFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const updateReservationRequest = async (details) => {
		const res = await updateReservation(id, {
			...details,
		});

		if (res.status == 200) {
			const data = formatFetchReservationView(res.data.data);
			setReservationDetails(data);
			setEdit(false);
			store.addNotification({
				title: "success!",
				message: "Reservation Updated Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const definiteConfirmationHandler = async () => {
		const response = await definiteConfirmation(id);
		if (response.status == 200) {
			const data = formatFetchReservationView(response.data.data);
			setReservationDetails(data);
		}
	};

	useEffect(() => {
		if (Object.values(errors.info).length > 1 || errors.rooms.length > 0) {
			const flag = errors.rooms.every((error) => {
				if (!isFormValid(error)) {
					return false;
				}
				return true;
			});

			// ****************** Edit Group ****************** //

			if (edit && isFormValid(errors.info) && flag) {
				if (tentativeStatus) {
					const { totalSellingPrice } = roomsTotals(reservationDetails);
					const { total } = paymentsTotals(reservationDetails.payments);

					if (totalSellingPrice <= total && paymentsRoute) {
						///send Request
						const formatRoom = reservationDetails.rooms.map((i) => ({
							id: i.id,
							count: i.room_count,
							num_adults: i.number_of_adult,
							num_children: i.children,
							room_type_id: i.room_type?.value,
							room_view_id: i.room_view?.value,
							net: i.net_rate,
							selling: i.selling_rate,
						}));
						const formatedData = {
							supplier_id: reservationDetails?.supplier?.value,
							hotel_id: reservationDetails.supplier_hotel?.value,
							hotel_name: reservationDetails.hotel_name,
							date_from: reservationDetails.date_from,
							date_to: reservationDetails.date_to,
							destination_id: reservationDetails.destination?.value,
							meal_plan_id: reservationDetails.meal_plan?.value,
							nationality: allCountries.filter(
								(item) => item.id == reservationDetails.nationality?.value
							)[0].country_code,
							residency: allCountries.filter(
								(item) => item.id == reservationDetails.residence?.value
							)[0].country_code,
						};
						const formatPayments = reservationDetails.payments.map((i) => ({
							...i,
							payment_date: i.option_date,
							status: i.status == "pending" ? 1 : 2,
							amount: i?.provider_amount,
						}));

						updateReservationRequest({
							...formatedData,
							rooms: formatRoom,
							payments: formatPayments,
						});

						return;
					}

					if (paymentsRoute) {
						store.addNotification({
							title: "error!",
							message: "Total Amount Not Equal Add Type",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 3000,
								onScreen: true,
								pauseOnHover: true,
							},
						});
					} else {
						// updateTentativeModalHandler();
						push(`${url}/payments`);
					}

					return;
				}

				const formatRoom = reservationDetails.rooms.map((i) => ({
					id: i.id,
					count: i.room_count,
					num_adults: i.number_of_adult,
					num_children: i.children,
					room_type_id: i.room_type?.value,
					room_view_id: i.room_view?.value,
					net: i.net_rate,
					selling: i.selling_rate,
				}));
				const formatedData = {
					supplier_id: reservationDetails?.supplier?.value,
					hotel_id: reservationDetails.supplier_hotel?.value,
					hotel_name: reservationDetails.hotel_name,
					date_from: reservationDetails.date_from,
					date_to: reservationDetails.date_to,
					destination_id: reservationDetails.destination?.value,
					meal_plan_id: reservationDetails.meal_plan?.value,
					// nationality: reservationDetails.nationality?.value,
					// residency: reservationDetails?.residence?.value,
					nationality: allCountries.filter(
						(item) => item.id == reservationDetails.nationality?.value
					)[0].country_code,
					residency: allCountries.filter(
						(item) => item.id == reservationDetails.residence?.value
					)[0].country_code,
				};

				updateReservationRequest({
					...formatedData,
					rooms: formatRoom,
					payments: [],
				});
				return;
			}

			if (isFormValid(errors.info) && flag) {
				setTentativeModal(true);
			}
		}
		return () => {};
	}, [isErrorLoaded]);
	//downLoad Tentative PDF
	const dawnloadTentativePDF = async () => {
		const { data: PDF } = await fetchGroupReservationPDF(id);
		const allRemark = `${
			PDF?.data?.remarks?.length > 0
				? `<div class="hero-section border-bottom-bold py-1">
		<p>Remarks : ${PDF?.data?.remarks?.map((remark, index) => {
			return `<span>${remark.content}</span>`;
		})}</p>
	</div>`
				: ""
		}`;

		const allGuests =
			PDF?.data?.guests && PDF?.data?.guests?.length > 0
				? PDF?.data?.guests?.length > 5
					? PDF?.data?.guests?.slice(0, 4).map((guest, index) => {
							return `<span>${guest.name}</span>`;
					  })
					: PDF?.data?.guests?.map((guest, index) => {
							return `<span>${guest.name}</span>`;
					  })
				: "-";

		const allRooms =
			PDF?.data?.rooms?.length > 0
				? PDF?.data?.rooms.map((room, index) => {
						return `<tr key=${index}>
									<td class="td">${+room?.room_count}</td>
									<td class="td">${room?.room_type?.name} Room</td>
									<td class="td">${room?.selling}</td>
									<td class="td">${room?.room_view?.name}</td>
									<td class="td">${
										PDF?.data?.meal_plan?.name
											? PDF?.data?.meal_plan?.name
											: "-"
									}</td>
									</tr>`;
				  })
				: [];
		if (PDF) {
			debugger;
			let respos = TentativePDF;
			console.log(respos);
			respos = respos.replace("[allRooms]", allRooms);
			respos = respos.replace("[Date]", PDF?.data?.created_at);
			respos = respos.replace("[To]", PDF?.data?.company?.name?.en);
			//PDF?.data?.date_to
			respos = respos.replace("[From]", "Safa Soft");
			//PDF?.data?.date_from
			respos = respos.replace("[Attn]", "");
			respos = respos.replace("[Fax]", "");

			respos = respos.replace("[resNo]", id);
			respos = respos.replace("[arrival_date]", PDF?.data?.date_to);
			respos = respos.replace("[guest_name]", allGuests);
			respos = respos.replace("[hotel_name]", PDF?.data?.hotel_name);
			respos = respos.replace("[hotel_name1]", PDF?.data?.hotel_name);
			respos = respos.replace("[depart_date]", PDF?.data?.date_from);

			respos = respos.replace(
				"[option_date]",
				PDF?.data?.payments[0]?.option_date
			);
			respos = respos.replace("[remarks]", allRemark);
			respos = respos.replace("[net_currency]", PDF?.data?.currency);
			respos = respos.replace("[net_currency1]", PDF?.data?.currency);
			respos = respos.replace("[net_amount]", PDF?.data?.total_sell_price);

			respos = respos.replace("[SafaLogo]", safaImg);
			respos = respos.replace("[status]", reservationDetails?.status?.name);
			respos = respos.replace(
				"[confirmation_number]",
				PDF?.data?.confirmation_number ?? " - "
			);
			respos = respos.replace("[user_name]", PDF?.data?.accepted_by);

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>

					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	//download Voucher
	const dawnloadDefinitVoucher = async () => {
		const { data: voucher } = await fetchGroupReservationVoucher(id);
		const allRemark =
			voucher?.data?.remarks?.length > 0
				? voucher?.data?.remarks?.map((remark, index) => {
						return `<span>${remark.content}</span>`;
				  })
				: "-";
		const allGuests =
			voucher?.data?.guests && voucher?.data?.guests?.length > 0
				? voucher?.data?.guests?.map((guest, index) => {
						return `<span>${guest.name}</span>`;
				  })
				: "-";

		const allRooms =
			voucher?.data?.rooms?.length > 0
				? voucher?.data?.rooms.map((room, index) => {
						return `<div>
								<span class="mx-3">${room.room_type.name}  </span>
								<span>${room.room_view.name}</span>
									</div>`;
				  })
				: [];
		if (voucher) {
			debugger;
			let respos = DefinitVoucher;
			console.log(respos);
			respos = respos.replace("[allRooms]", allRooms);

			respos = respos.replace(
				"[confirmation_number]",
				voucher?.data?.confirmation_number ?? " - "
			);
			respos = respos.replace("[hotel_name]", voucher?.data?.hotel_name);
			respos = respos.replace("[isuee_date]", voucher?.data?.created_at);
			respos = respos.replace("[staff_name]", voucher?.data?.accepted_by);
			respos = respos.replace(
				"[hotel_rsr]",
				voucher?.data?.confirmation_number ?? " - "
			);
			respos = respos.replace("[check_in]", voucher?.data?.date_from);
			respos = respos.replace("[check_out]", voucher?.data?.date_to);
			respos = respos.replace("[guest_name]", allGuests);
			respos = respos.replace("[remarks]", allRemark);

			respos = respos.replace("[SafaLogo]", safaImg);
			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>

					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	return (
		<div
			id="sbs-reservations-main"
			className="d-flex justify-content-between flex-column h-100"
		>
			<div>
				<h4>{backOffice.sbsGroupReservation}</h4>
				{tentativeModal && (
					<SendTentativeModal
						modalState={tentativeModal}
						toggleModel={tentativeModalHandler}
						details={reservationDetails}
						setReservationDetails={setReservationDetails}
					/>
				)}
				{updateTentativeModal && (
					<UpdateTentativeModal
						modalState={updateTentativeModal}
						toggleModel={updateTentativeModalHandler}
						details={reservationDetails}
						setReservationDetails={setReservationDetails}
					/>
				)}
				{cancelGroupModel && (
					<CancelModal
						modalState={cancelGroupModel}
						toggleModel={cancelGroupModelHandler}
						details={reservationDetails}
						setReservationDetails={setReservationDetails}
					/>
				)}
				{false && (
					<ViewTransactionsModal
						modalState={false}
						// toggleModel={tentativeModalHandler}
					/>
				)}

				<Nav tabs style={{ position: "relative" }}>
					{tabsHeader.map((tab) => {
						return tab.hasPermission ? (
							<NavItem key={tab.id}>
								<NavLink
									className={classnames({
										active: pathname.includes(tab.route),
									})}
									onClick={() => {
										push(`${url}/${tab.route}`);
									}}
								>
									{tab.name}
								</NavLink>
							</NavItem>
						) : null;
					})}

					<div className="tap-status">
						<span>{booking.Status}: </span>
						<span
							className={`${
								groupStatusId == 4
									? "definite_color"
									: groupStatusId == 5
									? "cancel_color"
									: ""
							}`}
						>
							{status}
						</span>
					</div>
				</Nav>

				<TabContent style={{ marginTop: 15 }} activeTab="1">
					<Switch>
						{tabsContent.map((pane) => {
							return pane.hasPermission ? (
								<Route path={`${path}/${pane.route}`}>{pane.component}</Route>
							) : null;
						})}
					</Switch>
				</TabContent>
			</div>
			<div>
				<ShowForPermission permission="SBS-Manual-Reservations-Manage">
					{(groupStatusId == 4 || groupStatusId == 3) && (
						<button className="btn btn-primary" onClick={dawnloadTentativePDF}>
							{backOffice.DownloadPDF}
						</button>
					)}

					{groupStatusId == 4 && (
						<button
							className="btn btn-primary mx-2"
							onClick={dawnloadDefinitVoucher}
						>
							{backOffice.DownloadVoucher}
						</button>
					)}
				</ShowForPermission>
			</div>
			<div className="w-100 d-flex justify-content-between mb-4">
				<div className="d-flex gap-2">
					<Link
						className="btn btn-gray p-2 my-2"
						to={`/manual-reservations/sbs-reservations`}
					>
						<i class="fas fa-arrow-left  mx-1"></i>
						{backOffice.BackToReservations}
					</Link>

					<ShowForPermission permission="SBS-Manual-Reservations-Manage">
						{reservationStatusCondition &&
							!edit &&
							pathname.includes("details") && (
								<button
									className="btn btn-gray p-2 my-2"
									onClick={() => {
										setEdit(true);
										if (tentativeStatus) {
											const { totalSellingPrice } =
												roomsTotals(reservationDetails);
											handleReservationState({
												name: "oldTotalPrice",
												value: totalSellingPrice,
											});
										}
									}}
								>
									<i class="fas fa-edit  mx-1"></i>
									{backOffice.edit}
								</button>
							)}
					</ShowForPermission>
				</div>

				<ShowForPermission permission="SBS-Manual-Reservations-Manage">
					<div className="d-flex gap-2">
						{groupStatusId != 5 && (
							<button
								className="btn btn-cancel p-2 my-2"
								onClick={cancelGroupModelHandler}
							>
								{booking.messages.cancel}
							</button>
						)}

						{edit ? (
							<button className="btn btn-info p-2 my-2" onClick={onEditForm}>
								{backOffice.SaveSend}
							</button>
						) : groupStatusId == 3 ? (
							<button
								className="btn btn-success p-2 my-2"
								onClick={definiteConfirmationHandler}
							>
								{backOffice.DefiniteConfirmation}
							</button>
						) : groupStatusId == 1 || groupStatusId == 2 ? (
							<button
								className={`btn  p-2 my-2 ${
									newRequestStatus ? "btn-success" : "btn-dark-orange"
								}`}
								onClick={
									newRequestStatus ? acceptRequests : onTentativeConfirmation
								}
							>
								{newRequestStatus
									? backOffice.AcceptRequest
									: backOffice.TentativeConfirmation}
							</button>
						) : (
							""
						)}
					</div>
				</ShowForPermission>
			</div>
		</div>
	);
};

export default SBSReservationView;
