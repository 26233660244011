import validate from "helpers/validate";
import Locale from "translations";
import PromoHotelItem from "./HotelItem";
export default function PromoHotels({
	promoDataState,
	setPromoDataState,
	errors,
	setErrors,
	initalHotelsObj,
	isEditMode,
}) {
	function handleHotelChange(index, key, value) {
		let clonedHotels = promoDataState?.hotels;
		let currentHotel = clonedHotels[index];
		currentHotel = { ...currentHotel, [key]: value };
		clonedHotels[index] = currentHotel;
		setPromoDataState({ ...promoDataState, hotels: clonedHotels });
		setErrors({
			...errors,
			...validate({ name: `${key}-${index}`, value }, { required: true }),
		});
	}

	function addNewHoteltRow() {
		setPromoDataState({
			...promoDataState,
			hotels: [...promoDataState?.hotels, initalHotelsObj],
		});
	}

	function removeHotelRow(index) {
		let hotelsClone = [...promoDataState?.hotels];
		hotelsClone.splice(index, 1);
		setPromoDataState({
			...promoDataState,
			hotels: hotelsClone,
		});
	}
	const { backOffice } = Locale;
	return (
		<div className="">
			{promoDataState?.hotels?.length > 0 ? (
				promoDataState?.hotels?.map((hotel, index) => {
					return (
						<PromoHotelItem
							key={hotel?.id}
							removeHotelRow={removeHotelRow}
							currency={promoDataState?.currency}
							index={index}
							hotel={hotel}
							cityId={promoDataState?.cityName?.value}
							handleHotelChange={handleHotelChange}
							errors={errors}
							isEditMode={isEditMode}
						/>
					);
				})
			) : (
				<div className="text-center text-danger text-capitalize my-3">
					{backOffice.youmustaddonehotelatleast}
				</div>
			)}

			<div className="d-flex align-items-center justify-content-end w-100">
				<button
					className="btn text-success font-weight-bold shadow-none px-0"
					onClick={addNewHoteltRow}
				>
					<i className="fas fa-plus-circle"></i>
					<span className="mx-1">{backOffice.addHotel}</span>
				</button>
				{/* for spacing */}
				<div className="col-1"></div>
			</div>
		</div>
	);
}
