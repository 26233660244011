import ArrowRightIcon from "assets/images/arrow-right.png";
import CheckBox from "components/chekbox";
import { Arrow } from "modules/backOffice/Components/SideBar/Icons";
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";

export default function ModuleRow({
	moduleData,
	handleModuleSelection,
	noSelection,
	expandAll,
}) {
	const [isOpen, setIsOpen] = useState(expandAll || false);
	const { plan_id } = useParams();

	const toggle = () => setIsOpen(!isOpen);

	let collectionPlan = [];
	const allSubModules =
		moduleData?.length > 0 &&
		moduleData.map((module, index) => {
			collectionPlan = [...collectionPlan, module.id];
			return (
				<div className="module-list-item" key={module.id}>
					<div className="row mx-0">
						<div className="col-md-9 module-name">
							<div
								className="d-flex mt-1 sub-module-name"
								style={{ gap: "8px" }}
							>
								<CheckBox
									type="Checkbox"
									name={module?.name}
									id={module?.id}
									onChange={(e) => {}}
									disabled={!!plan_id}
								/>
								<p className="mx-4">
									{" "}
									<img src={ArrowRightIcon} className="mb-1 mx-2" alt="" />
									{module?.name}
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		});
	const { backOffice } = Locale;

	return (
		<>
			{/** module name Row */}
			<div className="module-list-item">
				<div className="row mx-0" style={{ rowGap: 14 }}>
					<div className="col-12 col-md-9 module-name">
						<div className="d-flex mt-1" style={{ gap: "8px" }}>
							{noSelection ? null : (
								<CheckBox
									type="Checkbox"
									name={moduleData?.name}
									id={moduleData?.id}
									checked={moduleData?.isSelected}
									onChange={(e) => {
										handleModuleSelection(moduleData?.id, e.target.checked);
									}}
									disabled={!!plan_id}
								/>
							)}

							<p>
								<span>{moduleData?.name}</span>
								<button className="p-0 bg-transparent ms-2" onClick={toggle}>
									<Arrow
										style={{
											width: 8,
											transform: `rotate(${isOpen ? "-90deg" : "90deg"})`,
											transition: "transform .3s ease-in-out",
										}}
									/>
								</button>
							</p>
						</div>
					</div>
					<div
						className="col-12 col-md-3 d-flex align-items-center module-price text-center"
						style={{ gap: 8 }}
					>
						<input
							type="number"
							min={1}
							value={moduleData?.price}
							disabled={true}
							className="text-dark mdi-cursor-default fs-5  w-100"
							style={{ fontWeight: 800 }}
						/>

						<span className="fs-5">{moduleData?.currency}</span>
					</div>
				</div>
			</div>
			{/** sub module name row  */}
			{/*** Here Loop for sub module */}
			{allSubModules}

			<Collapse isOpen={isOpen}>
				<Card className="mt-2 border-0">
					<CardBody>
						<p className="h2 mb-4">{backOffice.Includes}: </p>
						<ul className="row w-100" style={{ rowGap: 14, paddingLeft: 12 }}>
							{moduleData.modules.map((item, index) => (
								<li
									className="col-12 col-md-6 fs-5 text-gray list-item-bullet"
									key={index}
								>
									{item.title}
								</li>
							))}
						</ul>
					</CardBody>
				</Card>
			</Collapse>
		</>
	);
}
