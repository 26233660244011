import closeButtonImage from "assets/images/offline-reservation-close-btn.svg";
import SelectField from "components/Form/SelectField/SelectField";
import { Modal } from "reactstrap";
import Locale from "translations";

export default function ConvertOnlineModal(props) {
	const {
		convertModalIsOpen,
		setConvertModalIsOpen,
		companiesLookups,
		selectedCompany,
		setSelectedCompany,
		handleConvertToOnline,
	} = props;
	const { backOffice, booking, Companies } = Locale;
	return (
		<Modal isOpen={convertModalIsOpen} centered={true}>
			<div className="py-3">
				<div className="d-flex flex-row align-items-center justify-content-between px-3 w-100">
					<h4 className="font-weight-bolder m-0">
						{backOffice.ConvertToOnline}
					</h4>
					<button
						type="button"
						className="btn modal_btn-close p-0"
						onClick={() => {
							setConvertModalIsOpen(false);
							setSelectedCompany(null);
						}}
					>
						<img src={closeButtonImage} alt="" />
					</button>
				</div>
				<div className="w-75 my-0 pt-3 mx-auto">
					<SelectField
						hasLabel={true}
						label={backOffice.CompanyNameEmailAddress}
						placeholder={backOffice.CompanyNameEmailAddress}
						options={companiesLookups}
						value={selectedCompany?.label}
						onChange={(e) => {
							setSelectedCompany(e);
						}}
					/>
					{selectedCompany ? (
						<div id="reserv-details" className="px-2 py-2  text-capitalize">
							<>
								<p className="mb-1 font-weight-bolder">
									{backOffice.CompanyDetails}
								</p>
								<div className="row">
									<div class="reserv_details_info_key col-5">
										{backOffice.companyName}
									</div>
									<div class="reserv_details_info_value col-7">
										{selectedCompany?.name}
									</div>
								</div>

								<div className="row">
									<div class="reserv_details_info_key col-5">
										{Companies.email}
									</div>
									<div class="reserv_details_info_value col-7 text-lowercase">
										{selectedCompany?.business_email}
									</div>
								</div>

								<div className="row">
									<div class="reserv_details_info_key col-5">
										{backOffice.country}
									</div>
									<div class="reserv_details_info_value col-7">
										{selectedCompany?.country?.name}
									</div>
								</div>

								<div className="row">
									<div class="reserv_details_info_key col-5">
										{backOffice.name}
									</div>
									<div class="reserv_details_info_value col-7">
										{selectedCompany?.responsible_name}
									</div>
								</div>

								<div className="row">
									<div class="reserv_details_info_key col-5">
										{booking.Phone}
									</div>
									<div class="reserv_details_info_value col-7">
										{selectedCompany?.phone?.phone_code}
										{selectedCompany?.phone?.phone}
									</div>
								</div>
							</>
						</div>
					) : null}
					<div className="w-100 px-2 py-0 my-0">
						<button
							type="button"
							className={`btn btn-success my-2 w-100 ${
								selectedCompany?.value ? "" : "disabled text-muted"
							}`}
							disabled={selectedCompany?.value ? false : true}
							onClick={handleConvertToOnline}
						>
							{backOffice.Convert}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
