import Pagination from "components/shared/Pagination";

import ConfirmModal from "components/Modals/ConfirmModal";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { downloadFileWithUUID } from "helpers/downloadFileWithUUID";
import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
import validate from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
	addManualVisa,
	attachManualVisaToOfflineSupplier,
	deleteManualVisa,
	editManualVisa,
	exportToExcelDirectVisa,
	fetchManualVisaList,
	listOfflineSuppliers,
	togglePaymentDirectVisa,
} from "services/Request";
import { uploadFile } from "services/auth";
import Locale from "translations";
import CustomModal from "../Modal";
import AddDirectVisaModal from "./AddDirectVisaModal";
import DirectVisaFilter from "./Filter";

export default function DirectVisa() {
	const { requests, backOffice } = Locale;
	const { locale, allCountries } = useGlobalState();

	const [offlineSuppliers, setOfflineSuppliers] = useState([]);
	const [addEditSupplierModal, setAddEditSupplierModal] = useState({
		isOpen: false,
		isEdit: false,
	});
	const [selectedOfflineSupplier, setSelectedOfflineSupplier] = useState(null);

	const [countries, setCountries] = useState([]);
	const [meta, setMeta] = useState();
	const [addVisaModal, setAddVisaModal] = useState(false);
	const [filters, setFilters] = useState({
		page: null,
		search: "",
		nationality: null,
		offline_supplier_id: null,
	});
	const [reload, setReload] = useState(false);
	const [allDirectVisas, setAllDirectVisas] = useState([]);
	const [directVisaData, setDirectVisaData] = useState({
		mode: "add",
		passport_number: null,
		nationality: null,
		company_name: null,
		visa_number: "",
		visa_document: {
			fileObject: null,
			fileDisplay: null,
		},
		price: "",
	});
	const [errors, setErrors] = useState({});
	const [confirmationModal, setConfiramtionModal] = useState({
		isOpen: false,
		visaId: null,
	});

	const [isOpen, setIsOpen] = useState(false);
	const [visaSelected, setVisaSelected] = useState();
	const togglePayment = (id = null, mark = null) => {
		setVisaSelected({ id, mark });
		setIsOpen(!isOpen);
	};

	function toggleAddVisaModal(selectedVisa) {
		selectedVisa = selectedVisa || directVisaData;
		setAddVisaModal(!addVisaModal);
		setDirectVisaData(selectedVisa);
		setErrors({});
	}

	function toggleConfirmationModal(visaId) {
		setConfiramtionModal({
			isOpen: !confirmationModal?.isOpen,
			visaId: visaId || null,
		});
	}

	function goTo(page) {
		setFilters({ ...filters, page: page });
	}

	function handleFilters(value, keyName) {
		setFilters({
			...filters,
			[keyName]: value,
		});
	}

	function resetAllFilters() {
		setFilters({
			page: null,
			search: "",
			nationality: null,
			offline_supplier_id: null,
		});
	}
	// get all direct visas
	async function fetchAllDirectVisas() {
		const params = {
			...filters,
			nationality: filters?.nationality?.value,
			offline_supplier_id: filters?.offline_supplier_id?.value,
		};
		const res = await fetchManualVisaList(params);
		if (res?.status === 200) {
			setAllDirectVisas(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	}

	function handleDirectVisaInputs(value, keyName) {
		setDirectVisaData({
			...directVisaData,
			[keyName]: value,
		});

		if (keyName === "passport_number" || keyName === "nationality") {
			setErrors({
				...errors,
				...validate({ name: [keyName], value }, { required: true }),
			});
		}
	}

	function handleDirectVisaDocument(e) {
		setDirectVisaData({
			...directVisaData,
			visa_document: {
				fileDisplay: URL.createObjectURL(e.target.files[0]),
				fileObject: e.target.files[0],
			},
		});
	}
	// handle api response notification
	function handleResponseNotification(statusType, title, msg, reload) {
		store.addNotification({
			title,
			message: msg,
			type: statusType,
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 1000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		reload && setReload((prev) => !prev);
	}

	// save new direct visa
	async function addEditDirectVisa() {
		let uuid = directVisaData?.mode === "edit" ? directVisaData?.uuid : "";
		if (directVisaData?.visa_document?.fileObject) {
			let formData = new FormData();
			formData.append("file", directVisaData.visa_document.fileObject);
			const uploadFileRes = await uploadFile(formData);
			if (uploadFileRes?.status === 200) {
				uuid = uploadFileRes?.data?.uuid;
			} else {
				handleResponseNotification(
					"danger",
					"",
					"error while uploading visa document",
					false
				);
			}
		}

		const data = {
			passport_number: directVisaData.passport_number,
			visa_number: directVisaData.visa_number,
			visa_document: uuid,
			price: directVisaData.price,
			nationality: +directVisaData.nationality?.value,
			company_name: directVisaData?.company_name,
			offline_supplier_id: selectedOfflineSupplier?.value,
			offline_supplier_name: selectedOfflineSupplier?.label,
		};

		let directVisaRes =
			directVisaData?.mode === "add"
				? await addManualVisa(data)
				: directVisaData?.mode === "edit"
				? await editManualVisa(directVisaData?.id, data)
				: null;

		if (directVisaRes?.status === 200) {
			const message =
				directVisaData.mode === "add"
					? "Direct visa added successfully"
					: "Direct visa Edited successfully";
			handleResponseNotification("success", "", message, true);
			toggleAddVisaModal({});
			setSelectedOfflineSupplier(null);
		}
	}

	async function deleteDirectVisa(directVisaId) {
		const deleteRes = await deleteManualVisa(directVisaId);
		if (deleteRes?.status === 200) {
			handleResponseNotification(
				"success",
				"",
				"Direct visa deleted successfully",
				true
			);
			toggleConfirmationModal();
		}
	}

	useEffect(() => {
		fetchAllDirectVisas();
	}, [reload, filters]);

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		if (allCountries && countries.length === 0) {
			countriesLookups();
		}
	}, [allCountries]);

	const changeStatus = async (id, mark) => {
		const res = await togglePaymentDirectVisa(id, mark);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilters({ ...filters });
			togglePayment();
		}
	};

	useEffect(() => {
		const getOfflineSuppliers = async () => {
			const res = await listOfflineSuppliers({ type: "visa" });
			if (res.status === 200 || res.status === 201) {
				const formatted = res.data.data.map((item) => {
					return { value: item.id, label: item.name };
				});
				setOfflineSuppliers(formatted);
			}
		};

		addEditSupplierModal && getOfflineSuppliers();
	}, []);

	//Export File
	const exportToFile = async () => {
		const res = await exportToExcelDirectVisa({
			...filters,
			nationality: filters?.nationality?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`direct-visa-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<div>
			<div
				className=" tabs d-flex align-items-center all-tabs"
				style={{ borderBottom: "1px solid #d9dde0" }}
			>
				<NavLink
					exact={true}
					to="/online-visa/requests/waiting"
					activeClassName="is-active"
					className="d-block p-3 our-taps"
				>
					<i class="fas fa-hourglass-half mx-1"></i>{" "}
					{requests.WaitingForIssuingVisa}
				</NavLink>

				<NavLink
					exact={true}
					to="/online-visa/requests/issued"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
				>
					<i class="fas fa-check-circle mx-1"></i>
					{requests.IssuedVisa}
				</NavLink>

				<NavLink
					exact={true}
					to="/online-visa/requests/direct"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
				>
					{/* <i class="fas fa-check-circle mx-1"></i> */}
					{backOffice.Directvisa}
				</NavLink>
				<NavLink
					exact={true}
					to="/online-visa/requests/eup"
					activeClassName="is-active"
					className="d-block mx-3 p-3 our-taps"
				>
					{backOffice.EUPBarcode}
				</NavLink>
				<ShowForPermission permission="Visa-Requests-Manage">
					<button
						className="btn btn-primary ms-auto"
						onClick={() => toggleAddVisaModal({ mode: "add" })}
					>
						{backOffice.AddNewDirectVisa}
					</button>
				</ShowForPermission>
			</div>

			<DirectVisaFilter
				countries={countries}
				filters={filters}
				handleFilters={handleFilters}
				resetAllFilters={resetAllFilters}
				exportToFile={exportToFile}
				offlineSuppliers={offlineSuppliers}
			/>

			<div className=" table-responsive  px-4 our-border ">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>{backOffice.passport}</th>
							<th>{requests.nationality}</th>
							<th>{backOffice.companyName}</th>
							<th>{backOffice.visaNumber}</th>
							<th>{backOffice.visaDocument}</th>
							<th>{backOffice.createdAt}</th>
							<th>{requests.Price}</th>
							<th width="7%">{backOffice.paymentStatus}</th>
							<th>{backOffice.supplierName}</th>
							<th>{backOffice.actions}</th>
						</tr>
					</thead>

					<tbody>
						{allDirectVisas?.length > 0 ? (
							allDirectVisas?.map((visa) => {
								const noPayment = visa?.payment_mark === 0;
								return (
									<tr className="text-nowrap" key={visa.id}>
										<td>{visa?.passport_number}</td>
										<td>{visa?.nationality?.name[locale]}</td>
										<td>{visa?.company_name}</td>
										<td>{visa?.visa_number}</td>
										<td>
											{visa?.visa_document ? (
												<button
													className="waiting bg-transparent"
													onClick={() =>
														downloadFileWithUUID(
															visa?.visa_document,
															`visa-document-${visa?.passport_number}`
														)
													}
												>
													<i class="fas fa-download"></i>
												</button>
											) : (
												"-"
											)}
										</td>
										<td>
											{visa?.created_at
												? moment(visa?.created_at).format("DD-MM-YYYY")
												: "-"}
										</td>
										<td>{visa?.price ? visa?.price : "-"}</td>
										<td className="text-center">
											{noPayment ? (
												<ShowForPermission permission="Visa-Requests-Manage">
													<Switch
														height={24}
														width={45}
														handleDiameter={20}
														className="mx-2 align-middle"
														onChange={() =>
															noPayment ? togglePayment(visa.id, 1) : null
														}
														checked={noPayment ? false : true}
														disabled={!noPayment}
														onColor={!noPayment && "#1777fb"}
													/>
												</ShowForPermission>
											) : (
												<i class="far fa-check-circle text-success"></i>
											)}
										</td>

										<td>
											{visa?.offline_supplier_id &&
											visa?.offline_supplier_name ? (
												<>
													{visa.offline_supplier_name}
													<i
														onClick={() => {
															setAddEditSupplierModal({
																isOpen: true,
																isEdit: true,
																id: visa.id,
															});

															setSelectedOfflineSupplier({
																value: visa.offline_supplier_name,
																label: visa.offline_supplier_name,
															});
														}}
														class="fas fa-edit text-primary pointer mx-2"
													></i>
												</>
											) : (
												<>
													<button
														className="btn companies-btn w-100"
														onClick={() =>
															setAddEditSupplierModal({
																isOpen: true,
																isEdit: false,
																id: visa?.id,
															})
														}
													>
														Assign
													</button>
												</>
											)}
										</td>

										<td>
											{/* edit button */}
											<ShowForPermission permission="Visa-Requests-Manage">
												<button
													className="btn shadow-none p-0 mx-3"
													onClick={() => {
														toggleAddVisaModal({
															id: visa?.id,
															passport_number: visa?.passport_number,
															nationality: {
																...visa?.nationality,
																value: visa?.nationality?.country_id,
																code: visa?.nationality?.country_code,
																label: visa?.nationality?.name[locale],
															},
															visa_number: visa?.visa_number,
															visa_document: {
																fileObject: null,
																fileDisplay: `${process.env.REACT_APP_API_URL}/api/files/fetch/${visa?.visa_document}`,
															},
															price: visa?.price,
															mode: "edit",
															uuid: visa?.visa_document,
															company_name: visa?.company_name,
														});

														// set selected offline supplier
														setSelectedOfflineSupplier({
															value: visa?.offline_supplier_id,
															label: visa?.offline_supplier_name,
														});
													}}
												>
													<i className="fas fa-edit"></i>
												</button>
												{noPayment && (
													<button
														className="btn shadow-none text-danger p-0 mx-2"
														onClick={() => toggleConfirmationModal(visa?.id)}
													>
														<i class="fas fa-trash"></i>
													</button>
												)}
											</ShowForPermission>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>

			{/* direct visa modal */}
			{addVisaModal ? (
				<AddDirectVisaModal
					addEditDirectVisa={addEditDirectVisa}
					addVisaModal={addVisaModal}
					toggleAddVisaModal={toggleAddVisaModal}
					countries={countries}
					handleDirectVisaDocument={handleDirectVisaDocument}
					directVisaData={directVisaData}
					handleDirectVisaInputs={handleDirectVisaInputs}
					errors={errors}
					offlineSuppliers={offlineSuppliers}
					selectedOfflineSupplier={selectedOfflineSupplier}
					setSelectedOfflineSupplier={setSelectedOfflineSupplier}
				/>
			) : null}
			{/* confiramtion modal to delete visa row */}
			{confirmationModal?.isOpen && confirmationModal?.visaId ? (
				<CustomModal
					header={"Delete Direct Visa"}
					modalIsOpen={confirmationModal}
					toggle={() => toggleConfirmationModal(null)}
					size={"md"}
					centered={false}
				>
					<p>Are You sure you want to delete this visa?</p>
					<div className="d-flex justify-content-end">
						<button
							className="btn btn-primary mx-3 px-3"
							onClick={() => deleteDirectVisa(confirmationModal?.visaId)}
						>
							Yes
						</button>

						<button
							className="btn btn-secondary px-3"
							onClick={() => toggleConfirmationModal(null)}
						>
							No
						</button>
					</div>
				</CustomModal>
			) : null}

			<ConfirmModal
				IsOpen={isOpen}
				toggle={togglePayment}
				message={"Are you sure you want to enable payment this visa?"}
				onConfirm={() => changeStatus(visaSelected?.id, visaSelected?.mark)}
				type="comfirm"
			/>

			{addEditSupplierModal?.isOpen ? (
				<Modal isOpen={addEditSupplierModal?.isOpen}>
					<ModalHeader
						toggle={() => {
							setAddEditSupplierModal({
								...addEditSupplierModal,
								isOpen: false,
							});
							setSelectedOfflineSupplier(null);
						}}
					>
						{addEditSupplierModal?.isEdit ? "Edit Supplier" : "Add Supplier"}
					</ModalHeader>
					<ModalBody>
						<div className="d-flex flex-column">
							<div className="form-group">
								<SelectField
									label={requests.SelectSupplier}
									placeholder={requests.SelectSupplier}
									name="supplier_id"
									options={offlineSuppliers}
									value={selectedOfflineSupplier}
									onChange={(selectedSupplier) => {
										setSelectedOfflineSupplier(selectedSupplier);
									}}
								/>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<button
							className="btn btn-secondary"
							onClick={() => {
								if (
									!addEditSupplierModal?.id &&
									!selectedOfflineSupplier?.value
								)
									return;

								attachManualVisaToOfflineSupplier({
									manual_visa_id: addEditSupplierModal?.id,
									offline_supplier_id: selectedOfflineSupplier?.value,
									offline_supplier_name: selectedOfflineSupplier?.label,
								}).then((res) => {
									if (res.status === 200 || res.status === 201) {
										getNotification(res.data.message);
										setAddEditSupplierModal({
											isOpen: false,
											isEdit: false,
										});
										setSelectedOfflineSupplier(null);
										fetchAllDirectVisas();
									}
								});
							}}
						>
							Save
						</button>
					</ModalFooter>
				</Modal>
			) : null}
		</div>
	);
}
