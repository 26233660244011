// Translation
import Locale from 'translations';
// ReactStrap
import { Modal, ModalBody } from "reactstrap";


// ---------------------------------------------------------

export default function ConfirmModal(props) {
  const { isOpen, toggle, confirmBtnText, status, messageText, icon, onConfirm, onCancel } = props
  // Hooks
  const { safaVisa } = Locale;
  // --------- JSX Code --------
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      className='safaVisa-wrapper confirm-popup'
    >
      <div className="lan d-flex justify-content-between align-items-baseline p-4 ">
        <span className="icon-wrapper mb-0 ms-auto me-auto">
          {icon}
        </span>
      </div>
      <ModalBody className="lan ps-3 pe-3 pt-0 pb-0 d-flex flex-column justify-content-center align-items-center ">

        <div className="w-100 d-flex flex-column justify-content-center align-items-center" >
          <p className='mb-2 main-title'>{safaVisa?.areYouSure}</p>
          <p className='message-title'>{messageText}</p>
        </div>

        <div className="w-100 pt-0 pt-4 pb-4 d-flex gap-2 justify-content-between align-items-center ">
          <button
            className={`btn rounded w-100 ${status === 0 ? 'active-status' : 'delete-btn'}  `}
            onClick={onConfirm}
          >
            {confirmBtnText}
          </button>
          <button
            className="btn rounded cancel-btn w-100"
            onClick={onCancel}
          >
            {safaVisa.cancel}
          </button>
        </div>

      </ModalBody>
    </Modal>
  )
}

