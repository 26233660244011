import { useEffect, useState } from 'react';
// Transalttion
import Locale from 'translations';
// Components
import SelectField from 'components/shared/SelectField';
// Module Component
import MultiDatePicker from 'components/shared/MultiDatePicker';
// Context
import { useGlobalState } from 'context/global';
// Icons
import { ResetIcon } from 'components/Icons';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Moment
import moment from "moment-hijri";
// ------------------------------------------------------------------------
export default function PackagesFilter({ filters, onFilter, onApplyFilter }) {
  // *** Hooks
  const { safaVisa } = Locale

  //*** Context
  const { allCountries, allCurrencies } = useGlobalState();

  // *** Custom Hook
  const { formattedCountries,
    formattedCurrencies,
    calendarTypesOprtion,
    packagesTypeOptions,
    audienceTypeOptions,
    maxHijriDate
  } = useSafaVisaData();

  const allAudienceTypeOptions = [
    ...audienceTypeOptions
    , { label: safaVisa?.directed, value: 'direct' }]
  // *** State
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  // *** Function
  const handleSearchFilter = ({ key, value }) => {
    onFilter({
      action: "filter",
      key: key,
      payload: value,
    });
  }


  useEffect(() => {
    setCountries(formattedCountries(allCountries));
    setCurrencies(formattedCurrencies(allCurrencies));
  }, [allCountries, allCurrencies]);

  // console.log(filters?.calendar_type?.value)
  // --------- JSX Code ---------
  return (
    <div className='safa-visa-filter'>
      <div className='row align-items-center'>

        {/* Country Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <SelectField
              label={safaVisa.select}
              hasLabel={false}
              options={countries}
              placeholder={safaVisa.country}
              id="country_ids"
              value={filters?.country_ids}
              onChange={(e) => handleSearchFilter({ key: 'country_ids', value: e })}
            />
          </div>
        </div>

        {/* Currency Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <SelectField
              label={safaVisa.select}
              hasLabel={false}
              options={currencies}
              placeholder={safaVisa.currency}
              id="currency"
              value={filters?.currency}
              onChange={(e) => handleSearchFilter({ key: 'currency', value: e })}
            />
          </div>
        </div>

        {/* Package Type Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <SelectField
              // label={safaVisa.select}
              hasLabel={false}
              options={packagesTypeOptions}
              placeholder={safaVisa.package}
              id="package_type"
              value={filters?.package_type}
              onChange={(e) => handleSearchFilter({ key: 'package_type', value: e })}
            />
          </div>
        </div>

        {/* Audience Type Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <SelectField
              hasLabel={false}
              options={allAudienceTypeOptions}
              placeholder={safaVisa.audienceType}
              id="audience_type"
              value={filters?.audience_type}
              onChange={(e) => handleSearchFilter({ key: 'audience_type', value: e })}
            />
          </div>
        </div>

        {/* Calender Type Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <SelectField
              label={safaVisa.select}
              hasLabel={false}
              options={calendarTypesOprtion}
              placeholder={safaVisa.calendarType}
              id="calendar_type"
              value={filters?.calendar_type}
              onChange={(e) => handleSearchFilter({ key: 'calendar_type', value: e })}
            />
          </div>
        </div>

        {/* Start Date Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <MultiDatePicker
              placeholder={safaVisa.fromDate}
              type={filters?.calendar_type?.value}
              value={filters?.date_from}
              onChangeDate={(e) => handleSearchFilter({ key: 'date_from', value: e?.toDate() })}
              format={'YYYY/MM/DD'}
              maxDate={filters?.calendar_type?.value === 'gregorian' ?
                moment(filters?.date_to).format('YYYY/MM/DD')
                :
                moment(filters?.date_to).format("iYYYY/iMM/iDD")
              }
            />
          </div>
        </div>

        {/* End Date Input */}
        <div className='col-3'>
          <div style={{ width: '100%', flex: 1 }}>
            <MultiDatePicker
              placeholder={safaVisa.toDate}
              type={filters?.calendar_type?.value}
              value={filters?.date_to}
              onChangeDate={(e) => handleSearchFilter({ key: 'date_to', value: e?.toDate() })}
              format={'YYYY/MM/DD'}
              minDate={
                filters?.calendar_type?.value === 'gregorian' ?
                  moment(filters?.date_from).format('YYYY/MM/DD')
                  :
                  moment(filters?.date_from).format("iYYYY/iMM/iDD")
              }
              maxDate={filters?.calendar_type?.value === 'gregorian' ? "2100/12/31" : maxHijriDate}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className='col-3'>
          <div
            className='d-flex gap-2 justify-content-center align-items-center'
            style={{ width: '100%' }}
          >
            <button
              className="btn filter-btn w-100"
              onClick={() => onFilter({
                action: 'reset'
              })}
            >
              <ResetIcon color='#1E85FF' />
              {safaVisa.clear}
            </button>
            <button
              className="btn add-btn w-100"
              onClick={onApplyFilter}
            >
              {safaVisa.apply}
            </button>
          </div>
        </div>

      </div >
    </div>
  )
}

