import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import VisaRow from "./groundRow";

export default function AddVisa({
	country,
	destination,
	setDestination,
	indexConfiguration,
	setIndexConfiguration,

	inputsState,
	setInputsState,

	arrayCountry,
	setArrayCountry,
	viewConfiguration,
	setviewConfiguration,
	countries,
	serviceType,
}) {
	// let locale = localStorage.getItem("currentLocale") || "en";
	const { allCurrencies } = useGlobalState();
	// const { backOffice } = Locale;

	//state no main
	//state main
	const [VisaRowState, setVisaRowState] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	// const handleVisa = (e, data) => {
	// 	console.log(viewConfiguration);
	// 	let newData = viewConfiguration;
	// 	let counrtyX = newData.filter((res) => res.country == data.country)[0];
	// 	counrtyX.visa_types.push({
	// 		id: e.value,
	// 		name: e?.label,
	// 		residences: [{ residence: "", currency: "" }],
	// 	});
	// 	setviewConfiguration([...newData]);
	// };
	const handleDelete = (index, ind = 0) => {
		let clone = [...viewConfiguration];
		clone = clone.filter((res, index) => ind != index);
		setviewConfiguration(clone);
	};
	//Collapse
	const [alertToggle, setAlertToggle] = useState(false);

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);
	const { backOffice } = Locale;

	return (
		<>
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					{viewConfiguration?.map((configData, index) => (
						<div className="p-0 border mb-2">
							<div className=" header-add-visa  mt-2 px-4 d-flex justify-content-between">
								<p className="country-visa">{configData?.country}</p>

								<ShowForPermission permission="Ground-Configuration-Manage">
									<div className="visa-select d-flex align-items-center ">
										<span
											className="btn-link text-danger mx-1 w-100 pointer"
											onClick={() => handleDelete(indexConfiguration, index)}
										>
											<i class="fas fa-trash"></i> {backOffice.Remove}
										</span>
									</div>
								</ShowForPermission>
							</div>
							<Collapse isOpen={true}>
								<VisaRow
									index={index}
									country={configData?.country}
									//visa={item}
									handleDelete={handleDelete}
									destination={destination}
									setDestination={setDestination}
									indexConfiguration={index}
									setIndexConfiguration={setIndexConfiguration}
									setviewConfiguration={setviewConfiguration}
									viewConfiguration={viewConfiguration}
									inputsState={(inputsState = configData?.residences)}
									setInputsState={setInputsState}
									arrayCountry={arrayCountry}
									setArrayCountry={setArrayCountry}
									configData={configData?.residences}
									allConfigData={configData}
									setVisaRowState={setVisaRowState}
									VisaRowState={VisaRowState}
									currencies={currencies}
									countries={countries}
									serviceType={serviceType}
									alertToggle={alertToggle}
									setAlertToggle={setAlertToggle}
								/>
							</Collapse>
						</div>
					))}
				</>
			) : (
				""
			)}
		</>
	);
}
