
import { useState } from 'react';
// Translation
import Locale from 'translations';
// ReactStrap
import { Modal, ModalBody } from "reactstrap";
// Components
import SelectField from 'components/shared/SelectField';
import TextField from 'components/shared/TextField';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// import { MultiSelect } from 'react-multi-select-component';
// --------------------------------------------------------------------------
function AddEditVisaTypeModal(props) {

  const { isOpen, listLength, visaInfo, setVisaInfo, toggle, onSave } = props

  // Hooks
  const { safaVisa, commons } = Locale;

  // *** Custom Hook
  const { requiredVisasInputs, visaTypesOptions } = useSafaVisaData();

  // ** State
  const [errors, setErrors] = useState();

  // *** Functions 
  const checkFormErrors = () => {
    let submitError = {};
    requiredVisasInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: visaInfo[key] },
          { required: true }
        )
      }
    })
    setErrors((prev) => ({ ...prev, ...submitError }));
    return submitError;
  }

  const handleChangeInputs = ({ key, value }) => {
    const visaDataClone = { ...visaInfo }
    visaDataClone[`${key}`] = value
    visaDataClone[`id`] = visaDataClone?.id ? visaDataClone?.id : Number(listLength) + 1
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setVisaInfo({ ...visaDataClone })
  }


  async function submit(e, submitType) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    onSave()
  }
  // ---------- JSX Code ----------
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      className='safaVisa-wrapper'
    >
      <div className="lan d-flex justify-content-between align-items-baseline p-4 ">
        <h4 className="main-title mb-0 ms-auto me-auto"> {safaVisa?.addEditVisa} </h4>
        <i
          className="fas fa-times text-black p-1 pointer"
          onClick={() => toggle(null)}
        ></i>
      </div>
      <ModalBody className="lan d-flex flex-column justify-content-center align-items-center ">

        <form onSubmit={submit} className="w-100 ps-2 pe-2">

          <div className="w-100" >

            <div className='mb-1 d-flex justify-content-between align-items-center'>

              <p>{safaVisa?.visaType} *</p>


              <div className={`flex-grow-1 control-field__feedback--${errors?.visa_type?.required ? 'danger' : ''}  mx-2`}>
                <SelectField
                  hasLabel={false}
                  label={safaVisa?.visaType}
                  placeholder={safaVisa?.select}
                  id="visa_type"
                  name="visa_type"
                  value={visaInfo?.visa_type}
                  options={visaTypesOptions}
                  onChange={(e) => handleChangeInputs({ key: 'visa_type', value: e })}
                  color={errors?.visa_type?.required ? "danger" : ""}
                  errors={errors?.visa_type}
                  disabled={visaInfo?.editMode}
                />
                {/* <MultiSelect
                    options={visaTypesOptions?.filter((item) => item?.value !== 'all')}
                    id="visa_type"
                    name="visa_type"
                    isMulti
                    valueRenderer={valueRenderer}
                    onChange={(e) => { handleChangeInputs({ key: 'visa_type', value: e }) }}
                    value={visaPriceInfo?.visa_type || []}
                    overrideStrings={{
                      "selectSomeItems": safaVisa?.select, // Custom placeholder text
                      "allItemsAreSelected": "All selected",
                      "clearSearch": "Clear",
                    }}
                  /> */}
                {/* Error Message */}
                {/* <small
                    className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
                  >
                    {generateErrorsMsgs(errors?.visa_type, { label: safaVisa?.visaType })}
                  </small> */}
              </div>

            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <p>{safaVisa?.count} *</p>
              <div className='flex-grow-1 mx-2'>
                <TextField
                  type="number"
                  hasLabel={false}
                  label={safaVisa?.count}
                  placeholder={safaVisa?.amount}
                  name="amount"
                  id="amount"
                  value={visaInfo?.amount}
                  onChange={(e) => handleChangeInputs({ key: 'amount', value: e?.target?.value })}
                  color={errors?.amount?.required ? "danger" : ""}
                  errors={errors?.amount}
                />
              </div>
            </div>

          </div>

          <div className="w-100 pt-0 pb-4 border-top-0 text-center ">
            <button
              type='submit'
              className="btn rounded add-btn mt-3 w-100"
            //   onClick={onSave}
            // // disabled={popupInfo.amount?.length > 9}
            >
              {safaVisa.save}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AddEditVisaTypeModal