import axios from "axios";
import { cleanEmpty } from "./general";
const umrahforumURL = process.env.REACT_APP_API_URL + "/api/v1/safa-event";

export const listApplicants = async (page) => {
	return await axios
		.get(`${umrahforumURL}/applicants`, { params: cleanEmpty(page) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const listAccepted = async (page) => {
	return await axios
		.get(`${umrahforumURL}/accepted`, { params: cleanEmpty(page) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const listRecommendations = async (page) => {
	return await axios
		.get(`${umrahforumURL}/recommendations`, { params: cleanEmpty(page) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const viewRecommendations = async (id) => {
	return await axios
		.get(`${umrahforumURL}/recommendations/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
