import axios from "axios";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useState } from "react";
import { store } from "react-notifications-component";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	airlineHandleChangePending,
	airlineHandleWaiting,
	deleteFlightTicket,
	sendChangeRequestEmail,
} from "services/airlines";
import Locale from "translations";
import ConfirmPendingRequestModal from "../Reservations/view/Requests/Modals/ConfirmPendingRequest";
import HandleRoutes from "../Reservations/view/Requests/Modals/HandleRoutes";
import RejectRequestModal from "../Reservations/view/Requests/Modals/RejectRequest";
import RequestItemHeader from "./RequestItemHeader";

export default function ChangeRequest({ request, fetchData, isView = false }) {
	const { backOffice, translate_companies } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [confirmPendingModal, setConfirmPendingModal] = useState(false);
	const [routeModal, setRouteModal] = useState(false);
	const [passengerCollapse, setPassengerCollapse] = useState([]);
	const [rejectReason, setRejectReason] = useState("");
	const [selectedTicket, setSelectedTicket] = useState(null);
	function toggleCollapse() {
		setIsOpen(!isOpen);
	}
	function togglePassengerCollapse(index) {
		setPassengerCollapse((prev) => {
			const clone = [...prev];
			clone[index] = !clone[index];
			return clone;
		});
	}
	function toggleConfirmModal(xType = "accept") {
		setConfirmModalOpen({
			...confirmModalOpen,
			status: !confirmModalOpen.status,
			type: xType,
		});
	}
	function toggleConfirmPendingModal() {
		setConfirmPendingModal(!confirmPendingModal);
	}

	function toggleRejectModal() {
		setRejectModal(!rejectModal);
	}
	async function deleteRequestModal(ticketId) {
		const res = await deleteFlightTicket(request.id, ticketId);
		if (res.status === 200) {
			fetchData();
			store.addNotification({
				title: "Ticket",
				message: `Ticket Deleted Successfully`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}
	const toggleAddRouteModal = (ticket) => {
		setRouteModal((prev) => !prev);
		setSelectedTicket(ticket);
	};
	const toggleEditRouteModal = (ticket) => {
		setRouteModal((prev) => !prev);
		setSelectedTicket(ticket);
	};

	async function handleRejectRequest(e) {
		let data = {
			request_id: request.id,
			action: "reject",
			reject_reason: e.reject_reason,
		};
		const res = await airlineHandleChangePending(data);
		if (res.status === 200 || res.status === 201) {
			toggleRejectModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const handleAcceptRequest = async (e) => {
		let data = {
			request_id: request.id,
			action: "accept",
			amount: e.amount,
		};
		const res = await airlineHandleChangePending(data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmPendingModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const handleAcceptWaiting = async (status) => {
		let data;
		if (status === "reject") {
			data = {
				request_id: request.id,
				action: status,
				reject_reason: rejectReason,
			};
		} else {
			data = {
				request_id: request.id,
				action: status,
			};
		}
		const res = await airlineHandleWaiting("change", data);
		if (res.status === 200 || res.status === 201) {
			toggleConfirmModal();
			fetchData();
			store.addNotification({
				title: "Change",
				message: "Reservation Changed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	// "pending" | "rejected" | "pending approval" | "under review" | "confirmed";
	const status = request?.status;

	function downloadCertFiles() {
		const certFiles = request?.certification_files;
		const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
		const config = {
			fetchFilesURL,
			method: "GET",
			responseType: "blob",
		};
		for (let i = 0; i < certFiles.length; i++) {
			axios.get(`${fetchFilesURL}/${certFiles[i]}`, config).then((res) => {
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				const anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`${certFiles[i]}.${res.data.type.split("/")[1]}`
				);
				anchor.click();
			});
		}
	}

	const newPayload = request?.new_payload;
	const originalPayload = request?.original_payload;
	const passengerPayload = newPayload ?? originalPayload;

	const getOldValue = (passengerIndex, ticket, key, time) => {
		const originalTicket = originalPayload?.[passengerIndex]?.tickets.find(
			(i) => i.id === ticket.id
		);

		let changedValue = null;
		let newPayloadValue = ticket?.[key];
		let originalPayloadValue = originalTicket?.[key];

		///// check for Date and Time /////
		if (
			key.includes("date") &&
			time &&
			originalPayloadValue &&
			newPayloadValue
		) {
			newPayloadValue = moment(newPayloadValue).format("HH:mm");
			originalPayloadValue = moment(originalPayloadValue).format("HH:mm");
		} else if (
			key.includes("date") &&
			originalPayloadValue &&
			newPayloadValue
		) {
			newPayloadValue = moment(newPayloadValue).format("DD/MM/YYYY");
			originalPayloadValue = moment(originalPayloadValue).format("DD/MM/YYYY");
		}

		///// check for Date and Time /////

		if (
			typeof newPayloadValue == "string" &&
			typeof originalPayloadValue == "string"
		) {
			newPayloadValue = newPayloadValue.toLowerCase();
			originalPayloadValue = originalPayloadValue.toLowerCase();
		}

		if (newPayload && newPayloadValue !== originalPayloadValue) {
			changedValue = originalPayloadValue;
		}
		return changedValue;
	};

	const canApply = status === "confirmed" && !request?.is_confirmed;

	const sendEmail = async () => {
		const res = await sendChangeRequestEmail(request.id);
		if (res.status === 200) {
			fetchData();
			store.addNotification({
				title: "Confirm",
				message: "Confirmed Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<div className="my-3">
			<RequestItemHeader
				isOpen={isOpen}
				toggleCollapse={toggleCollapse}
				requestTypeText={backOffice.changeRequest}
				status={status}
				details={request}
				isView={isView}
			/>

			<Collapse isOpen={isOpen}>
				<div className="flight-requests-details">
					<div className="row m-0 justify-content-end ">
						<div className="col-md-6 col-12">
							{/* flights have been changed  */}
							<div className="flight-requests-refund-passenger mb-1">
								{backOffice.ChangedFlight}
							</div>

							{request?.journeys?.map((item) => (
								<div
									key={item?.id}
									className="flight-requests-change-passenger pt-3 row justify-content-start "
								>
									<div className="col-3 pt-2">
										<p className="mx-1 mb-0">{backOffice.Route}</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{`${item?.journey_info?.departure} - ${item?.journey_info?.arrival}`}
										</p>
									</div>
									<div className="col-3 pt-2">
										<p className="mx-1 mb-0">{backOffice.date}</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{moment(item?.date).format("YYYY/MM/DD")}
										</p>
									</div>
									<div className="col-3 pt-2">
										<p className="mx-1 mb-0">{backOffice.Time}</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{moment(item?.date).format("HH:mm")}
										</p>
									</div>
									<div className="col-3 pt-2">
										<p className="mx-1 mb-0">{backOffice.Class}</p>
										<p className="mx-1 mb-0 font-weight-bold">
											{item?.cabin_class}
										</p>
									</div>
								</div>
							))}
							{/* passengers data */}
							<div className="d-flex flex-column mt-3">
								<h2 className="text-capitalize blue h6">
									{backOffice.passengers}
								</h2>
								{passengerPayload?.map((passenger, index) => {
									return (
										<div key={passenger.id}>
											<div className="flight-requests-refund-passenger p-0 mb-1">
												<div className="ps-2 py-2">
													<i className="far fa-user-circle"></i>
													<span className="mx-1">
														{`${passenger?.firstName} / ${passenger.lastName}`}
													</span>
												</div>
												<span className="col-3">{passenger?.psgType}</span>

												<button
													className="btn-toggel btn"
													onClick={() => togglePassengerCollapse(index)}
												>
													{passengerCollapse[index] ? (
														<i className="fas fa-chevron-up"></i>
													) : (
														<i className="fas fa-chevron-down"></i>
													)}
												</button>
											</div>
											<Collapse isOpen={Boolean(passengerCollapse[index])}>
												{passenger.tickets?.map((item) => (
													<div key={item.id}>
														<div className="d-flex justify-content-between">
															<div className="segment-header">
																<span className="font-weight-bold">
																	{" "}
																	{`${item?.departure?.code} - ${item?.arrival?.code} | ${item?.airline?.name} |`}
																</span>{" "}
																<span>{backOffice.FlightNo}:</span>
																<span className="font-weight-bold">
																	{item.flight_num}
																</span>
															</div>
															{canApply && (
																<div>
																	<button
																		className="btn text-success py-0"
																		onClick={() =>
																			toggleEditRouteModal({
																				...item,
																				passengerId: passenger.id,
																				update: true,
																			})
																		}
																	>
																		<i className="fas fa-pencil-alt pe-1"></i>
																		{backOffice.edit}
																	</button>
																	<button
																		className="btn text-danger py-0"
																		onClick={() => deleteRequestModal(item.id)}
																	>
																		<i className="fas fa-trash pe-1"></i>
																		{backOffice.delete}
																	</button>
																</div>
															)}
														</div>
														<div className="flight-requests-change-passenger pt-3 row justify-content-start ">
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.DepartureTerminal}
																</p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.departure_terminal}
																	{getOldValue(
																		index,
																		item,
																		"departure_terminal"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_terminal"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.ArrivalTerminal}{" "}
																</p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.arrival_terminal}
																	{getOldValue(
																		index,
																		item,
																		"arrival_terminal"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_terminal"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.FlightNo}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.ticket_number}
																	{getOldValue(
																		index,
																		item,
																		"ticket_number"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"ticket_number"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">PNR </p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.pnr}
																	{getOldValue(index, item, "pnr") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "pnr")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>

															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.SegmentID}{" "}
																</p>
																<p className="mx-1 mb-0 font-weight-bold text-break">
																	{item?.segment_id}
																	{getOldValue(index, item, "segment_id") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "segment_id")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">{backOffice.Class} </p>
																<p className="mx-1 mb-0 font-weight-bold">
																	{item?.cabin_class}
																	{getOldValue(index, item, "cabin_class") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"cabin_class"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.Equipment}{" "}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{item?.equipment}
																	{getOldValue(index, item, "equipment") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(index, item, "equipment")}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.departureDate}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.departure_date).format(
																		"DD/MM/YYYY"
																	)}
																	{getOldValue(
																		index,
																		item,
																		"departure_date"
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_date"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.DepartureTime}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.departure_date).format(
																		"hh:mm a"
																	)}
																	{getOldValue(
																		index,
																		item,
																		"departure_date",
																		true
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"departure_date",
																					true
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.arrivalDate}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.arrival_date).format(
																		"DD/MM/YYYY"
																	)}
																	{getOldValue(index, item, "arrival_date") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_date"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.ArrivalTime}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{moment(item?.arrival_date).format("hh:mm a")}
																	{getOldValue(
																		index,
																		item,
																		"arrival_date",
																		true
																	) && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"arrival_date",
																					true
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
															<div className="col-3 pt-2">
																<p className="mx-1 mb-0">
																	{backOffice.FlightTime}
																</p>
																<p className="mx-1 mb-0 font-weight-bold ">
																	{`${item?.flight_time}M`}
																	{getOldValue(index, item, "flight_time") && (
																		<span className="old-value">
																			<span>
																				{getOldValue(
																					index,
																					item,
																					"flight_time"
																				)}
																			</span>
																			<i class="fas fa-info-circle"></i>
																		</span>
																	)}
																</p>
															</div>
														</div>
														<div className="d-flex justify-content-between">
															{canApply && (
																<button
																	className="btn text-primary"
																	onClick={() =>
																		toggleAddRouteModal({
																			...item,
																			passengerId: passenger.id,
																		})
																	}
																>
																	<i className="fas fa-plus-circle pe-1"></i>
																	{backOffice.AddRoutes}
																</button>
															)}
														</div>
													</div>
												))}
											</Collapse>
										</div>
									);
								})}
							</div>
							{/* change reason */}
							<div className="mt-3">
								<h3 className="my-2 text-capitalize blue h6">
									{backOffice.changeReason}
								</h3>
								<div className="d-flex align-items-center justify-content-between">
									<span className="flight-requests-box p-1">
										{request?.reason}
									</span>
									{request?.certification_files &&
										request?.certification_files?.length > 0 && (
											<button
												className="download-cert"
												onClick={downloadCertFiles}
											>
												<i class="fas fa-download"></i>
												<span className="mx-1">
													{backOffice.downloadCertificationFile}
												</span>
											</button>
										)}
								</div>
								{request?.remarks && (
									<p className="flight-requests-box p-1 mt-2 lh-1">
										{request?.remarks}
									</p>
								)}
							</div>
						</div>

						<div className="col-md-6 col-12">
							{/* contact info */}
							<RequestContactInfo details={request} />

							{/* actions and status */}
							{status === "rejected" && (
								<div className="flight-requests-danger-box py-1 px-2 mt-3">
									<h3 className="text-capitalize my-2 h6">
										{backOffice.changeRejectReason}
									</h3>
									<p>{request?.reject_reason}</p>
								</div>
							)}

							{(status === "confirmed" ||
								status === "waiting_approval" ||
								status === "pending_approval") && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<h3 className="text-capitalize mb-2 blue h6">
										{backOffice.ChangeFees}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{backOffice.fees}</span>
										<span className="text-danger">{`${request?.amount} ${request?.currency}`}</span>
									</p>
								</div>
							)}

							{status === "pending" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									{/* <h3 className="text-capitalize mb-2 h6">
										{backOffice.refundAmount}
									</h3>
									<p className="d-flex align-items-center justify-content-between font-weight-bold px-3 py-2 bg-white">
										<span>{backOffice.amount}</span>
										<span className="text-danger">180.00 SAR</span>
									</p> */}
									<ShowForPermission permission="Manage-Airlines-Requests">
										<div className="flight-requests-refund-actions mt-2">
											<button
												className="reject-btn mx-3"
												onClick={toggleRejectModal}
											>
												{translate_companies.reject}
											</button>
											<button
												className="accept-btn"
												onClick={toggleConfirmPendingModal}
											>
												{backOffice.confirm}
											</button>
										</div>
									</ShowForPermission>
								</div>
							)}

							{status === "waiting_approval" && (
								<div className="flight-requests-refund-amount p-2 mt-3">
									<ShowForPermission permission="Manage-Airlines-Requests">
										<div className="flight-requests-refund-actions mt-2">
											<button
												className="reject-btn mx-3"
												onClick={() => toggleConfirmModal("reject")}
											>
												{translate_companies.reject}
											</button>

											<button
												className="accept-btn"
												onClick={() => toggleConfirmModal("accept")}
											>
												{backOffice.confirm}
											</button>
										</div>
									</ShowForPermission>
								</div>
							)}
						</div>
						{canApply && newPayload && (
							<button onClick={sendEmail} className="accept-btn">
								{backOffice.ConfirmAll}
							</button>
						)}
					</div>
				</div>
			</Collapse>

			{/* confirm request modal */}
			{confirmModalOpen.status && (
				<Modal isOpen={confirmModalOpen.status} centered={true}>
					<ModalHeader toggle={toggleConfirmModal}>
						<span>{backOffice.confirmRequest}</span>
					</ModalHeader>
					<ModalBody>
						<div className="p-3 text-center">
							{confirmModalOpen.type === "accept" ? (
								<i
									className="fas fa-question-circle"
									style={{ fontSize: "3.6rem", color: "#F0E5D3" }}
								/>
							) : (
								<textarea
									placeholder={`${backOffice.reasonForRejection}...`}
									name="reject-reason"
									id="reject-reason"
									rows="5"
									className="w-100 control-field__input h-auto"
									value={rejectReason}
									onChange={(e) => setRejectReason(e.target.value)}
								/>
							)}

							{confirmModalOpen.type === "accept" && (
								<p className="py-4 h5 font-weight-bold">
									{backOffice.youSureConfirmRequest}
								</p>
							)}
							<button
								className={`flight-checkout-btn w-100 font-weight-bold border-0 rounded py-2 ${
									confirmModalOpen.type == "accept"
										? "accept-btn"
										: "reject-btn"
								}`}
								onClick={() => handleAcceptWaiting(confirmModalOpen.type)}
								disabled={
									confirmModalOpen.type !== "accept" &&
									rejectReason.length === 0
								}
							>
								{confirmModalOpen.type === "accept"
									? backOffice.confirm
									: backOffice.reject}
							</button>
						</div>
					</ModalBody>
				</Modal>
			)}
			{rejectModal && (
				<RejectRequestModal
					isOpen={rejectModal}
					toggle={toggleRejectModal}
					submitReject={handleRejectRequest}
				/>
			)}
			{confirmPendingModal && (
				<ConfirmPendingRequestModal
					isOpen={confirmPendingModal}
					toggle={toggleConfirmPendingModal}
					submitAmount={handleAcceptRequest}
				/>
			)}

			{routeModal && (
				<HandleRoutes
					isOpen={routeModal}
					toggle={() => toggleAddRouteModal()}
					onSuccess={fetchData}
					submitAmount={handleAcceptRequest}
					ticket={{ ...selectedTicket, requestId: request.id }}
				/>
			)}
		</div>
	);
}

export function RequestContactInfo({ details }) {
	const { backOffice } = Locale;
	return (
		<>
			<h2 className="text-capitalize blue h6">{backOffice.contact}</h2>
			<div className="flight-requests-contact">
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.contactName}</span>
					<span>{details?.contact_info?.full_name}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.email}</span>
					<span>{details?.contact_info?.email}</span>
				</p>
				<p className="d-flex flex-wrap justify-content-between align-content-center my-1">
					<span className="font-weight-bold">{backOffice.cellphone}</span>
					<span>
						{details?.contact_info?.phone_code}
						{details?.contact_info?.phone}{" "}
					</span>
				</p>
			</div>
		</>
	);
}
