import { useEffect, useLayoutEffect } from 'react';
// Translation
import Locale from 'translations';
// Componnets
import RadioGroup from 'components/shared/RadioGroup';
import TextField from 'components/shared/TextField';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Cutom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// ----------------------------------------------------------------------
export default function PackageSubscriptionTypes(props) {
  const {
    packageData,
    setPackageData,
    setActiveTab,
    activeTab,
    tabs,
    setTabs,
    errors,
    setErrors,
    allRequiredInputs,
    setAllRequiredInputs,
  } = props

  // *** Hook
  const { safaVisa } = Locale;

  const { packageSubscriptionOptions } = useSafaVisaData();

  // *** Functions
  const handleChangeInputs = ({ key, value }) => {
    const packageDataClone = { ...packageData }
    packageDataClone[`${key}`] = value
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setPackageData(packageDataClone)
  }

  const checkInternalFormErrors = () => {
    let submitError = {};
    allRequiredInputs?.requiredPricingInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    // setErrors((prev) => ({ ...prev, ...submitError }));
    setErrors({ ...submitError });
    return submitError;
  }

  const checkPackageDetailsFormErrors = () => {
    let externalError = {};
    allRequiredInputs?.requiredDetailsInputs.forEach(key => {
      externalError = {
        ...externalError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    // setErrors((prev) => ({ ...prev, ...externalError }));
    // setErrors({ ...externalError });

    return externalError;
  }

  const handleNextButton = () => {
    let formErrors = checkInternalFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    if (activeTab?.index <= tabs?.length) {
      setActiveTab(tabs[Number(activeTab?.index + 1)])
      tabs[activeTab?.index + 1].isDisabled = true
      setTabs(tabs)
    }
  }

  useEffect(() => {
    if (packageData?.subscription_type === 'all') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredPricingInputs: ['prepaid_price', 'postpaid_price']
      })
    }
    if (packageData?.subscription_type === 'prepaid') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredPricingInputs: ['prepaid_price']
      })
      // make the second subscription Type is null
      handleChangeInputs({ key: 'postpaid_price', value: null })
    }
    if (packageData?.subscription_type === 'postpaid') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredPricingInputs: ['postpaid_price']
      })
      handleChangeInputs({ key: 'prepaid_price', value: null })
    }
  }, [packageData?.subscription_type]);

  useLayoutEffect(() => {
    let externalFormErrors = checkPackageDetailsFormErrors();
    if (isFormValid(externalFormErrors) === false) {
      setActiveTab(tabs[activeTab?.index - 1])
      return
    }
  }, [])

  // console.log(packageData)
  // console.log(errors)
  // console.log(allRequiredInputs)
  // -------- JSX Code -------
  return (
    <form
      // onSubmit={submit} 
      className="mt-4"
    >

      <div className='d-flex justify-content-between align-items-center mb-2'>
        <p >{safaVisa?.subscriptionTypes} *</p>
        {/* Subscription Type    */}
        <div className="col-5" >
          <RadioGroup
            label={safaVisa?.subscriptionType}
            name='subscription_type'
            groupOptions={packageSubscriptionOptions}
            onChange={(e) => handleChangeInputs({ key: 'subscription_type', value: e?.target?.value })}
            value={packageData?.subscription_type
              //  ||
              // (packageData?.postpaid_price && packageData?.postpaid_price) ? 'all'
              // :
              // packageData?.postpaid_price ? 'postpaid' : 'prepaid'
            }
          />
        </div>
      </div>

      {(packageData?.subscription_type === 'prepaid' || packageData?.subscription_type === 'all') &&
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <p >{safaVisa?.prepaid}</p>
          <div className="col-6 d-flex justify-content-between align-items-center ">
            <div className='flex-grow-1 col-10' >
              <TextField
                type="number"
                label={safaVisa?.prepaid}
                hasLabel={false}
                placeholder={safaVisa?.prepaid}
                name="prepaid_price"
                id="prepaid_price"
                value={packageData?.prepaid_price}
                onChange={(e) => handleChangeInputs({ key: 'prepaid_price', value: e?.target?.value })}
                color={errors?.prepaid_price?.required ? "danger" : ""}
                errors={errors?.prepaid_price}
              />
            </div>
            <p style={{ color: '#1e85ff' }}>{packageData?.currency?.label}</p>
          </div>
        </div>
      }

      {(packageData?.subscription_type === 'postpaid' || packageData?.subscription_type === 'all') &&
        <div className='d-flex justify-content-between align-items-center'>
          <p >{safaVisa?.postpaid}</p>
          <div className="col-6 d-flex justify-content-between align-items-center ">
            <div className='flex-grow-1 col-10' >
              <TextField
                type="number"
                label={safaVisa?.postpaid}
                hasLabel={false}
                placeholder={safaVisa?.postpaid}
                name="postpaid_price"
                id="postpaid_price"
                value={packageData?.postpaid_price}
                onChange={(e) => handleChangeInputs({ key: 'postpaid_price', value: e?.target?.value })}
                color={errors?.postpaid_price?.required ? "danger" : ""}
                errors={errors?.postpaid_price}
              />
            </div>
            <p style={{ color: '#1e85ff' }} >{packageData?.currency?.label}</p>
          </div>
        </div>
      }

      {/* Actions Button */}
      <div className="col-12 d-flex justify-content-between gap-10 mt-3">

        <button
          className="btn back-btn py-2 "
          onClick={() => setActiveTab(tabs[Number(activeTab?.index - 1)])}
        >
          <span >
            <LeftArrowIcon color='#1e85ff' width='24' height='24' />
          </span>
          {safaVisa.back}
        </button>

        <button type='button' className="btn add-btn py-2" onClick={handleNextButton}>
          {safaVisa.next}
        </button>

      </div>
    </form>
  )
}

