import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";
import CustomModal from "../Modal";

export default function AddDirectVisaModal({
	addEditDirectVisa,
	addVisaModal,
	toggleAddVisaModal,
	countries,
	handleDirectVisaDocument,
	directVisaData,
	handleDirectVisaInputs,
	errors,
	offlineSuppliers,
	selectedOfflineSupplier,
	setSelectedOfflineSupplier,
}) {
	const { backOffice, requests } = Locale;

	console.log({ selectedOfflineSupplier });

	return (
		<CustomModal
			header={
				directVisaData?.mode === "edit"
					? backOffice.EditDirectVisa
					: backOffice.AddDirectVisa
			}
			modalIsOpen={addVisaModal}
			toggle={() => toggleAddVisaModal()}
			size={"md"}
			centered={false}
		>
			<div className="row direct-visa-form">
				{/* passport number */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={backOffice.passport}
						label={backOffice.passport}
						id="passport_number"
						name="passport_number"
						value={directVisaData.passport_number}
						onChange={(e) =>
							handleDirectVisaInputs(e.target.value, "passport_number")
						}
						errors={errors?.passport_number}
						color={errors?.passport_number?.required ? "danger" : ""}
					/>
				</div>
				{/* nationality */}
				<div className="col-12">
					<SelectField
						placeholder={backOffice.nationality}
						label={backOffice.nationality}
						id="nationality"
						name="nationality"
						options={countries}
						value={directVisaData.nationality}
						onChange={(e) => handleDirectVisaInputs(e, "nationality")}
						errors={errors?.nationality}
						color={errors?.nationality?.required ? "danger" : ""}
					/>
				</div>
				{/* visa number */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={backOffice.companyName}
						label={backOffice.companyName}
						id="company_name"
						name="company_name"
						value={directVisaData.company_name}
						onChange={(e) =>
							handleDirectVisaInputs(e.target.value, "company_name")
						}
					/>
				</div>
				{/* visa number */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={backOffice.visaNumber}
						label={backOffice.visaNumber}
						id="visa_number"
						name="visa_number"
						value={directVisaData.visa_number}
						onChange={(e) =>
							handleDirectVisaInputs(e.target.value, "visa_number")
						}
					/>
				</div>
				{/* price */}
				<div className="col-12">
					<TextField
						type="text"
						placeholder={requests.Price}
						label={requests.Price}
						id="price"
						name="price"
						value={directVisaData.price}
						onChange={(e) => handleDirectVisaInputs(e.target.value, "price")}
					/>
				</div>

				<div className="col-12">
					<SelectField
						label={requests.SelectSupplier}
						placeholder={requests.SelectSupplier}
						name="supplier_id"
						options={offlineSuppliers}
						value={selectedOfflineSupplier}
						onChange={(selectedSupplier) => {
							setSelectedOfflineSupplier(selectedSupplier);
						}}
					/>
				</div>

				{/* upload visa document */}
				<div className="col-12">
					<div className=" our-border-dashed my-2">
						<h6 className="p-2 span">{backOffice.UploadVisaDocument}</h6>

						<div
							className={`py-3  text-center d-flex flex-column justify-content-center`}
						>
							{/* display image */}
							{directVisaData.visa_document?.fileDisplay ? (
								<figure className="d-flex  justify-content-center ">
									{directVisaData.visa_document?.fileObject?.type !==
									"application/pdf" ? (
										<img
											src={`${directVisaData.visa_document?.fileDisplay}`}
											width={150}
											// height={150}
											alt="attachment"
										/>
									) : (
										<>
											<i className="fas fa-paperclip file-attach" />
											{directVisaData?.visa_document?.fileObject?.name}
										</>
									)}
								</figure>
							) : null}

							<button className="file-btn shape-border w-50 m-auto pointer">
								{backOffice.AddAttachment}
								<input
									type="file"
									className="custom-file"
									name="visa_document"
									id="visa_document"
									accept="image/png, image/gif,image/jpeg,application/pdf"
									onChange={(e) => handleDirectVisaDocument(e)}
								/>
							</button>
						</div>
					</div>
				</div>

				<div className="col-12">
					<button
						class="btn companies-btn w-100 my-3"
						disabled={
							!directVisaData.passport_number || !directVisaData.nationality
						}
						onClick={addEditDirectVisa}
					>
						{directVisaData?.mode === "edit" ? backOffice.edit : backOffice.add}
					</button>
				</div>
			</div>
		</CustomModal>
	);
}
