import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { SoldVisaList, exportToExcelSoldVisas } from "services/Request";
import Locale from "translations";
import Filter from "./Filter";
import ListSoldVisas from "./List";

export default function SoldVisas() {
	const { backOffice } = Locale;
	const [ListVisa, setListVisa] = useState([]);
	const [lookupsList, setLookupsList] = useState({
		visaTypes: [],
		sources: [],
	});
	const [meta, setMeta] = useState([]);
	const [reload, setReload] = useState(false);
	const [filter, setfilter] = useState({
		company_name: "",
		destination_id: null,
		visa_type_id: null,
		// request_date: null,
		request_date_start: undefined,
		request_date_end: undefined,
		source: null,
		status: null,
		page: 1,
	});
	const goto = (num) => {
		setfilter({ ...filter, page: num });
	};

	const fetchListVisa = async () => {
		const res = await SoldVisaList(
			{
				company_name: filter?.company_name,
				destination_id: filter.destination_id
					? filter.destination_id.value
					: null,
				visa_type_id: filter?.visa_type_id?.value
					? filter.visa_type_id.value
					: null,
				// request_date: filter.request_date
				// 	? moment(filter.request_date).format("YYYY-MM-DD")
				// 	: null,
				request_date_start: filter.request_date_start
					? moment(filter.request_date_start).format("YYYY-MM-DD")
					: null,
				request_date_end: filter.request_date_end
					? moment(filter.request_date_end).format("YYYY-MM-DD")
					: null,
				status: filter.status ? filter.status.value : null,
				source: filter?.source?.value,
			},
			filter?.page
		);
		setListVisa(res?.data?.data);
		setMeta(res?.data?.meta);
		debugger;
		const formatVisaTypes = await res?.data?.visa_types.map((t) => ({
			value: t.id,
			label: t.name,
		}));
		const formatSources = await res?.data?.sources.map((source) => ({
			value: source,
			label: source,
		}));
		setLookupsList({ visaTypes: formatVisaTypes, sources: formatSources });
	};
	useEffect(() => {
		fetchListVisa();
	}, [filter, reload]);
	const exportToFile = async () => {
		const res = await exportToExcelSoldVisas({
			company_name: filter?.company_name,
			destination_id: filter.destination_id
				? filter.destination_id.value
				: null,
			visa_type_id: filter?.visa_type_id?.value
				? filter.visa_type_id.value
				: null,

			request_date_start: filter.request_date_start
				? moment(filter.request_date_start).format("YYYY-MM-DD")
				: null,
			request_date_end: filter.request_date_end
				? moment(filter.request_date_end).format("YYYY-MM-DD")
				: null,
			status: filter.status ? filter.status.value : null,
			source: filter?.source?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Sold-Visas-Booking-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between">
				<h3>{backOffice.SoldVisas}</h3>
				<button className="btn btn-primary" onClick={() => exportToFile()}>
					{backOffice.ExportToExcel}
				</button>
			</div>
			<Filter lookups={lookupsList} setfilter={setfilter} filter={filter} />
			<ListSoldVisas
				ListVisa={ListVisa}
				meta={meta}
				goTo={goto}
				setReload={setReload}
				reload={reload}
			/>
		</>
	);
}
