import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchAreas, fetchCities } from "services/lookups";
import {
	createNewSupplier,
	updateSupplier,
	viewSupplier,
} from "services/manualSupplier";
import Locale from "translations";
import HotelDetails from "./hotelDetails";
import ResponsibleDetails from "./responsibleDetails";
import SupplierDetails from "./supplierDetails";

const AddSupplier = () => {
	const { allCountries } = useGlobalState();
	const locale = localStorage.getItem("currentLocale") || "en";
	const { type } = useParams();
	const [supplier, setSupplier] = useState({
		supplierName: "",
		supplierCountry: "",
		supplierCity: "",
		supplierEmail: "",
		responsibleName: "",
		responsibleTitle: "",
		responsibleEmail: "",
		responsiblePhone: "",
		hotel: [],
		type: [],
	});
	const history = useHistory();
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [areas, setAreas] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const location = useLocation();
	const data = type == "edit" ? location.state?.detail : null;
	const { backOffice } = Locale;
	useEffect(() => {
		const fetchSuppliers = async () => {
			if (type == "edit") {
				const res = await viewSupplier(data?.id);
				// setSupplier(res.data.data);
				setSupplier({
					supplierName: res.data.data?.name,
					supplierCountry: {
						value: res.data.data?.country?.id,
						label: res.data.data?.country?.name,
					},
					supplierCity: {
						value: res.data.data?.city?.id,
						label: res.data.data?.city?.name,
					},
					supplierEmail: res.data.data?.responsible_email,
					responsibleName: res.data.data?.responsible_name,
					responsibleTitle: res.data.data?.responsible_title,
					responsibleEmail: res.data.data?.responsible_email,
					responsiblePhone: res.data.data?.responsible_phone,
					hotel: res.data.data?.hotels.map((item) => {
						return {
							id: item?.id,
							hotelName: item?.hotel_name,
							hotelRating: { value: item?.rating, label: item?.rating },
							hotelCountry: {
								value: item.country?.id,
								label: item.country?.name,
							},
							hotelCity: { value: item.city?.id, label: item.city?.name },
							hotelArea: item?.area_id,
							hotelAddress: item?.address,
							hotelPhone: item?.phone,
						};
					}),
					type: res.data.data?.type,

					// [
					// 	{
					// 		hotelName: ,
					// 		hotelRating: ,
					// 		hotelCountry: ,
					// 		hotelCity: "",
					// 		// hotelArea: "",
					// 	},
					// ],
				});

				// show hotel or visa based on the data
			}
		};

		fetchSuppliers();
	}, []);

	//Fetch Countries
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);
	//Fetch Cities
	useEffect(() => {
		const getCities = async () => {
			if (supplier?.supplierCountry?.value) {
				const res = await fetchCities(supplier?.supplierCountry?.value);
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCities(format);
			}
		};
		getCities();
	}, [supplier?.supplierCountry]);
	const ratingOptions = [
		{ label: 1, value: 1 },
		{ label: 2, value: 2 },
		{ label: 3, value: 3 },
		{ label: 4, value: 4 },
		{ label: 5, value: 5 },
	];

	function innerObject(arr) {
		let all = {};
		arr.map((item, index) => {
			all = { ...all, ...item };
		});
		return all;
	}
	const checkFormErrors = () => {
		let Error = [];
		if (supplier.type.includes("hotel")) {
			supplier.hotel.map((res, index) => {
				Object.entries(res).forEach(([key, value]) => {
					console.log("key", key);
					let newError = {
						...validate(
							{
								name: `${key}${index}`,
								value: `${value}`,
							},
							{
								required: key === "hotelArea" ? false : true,
								phone: key == "hotelPhone" ? true : false,
							}
						),
					};
					Error.push(newError);
				});
			});
		}

		setErrors({
			...errors,
			...validate(
				{ name: "supplierName", value: supplier.supplierName },
				{ required: true }
			),
			...validate(
				{ name: "supplierCountry", value: supplier.supplierCountry },
				{ required: true }
			),
			...validate(
				{ name: "supplierCity", value: supplier.supplierCity },
				{ required: true }
			),
			...validate(
				{ name: "supplierEmail", value: supplier.supplierEmail },
				{ required: true, email: true }
			),
			...validate(
				{ name: "responsibleName", value: supplier.responsibleName },
				{ required: true }
			),
			...validate(
				{ name: "responsibleTitle", value: supplier.responsibleTitle },
				{ required: true }
			),
			...validate(
				{ name: "responsibleEmail", value: supplier.responsibleEmail },
				{ required: true, email: true }
			),
			...validate(
				{ name: "responsiblePhone", value: supplier.responsiblePhone },
				{ required: true }
			),
			...validate(
				{ name: "type", value: supplier.type },
				{ required: supplier.type.length === 0 }
			),
			...innerObject(Error),
		});
	};
	const handleHotels = (value, index, name) => {
		let clone = supplier.hotel;
		clone[index][name] = value;
		if (name == "hotelCountry") clone[index]["hotelCity"] = null;
		setSupplier({ ...supplier });
	};
	const addOneHotel = () => {
		let clone = supplier.hotel;
		clone.push({
			hotelName: "",
			hotelCountry: "",
			hotelCity: "",
			hotelRating: "",
			hotelAddress: "",
			hotelPhone: "",
		});
		setSupplier({ ...supplier });
	};
	const deleteHotel = (index) => {
		let clone = supplier.hotel;
		if (clone.length > 1) {
			clone.splice(index, 1);
		}
		setSupplier({ ...supplier });
	};
	const getHotelsCities = async (item) => {
		if (item?.hotelCountry?.value) {
			const res = await fetchCities(item?.hotelCountry?.value);
			const format = res?.data?.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCities(format);
		}
	};
	const getHotelsArea = async (item) => {
		if (item?.hotelCity?.value) {
			const res = await fetchAreas(item?.hotelCity?.value);
			const format = res?.data?.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setAreas(format);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			const CreateSuplier = async () => {
				var res = null;
				let hotelData = supplier.hotel.map((item, index) => {
					if (item.hasOwnProperty("id")) {
						return {
							id: item?.id,
							hotel_name: item?.hotelName,
							rating: item?.hotelRating?.value,
							country_id: item?.hotelCountry?.value,
							city_id: item?.hotelCity?.value,
							address: item?.hotelAddress,
							phone: item?.hotelPhone,
						};
					} else {
						return {
							hotel_name: item?.hotelName,
							rating: item?.hotelRating?.value,
							country_id: item?.hotelCountry?.value,
							city_id: item?.hotelCity?.value,
							address: item?.hotelAddress,
							phone: item?.hotelPhone,
						};
					}
				});

				let date = {
					name: supplier.supplierName,
					company_email: supplier.supplierEmail,
					country_id: supplier.supplierCountry?.value,
					city_id: supplier.supplierCity?.value,
					responsible_name: supplier.responsibleName,
					responsible_title: supplier.responsibleTitle,
					responsible_email: supplier.responsibleEmail,
					responsible_phone: supplier.responsiblePhone,
					hotels: hotelData,
					type: supplier.type,
				};
				if (type == "add") {
					res = await createNewSupplier(date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Create New Supplier Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push("/manual-suppliers");
							},
						});
					}
				} else {
					res = await updateSupplier(data?.id, date);
					if (res.status == 200 || res.status == 201) {
						store.addNotification({
							title: "Info!",
							message: "Edit Supplier Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 2000,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								history.push("/manual-suppliers");
							},
						});
					}
				}
			};
			CreateSuplier();
		}
	}, [isErrorLoaded]);
	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (supplier.type.includes("hotel") && supplier.hotel.length === 0) {
			setSupplier({
				...supplier,
				hotel: [
					{
						hotelName: "",
						hotelRating: "",
						hotelCountry: "",
						hotelCity: "",
						hotelArea: "",
						hotelAddress: "",
						hotelPhone: "",
					},
				],
			});
		}
	}, [supplier.type]);

	const handleChooseServices = (e) => {
		const type = [...supplier.type];
		if (e.target.checked) {
			type.push(e.target.value);
		} else {
			type.splice(type.indexOf(e.target.value), 1);
		}

		setErrors({
			...errors,
			type: {
				required: type.length === 0,
			},
		});

		if (!type.includes("hotel")) {
			setErrors({
				...errors,
				hotelAddress0: { required: false },
				hotelArea0: { required: false },
				hotelCity0: { required: false },
				hotelCountry0: { required: false },
				hotelName0: { required: false },
				hotelPhone0: { required: false },
				hotelRating0: { required: false },
			});
		}

		setSupplier({
			...supplier,
			type,
		});
	};

	return (
		<div className="px-3">
			<h3>
				{type == "add" ? backOffice.AddSupplier : backOffice.EditSupplier}
			</h3>
			<SupplierDetails
				supplier={supplier}
				setErrors={setErrors}
				errors={errors}
				countries={countries}
				cities={cities}
				setSupplier={setSupplier}
			/>

			<ResponsibleDetails
				supplier={supplier}
				setErrors={setErrors}
				errors={errors}
				setSupplier={setSupplier}
			/>

			<div className="supplier-details">
				<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
					<p className="m-0">{backOffice.CompanyServices}</p>
				</div>

				<div className="row pb-3 px-5 our-border py-0 m-0">
					<div className="d-flex mt-3" style={{ gap: 12 }}>
						<div className="d-flex align-items-center" style={{ gap: 4 }}>
							<input
								type="checkbox"
								name="type"
								value="hotel"
								checked={supplier.type.includes("hotel")}
								onChange={handleChooseServices}
							/>
							<label className="m-0 ml-1">{backOffice.hotel}</label>
						</div>

						<div className="d-flex align-items-center" style={{ gap: 4 }}>
							<input
								type="checkbox"
								name="type"
								value="visa"
								checked={supplier.type.includes("visa")}
								onChange={handleChooseServices}
							/>
							<label className="m-0 ml-1">{backOffice.visa}</label>
						</div>
					</div>
				</div>

				{errors?.type?.required && (
					<p className="text-danger m-0">{backOffice.typeIsRequired}</p>
				)}
			</div>

			{supplier.type.includes("hotel") && (
				<HotelDetails
					supplier={supplier}
					setErrors={setErrors}
					errors={errors}
					setSupplier={setSupplier}
					countries={countries}
					cities={cities}
					handleHotels={handleHotels}
					getHotelsCities={getHotelsCities}
					getHotelsArea={getHotelsArea}
					areas={areas}
					deleteHotel={deleteHotel}
					addOneHotel={addOneHotel}
					ratingOptions={ratingOptions}
				/>
			)}

			<div className="d-flex justify-content-end my-2">
				<button
					className="btn btn-gray bg-gray-100 mx-2"
					onClick={() => history.push("/manual-suppliers")}
				>
					{backOffice.back}
				</button>
				<ShowForPermission permission="Manual-Suppliers-Manage">
					<button className="btn btn-success" onClick={submit}>
						{type == "add" ? (
							<>{backOffice.AddSupplier}</>
						) : (
							<>{backOffice.EditSupplier}</>
						)}
					</button>
				</ShowForPermission>
			</div>
		</div>
	);
};

export default AddSupplier;
