import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import TextField from "components/shared/TextField";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ChargeWalletModal({
	isOpen,
	toggle,
	chargeWalletState,
	setChargeWalletState,
	chargeWalet,
}) {
	const { wallet, backOffice } = Locale;

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<p className=" text-secondary ">{backOffice.ChargeWallet}</p>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<div className=" m-0 p-0 w-75">
					<div className="mx-1">
						<TextField
							type="number"
							placeholder={wallet.Amount}
							hasLabel={true}
							label={"Amount"}
							name="Amount"
							value={chargeWalletState.amount}
							onChange={(e) => {
								setChargeWalletState({
									...chargeWalletState,
									amount: e.target.value,
								});
							}}
						/>
						{chargeWalletState.amount?.length > 9 ? (
							<span className="text-danger ">
								{backOffice.amountShouldBeMaxDigitonly}
							</span>
						) : (
							""
						)}
					</div>
					<div className="mx-1">
						<TextAreaField
							label={backOffice.description}
							placeholder={backOffice.description}
							hasLabel={true}
							name="description"
							value={chargeWalletState.description}
							rows="3"
							className="control-field__textarea_input"
							onChange={(e) => {
								setChargeWalletState({
									...chargeWalletState,
									description: e.target.value,
								});
							}}
						/>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center    px-3 ">
				<button
					className="btn rounded companies-btn  w-75"
					onClick={chargeWalet}
					disabled={chargeWalletState.amount?.length > 9}
				>
					{backOffice.ChargeWallet}
				</button>
			</div>
		</Modal>
	);
}
