import { useState } from "react";
import { TabPane } from "react-bootstrap";
import Locale from "translations";
import ContractList from "./ContractList";
import ContractRequest from "./ContractRequest";

export default function ContractMangment() {
	const { backOffice } = Locale;
	const [activeTap, setActiveTap] = useState(1);
	return (
		<>
			<div className="requestTaps mt-4">
				<TabPane
					style={{ fontSize: "18px" }}
					className={`tap p-2 ${activeTap === 1 ? `active` : "pointer"}`}
					onClick={() => {
						setActiveTap(1);
					}}
					tabId={1}
				>
					{backOffice.contractRequest}
				</TabPane>
				<TabPane
					style={{ fontSize: "18px" }}
					className={`tap p-2 ${activeTap === 2 ? `active` : "pointer"}`}
					onClick={() => {
						setActiveTap(2);
					}}
					tabId={2}
				>
					{backOffice.contractList}
				</TabPane>
			</div>
			{activeTap === 1 && <ContractRequest />}
			{activeTap === 2 && <ContractList />}
		</>
	);
}
