import axios from "axios";
const backOfficeApi = process.env.REACT_APP_API_URL + "/api/v1";
//--------------------------------------------------------------------------

// @@ SafaVisa Default Pricing Services
export const addDefaultPricing = async (data) => {
  return await axios
    .post(`${backOfficeApi}/visa-pricing/default-pricing`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editDefaultPricing = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/default-pricing/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDefaultPricingList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/default-pricing`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelDefaultPricingList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/default-pricing/export`,
      {
        params: params,
        responseType: "blob",
      }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelDefaultPricingHistory = async (id) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/default-pricing/${id}/history/export`,
      { responseType: "blob" }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const updatePricingState = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/default-pricing/${id}/status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDefaultPricingById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/default-pricing/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDefaultPricingHistoryById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/default-pricing/${id}/history`)
    .then((res) => res)
    .catch((err) => err.response);
};



// @@ SafaVisa Packages Pricing Services
export const getPackagesPricingList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-package`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const updatePackageStatus = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-package/${id}/status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelPackagesPricingList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-package/export`,
      {
        params: params,
        responseType: "blob",
      }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPackagePricingById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-package/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPackagePricingHistoryById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-package/${id}/history`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelPackagePricingHistory = async (id) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-package/${id}/history/export`,
      { responseType: "blob" }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const addPackagePricing = async (data) => {
  return await axios
    .post(`${backOfficeApi}/visa-pricing/pricing-package`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editPackagePricing = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-package/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

// @@ SafaVisa Discount Pricing Services

export const getPackagesLookups = async (params) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/lookup/pricing-package`,
      { params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDefaultPricingLookups = async (params) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/lookup/default-pricing`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const addDiscount = async (data) => {
  return await axios
    .post(`${backOfficeApi}/visa-pricing/pricing-discount`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editDiscount = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-discount/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDiscountDetailsById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-discount/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDiscountList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-discount`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateDiscountStatus = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-discount/${id}/status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelDiscountList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-discount/export`,
      {
        params: params,
        responseType: "blob",
      }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const getDiscountHistoryById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-discount/${id}/history`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelDiscountHistory = async (id) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-discount/${id}/history/export`,
      { responseType: "blob" }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

// @@ SafaVisa Campaign Pricing Services

export const getCampaignList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-campaign`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelCampaignList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-campaign/export`,
      {
        params: params,
        responseType: "blob",
      }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateCampaignStatus = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-campaign/${id}/status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getCampaignDetailsById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-campaign/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getCampaignHistoryById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/pricing-campaign/${id}/history`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelCampaignHistory = async (id) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/pricing-campaign/${id}/history/export`,
      { responseType: "blob" }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const addCampaign = async (data) => {
  return await axios
    .post(`${backOfficeApi}/visa-pricing/pricing-campaign`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editCampaign = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/pricing-campaign/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

// @@ SafaVisa Promo Code Pricing Services

export const getPromoCodeList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/promo-code`,
      { params: params }
    )
    .then((res) => res)
    .catch((err) => err.response);
};


export const exportExcelPromoCodeList = async (params) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/promo-code/export`,
      {
        params: params,
        responseType: "blob",
      }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const updatePromoCodeStatus = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/promo-code/${id}/status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPromoCodeDetailsById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/promo-code/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPromoCodeHistoryById = async (id) => {
  return await axios
    .get(`${backOfficeApi}/visa-pricing/promo-code/${id}/history`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const exportExcelPromoCodeHistory = async (id) => {
  return await axios
    .get(
      `${backOfficeApi}/visa-pricing/promo-code/${id}/history/export`,
      { responseType: "blob" }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const addPromoCode = async (data) => {
  return await axios
    .post(`${backOfficeApi}/visa-pricing/promo-code`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editPromoCode = async (id, data) => {
  return await axios
    .put(`${backOfficeApi}/visa-pricing/promo-code/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};