import SelectField from "components/Form/SelectField/SelectField";
import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	fetchBookingHistoryUsers,
	fetchHistoryBooking,
} from "services/Request";
import Locale from "translations";
// import { FilterIcon } from "../Main/DetailsIcons";

const statusLookups = [
	{ value: 1, label: "NEW_REQUEST" },
	{ value: 2, label: "PENDING" },
	{ value: 3, label: "TENTATIVE" },
	{ value: 4, label: "DEFINITE" },
	{ value: 5, label: "CANCELED" },
];

function History() {
	const { id } = useParams();
	const { backOffice } = Locale;
	const [historyList, setHistoryList] = useState([]);
	const [historyUsers, setHistoryUsers] = useState([]);
	const [filters, setFilters] = useState({
		date: null,
		responsibleName: "",
		page: 1,
	});
	const [meta, setMeta] = useState(null);

	const fetchHistoryLookups = async () => {
		const userHistory = await fetchBookingHistoryUsers(id);
		const formatData =
			userHistory.data.data && userHistory.data.data.length > 0
				? userHistory.data.data.map((res) => ({
						label: res.user_name,
						value: res.user_id,
				  }))
				: null;
		setHistoryUsers(formatData);
	};

	useEffect(() => {
		fetchHistoryLookups();

		return () => {};
	}, []);

	useEffect(() => {
		async function fetchReservationsFilterdData() {
			// const dataFilter= {
			// 	...filters,
			// 	date: filters?.date?moment(filters.date).format("DD-MM-YYYY"):"",
			// 	responsibleName: filters.responsibleName?.value,
			// }
			const response = await fetchHistoryBooking(id, {
				...filters,
				date: filters?.date ? moment(filters.date).format("YYYY-MM-DD") : "",
				responsibleName: filters.responsibleName?.label,
			});
			if (response?.status === 200) {
				setHistoryList(response?.data.data);
				setMeta(response.data.meta);
			}
		}

		fetchReservationsFilterdData();
	}, [filters]);

	const goTo = (page) => {
		setFilters({ ...filters, page: page });
	};
	const status = (element) => {
		if (element.attribute === "status") {
			return `Status changed from ${
				statusLookups.find((i) => i.value == +element.old_value)?.label
			} to ${statusLookups.find((i) => i.value == +element.value)?.label}`;
		}
		if (element.attribute === "date_from" || element.attribute === "date_to") {
			return `${
				element.attribute === "date_from" ? "Date from" : "Date To"
			} changed from ${element.old_value} to ${element.value}`;
		}
		if (element.attribute === "total_sell_price") {
			return `Total Selling Price changed from ${element.old_value} to ${element.value}`;
		}
		if (element.attribute === "confirmation_number") {
			return `Confirmation Number changed from ${
				element.old_value ? element.old_value : "-"
			} to ${element.value}`;
		}
		if (
			element.attribute.includes("/") &&
			!element.attribute.includes("canceled")
		) {
			const str = element.attribute;
			const splitArr = str.split("/");
			return `Room Key Number ${splitArr[0]} changed ${splitArr[1]} from ${
				element.old_value ? element.old_value : "-"
			} to ${element.value}`;
		}
		if (element.attribute.includes("canceled")) {
			if (!element.attribute.includes("refund")) {
				const str = element.attribute;
				const splitArr = str.split("/");
				return `Room Key Number ${splitArr[0]} is canceled`;
			} else {
				return `${element.attribute} ${element.value} ${
					element.attribute.includes("percentage") ? "%" : ""
				}`;
			}
		}
	};
	return (
		<div id="reserv-history" className="border">
			<div className="history_filter py-2 px-3 border-bottom row gap-2">
				<div className="col-md-3 history_filter_select">
					<SelectField
						label={backOffice.ResponsibleName}
						placeholder={backOffice.ResponsibleName}
						value={filters?.responsibleName?.label}
						name="responsibleName"
						options={historyUsers}
						onChange={(e) => {
							setFilters((current) => ({
								...current,
								responsibleName: e,
							}));
						}}
					/>
				</div>
				<div className="col-md-3 history_filter_date">
					<DatePickerField
						placeholder={"DD/MM/YYYY"}
						label={backOffice.date}
						date={filters.date}
						onChangeDate={(filterDate) =>
							setFilters((current) => ({ ...current, date: filterDate }))
						}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>
				<div className="pt-4 d-flex">
					<button
						className="btn p-0"
						onClick={() =>
							setFilters({ date: "", responsibleName: "", page: 1 })
						}
					>
						{/* <FilterIcon /> */}

						<span className="mx-2">{backOffice.resetFilter}</span>
					</button>
				</div>
			</div>
			<div className="main_history px-3">
				{historyList?.length > 0 ? (
					historyList.map((i) => (
						<div class="history_block pt-2">
							<div class="d-flex">
								<div class="px-2">
									<span class="tes">
										<i class="fa fa-circle"></i>
									</span>
								</div>
								<div className="history_content">
									<h6 class="font-weight-bold">{status(i)}</h6>
									<p>
										{`${i.user_name ? i.user_name + " |" : ""}  ${
											i.created_at
										}`}
									</p>
								</div>
							</div>
						</div>
					))
				) : (
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				)}
			</div>
			<Pagination info={meta} goTo={goTo} />
		</div>
	);
}

export default History;
