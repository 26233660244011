
import { Fragment, useState } from 'react';
// React-Router-Dom
import { Link, useHistory } from 'react-router-dom';

// ReactStrap
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
// Translation
import Locale from 'translations';
// React-Switch
import Switch from "react-switch";
// Module Component
import ConfirmModal from '../../shared/ConfirmModal';
// Helper
import ShowForPermission from 'helpers/showForPermission';
// Icons
import { EyeIcon } from 'assets/images/icons/OutlineIcons';
import { CopyIcon, DocumentIcon, EditIcon, InfoIcon, MoreIcon } from 'components/Icons';
// Services
import { updateDiscountStatus } from 'services/safaVisa';
// Context
import { useGlobalState } from 'context/global';
// ----------------------------------------------------------------------
function DiscountList({ discountList, reload, onReload }) {

  // *** Hooks
  let history = useHistory();

  const { backOffice, safaVisa } = Locale;

  const { locale } = useGlobalState();

  // *** State
  const [switchedItem, setSwitchedItem] = useState(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);


  // *** Function
  const defineIsActive = (status) => {
    return status === 1 ? true : false
  }

  const defineOfferType = (data) => {
    const { pricing_type, extraUnits, prepaid_discount, postpaid_discount } = data
    if (pricing_type === 'package') {
      if ((prepaid_discount || postpaid_discount) && extraUnits === 1) {
        return safaVisa?.extraUnitsAndPrice
      }
      if (prepaid_discount || postpaid_discount) {
        return safaVisa?.price
      }
    } if (pricing_type === 'default_price') {
      return safaVisa?.price
    }
    // if (extraUnits === 1 && !priceOffer) {
    //   return safaVisa?.extraUnits
    // }
    // if (extraUnits === 1 && priceOffer === 1) {
    //   return safaVisa?.extraUnitsAndPrice
    // }
    // if (extraUnits === 1 && priceOffer === 1) {
    //   return safaVisa?.extraUnitsAndPrice
    // }

  }

  const handleChangeStatus = async (id, status) => {
    const updatedState = status === 1 ? false : true
    const response = await updateDiscountStatus(id, { is_active: updatedState })
    if (response?.status === 200) {
      onReload(!reload)
    }
  }

  const handleToggleConfirmModal = (data) => {
    setIsOpenConfirm(!isOpenConfirm)
    setSwitchedItem(data)
  }

  const handleConfirmAction = async () => {
    handleChangeStatus(switchedItem?.id, switchedItem?.status)
    handleToggleConfirmModal(null)
  }

  const handleCancelAction = () => handleToggleConfirmModal()

  // ------- JSX Code -------
  return (
    <div className="our-border table-responsive  mt-4">
      <table className="custom-table back-office-table table table-striped mb-0">

        <thead>
          <tr>
            <th>{safaVisa.id}</th>
            <th> {safaVisa.title}</th>
            <th> {safaVisa.code}</th>
            <th>{safaVisa.country}</th>
            <th> {safaVisa.pricingType}</th>
            <th> {safaVisa.offerType}</th>
            <th> {safaVisa.discountType}</th>
            <th>{safaVisa.updateDate}</th>
            <th>{safaVisa.status}</th>
            <th>{safaVisa.activation}</th>
            <ShowForPermission permission="safa-visa-Manage">
              {" "}
              <th>{safaVisa.actions}</th>
            </ShowForPermission>
          </tr>
        </thead>

        <tbody>
          {discountList?.length > 0 ? (
            discountList?.map((row, index) => {
              return (<tr key={row?.code}>
                <td><p className='id-text'> {row?.id} </p></td>
                <td>{row?.title?.[locale]}</td>
                <td>{row?.code}</td>
                <td >
                  <div className='d-flex gap-2'>
                    {row?.countries[0]?.name}
                    {row?.countries?.length > 1 &&
                      <Fragment >
                        <span
                          href="#"
                          className='more-info-toolTip'
                          id={`UncontrolledTooltipExample${row?.id}`}
                        >
                          +{row?.countries?.length - 1}
                        </span>
                        <UncontrolledTooltip
                          placement="top"
                          target={`UncontrolledTooltipExample${row?.id}`}
                        >
                          {row?.countries.slice(1).map((item, index) => <span key={index}>{item?.name}{' '}, </span>
                          )}
                        </UncontrolledTooltip>
                      </Fragment>
                    }
                  </div>
                </td>
                <td>{safaVisa[row?.pricing_type]}</td>
                <td>
                  <div className='min-width'>
                    {
                      defineOfferType({
                        pricing_type: row?.pricing_type,
                        priceOffer: row?.is_price_offer,
                        extraUnits: row?.is_quota_offer,
                        prepaid_discount: row?.prepaid_discount,
                        postpaid_discount: row?.postpaid_discount,
                      })
                    }
                  </div>
                </td>
                <td>{safaVisa[row?.discount_type]}</td>
                <td >{row?.updated_at}</td>
                <td >
                  <p className={`${defineIsActive(row?.is_active) ? 'active-status ' : 'notActivated-status'}`}>
                    {row?.is_active === 1 ? safaVisa?.active : safaVisa?.notActive}
                  </p>
                </td>

                <td>
                  <ShowForPermission permission="Tourism-Company-Manage">
                    <Switch
                      height={24}
                      width={45}
                      handleDiameter={20}
                      className="mx-2 align-middle"
                      onChange={() => {
                        handleToggleConfirmModal({ id: row?.id, status: row?.is_active })
                        // if (row?.is_active === 1) {
                        //   handleToggleConfirmModal({ id: row?.id, status: row?.is_active })
                        // } else {
                        //   handleChangeStatus(row?.id, row?.is_active)
                        // }
                      }}
                      checked={row?.is_active === 1}
                      onColor="#57a866"
                    // disabled={row?.is_active === 0}
                    />
                  </ShowForPermission>
                </td>

                <td >

                  <div className='d-flex justify-content-start  align-items-center'>
                    <Link to={`/safaVisa-discount/details/${row?.id}`} className='pointer'>
                      <EyeIcon color='#9C9FAB' width='24' height='24' />
                    </Link>

                    <div className='pointer' >
                      <UncontrolledDropdown
                        className="me-2"
                        direction="down"
                      >
                        <DropdownToggle caret>
                          <MoreIcon color='#9C9FAB' width='24' height='24' />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => history.push(`/safaVisa-discount/edit/${row?.id}`)}>
                            <EditIcon color='#2C302E' />
                            {safaVisa.edit}
                          </DropdownItem>
                          <DropdownItem onClick={() => history.push(`/safaVisa-discount/duplicate/${row?.id}`)}>
                            <CopyIcon color='#2C302E' />
                            {safaVisa.duplicate}
                          </DropdownItem>
                          {/* <DropdownItem divider /> */}
                          <DropdownItem onClick={() => history.push(`/safaVisa-discount/history/${row?.id}`)}>
                            <DocumentIcon color='#2C302E' />
                            {safaVisa.history}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>

                </td>

              </tr>)
            }
            )
          ) : (
            <tr>
              <td colSpan="15">
                <div className="product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{backOffice.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>

      </table>

      <ConfirmModal
        isOpen={isOpenConfirm}
        toggle={handleToggleConfirmModal}
        status={switchedItem?.status}
        confirmBtnText={switchedItem?.status === 0 ? safaVisa?.activate : safaVisa?.stop}
        messageText={switchedItem?.status === 0 ? safaVisa?.aboutToActivate : safaVisa?.aboutToDeActivate}
        icon={<InfoIcon color='#EA5455' />}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </div >
  )
}

export default DiscountList