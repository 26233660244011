import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import {
	exportTransactionWalletoExcel,
	getListTransactions,
} from "services/financial";
import Locale from "translations";
import FilterTransaction from "./Filtertransaction";
import ListTransaction from "./ListTransaction";

export default function TransactionWallet() {
	const { backOffice } = Locale;
	const { id } = useParams();

	const [transaction, setTransaction] = useState([]);
	const [filter, setFilter] = useState({
		search: "",
		// current_balance_sort: "desc",
		// amount_sort: "desc",
		page: 1,
	});

	useEffect(() => {
		async function getPaymentCards() {
			const response = await getListTransactions({
				...filter,
				payment_card_id: id,
			});
			debugger;
			setTransaction(response.data);
		}
		getPaymentCards();
	}, [filter]);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
	};
	function handleSort(sort_by) {
		let sort_type;
		if (filter[sort_by] === "asc") {
			sort_type = "desc";
		} else if (filter[sort_by] === "desc") {
			sort_type = "asc";
		} else {
			sort_type = "asc";
		}

		setFilter({
			[sort_by]: sort_type,
		});
	}
	const exportToFile = async () => {
		const res = await exportTransactionWalletoExcel({
			...filter,
			payment_card_id: id,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Transaction-Wallet-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between mx-1">
				<h1>{backOffice.ViewTransactions}</h1>
				<button className="btn btn-primary px-2" onClick={() => exportToFile()}>
					<i class="fas fa-file-excel"></i> {backOffice.ExportToExcel}
				</button>
			</div>
			{/* <FilterTransaction setFilter={setFilter} Filter={filter} /> */}
			<FilterTransaction setFilter={setFilter} Filter={filter} />
			<ListTransaction
				goTo={goTo}
				transaction={transaction}
				handleSort={handleSort}
				filter={filter}
			/>
		</>
	);
}
