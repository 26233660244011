import resetIcon from "assets/images/wallet/resetIcon.png";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({ filter, setfilter, lookups }) {
	const { requests, backOffice, booking } = Locale;
	const [countries, setCountries] = useState([]);
	const { locale, allCountries } = useGlobalState();

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const statusLookups = [
		{
			label: "Pending",
			value: "pending",
		},
		{
			label: "in progress",
			value: "inprogress",
		},
		{
			label: "Waiting for issuing visa",
			value: "waiting",
		},
		{
			label: "Issued",
			value: "issued",
		},
		{
			label: "Expired",
			value: "expired",
		},
		{
			label: "Canceled",
			value: "canceled",
		},
		{
			label: "Rejected",
			value: "rejected",
		},
	];

	return (
		<div className="main-filter py-2 my-2 px-3 border-bottom d-block main-filter-sold-visa">
			<div className="row align-items-center  w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						onChange={(e) => {
							setfilter({ ...filter, company_name: e?.target?.value });
						}}
						value={filter?.travelAgent}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={requests.Destination}
						label={requests.Destination}
						name="Destination"
						options={countries}
						onChange={(e) => {
							setfilter({ ...filter, destination_id: e });
						}}
						value={filter.destination_id}
					/>
				</div>

				<div className="col-2">
					<SelectField
						placeholder={requests.VisaType}
						label={requests.VisaType}
						name="VisaType"
						options={lookups.visaTypes}
						onChange={(e) => {
							setfilter({ ...filter, visa_type_id: e });
						}}
						value={filter.visa_type_id}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={backOffice.select}
						label={backOffice.source}
						name="source"
						options={lookups.sources}
						value={filter.source}
						onChange={(e) => {
							setfilter({ ...filter, source: e });
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={requests.status}
						label={requests.status}
						name="status"
						options={statusLookups}
						onChange={(e) => {
							setfilter({ ...filter, status: e });
						}}
						value={filter.status}
					/>
				</div>

				{/* <div className="col">
					<DatePickerField
						type="text"
						onOutsideClick={false}
						placeholder={"DD/MM/YYYY"}
						label={"Booking Date"}
						date={filter?.request_date}
						onDateChange={(e) => {
							debugger;
							setfilter({ ...filter, request_date: e });
						}}
					/>
				</div> */}
				<div className="col-3 date-range-input date-range-input-right-50">
					<p className="mb-0 mt-2">{backOffice.BookingDate}</p>
					<DateRangeField
						startDate={filter.request_date_start}
						endDate={filter.request_date_end}
						startDatePlaceholder={booking.StartDate}
						endDatePlaceholder={booking.EndDate}
						readOnly={true}
						onDatesChange={({ startDate, endDate }) => {
							//.add(1, "d")
							endDate = endDate ? moment(endDate)._d : moment(startDate)._d;
							setfilter({
								...filter,
								request_date_start: moment(startDate)._d,
								request_date_end: endDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>

				<div className="col">
					<div
						role="button"
						oncha
						onClick={() => {
							setfilter({
								company_name: "",
								destination_id: null,
								visa_type_id: null,
								request_date: null,
								status: null,
								source: null,
								page: 1,
							});
						}}
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />

						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
