import ShowForPermission from "helpers/showForPermission";
import { Link } from "react-router-dom";
import Locale from "translations";

function LatestHotelReservations({ latestitems }) {
	const { backOffice, booking } = Locale;

	return (
		<div className="col-6">
			<h4 className="p-1 pt-5">{backOffice.B2BHotelReservations}</h4>
			<div className="our-border  px-4">
				<table className="custom-table reservation-table back-office-table table table-striped ">
					<thead>
						<tr>
							<th>{booking.BRN}</th>
							<th>{backOffice.hotelName}</th>
							<th>{backOffice.status}</th>
						</tr>
					</thead>
					<tbody>
						{latestitems?.hotels_reservations?.length ? (
							latestitems?.hotels_reservations?.map((res) => {
								return (
									<tr>
										<td>{res?.brn}</td>
										<td>{res?.hotel_name}</td>
										<td className={res?.reservation_status}>
											{res?.reservation_status}
										</td>
										<td>
											<ShowForPermission permission="Bookings-View">
												<Link
													to={{
														pathname: `/booking/view/${res.id}/bookings`,
														state: {
															BookingInfo: res,
														},
													}}
												>
													<i class="fas fa-angle-right"></i>
												</Link>
											</ShowForPermission>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default LatestHotelReservations;
