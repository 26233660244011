import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";

import resetIcon from "assets/images/wallet/resetIcon.png";
import Locale from "translations";

export default function CommercialDistTransferFilters({ filters, setFilters }) {
	const { backOffice, vendor, booking } = Locale;

	const resetFilters = () => {
		setFilters({
			search: "",
			confirmation_date: "",
			free_cancellation_date: "",
			status: "",
			page: 1,
		});
	};
	const STATUS = [
		{ value: 6, label: "confirmed" },
		{ value: 4, label: "canceled" },
		// { value: 1, label: "pending" },
		// { value: 2, label: "rejected" },
		// { value: 7, label: "expired" },
	];

	return (
		<div className="main-filter bg-white-blue py-2 my-2">
			<div className="row align-items-center w-100 no-gutter m-0 flex-wrap">
				<div className="col-md-3">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						hasLabel={true}
						value={filters.search}
						onChange={(e) => {
							setFilters({
								...filters,
								search: e.target.value,
							});
						}}
					/>
				</div>
				<div className="col-md-2">
					<DatePickerField
						hasLabel={true}
						label={booking.ConfirmationDate}
						date={filters.confirmation_date}
						placeholder={backOffice.date}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								confirmation_date: filterDate,
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>
				<div className="col-md-3">
					<DatePickerField
						hasLabel={true}
						label={backOffice.freeCancelationChargeDate}
						date={filters.free_cancellation_date}
						placeholder={backOffice.date}
						onChangeDate={(filterDate) => {
							setFilters({
								...filters,
								free_cancellation_date: filterDate,
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>
				<div className="col-md-2">
					<SelectField
						hasLabel={true}
						label={backOffice.status}
						value={filters.status}
						placeholder={backOffice.status}
						name="status"
						options={STATUS}
						onChange={(e) => {
							setFilters({
								...filters,
								status: e,
							});
						}}
					/>
				</div>

				<div className="col-md">
					<div
						onClick={resetFilters}
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
					>
						<img src={resetIcon} alt="" srcset="" />
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
