import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DateRangeField from "components/shared/DateRangeField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { fetchCitiesHotels } from "services/lookups";
import Locale from "translations";
export default function PromoHotelItem({
	hotel,
	index,
	cityId,
	handleHotelChange,
	removeHotelRow,
	errors,
	isEditMode,
}) {
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const cityHotels = await fetchCitiesHotels(cityId, inputValue);
			let result = cityHotels?.data?.data.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAuto(result);
		}
	};
	const { backOffice } = Locale;

	return (
		<div className="row m-0 align-items-start">
			<div className="col-11 p-0 d-flex align-items-start  position-relative">
				{/* Hotel Name */}
				<div className="col-md-4 no-padding mt-1">
					<AutoCompleteField
						label={backOffice.hotelName}
						id={`hotelName-${index}`}
						name={`hotelName-${index}`}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						onChange={(e) => handleHotelChange(index, "hotelName", e)}
						onSelectValue={(e) => handleHotelChange(index, "hotelName", e)}
						value={hotel?.hotelName?.label}
						errors={errors?.[`hotelName-${index}`]}
						color={errors?.[`hotelName-${index}`]?.required ? "danger" : ""}
					/>
				</div>

				{/* Price Starting From */}
				<div className="col-md-2 no-padding">
					<TextField
						type="text"
						label={backOffice.PriceStartingFrom}
						id={`priceFrom-${index}`}
						name={`priceFrom-${index}`}
						value={hotel?.priceFrom}
						onChange={(e) =>
							handleHotelChange(index, "priceFrom", e.target.value)
						}
						errors={errors?.[`priceFrom-${index}`]}
						color={errors?.[`priceFrom-${index}`]?.required ? "danger" : ""}
					/>
				</div>

				{/* Discount */}
				<div className="col-md-2 no-padding">
					<TextField
						type="text"
						label={backOffice.discount}
						id={`hotelDiscount-${index}`}
						name={`hotelDiscount-${index}`}
						value={hotel?.hotelDiscount}
						onChange={(e) =>
							handleHotelChange(index, "hotelDiscount", e.target.value)
						}
						errors={errors?.[`hotelDiscount-${index}`]}
						color={errors?.[`hotelDiscount-${index}`]?.required ? "danger" : ""}
					/>
				</div>

				{/* Valid Booking Date */}
				<div className="col-md-2 no-padding">
					<TextField
						label={backOffice.Validbookingdate}
						id={`bookingDate-${index}`}
						name={`bookingDate-${index}`}
						value={hotel?.bookingDate}
						onChange={(e) =>
							handleHotelChange(index, "bookingDate", e.target.value)
						}
						errors={errors?.[`bookingDate-${index}`]}
						color={errors?.[`bookingDate-${index}`]?.required ? "danger" : ""}
					/>
					{/* <DateRangeField
            startDate={hotel.bookingDate?.from}
            endDate={hotel.bookingDate?.to}
            startDatePlaceholderText="DD/MM/YYY"
            endDatePlaceholderText="DD/MM/YYY"
            showClearDates={true}
            onDatesChange={({ startDate, endDate }) => {
              handleHotelChange(
                index,
                'bookingDate',
                {
                  from: moment(startDate)._d,
                  to: endDate
                    ? moment(endDate)._d
                    : moment(startDate).add(1, "d")._d,
                }
              )
            }}
            isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
          /> */}
				</div>

				{/* Valid Travel Date */}
				<div className="col-md-2 date-range-input">
					<p className="mb-0 mt-2">{backOffice.ValidTraveldate}</p>
					<DateRangeField
						startDate={hotel.travelDate?.from}
						endDate={hotel.travelDate?.to}
						startDatePlaceholderText="DD/MM/YYY"
						endDatePlaceholderText="DD/MM/YYY"
						showClearDates={true}
						readOnly={true}
						onDatesChange={({ startDate, endDate }) => {
							handleHotelChange(index, "travelDate", {
								from: moment(startDate)._d,
								to: endDate
									? moment(endDate)._d
									: moment(startDate).add(1, "d")._d,
							});
						}}
						isOutsideRange={(day) =>
							!isInclusivelyAfterDay(day, moment().add(1, "day"))
						}
					/>
				</div>
			</div>

			{isEditMode || index > 0 ? (
				<div className="col-1 mt-3">
					<button
						className="btn p-0 font-weight-bold shadow-none mt-4"
						onClick={() => removeHotelRow(index)}
					>
						<i className="fas fa-trash h5 text-danger"></i>
					</button>
				</div>
			) : null}
		</div>
	);
}
