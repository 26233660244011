import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { viewRecommendations } from "services/umrahForum";
import Locale from "translations";

export default function ViewRecommendations() {
	const { id } = useParams();
	const { backOffice } = Locale;
	const [Recommendations, setRecommendations] = useState([]);
	useEffect(() => {
		async function fetchRecommendations() {
			const res = await viewRecommendations(id);
			if (res.status === 200) {
				setRecommendations(res.data?.data);
			}
		}
		fetchRecommendations();
	}, [id]);
	return (
		<>
			<div className="">
				<h1>{Recommendations?.sender_name}</h1>
			</div>
			<div className=" table-responsive  our-border  px-3">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>{backOffice.name}</th>
							<th>{backOffice.companyName}</th>
							<th>{backOffice.title}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.phone}</th>
							<th>{backOffice.email}</th>
						</tr>
					</thead>
					<tbody>
						{Recommendations?.users?.length > 0 ? (
							<>
								{Recommendations?.users?.map((data, index) => (
									<tr key={index}>
										<td>{data.name}</td>
										<td>{data.company_name}</td>
										<td>{data.job_title}</td>
										<td>{data.country_name}</td>
										<td>
											{data?.phone_code}
											{data.phone_number}
										</td>
										<td>{data.email}</td>
									</tr>
								))}
							</>
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-build__product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
}
