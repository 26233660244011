import resetIcon from "assets/images/wallet/resetIcon.png";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function PromoFilter({ filters, setFilters }) {
	const { backOffice } = Locale;

	function resetFilter() {
		setFilters({ search: "", page: 1 });
	}

	return (
		<div className="main-filter bg-white-blue py-2 mt-2">
			<div className="row align-items-center  w-100 no-gutter m-0">
				<div className="col-md-4 col-6">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						value={filters.search}
						onChange={(e) => {
							setFilters({
								...filters,
								search: e.target.value,
								page: "",
							});
						}}
					/>
				</div>

				<button
					className="d-flex align-items-center text-secondary pointer reset-btn"
					onClick={resetFilter}
				>
					<img src={resetIcon} alt="reset icon" />
					<span>{backOffice.resetFilter}</span>
				</button>
			</div>
		</div>
	);
}
