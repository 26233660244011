import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import {
	addNotes,
	editConfirmation,
	editHotelBooking,
	getListNotes,
} from "services/Request";
import { fetchOnlineProviders } from "services/lookups";
import Locale from "translations";
import CustomModal from "./Modal";

export default function Booking({ bookingInfo, setReload }) {
	const { booking, backOffice, messages, requests } = Locale;
	const { id } = useParams();

	const [notesState, setNotesState] = useState();
	const [notesInputs, setNotesInputs] = useState("");
	const [active, setactive] = useState(false);
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const [confirmationNumber, setConfirmationNumber] = useState();
	const [confirmationNotes, setConfirmationNotes] = useState(null);
	const [editModalOpen, setEditModalOepn] = useState(false);
	const [editData, setEditDate] = useState({
		newBrn: null,
		newPrice: null,
		gds: null,
	});
	const [suppliersList, setSuppliersList] = useState(null);

	const supplierName = suppliersList?.filter(
		(supplier) => supplier?.id === bookingInfo?.gds
	)[0];
	const updatedSupplierName = suppliersList?.filter(
		(supplier) => supplier?.id === bookingInfo?.updated_gds
	)[0];

	const toggleConfirmation = () => {
		setConfirmationIsOpen(!confirmationIsOpen);
	};

	const toggleEdit = () => {
		setEditModalOepn(!editModalOpen);
		setEditDate({
			newBrn: null,
			newPrice: null,
			gds: null,
		});
	};

	async function getSuppliersLookup() {
		const supplierRes = await fetchOnlineProviders("hotels");
		const formatSupplier = supplierRes.data?.data?.map((t) => ({
			id: t.id,
			value: t.id,
			label: t.name,
		}));
		setSuppliersList(formatSupplier);
	}

	const fetchNotes = async () => {
		const res = await getListNotes(bookingInfo?.id);
		setNotesState(res?.data?.data);
	};
	useEffect(() => {
		fetchNotes();
		setConfirmationNumber(bookingInfo?.confirmation_number);
	}, [bookingInfo?.id]);

	useEffect(() => {
		setConfirmationNumber(bookingInfo?.confirmation_number);
	}, [bookingInfo]);

	const addNotes1 = async () => {
		const res = await addNotes({
			id: bookingInfo?.id,
			body: notesInputs,
		});

		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "Add Note successfully!",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					fetchNotes();
				},
			});
			setNotesInputs("");
		}
	};

	const allNoteList = useMemo(() => {
		return notesState?.length > 0
			? notesState?.map((datas, index) => {
					return (
						<div className="bg-gray-100 p-2 mt-2 border rounded" key={index}>
							<p className="m-0 txt-blue font-weight-bold">
								{datas.username} | {datas?.created_at}
							</p>
							<p className="m-0">{datas.body}</p>
						</div>
					);
			  })
			: [];
	}, [notesState]);

	const handleConfirmation = async () => {
		const res = await editConfirmation({
			brn: bookingInfo?.brn,
			confirmation_number: confirmationNumber,
			confirmation_notes: confirmationNotes,
		});
		if (res.status == 200) {
			toggleConfirmation();
			store.addNotification({
				title: "Info!",
				message: res?.data?.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					window.location.reload();
				},
			});
		}
	};

	async function handleBookingEdit() {
		const data = {
			brn: editData.newBrn,
			gds: editData.gds?.value?.toString(),
			updated_price: +editData.newPrice,
		};
		const editBookingRes = await editHotelBooking(bookingInfo?.brn_id, data);
		if (editBookingRes?.status === 200) {
			toggleEdit();
			store.addNotification({
				title: "Info!",
				message: editBookingRes?.data?.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setReload((prev) => !prev);
		}
	}

	useEffect(() => {
		if (!suppliersList) {
			getSuppliersLookup();
		}
	}, [bookingInfo?.reservation_status]);

	return (
		<div className="mt-2">
			<div className="border bg-white">
				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Reference}</p>
					<p className="m-0 p-2 col-4 border-booking">{bookingInfo?.brn}</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.Agency}</p>
					<p className="m-0 p-2 col-4 border-booking">{bookingInfo?.agency}</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.HotelName}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.hotel_name}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.CreatedBy}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.created_by}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">
						{" "}
						{backOffice.createdAt}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.created_at}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{backOffice.paymentMethod}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.payment_method}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">
						{backOffice.TotalPrice}
					</p>
					<p className="m-0 p-2 col-4 border-booking d-flex justify-content-between">
						<ShowForPermission permission="Hotels-Bookings-Manage">
							{bookingInfo?.total_price}
							{bookingInfo?.currency}
							{bookingInfo?.reservation_status === "paid" ? (
								<button className="btn btn-primary col-2" onClick={toggleEdit}>
									{backOffice.edit}
								</button>
							) : null}
						</ShowForPermission>
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{booking.ConfirmationNo}
					</p>
					{/* <p className="m-0 p-2 col-4">{bookingInfo?.confirmation_number}</p> */}
					<p
						className="m-0 col-4  border-booking  d-flex"
						style={{ background: "#fff" }}
					>
						<p className="mt-2 col-9 inp-confirmation">
							{bookingInfo?.confirmation_number}
						</p>
						<ShowForPermission permission="Hotels-Bookings-Manage">
							{bookingInfo?.reservation_status !== "canceled" ? (
								<button
									className="btn btn-primary col-3"
									onClick={toggleConfirmation}
								>
									{backOffice.confirmation}
								</button>
							) : null}
						</ShowForPermission>
						{/* <p
							className="m-0  col-6 pointer my-2 d-flex justify-content-end "
							style={{ background: "#fff" }}
							onClick={() => {
								toggleConfirmation();
							}}
						>
							<span
								className={` btn btn-dark p-0 ${
									active == false ? "disabled" : ""
								} `}
							>
								{"save"}
							</span>
						</p> */}
					</p>
					{parseInt(bookingInfo?.updated_price) > 0 && (
						<>
							<p className="m-0 p-2 col-2 bg-bookingView">
								{backOffice.UpdatedPrice}
							</p>
							<p className="m-0 p-2 col-4 border-booking">
								{bookingInfo?.updated_price}
								{bookingInfo?.currency}
							</p>
							<p className="m-0 p-2 col-2 bg-bookingView">
								{backOffice.UpdatedSupplier}
							</p>
							<p className="m-0 p-2 col-4 border-booking">
								{updatedSupplierName?.label}
							</p>
						</>
					)}
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Passengers}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{/* {bookingInfo?.payload?.rooms[0]?.passengers?.length} */}
						{bookingInfo?.payload?.rooms?.length > 0 &&
							bookingInfo.payload.rooms?.reduce(
								(prev, currnt) => +currnt.adults + +currnt.children + prev,
								0
							)}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.TripStarts}</p>
					<p className="m-0 p-2 col-4 border-booking">
						<span className="text-success">{bookingInfo?.start_date}</span> -{" "}
						<span className="text-danger">{bookingInfo?.end_date}</span>
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView">{booking.ContactEmail}</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.contact_email}
					</p>
					<p className="m-0 p-2 col-2 bg-bookingView">
						{booking.ContactPerson}
					</p>
					<p className="m-0 p-2 col-4 border-booking">
						{bookingInfo?.contact_person}
					</p>
				</div>

				<div className="row m-0 p-0 ">
					<p className="m-0 p-2 col-2 bg-bookingView"> {booking.Phone}</p>
					<p className="m-0 p-2 col-4 border-booking">{bookingInfo?.phone}</p>
					<p className="m-0 p-2 col-2 bg-bookingView ">
						{booking.messages.reservationStatus}
					</p>
					<p
						className={`m-0 p-2 col-4 ${
							bookingInfo?.reservation_status !== `${booking.messages.canceled}`
								? "text-success"
								: "text-danger"
						}`}
					>
						{bookingInfo?.reservation_status}
					</p>

					<p className="m-0 p-2 col-2 bg-bookingView"> {requests.Supplier}</p>
					<p className="m-0 p-2 col-4 border-booking">{supplierName?.label}</p>
				</div>
			</div>
			<div className="my-3">
				<p className="bg-gray m-0 p-2 font-weight-bold">{backOffice.Remark}</p>
				<div className="bg-gray-100 p-2 mt-1 border rounded">
					<p className="m-0 font-weight-bold">{bookingInfo?.remarks}</p>
				</div>
			</div>
			<ShowForPermission permission="Hotels-Bookings-Manage">
				<div className="">
					<p className="bg-gray m-0 mt-2 p-2 font-weight-bold">
						{booking.Notes}
					</p>
					<div className="mt-2 ">
						<textarea
							value={notesInputs}
							className="w-100 p-4"
							placeholder={booking.addNotes}
							onChange={(e) => setNotesInputs(e.target.value)}
						/>
					</div>
					<div className="d-flex justify-content-end">
						<button
							className="btn companies-btn"
							onClick={() => {
								addNotes1();
							}}
							disabled={notesInputs == ""}
						>
							{booking.addNotes}
						</button>
					</div>
				</div>
			</ShowForPermission>
			{notesState?.length > 0 ? allNoteList : null}

			<CustomModal
				modalIsOpen={confirmationIsOpen}
				header={"Confirmation"}
				toggle={toggleConfirmation}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						<div className="col-md-12">
							<TextField
								type="text"
								hasLabel={true}
								label="Confirmation Number"
								value={confirmationNumber}
								onChange={(e) => {
									setConfirmationNumber(e.target.value);
								}}
							/>
						</div>
						<div className="col-md-12 mt-2 px-3">
							<textarea
								className="w-100 p-3 my-2 border-gray"
								placeholder="Type Confirmation Note"
								value={confirmationNotes}
								onChange={(e) => setConfirmationNotes(e.target.value)}
							/>
						</div>
						<button
							disabled={!confirmationNotes || !confirmationNumber}
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100 mx-3"
							onClick={() => handleConfirmation()}
						>
							{backOffice.SendConfirmation}
						</button>
					</div>
				</div>
			</CustomModal>

			{/* edit modal */}

			<CustomModal
				modalIsOpen={editModalOpen}
				header={"Edit"}
				toggle={toggleEdit}
				size={"md"}
				centered={true}
			>
				<div>
					<div className="col-md-12">
						<TextField
							type="text"
							name="new-brn"
							id="new-brn"
							hasLabel={true}
							label="New BRN"
							value={editData.newRef}
							onChange={(e) => {
								setEditDate({ ...editData, newBrn: e.target.value });
							}}
						/>
					</div>
					<div className="col-md-12">
						<TextField
							type="number"
							name="new-price"
							id="new-price"
							hasLabel={true}
							label="New Price"
							value={editData.newPrice}
							onChange={(e) => {
								setEditDate({ ...editData, newPrice: e.target.value });
							}}
							onWheel={(e) => e.target.blur()}
						/>
					</div>
					<div className="col-md-12">
						<SelectField
							id="new-gds"
							name="new-gds"
							hasLabel={true}
							label={requests.Supplier}
							value={editData.gds}
							options={suppliersList}
							onChange={(e) => {
								setEditDate({ ...editData, gds: e });
							}}
						/>
					</div>
					<div className="col-md-12">
						<button
							disabled={!editData.newPrice || !editData.newBrn || !editData.gds}
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100"
							onClick={() => handleBookingEdit()}
						>
							Edit
						</button>
					</div>
				</div>
			</CustomModal>
		</div>
	);
}
