import CheckBox from "components/shared/Chekbox";
import { useGlobalState } from "context/global";
import Locale from "translations";

export default function PermissionGroup({
	permission,
	handelCheck,
	selectedPermissions,
	handelCheckAll,
	name,
}) {
	const { backOffice, teamManagement } = Locale;
	const { locale } = useGlobalState();

	let collectionPermission = [];

	const allCheckBoxs = permission.map((item) => {
		collectionPermission = [...collectionPermission, item.id];
		return (
			<div className="py-1 ms-4" key={item.id}>
				<CheckBox
					type="Checkbox"
					name={item.display_name["en"]}
					id={item.id}
					checked={selectedPermissions.includes(item.id)}
					text={item.display_name[locale]}
					onChange={(e) => {
						handelCheck(item.id, e.target.checked);
					}}
				/>
			</div>
		);
	});

	return (
		<div className=" col-4 col-md-4 p-2 mt-3">
			<div className="permission-card p-2">
				<h6 className="p-2 per-title">{name}</h6>
				<p className="h6 bg-teamMange_filter dark-blue-color  rounded mb-0  font-weight-bold py-2  w-100  px-2">
					<CheckBox
						type="Checkbox"
						name={permission[0].group_name}
						checked={collectionPermission.every((per) =>
							selectedPermissions.includes(per)
						)}
						text={backOffice.SelectAll}
						onChange={(e) => {
							handelCheckAll(collectionPermission, e.target.checked);
						}}
					/>
				</p>

				<div className="d-flex flex-column px-2 py-1">{allCheckBoxs}</div>
			</div>
		</div>
	);
}
