import LoginImage from "assets/images/backOffice/logo-sidebar.png";
import safaLogoSmall from "assets/images/backOffice/safaLogoSmall.svg";
import sitting from "assets/images/backOffice/setting.png";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { Link, useHistory } from "react-router-dom";
import Locale from "translations";
import SideBar from "../SideBar/SideBar";
import { NotifactionHeader } from "./notifactionHeader";

export default function NavBar(props) {
	const { isAuth, allCountries } = useGlobalState();
	const history = useHistory();
	const dispatch = useGlobalDispatch();
	const { locale } = useGlobalState();

	return (
		<header id="page-topbar">
			<div class="navbar-header">
				<div class="d-flex">
					<div
						className={`navbar-brand-box ${
							props.isOpen && !props.isMobile ? "opend" : "closed"
						}`}
					>
						<a className="logo logo-light" href={() => false}>
							{props.isOpen && !props.isMobile ? (
								<span className="logo">
									<img src={LoginImage} alt="Logo" height="30px" />{" "}
								</span>
							) : (
								<span className="logo">
									<img src={safaLogoSmall} alt="Logo" height="30px" />{" "}
								</span>
							)}
						</a>
					</div>

					<button
						type="button"
						className="btn btn-sm px-3 font-size-16 header-item"
						onClick={props.toggle}
					>
						<i class="fa fa-fw fa-bars"></i>
					</button>
				</div>
				<SideBar isOpen={props.isOpen} isMobile={props.isMobile} />

				<div className="d-flex align-items-center">
					<div className="dropdowns d-flex align-items-center">
						<LanguageSwitcher className="bg-white" />
						{/* {isAuth ? <UserSettings /> : null} */}
					</div>
					<NotifactionHeader />
					<div
						className={`dropdown d-inline-block me-2 ${
							locale === "en" ? "me-2" : "ms-2"
						}`}
					>
						<button
							className="btn header-item bg-soft-light border-start border-end"
							type="button"
							id="page-header-user-dropdown"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img
								className="rounded-circle header-profile-user"
								src={sitting}
								alt="Header Avatar"
							/>
							<span
								className={`d-none d-xl-inline-block fw-medium ${
									locale === "en" ? "ms-1" : "me-1"
								}`}
							>
								{Locale.Admin.settings}
							</span>
							<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
						</button>
						<div
							className={`dropdown-menu dropdown-menu-end ${
								locale === "ar" ? "arDropDown" : ""
							}`}
						>
							<Link to="/company-profile">
								<a
									className={`dropdown-item ${locale === "ar" && "arAlign"}`}
									href={() => false}
								>
									<i className="mdi mdi-account-settings font-size-16 align-middle me-1"></i>{" "}
									{Locale.Admin.settings}
								</a>
							</Link>
							<Link to="/change-password">
								<a
									className={`dropdown-item ${locale === "ar" && "arAlign"}`}
									href={() => false}
								>
									<i className="mdi mdi-account-settings font-size-16 align-middle me-1"></i>{" "}
									{Locale.backOffice.changePassword}
								</a>
							</Link>
							<div className="dropdown-divider"></div>
							<a
								className={`dropdown-item ${locale === "ar" && "arAlign"}`}
								href={() => false}
								onClick={() => {
									dispatch({ type: "logout" });
									history.push("/auth/login");
								}}
							>
								<i className="mdi mdi-logout font-size-16 align-middle me-1"></i>{" "}
								{Locale.messages.logout}
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
