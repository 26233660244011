import Locale from "translations";
function Travelers({ travelers }) {
	const { backOffice } = Locale;
	return (
		<div className="px-3 our-border mt-2">
			<table className="custom-table back-office-table table table-striped mb-0">
				<thead>
					<tr>
						<th>{backOffice.PassportNo}</th>
						<th>{backOffice.Name}</th>
						<th>{backOffice.gender}</th>
						<th>{backOffice.birthDate}</th>
						<th>{backOffice.nationality}</th>
						<th>{backOffice.PassportExpireDate}</th>

						<th>{backOffice.visa}</th>
						<th>{backOffice.Action}</th>
					</tr>
				</thead>

				<tbody>
					{travelers?.length > 0 ? (
						travelers.map((travel) => (
							<tr>
								<td>{travel.passport_number}</td>
								<td>{travel.name}</td>
								<td>{travel.gender}</td>
								<td>{travel.birth_date}</td>
								<td>{travel.nationality_name}</td>
								<td>{travel.passport_expiry}</td>
								<td>{travel.visa_status}</td>
								<td>{}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="15">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default Travelers;
