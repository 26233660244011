import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { commercialDistToursList } from "services/commercialDistribution";
import Locale from "translations";
import CommercialDistTourFilters from "./Filters";

export default function CommercialDistTourReservation() {
	const { allCountries, locale } = useGlobalState();
	const { backOffice, booking, vendor } = Locale;
	const { id } = useParams();

	// const [countries, setCountries] = useState(null);
	const [toursList, setToursList] = useState([]);
	const [meta, setMeta] = useState(null);

	const [filters, setFilters] = useState({
		search: "",
		country: null,
		booking_date: null,
		tour_date: null,
		status: null,
		page: 1,
	});

	function goTo(page) {
		setFilters({ ...filters, page: +page });
	}

	async function fetchTourReservations() {
		const filtersData = {
			search: filters?.search,
			status: filters?.status?.label,
			country_id: filters?.country?.id,
			created_at: filters?.booking_date
				? moment(filters?.booking_date).format("YYYY-MM-DD")
				: null,
			tour_date: filters?.tour_date
				? moment(filters?.tour_date).format("YYYY-MM-DD")
				: null,
			page: filters?.page,
		};
		const tourRes = await commercialDistToursList(id, filtersData);
		if (tourRes?.status === 200) {
			setToursList(tourRes?.data?.data);
			setMeta(tourRes?.data?.meta);
		}
	}

	useEffect(() => {
		fetchTourReservations();
	}, [filters]);

	// useEffect(() => {
	//   const formatCountries = allCountries?.map(country => ({
	//     id: country?.id,
	//     value: country.id,
	//     label: country.names[locale],
	//     code: country.country_code,
	//   }));
	//   setCountries(formatCountries);
	// }, [allCountries, locale]);

	const statusClasses = (status) => {
		switch (status?.toLowerCase()) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "issued":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};

	return (
		<div>
			<CommercialDistTourFilters
				filters={filters}
				setFilters={setFilters}
				// countries={countries}
			/>

			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Ref.</th>
							<th>{backOffice.name}</th>
							<th>{backOffice.bookingDate}</th>
							<th>{backOffice.freeCancelationChargeDate}</th>
							<th>{backOffice.Total_Price}</th>
							<th>{backOffice.status}</th>
						</tr>
					</thead>

					<tbody>
						{toursList?.length > 0 ? (
							toursList?.map((tour) => {
								return (
									<tr key={tour?.id}>
										<td>{tour?.reference_number}</td>
										<td>{tour?.leader_name}</td>
										<td>
											{tour?.booking_date
												? moment(tour?.booking_date).format("DD-MM-YYYY")
												: "-"}
										</td>
										<td>
											{tour?.free_cancel_date
												? moment(tour?.free_cancel_date).format("DD-MM-YYYY")
												: "-"}
										</td>
										<td>
											{tour?.total_price}
											{tour?.currency}
										</td>
										<td>
											<span
												className={`${statusClasses(
													tour.status
												)} text-lowercase`}
											>
												{tour?.status}
											</span>
										</td>
										<td>
											<Link
												className="d-inline-block action--btn"
												to={`/manage-commercial-distribution/${id}/view/tour/${tour.id}`}
											>
												<i class="fas fa-chevron-right mx-1"></i>
											</Link>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="9">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</div>
	);
}
