import Pagination from "components/shared/Pagination";
import Locale from "translations";
import ChangeRequest from "../shared/ChangeRequest";
import RefundRequest from "../shared/RefundRequest";
import VoidRequest from "../shared/VoidRequest ";

export default function OpenRequests({
	openedRequests,
	meta,
	goTo,
	FetchFilghtRequests,
}) {
	const { backOffice } = Locale;
	function renderRequestsByType(request) {
		switch (request?.type) {
			case "refund":
				return (
					<RefundRequest
						fetchData={FetchFilghtRequests}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
					/>
				);
			case "change":
				return (
					<ChangeRequest
						fetchData={FetchFilghtRequests}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
					/>
				);
			case "void":
				return (
					<VoidRequest
						fetchData={FetchFilghtRequests}
						request={request}
						key={`${request?.id}-${request?.request_num}`}
						isView={true}
					/>
				);
			default:
				return null;
		}
	}
	return (
		<div className="p-md-3 py-2 bg-white">
			{/* open request  */}
			<div>
				{openedRequests?.length > 0 ? (
					openedRequests?.map((request) => renderRequestsByType(request))
				) : (
					<div className="w-100 text-center">
						<div className="product-build__product-no-data fullHeight">
							<h4>
								<i className="fas fa-exclamation-triangle" />{" "}
								{backOffice.NotRequestFound}
							</h4>
						</div>
					</div>
				)}
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
