import { useCallback, useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// React-notifications
import { store } from "react-notifications-component";
// Components
import Pagination from 'components/shared/Pagination';
// Module Components
import QuickFilter from '../../shared/QuickFilter';
import PackagesFilter from './PackagesFilter';
import PackagesList from './PackagesList';
// Helper
import ShowForPermission from 'helpers/showForPermission';
// Moment
import moment from 'moment';
// Services
import { exportExcelPackagesPricingList, getPackagesPricingList } from 'services/safaVisa';
// Icons
import { ExportIcon } from 'components/Icons';
//  Custom Hoks
import useDebounce from 'hooks/useDebounce';
import useSafaVisaData from '../../shared/useSafaVisaData';
// -------------------------------------------------------

export default function Packages() {
	// *** Hooks
	let history = useHistory();

	// // *** Custom Hook
	// const { calendarTypesOprtion } = useSafaVisaData();

	const { safaVisa } = Locale;

	// *** Custom Hook
	const { initialQuickFilter } = useSafaVisaData();

	const initialFilter = {
		country_ids: '',
		currency: '',
		package_type: '',
		audience_type: '',
		calendar_type: { value: 'gregorian', label: safaVisa.gregorian }, // default calendar type
		date_from: '',
		date_to: '',
	}

	// ** State
	const [quickfilters, setQuickFilter] = useState(initialQuickFilter);
	const [filters, setFilters] = useState(initialFilter);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [reload, setReload] = useState(false);

	const [packagePricing, setPackagePricing] = useState({
		list: [],
		meta: {}
	});

	//** Function
	const goTo = (page) => {
		setQuickFilter({ ...quickfilters, page: page });
	};

	const fetctPackagesList = async (params) => {
		const response = await getPackagesPricingList(params)
		if (response?.status === 200) {
			setPackagePricing({
				list: response?.data?.data,
				meta: response?.data?.meta
			})
		}
	}

	const depounceSearchByTitle = useDebounce((params) =>
		fetctPackagesList({ ...params })
		, 300)

	const handleQuickFilter = useCallback(({ action, key, payload }) => {
		if (action === "reset") {
			setQuickFilter(initialQuickFilter);
		}
		if (action === "filter") {
			setQuickFilter((prevFilter) => ({ ...prevFilter, [key]: payload }));
		}
	}, []);

	const handleFilter = useCallback(({ action, key, payload }) => {
		if (action === "reset") {
			setFilters({ ...initialFilter });
			setQuickFilter({ ...initialQuickFilter, title: '' })
		}
		if (action === "filter") {
			setFilters((prevFilter) => ({ ...prevFilter, [key]: payload }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleApplyFilter = () => {
		fetctPackagesList({
			...quickfilters,
			title: quickfilters?.title || null,
			country_ids: filters?.country_ids?.value ? [Number(filters?.country_ids?.value)] : null,
			currency: filters?.currency?.label,
			audience_type: filters?.audience_type?.value,
			duration_type: filters?.package_type?.value,
			date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
			date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
		})
	}

	const handleToggleFilter = () => {
		setFilterIsOpen(!filterIsOpen)
	};

	const exportExcel = async () => {
		const res = await exportExcelPackagesPricingList({
			...quickfilters,
			title: quickfilters?.title || null,
			country_ids: filters?.country_ids?.value ? [Number(filters?.country_ids?.value)] : null,
			currency: filters?.currency?.label,
			audience_type: filters?.audience_type?.value,
			date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
			date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: 'xlsx',
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Packages Pricing List -${moment().format("DD-MM-YYYY")}.xlsx`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}


	useEffect(() => {
		if (quickfilters?.title) {
			depounceSearchByTitle({
				...quickfilters,
				title: quickfilters?.title || null,
				country_ids: filters?.country_ids?.value ? [Number(filters?.country_ids?.value)] : null,
				currency: filters?.currency?.label,
				audience_type: filters?.audience_type?.value,
				duration_type: filters?.package_type?.value,
				date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
				date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
			})
		} else {
			fetctPackagesList({
				...quickfilters,
				title: quickfilters?.title || null,
				country_ids: filters?.country_ids?.value ? [Number(filters?.country_ids?.value)] : null,
				currency: filters?.currency?.label,
				audience_type: filters?.audience_type?.value,
				duration_type: filters?.package_type?.value,
				date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
				date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
			})
		}
	}, [reload, quickfilters])

	// console.log(filters)
	// -------- JSX Code --------
	return (
		<div className='safaVisa-wrapper'>
			<div>
				{/* Packages Header */}
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="main-title mb-0">{safaVisa.packages}</h4>

					<div className='d-flex gap-2 align-items-center'>
						<ShowForPermission permission="Tourism-Company-Manage">
							<button
								className="btn export-btn py-2"
								onClick={exportExcel}
							>
								<ExportIcon color='#57A866' width='24' height='24' />
								{safaVisa.exportExcel}
							</button>
						</ShowForPermission>
						<ShowForPermission permission="Tourism-Company-Manage">
							<button
								className="btn  add-btn py-2"
								onClick={() => history.push("/safaVisa-packages/add")}
							>
								<i className="fas fa-plus text-white fa-md"></i>{" "}
								{safaVisa.add}
							</button>
						</ShowForPermission>
					</div>
				</div>

				<hr />

				{/* Packages Filter */}
				<div className='safa-visa-filter'>
					<QuickFilter
						quickfilters={quickfilters}
						onQuickFilter={handleQuickFilter}
						toggleFilter={handleToggleFilter}
						filterIsOpen={filterIsOpen}
					/>

					{filterIsOpen &&
						<PackagesFilter
							filters={filters}
							onFilter={handleFilter}
							onApplyFilter={handleApplyFilter}
						/>
					}

				</div>

				{/* Packages List */}
				<PackagesList
					packagesList={packagePricing?.list}
					reload={reload}
					onReload={setReload}
				/>

				{/* Pagination */}
				<Pagination info={packagePricing?.meta} goTo={goTo} items={10} />
			</div>
		</div>
	);
}


