
import { useEffect, useState } from 'react';
// React-Router-Dom
import { useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import MultiDatePicker from 'components/shared/MultiDatePicker';
import RadioGroup from 'components/shared/RadioGroup';
import SelectField from 'components/shared/SelectField';
import TextField from 'components/shared/TextField';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Context
import { useGlobalState } from 'context/global';
// Helper
import validate from 'helpers/validate';
// Moment
import moment from "moment-hijri";
// -----------------------------------------------------------------------
export default function TargetCampaignsDetailsForm(props) {

  const {
    targetCampaignData,
    setTargetCampaignData,
    defaultPriceOptions,
    errors,
    setErrors,
    setAllRequiredInputs,
  } = props

  // *** Hook
  const { safaVisa } = Locale;

  let { id, status } = useParams();

  // *** Custom Hook
  const {
    formattedCountries,
    pricingTypesOptions,
    calendarTypesOprtion,
    endDateVisiabiltyOptions,
    maxHijriDate
  } = useSafaVisaData();

  const { allCountries } = useGlobalState();

  const pricingTypesDropdownOptions = [
    ...pricingTypesOptions,
    { name: safaVisa?.all, label: safaVisa?.all, value: 'all' },
  ]

  // *** State
  const [countries, setCountries] = useState([]);

  const handleChangeInputs = ({ key, value }) => {
    const dataClone = { ...targetCampaignData }
    dataClone[`${key}`] = value
    // to change  End date input  required
    if (key === 'visible_end_date' && value === 'end-date-determined') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: [...prevStatus?.requiredDetailsInputs, 'end_date'],
      }))
    }
    if (key === 'visible_end_date' && value === 'end-date-indeterminate') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: prevStatus?.requiredDetailsInputs?.filter(e => e !== 'end_date'),
      }))
    }
    if (key === 'pricing_type' && (value === 'default_price' || value === 'all')) {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: [...prevStatus?.requiredDetailsInputs, 'default_pricing_id'],
      }))
    }
    if (key === 'pricing_type' && value === 'package') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: prevStatus?.requiredDetailsInputs?.filter(e => e !== 'default_pricing_id')
      }))
    }
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setTargetCampaignData(dataClone)
  }

  useEffect(() => {
    setCountries(formattedCountries(allCountries));
  }, [allCountries]);

  // -------- JSX Code ---------
  return (
    <form className="mt-4">

      {/* Campaign Details Form  */}
      <div className='row mb-4'>

        {/* Campaign Title Ar  */}
        <div className={`col-4  `}>
          <TextField
            type="text"
            label={safaVisa.campaignTitleAr}
            hasLabel={true}
            name="title_ar"
            id="title_ar"
            value={targetCampaignData?.title_ar}
            onChange={(e) => handleChangeInputs({ key: 'title_ar', value: e?.target?.value })}
            color={errors?.title_ar?.required ? "danger" : ""}
            errors={errors?.title_ar}
          />
        </div>

        {/* Campaign Title En  */}
        <div className={`col-4  `}>
          <TextField
            type="text"
            label={safaVisa.campaignTitleEn}
            hasLabel={true}
            name="title_en"
            id="title_en"
            value={targetCampaignData?.title_en}
            onChange={(e) => handleChangeInputs({ key: 'title_en', value: e?.target?.value })}
            color={errors?.title_en?.required ? "danger" : ""}
            errors={errors?.title_en}
          />
        </div>

        <div className={`col-4`} />

        {/* Campaign Countries  */}
        <div className={`col-4`}>
          <SelectField
            multi
            id="countries_id"
            name="countries_id"
            label={safaVisa?.country}
            // disabled={discountData?.discounted_item_id?.id}
            placeholder={safaVisa?.select}
            options={countries}
            value={targetCampaignData?.countries_id}
            onChange={(values, { action }) => {
              if (action === "remove-value" && !values) {
                handleChangeInputs({ key: 'countries_id', value: [] })
                return;
              }
              handleChangeInputs({ key: 'countries_id', value: values })
              setErrors({
                ...errors,
                ...validate(
                  { name: "countries_id", value: values.length },
                  { required: true }
                ),
              });
            }}
            color={errors?.countries_id?.required ? "danger" : ""}
            errors={errors?.countries_id}
          />
        </div>

        {/* Campaign Date Type  */}
        <div className="col-4" >
          <RadioGroup
            hasLabel={true}
            label={safaVisa?.calendarType}
            name='date_type'
            groupOptions={calendarTypesOprtion}
            onChange={(e) => handleChangeInputs({ key: 'date_type', value: e?.target?.value })}
            value={targetCampaignData?.date_type}
          />
        </div>

        {/* Campaign Start Date   */}
        <div className="col-4">
          <MultiDatePicker
            label={safaVisa.campaignStartDate}
            placeholder={safaVisa?.campaignStartDate}
            hasLabel={true}
            type={targetCampaignData?.date_type}
            value={
              targetCampaignData?.date_type === 'gregorian' ?
                targetCampaignData?.start_date
                :
                moment(targetCampaignData?.start_date).format("iYYYY/iMM/iDD")
            }
            // value={targetCampaignData?.start_date}
            onChangeDate={(e) => handleChangeInputs({ key: 'start_date', value: e?.toDate() })}
            format={'YYYY/MM/DD'}
            minDate={targetCampaignData?.date_type === 'gregorian' ?
              moment().format('YYYY/MM/DD')
              :
              moment().format("iYYYY/iMM/iDD")
            }
            maxDate={
              (targetCampaignData?.date_type === 'gregorian' && targetCampaignData?.visible_end_date === 'end-date-determined') ?
                moment(targetCampaignData?.end_date).format('YYYY/MM/DD')
                :
                (targetCampaignData?.date_type === 'hijri' && targetCampaignData?.visible_end_date === 'end-date-determined') ?
                  moment(targetCampaignData?.end_date).format("iYYYY/iMM/iDD")
                  :
                  (targetCampaignData?.date_type === 'gregorian' && targetCampaignData?.visible_end_date === 'end-date-indeterminate') ?
                    "2100/12/31"
                    :
                    maxHijriDate
            }
            color={errors?.start_date?.required ? "danger" : ""}
            errors={errors?.start_date}
          />
        </div>

        {/* Campaign End-date Visiablity  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.campaignValidity}
            name='visible_end_date'
            groupOptions={endDateVisiabiltyOptions}
            onChange={(e) => handleChangeInputs({ key: 'visible_end_date', value: e?.target?.value })}
            value={targetCampaignData?.visible_end_date}
            color={errors?.visible_end_date?.required ? "danger" : ""}
            errors={errors?.visible_end_date}
          />
        </div>

        {/* Campaign End Date   */}
        <div className="col-4" >
          <MultiDatePicker
            label={safaVisa.campaignEndDate}
            placeholder={safaVisa?.campaignEndDate}
            hasLabel={true}
            type={targetCampaignData?.date_type}
            value={
              targetCampaignData?.date_type === 'gregorian' ?
                targetCampaignData?.end_date
                :
                moment(targetCampaignData?.end_date).format("iYYYY/iMM/iDD")
            }
            // value={targetCampaignData?.end_date}
            onChangeDate={(e) => handleChangeInputs({ key: 'end_date', value: e?.toDate() })}
            format={'YYYY/MM/DD'}
            // minDate={moment(targetCampaignData?.start_date).add(1, 'days').format('YYYY/MM/DD')}
            // maxDate="2100-12-31"
            onOpen={() => {
              handleChangeInputs({
                key: 'end_date',
                value: targetCampaignData?.date_type === 'gregorian' ?
                  moment(targetCampaignData?.start_date).add(1, 'days').format('YYYY/MM/DD')
                  :
                  moment(targetCampaignData?.start_date).add(1, "days").format("iYYYY/iMM/iDD")
              })
            }}
            minDate={
              targetCampaignData?.date_type === 'gregorian' ?
                moment(targetCampaignData?.start_date).add(1, 'days').format('YYYY/MM/DD')
                :
                moment(targetCampaignData?.start_date).add(1, "days").format("iYYYY/iMM/iDD")
            }
            maxDate={targetCampaignData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
            disabled={targetCampaignData?.visible_end_date === 'end-date-indeterminate'}
            color={errors?.end_date?.required ? "danger" : ""}
            errors={errors?.end_date}
          />
        </div>

        <div className="col-4" />

        {/* Campaign Pricing Type  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.pricingType}
            name='pricing_type'
            groupOptions={pricingTypesDropdownOptions}
            onChange={(e) => handleChangeInputs({ key: 'pricing_type', value: e?.target?.value })}
            value={targetCampaignData?.pricing_type}
            color={errors?.pricing_type?.required ? "danger" : ""}
            errors={errors?.pricing_type}
          />
        </div>

        {/* Dafault Pricing   */}
        {(targetCampaignData?.pricing_type === 'default_price' ||
          targetCampaignData?.pricing_type === 'all') ?
          <div className={`col-4`}>
            <SelectField
              hasLabel={true}
              label={safaVisa?.defaultPricing}
              placeholder={safaVisa?.select}
              id="default_pricing_id"
              name="default_pricing_id"
              value={targetCampaignData?.default_pricing_id}
              options={defaultPriceOptions}
              onChange={(e) => handleChangeInputs({ key: 'default_pricing_id', value: e })}
              color={errors?.default_pricing_id?.required ? "danger" : ""}
              errors={errors?.default_pricing_id}
            />
          </div>
          :
          <div className={`col-4`} />
        }

        {/* Campaign Notification Ar */}
        <div className="col-6 custom-field mt-6">
          <TextAreaField
            rows={5}
            label={safaVisa?.campaignNotificationAR}
            placeholder={safaVisa?.campaignNotificationAR}
            value={targetCampaignData?.notification_text_ar}
            onChange={(e) => handleChangeInputs({ key: 'notification_text_ar', value: e?.target?.value })}
            color={errors?.notification_text_ar?.required ? "danger" : ""}
            errors={errors?.notification_text_ar}
          />

        </div>

        {/* Campaign Notification EN */}
        <div className="col-6 custom-field mt-6">
          <TextAreaField
            rows={5}
            label={safaVisa?.campaignNotificationEN}
            placeholder={safaVisa?.campaignNotificationEN}
            value={targetCampaignData?.notification_text_en}
            onChange={(e) => handleChangeInputs({ key: 'notification_text_en', value: e?.target?.value })}
            color={errors?.notification_text_en?.required ? "danger" : ""}
            errors={errors?.notification_text_en}
          />

        </div>

      </div>

    </form >
  )
}

