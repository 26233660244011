import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { listBankConfigrations } from "services/bankConfigrations";
import Locale from "translations";
import Bank from "./BankItem";
import Filter from "./Filter";

export default function BankConfigrations() {
	const { requests, backOffice, payment, financial } = Locale;

	const [filter, setFilter] = useState({
		country_id: null,
		currency: null,
		search: null,
	});
	const [banks, setBanks] = useState([]);
	let history = useHistory();

	useEffect(() => {
		const fetchListConfigrations = async () => {
			const response = await listBankConfigrations({
				country_id: filter?.country_id?.value,
				currency: filter?.currency?.label,
				search: filter?.search,
			});
			if (response.status === 200 || response.status === 200) {
				setBanks(response.data.data);
			}
		};
		fetchListConfigrations();
	}, [filter]);

	const allBanksConfigrations =
		banks.length > 0
			? banks.map((bank) => {
					return <Bank bank={bank} />;
			  })
			: [];

	return (
		<div>
			<div className="d-flex justify-content-between">
				<p className="main-title ">{backOffice.BankTransferDetails}</p>
				<div className="">
					<ShowForPermission permission="Bank-Transfer-Details-Manage">
						<button
							className="btn companies-btn w-100"
							onClick={() => {
								history.push("/bank-configuration/add");
							}}
						>
							{backOffice.addNew}
						</button>
					</ShowForPermission>
				</div>
			</div>

			<Filter filter={filter} setFilter={setFilter} />

			<div className="our-border px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{financial.currency}</th>
							<th>{financial.country}</th>
							<th>{requests.BankName}</th>
							<ShowForPermission permission="Bank-Transfer-Details-Manage">
								<th>{payment.messages.tools}</th>
							</ShowForPermission>
						</tr>
					</thead>

					<tbody>
						{allBanksConfigrations.length > 0 ? (
							allBanksConfigrations
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<Pagination />
			</div>
		</div>
	);
}
