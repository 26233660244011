import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router";
import { getPreCancelTour } from "services/Request";
import Locale from "translations";
import CancelModel from "./CancelModel";
export default function ToursBookingSummary({
	payNow,
	bookingDetails,
	setBookingDetails,
}) {
	const { backOffice, booking } = Locale;
	const [modalCancel, setModalCancel] = useState(false);
	const { id } = useParams();
	const [preCancel, setPreCancel] = useState();
	const toggleModalCancel = () => setModalCancel(!modalCancel);
	const canceledStatus = bookingDetails?.status === "canceled";
	const expiredStatus = bookingDetails?.status === "expired";
	const cancelDate =
		bookingDetails?.reservations &&
		bookingDetails?.reservations.every((item) => {
			return moment().isBefore(moment(item.start_date));
		});
	const canCancel =
		cancelDate &&
		!canceledStatus &&
		!expiredStatus &&
		bookingDetails?.payment_status !== "pending";
	payNow =
		bookingDetails?.payment_status === "pending" &&
		!canceledStatus &&
		!expiredStatus;
	const amountDetails = {
		totalPrice: bookingDetails?.total_price,
		currency: bookingDetails?.currency,
	};
	const confirmedReservations = bookingDetails?.reservations?.filter(
		(reservation) => reservation?.status === "confirmed"
	);

	async function getPreCancel() {
		const res = await getPreCancelTour(id);
		if (res.status === 200) {
			setModalCancel(true);
			setPreCancel(res?.data?.data);
		} else {
			setModalCancel(false);
		}
	}

	return (
		<div className=" mt-2 mt-md-0">
			<div className="bg-light-danger d-flex justify-content-between text-dark-blue p-3">
				<span>{backOffice.ReferenceNo}</span>
				<span className="mb-1">{bookingDetails?.safa_reference}</span>
			</div>
			<h6 className="text-dark-blue mt-3">{booking.messages.bookingSummary}</h6>
			{/* cart items */}
			{confirmedReservations?.length > 0 ? (
				<div className="p-2 mt-3 light-border bg-white">
					{confirmedReservations?.map((reservation, index) => (
						<div key={`summary-${reservation?.details?.id}`}>
							<div className="d-flex tour-booking-summary-item">
								<div className="col-md-9">
									<p className="font-weight-bold text-14">
										{reservation?.details?.name}
									</p>
									<div className="tour-booking-item-info flex-wrap">
										<i className="fas fa-map-marker-alt"></i>
										<span className="mx-1">
											{reservation?.details?.destinations?.map((city) => {
												return (
													<span key={city?.code}>
														{city?.name} ({city?.code}),{" "}
													</span>
												);
											})}
										</span>
									</div>

									<div className="tour-booking-item-info flex-wrap">
										<i className="far fa-calendar-alt"></i>
										<span className="mx-1">
											{moment(reservation?.start_date).format("DD/MM/YYYY")} (
											{moment(reservation?.start_date).format("dddd")})
										</span>
									</div>

									<div className="tour-booking-item-info flex-wrap">
										<i className="far fa-user"></i>
										<span className="mx-1">
											{reservation?.adults} {booking.messages.adults}
										</span>
										{reservation?.children?.length > 0 ? (
											<span>
												{reservation?.children?.length}{" "}
												{booking.messages.children} (
												{reservation?.children?.map((age, index) => {
													return (
														<span key={`age-${index}-${age}`}>
															{age}
															{index !== reservation?.children?.length - 1
																? ", "
																: " "}
														</span>
													);
												})}
												{backOffice.YearsOld} )
											</span>
										) : null}
									</div>
								</div>
								<span className="col font-weight-bold text-14">
									{reservation?.price?.toFixed(2)} {reservation?.currency}
								</span>
							</div>

							{index !== confirmedReservations?.length - 1 && <hr />}
						</div>
					))}
				</div>
			) : null}
			<div className="d-flex justify-content-between text-dark-blue bg-white p-2">
				<span className="fw-bold">{backOffice.TotalPrice}</span>
				<span className="fw-bold">
					{bookingDetails?.total_price?.toFixed(2)} {bookingDetails?.currency}
				</span>
			</div>
			{/* <ShowForPermission permission="Tours-Bookings-Manage">
				{canCancel && (
					<div
						className="text-light-danger tour-booking-action pointer mt-2"
						onClick={getPreCancel}
					>
						<i className="fas fa-ban text-light-danger"></i>
						<span className="mx-1">Cancel All Tour</span>
					</div>
				)}
			</ShowForPermission> */}

			<CancelModel
				model={modalCancel}
				toggleModel={toggleModalCancel}
				details={confirmedReservations}
				cancelType={"all-items"}
				preCancel={preCancel}
				amount={amountDetails}
				setBookingDetails={setBookingDetails}
			/>
		</div>
	);
}
