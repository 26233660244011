// Translation
import Locale from 'translations';
// React-notifications
import { store } from "react-notifications-component";
// Context
import { useGlobalState } from 'context/global';
// Icons
import { DollarIcon, InfoIcon, VisasIcon } from 'components/Icons';
import moment from "moment-hijri";
// ------------------------------------------------------------
function useSafaVisaData() {

  //***  Hooks
  const { locale } = useGlobalState();

  const { safaVisa } = Locale;

  // Get current Hijri year
  const hijriYear = moment().iYear();

  // Set the date to 15 Sha'ban (08-15) of the current Hijri year
  const defaultHijriDate = `${hijriYear}-08-15`;

  const maxHijriDate = moment("1500-12-30", "iYYYY-iMM-iDD").format("iYYYY/iMM/iDD");

  const defaultPricingTabs = [
    {
      index: 0,
      label: safaVisa?.pricingDetails,
      value: 'pricing-details',
      isDisabled: false,
      icon: <InfoIcon color='#9C9FAB' />
    },
    {
      index: 1,
      label: safaVisa?.pricing,
      value: 'pricing-list',
      isDisabled: false,
      icon: <DollarIcon color='#9C9FAB' />
    },
  ]

  const initialQuickFilter = {
    title: null,
    is_active: null,
    page: 1,
  }

  const packagesPricingTabs = [
    {
      index: 0,
      label: safaVisa?.packageDetails,
      value: 'package-details',
      isDisabled: false,
      icon: <InfoIcon color='#9C9FAB' />
    },
    {
      index: 1,
      label: safaVisa?.pricing,
      value: 'subscription-types',
      isDisabled: false,
      icon: <DollarIcon color='#9C9FAB' />
    },
    {
      index: 2,
      label: safaVisa?.visas,
      value: 'package-visas',
      isDisabled: false,
      icon: <VisasIcon color='#9C9FAB' />
    },
  ]

  const calendarTypesOprtion = [
    { name: safaVisa.hijri, value: 'hijri', label: safaVisa.hijri },
    { name: safaVisa?.gregorian, value: 'gregorian', label: safaVisa.gregorian },
  ]

  const endDateVisiabiltyOptions = [
    { name: safaVisa?.determined, value: 'end-date-determined' },
    { name: safaVisa?.indeterminate, value: 'end-date-indeterminate' },
  ]

  const audienceTypeOptions = [
    { name: safaVisa?.public, value: 'public', label: safaVisa?.public },
    { name: safaVisa?.custom, value: 'custom', label: safaVisa?.custom },
  ]

  const packageSubscriptionOptions = [
    { name: safaVisa?.prepaid, value: 'prepaid', label: safaVisa?.prepaid },
    { name: safaVisa?.postpaid, value: 'postpaid', label: safaVisa?.postpaid },
    { name: safaVisa?.all, value: 'all', label: safaVisa?.all },
  ]

  const offerTypesOptions = [
    { name: safaVisa?.price, value: 'price', label: safaVisa?.price },
    { name: safaVisa?.units, value: 'units', label: safaVisa?.units },
    { name: safaVisa?.all, value: 'offers_all', label: safaVisa?.all },
  ]

  const discountTypesOptions = [
    { name: safaVisa?.percentage, value: 'percentage', label: safaVisa?.percentage },
    { name: safaVisa?.fixedValue, value: 'fixed', label: safaVisa?.fixedValue },
  ]

  const packagesTypeOptions = [
    { label: safaVisa?.yearly, value: 'yearly' },
    { label: safaVisa?.seasonal, value: 'seasonal' },
    { label: safaVisa?.custom, value: 'custom' },
  ]

  const repurchaseOptions = [
    { name: safaVisa?.available, value: 'repurchase-available' },
    { name: safaVisa?.notAvailable, value: 'repurchase-notAvailable' },
  ]

  const pricingTypesOptions = [
    { name: safaVisa?.package, label: safaVisa?.package, value: 'package' },
    { name: safaVisa?.default, label: safaVisa?.default, value: 'default_price' },
  ]


  const contractsOptions = [
    { name: safaVisa?.determined, value: 'contract-determined' },
    { name: safaVisa?.indeterminate, value: 'contarct-indeterminate' },
  ]

  const agentsOptions = [
    { name: safaVisa?.determined, value: 'agents-determined' },
    { name: safaVisa?.indeterminate, value: 'agents-indeterminate' },
  ]

  const promoCodeValidityOptions = [
    { name: safaVisa?.determined, value: 'promoCode-determined' },
    { name: safaVisa?.indeterminate, value: 'promoCode-indeterminate' },
  ]

  const visaTypesOptions = [
    { label: safaVisa.all, value: 'all' },
    { label: safaVisa.umrah, value: 'umrah' },
    { label: safaVisa.visit, value: 'visit' },
    { label: safaVisa.hajj, value: 'hajj' },
  ]

  const requiredVisaPriceInputs = ['visa_type', 'price']

  const requiredVisasInputs = ['visa_type', 'amount']

  const initialPricingData = {
    title_ar: '',
    title_en: '',
    description_ar: '',
    description_en: '',
    countries_id: [],
    currency: '',
    audience_type: '',
    contract_count: '',
    agents_count: '',
    prices: [],
    with_contracts: 'contarct-indeterminate',
    with_Agents: 'agents-indeterminate',
  }

  const initialPackageData = {
    title_ar: '',
    title_en: '',
    description_ar: '',
    description_en: '',
    countries_id: [],
    currency: '',
    audience_type: '',
    with_contracts: 'contarct-indeterminate',
    contract_count: '',
    with_Agents: 'agents-indeterminate',
    agents_count: '',
    date_type: 'gregorian',
    package_type: '', // duration_type in api
    duration_count: '',
    repurchase: 'repurchase-available', // repurchase in api
    share_start_date: new Date(),
    visible_share_end_date: 'end-date-indeterminate',
    share_end_date: '',
    // dates for season package
    season_start_date: '', // 15 Sha'ban current hijriYear
    season_end_date: '',
    season_days: '',
    // date for custom package
    start_date: new Date(),
    custom_package_days: '',
    end_date: '',
    subscription_type: 'all',
    // subscription types
    postpaid_price: null,
    prepaid_price: null,
    quotas: []
  }

  const initialDiscountData = {
    pricing_type: "",
    discounted_item_id: '',
    // pricing_package_id: null,
    // default_pricing_id: 1,
    countries_id: [],
    date_type: "gregorian",
    start_date: '',
    visible_end_date: 'end-date-indeterminate',
    end_date: null,
    notification_text_ar: '',
    notification_text_en: '',
    subscription_type: '',
    offer_type: 'price',
    discount_type: "",
    is_price_offer: true,
    is_quota_offer: true,
    postpaid_discount: '',
    prepaid_discount: '',
    quota_discounts: [
      // {
      //   visa_type: safaVisa.hajj,
      //   amount: 100,
      //   units: '',
      // },
    ],
    price_discounts: [
      // {
      //   visa_type: "visit",
      //   price: 100,
      //   currency: 'EGP',
      //   discount_percentage: '',
      //   price_after_discount: '',
      // },
    ],
    package_pricing: [
      // {
      //   name: safaVisa.postpaid,
      //   price: 100,
      //   currency: "EGP",
      //   postpaid_discount_percentage: '',
      //   postpaid_after_discount: '',
      // },
      // {
      //   name: safaVisa.prepaid,
      //   price: 100,
      //   currency: "EGP",
      //   prepaid_discount_percentage: '',
      //   prepaid_after_discount: '',
      // },
    ]
  }

  const initialCampaignData = {
    title_ar: '',
    title_en: '',
    countries_id: [],
    pricing_type: '',
    date_type: 'gregorian',
    start_date: new Date(),
    visible_end_date: 'end-date-indeterminate',
    end_date: '',
    notification_text_ar: '',
    notification_text_en: '',
    is_default_pricing: true,
    is_pricing_package: true,
    default_pricing_id: '',
    pricing_packages_id: [],
    companies_id: [],
  }

  const initialPromoCodeData = {
    countries_id: [],
    currency: '',
    date_type: 'gregorian',
    start_date: '',
    visible_end_date: 'end-date-indeterminate',
    end_date: '',
    code_validity: 'promoCode-indeterminate',
    max_user_usages: '',
    max_usages: '',
    audience_type: '',
    code: '',
    notification_text_ar: '',
    notification_text_en: '',
    discount_type: '',
    discount_amount: '',
    max_discount_amount: '',
    min_spend: '',
    pricing_type: '',
    companies_id: []
  }

  const requiredCampaignInputs = [
    "title_ar",
    "title_en",
    "countries_id",
    "notification_text_ar",
    "notification_text_en",
    "start_date",
    "pricing_type",
  ]

  const requiredPricingInputs = [
    "title_ar",
    "title_en",
    "description_ar",
    "description_en",
    "countries_id",
    "currency",
    "audience_type",
  ]

  const requiredPackagePricingInputs = [
    "title_ar",
    "title_en",
    "description_ar",
    "description_en",
    "countries_id",
    "currency",
    "package_type",
    "repurchase",
    "audience_type",
    "duration_count",
    "date_type",
    "share_start_date",
  ]

  const requiredDiscountInputs = [
    "pricing_type",
    "discounted_item_id",
    "countries_id",
    "date_type",
    "start_date",
    // "end_date",
    "notification_text_ar",
    "notification_text_en",
    // "is_price_offer",
    // "is_quota_offer",
    "discount_type",
  ]

  const requiredPromoCodeInputs = [
    "pricing_type",
    "countries_id",
    "currency",
    "start_date",
    "notification_text_ar",
    "notification_text_en",
    "discount_type",
    "discount_amount",
    "audience_type",
    "code",
  ]

  // *** Functions
  const formattedCountries = (allCountries) => {
    const countries = allCountries.map((t) => ({
      value: t.id,
      label: t.names?.[locale],
      code: t.country_code,
    }));
    return countries
  }

  const formattedCurrencies = (allCurrencies) => {
    const currencies = allCurrencies.map((t) => ({
      value: t.id,
      label: t?.currency_code,
      code: t.currency_code,
    }));
    return currencies
  }

  const exportExcel = async (params) => {
    const { apiFunctionService, fileName } = params
    const res = await apiFunctionService
    if (res?.status === 200 || res?.status === 201) {
      if (res.data) {
        const blob = new Blob([res.data], {
          type: 'xlsx',
        });
        const objectUrl = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `${fileName} -${moment().format("DD-MM-YYYY")}.xlsx`
        );
        anchor.click();
      }
      store.addNotification({
        title: "Info!",
        message: `Export To Excel Successfully`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  function truncateText(text, maxLength = 15) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  return {
    formattedCountries,
    formattedCurrencies,
    exportExcel,
    truncateText,
    defaultPricingTabs,
    packagesPricingTabs,
    audienceTypeOptions,
    contractsOptions,
    agentsOptions,
    initialPricingData,
    initialPackageData,
    initialDiscountData,
    initialCampaignData,
    initialPromoCodeData,
    requiredPricingInputs,
    requiredCampaignInputs,
    requiredPromoCodeInputs,
    requiredVisaPriceInputs,
    requiredVisasInputs,
    requiredPackagePricingInputs,
    requiredDiscountInputs,
    packageSubscriptionOptions,
    offerTypesOptions,
    visaTypesOptions,
    pricingTypesOptions,
    calendarTypesOprtion,
    packagesTypeOptions,
    discountTypesOptions,
    repurchaseOptions,
    endDateVisiabiltyOptions,
    promoCodeValidityOptions,
    initialQuickFilter,
    maxHijriDate
  }
}

export default useSafaVisaData