


import { useEffect, useState } from 'react';
// React-Router-dom
import { useHistory, useParams } from 'react-router-dom';
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Module Component
import DiscountDetailsForm from './DiscountDetailsForm';
import DiscountPricingTypes from './DiscountPricingTypes';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// Moment
import moment from 'moment/moment';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// Context
import { useGlobalState } from 'context/global';
// Services
import {
  addDiscount,
  editDiscount,
  getDefaultPricingLookups,
  getDiscountDetailsById,
  getPackagesLookups
} from 'services/safaVisa';
//---------------------------------------------------------------------
function AddEditDiscount() {
  // *** Hook
  let history = useHistory();

  const { safaVisa } = Locale;

  let { id, status } = useParams();

  const { locale } = useGlobalState();

  // *** Custom Hook
  const { requiredDiscountInputs, initialDiscountData } = useSafaVisaData();

  // *** State
  const [discountData, setDiscountData] = useState(initialDiscountData)
  const [dicountItemsOptions, setDicountItemsOptions] = useState([])
  const [allRequiredInputs, setAllRequiredInputs] = useState({
    requiredDetailsInputs: requiredDiscountInputs,
    requiredPricingInputs: []
  })
  const [errors, setErrors] = useState();

  // ** function 
  const checkFormErrors = () => {
    let submitError = {};

    // check required inputs of details form
    allRequiredInputs?.requiredDetailsInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: discountData[key] },
          { required: true }
        )
      }
    })

    // check required inputs of pricing in pacakge or default pricing and some conditions
    if ((discountData?.pricing_type === 'package' && discountData?.offer_type === 'offers_all') &&
      (discountData?.subscription_type?.includes('paid') || discountData?.subscription_type === 'all')) {
      allRequiredInputs?.requiredPricingInputs?.filter(el => el !== 'units')?.forEach((key, index) => {
        const checkedIndex = discountData['package_pricing']?.findIndex(el => el?.name?.toLowerCase() === key?.split('_')[0])
        submitError = {
          ...submitError,
          ...validate(
            { name: `${key}`, value: discountData[`package_pricing`][checkedIndex][key] },
            { required: true }
          ),
        }
      })
      allRequiredInputs?.requiredPricingInputs?.filter(el => el === 'units').forEach((key, index) => {
        submitError = {
          ...submitError,
          ...validate(
            // { name: `${key}`, value: discountData[`quota_discounts`][index][key] },
            { name: `${key}`, value: discountData[`quota_discounts`].some(e => e[key] > 0) },
            { required: true }
          ),
        }
      })
    } else {
      allRequiredInputs?.requiredPricingInputs?.forEach((key, index) => {
        const checkedProp = (
          (discountData?.subscription_type?.includes('paid') || discountData?.subscription_type === 'all')
          && (discountData?.offer_type === 'price' && discountData?.pricing_type === 'package')
        ) ?
          'package_pricing'
          :
          (discountData?.offer_type === 'units' && discountData?.pricing_type === 'package') ?
            'quota_discounts'
            :
            'price_discounts'
        // console.log(checkedProp)

        // const checkedIndex = (
        //   (discountData?.subscription_type?.includes('paid') || discountData?.subscription_type === 'all')
        //   && (discountData?.offer_type === 'price' && discountData?.pricing_type === 'package')
        // ) ?
        //   discountData['package_pricing']?.findIndex(el => el?.name?.toLowerCase() === key?.split('_')[0])
        //   :
        //   index

        // const checkedIndex = checkedProp === 'package_pricing' ?
        //   discountData['package_pricing']?.findIndex(el => el?.name?.toLowerCase() === key?.split('_')[0])
        //   :
        //   checkedProp === 'quota_discounts' ?
        //     discountData[checkedProp]?.findIndex(el => el?.units > 0) || index
        //     :
        //     discountData[checkedProp]?.findIndex(el => el[key]) || index
        // console.log(key)
        // console.log(discountData[`${checkedProp}`])
        // console.log(discountData[`${checkedProp}`].some(e => e[key] > 0))
        submitError = {
          ...submitError,
          ...validate(
            // { name: `${key}`, value: discountData[`${checkedProp}`][checkedIndex][key] },
            {
              name: `${key}`, value:
                discountData[`${checkedProp}`].some(e => e[key] > 0)
            },
            { required: true }
          ),
        }
      })
    }

    setErrors({ ...submitError });
    // console.log(submitError)
    return submitError;
  }

  const tranformDataToShow = (data) => {

    const packageQuotasList = data?.package?.quotas
    const quotaDiscountsList = data?.quota_discounts


    const defaultPricingList = data?.default_pricing?.prices
    const pricingDiscountsList = data?.price_discounts

    // create QouatsDiscountsList from two lists (orignal qoatus list of package , qoutas discount)
    // to create list of obj which key {visa_type, amount ,units} where unit represent extra units
    // in edit mode check if there is not quotasDiscount List then take the packagesQuotas to Show
    const qoutasDiscountToShow = quotaDiscountsList?.length ?
      packageQuotasList?.filter((item) => item?.visa_type === quotaDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.visa_type)
        ?.map((item) => ({
          ...item,
          visa_type: item?.visa_type,
          units: quotaDiscountsList?.find(e => e.visa_type === item?.visa_type)?.amount
        }))
      :
      packageQuotasList?.map(item => ({
        ...item,
        visa_type: item?.visa_type,
        units: ''
      }))

    // create PriceDiscountList from two lists (orignal prices list of defaultPricing , prices discount)
    // to create list of obj which key {visa_type, currency ,discount_percentage,price_after_discount}
    const priceDiscountToShow = defaultPricingList?.filter((item) => item?.visa_type === pricingDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.visa_type)
      ?.map((item) => ({
        ...item,
        visa_type: item?.visa_type,
        currency: data?.default_pricing?.currency,
        discount_percentage: Number((100 - ((pricingDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.price / item?.price) * 100))),
        price_after_discount: pricingDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.price
      }))

    // create PriceDiscountList from two lists (orignal prices list of defaultPricing , prices discount)
    // filter packagePricing removing item which has no price 
    const packagePricingToShow = [
      {
        name: 'postpaid',
        price: data?.package?.postpaid_price,
        currency: data?.package?.currency,
        postpaid_discount_percentage: Number((100 - ((data?.postpaid_discount / data?.package?.postpaid_price) * 100))),
        postpaid_after_discount: Number(data?.postpaid_discount) || data?.package?.postpaid_price,
      },
      {
        name: 'prepaid',
        price: data?.package?.prepaid_price,
        currency: data?.package?.currency,
        prepaid_discount_percentage: Number((100 - ((data?.prepaid_discount / data?.package?.prepaid_price) * 100))),
        prepaid_after_discount: Number(data?.prepaid_discount) || data?.package?.prepaid_price,
      },
    ].filter(el => el?.price !== null)

    return {
      pricing_type: data?.pricing_type,
      discounted_item_id: data?.pricing_type === 'package' ?
        { value: data?.package?.id, label: data?.package?.title[locale], ...data?.package }
        :
        { value: data?.default_pricing?.id, label: data?.default_pricing?.title[locale], ...data?.default_pricing }
      ,
      countries_id: data?.countries?.map(item => ({
        value: item?.id,
        label: item?.name,
        ...item
      })),
      date_type: "gregorian",
      start_date: data?.start_date,
      visible_end_date: data?.end_date ? 'end-date-determined' : 'end-date-indeterminate',
      end_date: data?.end_date ? data?.end_date : '',
      notification_text_ar: data?.notification_text?.ar,
      notification_text_en: data?.notification_text?.en,

      subscription_type:
        (packagePricingToShow?.some(e => e?.name === 'prepaid') && packagePricingToShow?.some(e => e?.name === 'postpaid'))
          ?
          'all'
          :
          packagePricingToShow?.some(e => e?.name === 'postpaid') ?
            'postpaid'
            :
            'prepaid'

      ,
      offer_type: data?.is_quota_offer && (data?.prepaid_discount || data?.prepaid_discount) ?
        'offers_all'
        :
        data?.is_quota_offer && (!data?.prepaid_discount || !data?.prepaid_discount) ?
          'units'
          :
          'price'
      ,

      discount_type: data?.discount_type,
      postpaid_discount: data?.postpaid_discount,
      prepaid_discount: data?.prepaid_discount,
      quota_discounts: qoutasDiscountToShow,
      price_discounts: priceDiscountToShow,
      package_pricing: packagePricingToShow,
    }

  }

  const handleSubmitPricing = async () => {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    let requestBody = {
      pricing_type: discountData?.pricing_type,
      pricing_package_id: discountData?.pricing_type === 'package' ? discountData?.discounted_item_id?.id : null,
      default_pricing_id: discountData?.pricing_type === 'default_price' ? discountData?.discounted_item_id?.id : null,
      countries_id: discountData?.countries_id?.map(item => item?.value),
      date_type: discountData?.date_type,
      start_date: moment(discountData?.start_date).format('YYYY-MM-DD'),
      end_date: (discountData?.visible_end_date === 'end-date-determined' && discountData?.end_date) ?
        moment(discountData?.end_date).format('YYYY-MM-DD') : null
      ,
      notification_text: {
        ar: discountData?.notification_text_ar,
        en: discountData?.notification_text_en,
      },
      is_price_offer: (discountData?.pricing_type === 'default_price') ? true : false,
      is_quota_offer: (discountData?.offer_type === 'units' || discountData?.offer_type === 'offers_all') ? true : false,
      discount_type: discountData?.discount_type,
      // is_price_offer: 1,
      // is_quota_offer: null,
      postpaid_discount:
        (discountData?.subscription_type === 'postpaid' || discountData?.subscription_type === 'all') ?
          Number(discountData?.package_pricing?.find(el => el.name.toLowerCase() === 'postpaid')?.postpaid_after_discount)
          :
          Number(discountData?.package_pricing?.find(el => el.name.toLowerCase() === 'postpaid')?.price)

      ,
      prepaid_discount:
        (discountData?.subscription_type === 'prepaid' || discountData?.subscription_type === 'all') ?
          Number(discountData?.package_pricing?.find(el => el.name.toLowerCase() === 'prepaid')?.prepaid_after_discount)
          :
          Number(discountData?.package_pricing?.find(el => el.name.toLowerCase() === 'prepaid')?.price)
      ,

      quota_discounts: (discountData?.offer_type === 'units' || discountData?.offer_type === 'offers_all') ?
        discountData?.quota_discounts?.map(item => ({
          visa_type: item?.visa_type?.toLowerCase(),
          amount: Number(item?.units)
        }))
        :
        null
      ,
      price_discounts: discountData?.pricing_type === 'default_price' ?
        discountData?.price_discounts?.map(item => ({
          visa_type: item?.visa_type?.toLowerCase(),
          price: item?.price_after_discount ? Number(item?.price_after_discount) : Number(item?.price)
        }))
        :
        null
    }
    let response;

    if (status === 'add' || status === 'duplicate') {
      response = await addDiscount(requestBody);
    }
    if (status === 'edit' && id) {
      response = await editDiscount(id, requestBody);
    }

    if ((response?.status === 200 || response?.status === 201)) {
      store.addNotification({
        message: safaVisa?.discountAddedSuccessfully,
        title: safaVisa?.success,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push('/safaVisa-discount')
    }
  }

  const fetchDiscountDetails = async () => {
    const response = await getDiscountDetailsById(id)
    if (response?.status === 200) {
      const transformedData = tranformDataToShow(response?.data?.data)
      setDiscountData(transformedData)
    }
  }

  const fetctDefaultPricingLookups = async (params) => {
    const response = await getDefaultPricingLookups(params)
    if (response?.status === 200) {
      const dropDownOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
        ...item
      }))
      setDicountItemsOptions(dropDownOption)
      if (!id && !status) {
        setDiscountData({ ...initialDiscountData, pricing_type: 'default_price' })
      }
    }
  }

  const fetctPackagesLookups = async (params) => {
    const response = await getPackagesLookups(params)
    if (response?.status === 200) {
      const dropDownOption = response?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
        ...item
      }))
      setDicountItemsOptions(dropDownOption)
      if (!id && !status) {
        setDiscountData({ ...initialDiscountData, pricing_type: 'package' })
      }
    }
  }

  // get discount details in edit mode
  useEffect(() => {
    if ((status === 'edit' || status === 'duplicate') && id) {
      fetchDiscountDetails()
    }
  }, [])

  // get package Lookup according to pricing type
  useEffect(() => {
    if (discountData?.pricing_type === 'default_price') { fetctDefaultPricingLookups({ is_active: 1 }) }
    if (discountData?.pricing_type === 'package') { fetctPackagesLookups({ is_active: 1 }) }
  }, [discountData?.pricing_type])

  // change the required Inputs according to some conditions
  useEffect(() => {
    if (discountData?.pricing_type === 'default_price' && discountData?.discount_type === 'fixed') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredPricingInputs: ['price_after_discount'],
      }))
    }
    if (discountData?.pricing_type === 'default_price' && discountData?.discount_type === 'percentage') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredPricingInputs: ['discount_percentage'],
      }))
    }
    if (discountData?.pricing_type === 'package' && discountData?.offer_type === 'units') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredPricingInputs: ['units'],
      }))
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'offers_all' &&
      discountData?.subscription_type === 'prepaid'
    ) {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_discount_percentage', 'units'],
        }))
      }
      if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_after_discount', 'units'],
        }))
      }
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'offers_all' &&
      discountData?.subscription_type === 'postpaid'
    ) {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_discount_percentage', 'units'],
        }))
      }
      if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_after_discount', 'units'],
        }))
      }
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'offers_all' &&
      discountData?.subscription_type === 'all'
    ) {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_discount_percentage', 'postpaid_discount_percentage', 'units'],
        }))
      }
      if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_after_discount', 'postpaid_after_discount', 'units'],
        }))
      }
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'price' &&
      discountData?.subscription_type === 'prepaid') {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_discount_percentage'],
        }))
      } if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['prepaid_after_discount'],
        }))
      }
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'price' &&
      discountData?.subscription_type === 'postpaid') {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_discount_percentage'],
        }))
      } if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_after_discount'],
        }))
      }
    }

    if (discountData?.pricing_type === 'package' &&
      discountData?.offer_type === 'price' &&
      discountData?.subscription_type === 'all') {
      if (discountData?.discount_type === 'percentage') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_discount_percentage', 'prepaid_discount_percentage'],
        }))
      } if (discountData?.discount_type === 'fixed') {
        setAllRequiredInputs((prevStatus) => ({
          ...prevStatus,
          requiredPricingInputs: ['postpaid_after_discount', 'prepaid_after_discount'],
        }))
      }
    }

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      discountData?.subscription_type,
      discountData?.offer_type,
      discountData?.discount_type,
    ]
  )

  console.log(discountData)
  console.log(allRequiredInputs)
  console.log(errors)

  // -------- JSX Code -------
  return (
    <div className='safaVisa-wrapper'>

      <div className='safa-visa-container'>

        {/* Header */}
        <div >
          <h4 className="main-title mb-0">{safaVisa.addDiscount}</h4>
          <hr />
        </div>

        <DiscountDetailsForm
          discountData={discountData}
          setDiscountData={setDiscountData}
          dicountItemsOptions={dicountItemsOptions}
          errors={errors}
          setErrors={setErrors}
          setAllRequiredInputs={setAllRequiredInputs}
        />

        {discountData?.discounted_item_id &&
          <DiscountPricingTypes
            discountData={discountData}
            setDiscountData={setDiscountData}
            errors={errors}
            setErrors={setErrors}
          />
        }

        {/* Actions Button */}
        <div className="col-12 d-flex justify-content-between gap-10 mt-5">

          <button
            className="btn back-btn py-2 "
            onClick={() => history.push("/safaVisa-discount")}
          >
            <span >
              <LeftArrowIcon color='#1e85ff' width='24' height='24' />
            </span>
            {safaVisa.back}
          </button>
          <button
            // disabled={pricingData?.prices?.length === 0}
            // disabled={isDisabledBtn}
            type='button'
            className="btn add-btn py-2"
            onClick={handleSubmitPricing}
          >
            {safaVisa.save}
          </button>
        </div>

      </div>

    </div>
  )
}

export default AddEditDiscount