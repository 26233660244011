import AddIcon from "assets/images/add.png";
import DollarIcon from "assets/images/dollar-circle.png";
import EditIcon from "assets/images/edit-2.png";

import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { listSubscriptionPlan } from "services/companies";
import Locale from "translations";
import { Eye } from "../../package/icons";
import InfoPlan from "./Models/infoPlan";
import usePrintingSubscriptionInvoice from "./usePrintingInvoices";

const groupData = (data) => {
	const map = new Map();

	data.forEach((item) => {
		const key = item.group_uuid || item.id;
		const collection = map.get(key);
		if (!collection) {
			map.set(key, {
				price: item.price,
				currency: item.currency,
				plans: [item],
			});
		} else {
			collection.plans.push(item);
			collection.price += item.price;
		}
	});

	console.log("map", map);
	return map;
};

const flatenGroupedData = (plans) => {
	const flatedned = [];
	for (const plan of plans) {
		flatedned.push(plan.plans[0]);
	}
	return flatedned;
};

export default function SubscriptionPlanList() {
	// ** hooks
	const { printSubscriptionInvoice, printFreeTrialInvoice } =
		usePrintingSubscriptionInvoice();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const history = useHistory();
	const { company_id } = useParams();
	const [planList, setPlanList] = useState([]);
	const [groupedPlans, setGroupedPlans] = useState(new Map());

	const toggleInfoPlan = (group_uuid = null) => {
		setIsOpen((prev) => !prev);
		setSelectedPlan(groupedPlans.get(group_uuid));
	};

	const isPlanHasCommission = (plan) => {
		const _plan = groupedPlans.get(plan.group_uuid || plan.id);

		if (_plan) {
			return _plan.plans.some((p) => p.has_commission);
		}

		return false;
	};

	const statusStyle = (status) => {
		switch (status) {
			case "new":
				return "new";
			case "paid":
				return "paid";
			case "pending":
				return "pending";
			case "canceled":
				return "canceled";
			case "free":
				return "free";
			case "expired":
				return "expired";
			default:
				return null;
		}
	};

	const downloadInvoice = (planId, isFreeTrial) => {
		if (planId) {
			if (isFreeTrial === 1) printFreeTrialInvoice(planId);
			else printSubscriptionInvoice(planId);
		}
	};

	useEffect(() => {
		async function FetchSubscriptionPlanList() {
			const res = await listSubscriptionPlan(company_id);
			const groupedData = groupData(res?.data?.data);
			const flatend = flatenGroupedData(Array.from(groupedData.values()));
			setPlanList(flatend);
			setGroupedPlans(groupedData);
		}
		FetchSubscriptionPlanList();
	}, []);

	const allPlan =
		planList.length > 0 &&
		planList.map((plan) => (
			<tr>
				<td>
					<span className="d-block">{plan?.name}</span>
					<small
						className="d-flex align-items-center text-primary"
						style={{ cursor: "pointer" }}
						role="button"
						onClick={() => toggleInfoPlan(plan.group_uuid || plan.id)}
					>
						<span style={{ fontSize: 8, marginRight: 2 }}>
							<Eye stroke="#007bff" width={18} height={19} />
						</span>
						<span>View More</span>
					</small>
				</td>
				<td>{moment(plan.start_date).format("YYYY-MM-DD")}</td>
				<td>{moment(plan.end_date).format("YYYY-MM-DD")}</td>
				<td>{moment(plan.end_date).diff(plan.start_date, "days")} Days</td>
				<td className="amount-td">{plan.price}</td>
				<td className="amount-td">{plan.currency}</td>
				<td
					className={`${
						plan.status === "active" ? "text-green" : "text-dangr"
					}`}
				>
					{plan.status}
				</td>
				<td>
					<span className={statusStyle(plan.payment_status)}>
						{plan.payment_status}
					</span>
				</td>
				<td>
					<ShowForPermission permission="Tourism-Company-Manage">
						<div className="action">
							{/* <span
								role="button"
								onClick={() => downloadInvoice(plan?.id, plan?.is_free_trial)}
							>
								<img
									src={DownloadIcon}
									alt="download icon"
									width={20}
									height={20}
								/>
							</span> */}
							{plan?.has_commission && plan?.status === "active" && (
								<span
									role="button"
									onClick={() =>
										history.push(
											`/tourism-company/${company_id}/subscription-plan/commission/${plan.id}`
										)
									}
								>
									<img
										src={DollarIcon}
										alt="Dollar icon"
										width={20}
										height={20}
									/>
								</span>
							)}

							{plan.status !== "canceled" && plan.status !== "expired" ? (
								<span
									role="button"
									onClick={() => {
										history.push(
											`/tourism-company/${company_id}/subscription-plan/edit/${plan.id}`
										);
									}}
								>
									<img src={EditIcon} alt="edit icon" width={20} height={20} />
								</span>
							) : null}

							<span
								role="button"
								onClick={() => {
									history.push(
										`/tourism-company/${company_id}/subscription-plan/view/${plan.id}`
									);
								}}
							>
								<Eye />
							</span>
						</div>
					</ShowForPermission>
				</td>
			</tr>
		));
	const { backOffice } = Locale;

	return (
		<div className="companies-subscription">
			<div className="title">
				<h4>{backOffice.SubscriptionPlanList}</h4>
				<ShowForPermission permission="Tourism-Company-Manage">
					<button
						className="btn btn-primary"
						onClick={() =>
							history.push(
								`/tourism-company/${company_id}/subscription-plan/add`
							)
						}
					>
						<img src={AddIcon} alt="add" />
						{backOffice.AddFreeTrial}
					</button>
				</ShowForPermission>
			</div>

			{/* <ShowForPermission permission="Tourism-Company-View"> */}
			<div className="subscription-table">
				<table className="table">
					<thead>
						<tr>
							<th>{backOffice.Name}</th>
							<th>{backOffice.validForm}</th>
							<th>{backOffice.VALIDTO}</th>
							<th>{backOffice.Duration}</th>
							<th>{backOffice.amount}</th>
							<th>{backOffice.currency}</th>
							<th>{backOffice.planStatus}</th>
							<th>{backOffice.PaymentStatus}</th>
							<th width="10%">{backOffice.Action}</th>
						</tr>
					</thead>
					<tbody>{allPlan}</tbody>
				</table>
			</div>
			{/* </ShowForPermission> */}
			<InfoPlan isOpen={isOpen} toggle={toggleInfoPlan} plan={selectedPlan} />
		</div>
	);
}
