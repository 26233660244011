import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import MultiDatePicker from 'components/shared/MultiDatePicker';
import RadioGroup from 'components/shared/RadioGroup';
import SelectField from 'components/shared/SelectField';
import TextField from 'components/shared/TextField';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Context
import { useGlobalState } from 'context/global';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// Moment
// import moment from 'moment';
// import "moment-hijri";
import moment from "moment-hijri";
// ----------------------------------------------------------------------------
export default function PackageDetailsForm(props) {

  const {
    packageData,
    setPackageData,
    setActiveTab,
    activeTab,
    tabs,
    setTabs,
    errors,
    setErrors,
    allRequiredInputs,
    setAllRequiredInputs
  } = props

  // *** Hook
  let history = useHistory();

  const { safaVisa } = Locale;

  // *** Custom Hook
  const {
    formattedCountries,
    formattedCurrencies,
    contractsOptions,
    agentsOptions,
    audienceTypeOptions,
    packagesTypeOptions,
    calendarTypesOprtion,
    endDateVisiabiltyOptions,
    repurchaseOptions,
    requiredPackagePricingInputs,
    maxHijriDate,
  } = useSafaVisaData();

  const allAudienceTypeOptions = [
    ...audienceTypeOptions
    , { label: safaVisa?.directed, value: 'direct' }]

  //*** Context
  const { allCountries, allCurrencies } = useGlobalState();

  // *** State
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  // *** Functions
  const checkFormErrors = () => {
    let submitError = {};
    allRequiredInputs?.requiredDetailsInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    // setErrors((prev) => ({ ...prev, ...submitError }));
    setErrors(submitError);
    return submitError;
  }

  const handleChangeInputs = ({ key, value }) => {
    const packageDataClone = { ...packageData }
    packageDataClone[`${key}`] = value
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    if (key === 'with_contracts' && value === 'contract-determined') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: [...allRequiredInputs?.requiredDetailsInputs, 'contract_count']
      })
    }
    if (key === 'with_contracts' && value === 'contarct-indeterminate') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: allRequiredInputs?.requiredDetailsInputs?.filter(el => el !== 'contract_count')
      })
    }
    if (key === 'with_Agents' && value === 'agents-determined') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: [...allRequiredInputs?.requiredDetailsInputs, 'agents_count']
      })
    }
    if (key === 'with_Agents' && value === 'agents-indeterminate') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: allRequiredInputs?.requiredDetailsInputs?.filter(el => el !== 'agents_count')
      })
    }
    if (key === 'package_type') {
      packageDataClone[`duration_count`] = ''
      setPackageData(packageDataClone)
    }
    if (key === 'duration_count' && packageData?.package_type?.value === 'custom') {
      packageDataClone[`end_date`] = moment(packageData?.start_date).add(Number(value), 'days')?.toDate()
      setPackageData(packageDataClone)
    }
    if (key === 'season_days' && packageData?.package_type?.value === 'seasonal') {
      packageDataClone[`season_end_date`] = moment(packageData?.season_start_date).add(Number(value), 'days')?.toDate()
      setPackageData(packageDataClone)
    }
    else {
      setPackageData(packageDataClone)
    }
  }

  const handleNextButton = () => {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    if (activeTab?.index <= tabs?.length) {
      setActiveTab(tabs[Number(activeTab?.index + 1)])
      tabs[activeTab?.index + 1].isDisabled = true
      setTabs(tabs)
    }
  }

  useEffect(() => {
    setCountries(formattedCountries(allCountries));
    setCurrencies(formattedCurrencies(allCurrencies));
  }, [allCountries, allCurrencies]);

  useEffect(() => {
    // Required inputs if yearly package
    if (packageData?.package_type?.value === 'yearly') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: requiredPackagePricingInputs
      })
      handleChangeInputs({ key: 'date_type', value: 'gregorian' })
    }
    // Required inputs if seasonal package
    if (packageData?.package_type?.value === 'seasonal') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: [...allRequiredInputs?.requiredDetailsInputs, 'season_start_date', 'season_days']
      })
      handleChangeInputs({ key: 'date_type', value: 'hijri' })
    }
    // Required inputs if custom package
    if (packageData?.package_type?.value === 'custom') {
      handleChangeInputs({ key: 'date_type', value: 'gregorian' })
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: [...allRequiredInputs?.requiredDetailsInputs, 'start_date', 'start_date']
      })
    }
  }, [packageData?.package_type]);

  useEffect(() => {
    if (packageData?.visible_share_end_date === 'end-date-determined') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: [...allRequiredInputs?.requiredDetailsInputs, 'share_end_date']
      })
    }
    if (packageData?.visible_share_end_date === 'end-date-indeterminate') {
      setAllRequiredInputs({
        ...allRequiredInputs,
        requiredDetailsInputs: allRequiredInputs?.requiredDetailsInputs.filter(el => el !== 'share_end_date')
      })
    }
  }, [packageData?.visible_share_end_date]);

  // console.log(packageData)
  // console.log(moment(packageData?.share_start_date).format('DD-MM-YYYY'))
  // console.log(maxHijriDate)
  // console.log(moment(packageData?.share_start_date).add(10, "days").format("iYYYY/iMM/iDD"))

  // ------- JSX Code -------
  return (
    <form
      // onSubmit={submit} 
      className="mt-4"
    >

      {/* Package Pricing Form  */}
      <div className='row mb-4'>

        {/* Package Name ar */}
        <div className="col-4 ">
          <TextField
            type="text"
            label={safaVisa?.titleAr}
            placeholder={safaVisa?.titleInAr}
            name="title_ar"
            id="title_ar"
            value={packageData?.title_ar}
            onChange={(e) => handleChangeInputs({ key: 'title_ar', value: e?.target?.value })}
            color={errors?.title_ar?.required ? "danger" : ""}
            errors={errors?.title_ar}
          />
        </div>

        {/* Package Name en */}
        <div className="col-4  ">
          <TextField
            type="text"
            label={safaVisa?.titleEn}
            placeholder={safaVisa?.titleInEn}
            name="title_en"
            id="title_en"
            value={packageData?.title_en}
            onChange={(e) => handleChangeInputs({ key: 'title_en', value: e?.target?.value })}
            color={errors?.title_en?.required ? "danger" : ""}
            errors={errors?.title_en}
          />
        </div>

        <div className="col-4" />

        {/* Package Countries  */}
        <div className={`col-4 `}>
          <SelectField
            multi
            id="countries_id"
            name="countries_id"
            label={safaVisa?.country}
            placeholder={safaVisa?.select}
            options={countries}
            value={packageData?.countries_id}
            onChange={(values, { action }) => {
              if (action === "remove-value" && !values) {
                handleChangeInputs({ key: 'countries_id', value: [] })
                return;
              }
              handleChangeInputs({ key: 'countries_id', value: values })
              setErrors({
                ...errors,
                ...validate(
                  { name: "countries_id", value: values.length },
                  { required: true }
                ),
              });
            }}
            color={errors?.countries_id?.required ? "danger" : ""}
            errors={errors?.countries_id}
          />
        </div>

        {/* Package Currency  */}
        <div className={`col-4  `}>
          <SelectField
            hasLabel={true}
            label={safaVisa?.currency}
            placeholder={safaVisa?.select}
            id="currency"
            name="currency"
            value={packageData?.currency}
            options={currencies}
            onChange={(e) => handleChangeInputs({ key: 'currency', value: e })}
            color={errors?.currency?.required ? "danger" : ""}
            errors={errors?.currency}
          />
        </div>

        {/* Package Type  */}
        <div className={`col-4  `}>
          <SelectField
            hasLabel={true}
            label={safaVisa?.packageType}
            placeholder={safaVisa?.select}
            id="package_type"
            name="package_type"
            value={packageData?.package_type}
            options={packagesTypeOptions}
            onChange={(e) => handleChangeInputs({ key: 'package_type', value: e })}
            color={errors?.package_type?.required ? "danger" : ""}
            errors={errors?.package_type}
          />
        </div>

        {/* Repurchase Type Validation   */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.repurchasePackage}
            name='repurchase'
            groupOptions={repurchaseOptions}
            onChange={(e) => handleChangeInputs({ key: 'repurchase', value: e?.target?.value })}
            value={packageData?.repurchase ||
              (packageData?.repurchase === true ? 'repurchase-available' : 'repurchase-notAvailable')
            }
            color={errors?.repurchase?.required ? "danger" : ""}
            errors={errors?.repurchase}
          />
        </div>

        {/* Package Audience Type  */}
        <div className={`col-4`}>
          <SelectField
            hasLabel={true}
            label={safaVisa?.audienceType}
            placeholder={safaVisa?.select}
            id='audience_type'
            name='audience_type'
            value={packageData?.audience_type}
            options={allAudienceTypeOptions}
            onChange={(e) => handleChangeInputs({ key: 'audience_type', value: e })}
            color={errors?.audience_type?.required ? "danger" : ""}
            errors={errors?.audience_type}
          />
        </div>

        {/* Date Type  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.calendarType}
            name='date_type'
            groupOptions={calendarTypesOprtion}
            onChange={(e) => handleChangeInputs({ key: 'date_type', value: e?.target?.value })}
            value={packageData?.date_type}
          />
        </div>


        {/* Yearly Package Inputs (Years Number)  */}
        {
          packageData?.package_type?.value === 'yearly' &&
          <>
            < div className="col-4">
              <TextField
                type="number"
                label={safaVisa?.noOfYears}
                placeholder={safaVisa?.noOfYears}
                name="duration_count"
                id="duration_count"
                min={0}
                value={packageData?.duration_count}
                onChange={(e) => handleChangeInputs({ key: 'duration_count', value: e?.target?.value })}
                color={errors?.duration_count?.required ? "danger" : ""}
                errors={errors?.duration_count}
              />
            </ div >
            < div className="col-8" />

          </>
        }


        {/* Seasons Package Dates Inputs (Years Number)  */}
        {
          packageData?.package_type?.value === 'seasonal' &&
          <>
            {/*Seasonal Start Date */}
            <div className="col-4" >
              <MultiDatePicker
                label={safaVisa.seasonalStartDate}
                hasLabel={true}
                placeholder={safaVisa.fromDate}
                type={packageData?.date_type}
                value={
                  packageData?.date_type === 'gregorian' ?
                    packageData?.season_start_date
                    :
                    moment(packageData?.season_start_date).format("iYYYY/iMM/iDD")
                }
                onChangeDate={(e) => handleChangeInputs({ key: 'season_start_date', value: e?.toDate() })}
                format={'YYYY/MM/DD'}
                minDate={packageData?.date_type === 'gregorian' ?
                  moment().format('YYYY/MM/DD')
                  :
                  moment().format("iYYYY/iMM/iDD")
                }
                maxDate={packageData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
                color={errors?.season_start_date?.required ? "danger" : ""}
                errors={errors?.season_start_date}
              />
            </div>

            {/*Seasonal Days  */}
            <div className="col-4  ">
              <TextField
                type="number"
                label={safaVisa?.seasonalDays}
                placeholder={safaVisa?.seasonalDays}
                name="season_days"
                id="season_days"
                min={0}
                value={packageData?.season_days}
                onChange={(e) => handleChangeInputs({ key: 'season_days', value: e?.target?.value })}
                color={errors?.season_days?.required ? "danger" : ""}
                errors={errors?.season_days}
              />
            </div>

            {/* Seasonal End Date   */}
            <div className="col-4" >
              <MultiDatePicker
                label={safaVisa.seasonalEndDate}
                hasLabel={true}
                placeholder={safaVisa.seasonalEndDate}
                type={packageData?.date_type}
                // value={packageData?.season_end_date}
                value={
                  packageData?.date_type === 'gregorian' ?
                    packageData?.season_end_date
                    :
                    moment(packageData?.season_end_date).format("iYYYY/iMM/iDD")
                }
                onChangeDate={(e) => handleChangeInputs({ key: 'season_end_date', value: e?.toDate() })}
                format={'YYYY/MM/DD'}
                disabled={true}
                // minDate={packageData?.season_start_date}
                maxDate={packageData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
                color={errors?.season_end_date?.required ? "danger" : ""}
                errors={errors?.season_end_date}
              />
            </div>

            {/*  No Of Seasons  */}
            <div className="col-4" >
              <TextField
                type="number"
                label={safaVisa?.noOfSeasons}
                placeholder={safaVisa?.noOfSeasons}
                name="duration_count"
                id="duration_count"
                min={0}
                value={packageData?.duration_count}
                onChange={(e) => handleChangeInputs({ key: 'duration_count', value: e?.target?.value })}
                color={errors?.duration_count?.required ? "danger" : ""}
                errors={errors?.duration_count}
              />
            </div>
            < div className="col-8" />
          </>
        }


        {/*Custom Package Dates Inputs */}
        {
          packageData?.package_type?.value === 'custom' &&
          <>
            {/* Custom Package Start Date   */}
            <div className="col-4">
              <MultiDatePicker
                label={safaVisa.startDate}
                hasLabel={true}
                placeholder={safaVisa.startDate}
                type={packageData?.date_type}
                // value={packageData?.start_date}
                value={
                  packageData?.date_type === 'gregorian' ?
                    packageData?.start_date
                    :
                    moment(packageData?.start_date).format("iYYYY/iMM/iDD")
                }
                onChangeDate={(e) => handleChangeInputs({ key: 'start_date', value: e?.toDate() })}
                minDate={packageData?.date_type === 'gregorian' ?
                  moment().format('YYYY/MM/DD')
                  :
                  moment().format("iYYYY/iMM/iDD")
                }
                maxDate={packageData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
                format={'YYYY/MM/DD'}
                color={errors?.start_date?.required ? "danger" : ""}
                errors={errors?.start_date}
              />
            </div>

            {/* Custom Package Duration   */}
            <div className="col-4">
              <TextField
                type="number"
                label={safaVisa?.packageDays}
                placeholder={safaVisa?.packageDays}
                name="duration_count"
                id="duration_count"
                value={packageData?.duration_count}
                min={0}
                onChange={(e) => {
                  // console.log(e?.target?.value)
                  handleChangeInputs({ key: 'duration_count', value: e?.target?.value })
                  // handleChangeInputs({
                  //   key: 'end_date',
                  //   value: moment(packageData?.start_date).add(Number(e?.target?.value), 'days')?.toDate()
                  // })
                }}
                color={errors?.duration_count?.required ? "danger" : ""}
                errors={errors?.duration_count}
              />
            </div>

            {/* Custom Package End Date   */}
            <div className="col-4">
              <MultiDatePicker
                label={safaVisa.endDate}
                hasLabel={true}
                placeholder={safaVisa.endDate}
                type={packageData?.date_type}
                // value={packageData?.end_date}
                value={
                  packageData?.date_type === 'gregorian' ?
                    packageData?.end_date
                    :
                    moment(packageData?.end_date).format("iYYYY/iMM/iDD")
                }
                onChangeDate={(e) => handleChangeInputs({ key: 'end_date', value: e?.toDate() })}
                disabled={true}
                format={'YYYY/MM/DD'}
                color={errors?.end_date?.required ? "danger" : ""}
                errors={errors?.end_date}
              />
            </div>
          </>
        }


        {/* Package Share Start Date   */}
        <div className="col-4">
          <MultiDatePicker
            label={safaVisa.packageAppearanceDate}
            hasLabel={true}
            placeholder={safaVisa.packageAppearanceDate}
            type={packageData?.date_type}
            // value={packageData?.share_start_date}
            value={
              packageData?.date_type === 'gregorian' ?
                packageData?.share_start_date
                :
                moment(packageData?.share_start_date).format("iYYYY/iMM/iDD")
            }
            onChangeDate={(e) => handleChangeInputs({ key: 'share_start_date', value: e?.toDate() })}
            minDate={packageData?.date_type === 'gregorian' ?
              moment().format('YYYY/MM/DD')
              :
              moment().format("iYYYY/iMM/iDD")
            }
            maxDate={
              (packageData?.date_type === 'gregorian' && packageData?.visible_share_end_date === 'end-date-determined') ?
                moment(packageData?.share_end_date).format('YYYY/MM/DD')
                :
                (packageData?.date_type === 'hijri' && packageData?.visible_share_end_date === 'end-date-determined') ?
                  moment(packageData?.share_end_date).format("iYYYY/iMM/iDD")
                  :
                  (packageData?.date_type === 'gregorian' && packageData?.visible_share_end_date === 'end-date-indeterminate') ?
                    "2100/12/31"
                    :
                    maxHijriDate
            }
            format={'YYYY/MM/DD'}
            color={errors?.share_start_date?.required ? "danger" : ""}
            errors={errors?.share_start_date}
          />
        </div>

        {/* Package Share End-date Visiablity  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.packageDisappearanceDate}
            name='visible_share_end_date'
            groupOptions={endDateVisiabiltyOptions}
            onChange={(e) => handleChangeInputs({ key: 'visible_share_end_date', value: e?.target?.value })}
            value={packageData?.visible_share_end_date || packageData?.share_start_date}
            color={errors?.repurchase?.required ? "danger" : ""}
            errors={errors?.repurchase}
          />
        </div>

        {/* Package Share End Date   */}
        <div className="col-4" >
          <MultiDatePicker
            label={safaVisa.packageExpirationDate}
            hasLabel={true}
            placeholder={safaVisa.packageExpirationDate}
            type={packageData?.date_type}
            // value={packageData?.share_end_date}
            value={
              packageData?.date_type === 'gregorian' ?
                packageData?.share_end_date
                :
                moment(packageData?.share_end_date).format("iYYYY/iMM/iDD")
            }
            onChangeDate={(e) => handleChangeInputs({ key: 'share_end_date', value: e?.toDate() })}
            format={'YYYY/MM/DD'}
            minDate={
              packageData?.date_type === 'gregorian' ?
                moment(packageData?.share_start_date).add(1, 'days').format('YYYY/MM/DD')
                :
                moment(packageData?.share_start_date).add(1, "days").format("iYYYY/iMM/iDD")
            }
            maxDate={packageData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
            disabled={packageData?.visible_share_end_date === 'end-date-indeterminate'}
            color={errors?.share_end_date?.required ? "danger" : ""}
            errors={errors?.share_end_date}
          />
        </div>

        {/* Contracts Validation   */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.contractsValidation}
            name='contarct'
            groupOptions={contractsOptions}
            onChange={(e) => handleChangeInputs({ key: 'with_contracts', value: e?.target?.value })}
            value={packageData?.with_contracts ||
              (+packageData?.contract_count > 0 ? 'contract-determined' : 'contarct-indeterminate')
            }
          />
        </div>

        {/* No Of   Contracts   */}
        <div className="col-4 ">
          <TextField
            disabled={packageData?.with_contracts === 'contarct-indeterminate'}
            type="number"
            label={safaVisa?.noOfContracts}
            placeholder={'2'}
            name="contract_count"
            id="contract_count"
            min={0}
            value={packageData?.contract_count}
            onChange={(e) => handleChangeInputs({ key: 'contract_count', value: e?.target?.value })}
            color={errors?.contract_count?.required ? "danger" : ""}
            errors={errors?.contract_count}
          />
        </div>

        <div className="col-4" />

        {/* Agents Validation  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.agentsValidation}
            name='agents'
            groupOptions={agentsOptions}
            onChange={(e) => handleChangeInputs({ key: 'with_Agents', value: e?.target?.value })}
            value={
              packageData?.with_Agents ||
              (+packageData?.agents_count > 0 ? 'agents-determined' : 'agents-indeterminate')
            }
          />
        </div>

        {/*  Number Of Agents  */}
        <div className="col-4 ">
          <TextField
            disabled={packageData?.with_Agents === 'agents-indeterminate'}
            type="number"
            label={safaVisa?.noOfAgents}
            placeholder={'2'}
            name="agents_count"
            id="agents_count"
            value={packageData?.agents_count}
            onChange={(e) => handleChangeInputs({ key: 'agents_count', value: e?.target?.value })}
            color={errors?.agents_count?.required ? "danger" : ""}
            errors={errors?.agents_count}
          />
        </div>

        <div className="col-4" />

        {/* Package Description Ar */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.descriptionAr}
            placeholder={safaVisa?.descriptionInAr}
            value={packageData?.description_ar}
            onChange={(e) => handleChangeInputs({ key: 'description_ar', value: e?.target?.value })}
            color={errors?.description_ar?.required ? "danger" : ""}
            errors={errors?.description_ar}
          />

        </div>

        {/* Package Description En */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.descriptionEn}
            placeholder={safaVisa?.descriptionInEn}
            value={packageData?.description_en}
            onChange={(e) => handleChangeInputs({ key: 'description_en', value: e?.target?.value })}
            color={errors?.description_en?.required ? "danger" : ""}
            errors={errors?.description_en}
          />

        </div>

      </div>

      {/* Actions Button */}
      <div className="col-12 d-flex justify-content-between gap-10 mt-3">

        <button
          className="btn back-btn py-2 "
          onClick={() => history.push("/safaVisa-packages")}
        >
          <span >
            <LeftArrowIcon color='#1e85ff' width='24' height='24' />
          </span>
          {safaVisa.back}
        </button>

        <button type='button' className="btn add-btn py-2" onClick={handleNextButton}>
          {safaVisa.next}
        </button>

      </div>

    </form >
  )
}

