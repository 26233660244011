

import { useEffect } from 'react';
// Translation
import Locale from 'translations';
//React-Router-Dom
import { useParams } from 'react-router-dom';
// Components
import TextField from 'components/shared/TextField';
// Helper
import validate from 'helpers/validate';
// -------------------------------------------------------------------------------------
export default function DefaultPricingVisasList(props) {

  const {
    discountData,
    setDiscountData,
    errors,
    setErrors,
  } = props

  // *** Hooks
  const { safaVisa } = Locale;

  let { id } = useParams();

  const handleChangeInputs = ({ key, value }, index) => {
    const dataClone = { ...discountData }
    const price_discounts = dataClone['price_discounts']
    if (key === 'discount_percentage' && value <= 100) {
      price_discounts[index][key] = value
      price_discounts[index]['price_after_discount'] = (price_discounts[index]['price'] - (value / 100) * price_discounts[index]['price']).toFixed(2)
      setDiscountData(dataClone)
    }
    if (key === 'price_after_discount' && value <= price_discounts[index]['price']) {
      price_discounts[index]['price_after_discount'] = value
      setDiscountData(dataClone)
    }
    setErrors({
      ...errors,
      ...validate(
        { name: `${key}`, value: value },
        { required: true }
      ),
    });
  }

  // to make sure if change discountType the Price After Discount Input will be ('')
  useEffect(() => {
    if (!id) {
      setDiscountData({
        ...discountData,
        price_discounts: discountData?.price_discounts?.map((item) => ({
          visa_type: item?.visa_type,
          price: item?.price,
          currency: item?.currency,
          discount_percentage: '',
          price_after_discount: '',
        }))
      })
    }
  }, [discountData?.discount_type])
  // ---------- JSX Code ---------
  return (
    <div className="our-border table-responsive  mt-4">
      <table className="custom-table back-office-table table table-striped mb-0">

        <thead>
          <tr>
            <th>{safaVisa.id}</th>
            <th> {safaVisa.visaType}</th>
            <th>{safaVisa.currency}</th>
            <th>{safaVisa.visaPriceBeforeDiscount}</th>
            {discountData?.discount_type === 'percentage' &&
              <th>{safaVisa.discountPercentage}</th>
            }
            <th>{safaVisa.visaPriceAfterDiscount}</th>
          </tr>
        </thead>

        <tbody>
          {
            discountData?.price_discounts?.map((row, index) =>
              <tr>
                <td><p className='id-text'> {index + 1} </p></td>

                <td> {safaVisa[row?.visa_type]}  </td>

                <td>{row?.currency}</td>

                <td> {row?.price} </td>

                {discountData?.discount_type === 'percentage' &&
                  <td >
                    <div className='d-flex align-items-center'>
                      <div className='col-12'>
                        <TextField
                          type="number"
                          label={safaVisa.percentage}
                          hasLabel={false}
                          placeholder='10 %'
                          name="discount_percentage"
                          id="discount_percentage"
                          value={row?.discount_percentage}
                          max={"100"}
                          min={0}
                          onChange={(e) => handleChangeInputs({ key: 'discount_percentage', value: e?.target?.value }, index)}
                          color={errors?.[`discount_percentage`]?.required ? "danger" : ""}
                          errors={errors?.[`discount_percentage`]}
                        />
                      </div>
                      %
                    </div>
                  </td>
                }

                <td >
                  {discountData?.discount_type === 'fixed'
                    ?
                    <TextField
                      type="number"
                      label={safaVisa.priceAfterDiscount}
                      hasLabel={false}
                      name="price_after_discount"
                      id="price_after_discount"
                      value={row?.price_after_discount}
                      max={row?.price}
                      min={0}
                      onChange={(e) => handleChangeInputs({ key: 'price_after_discount', value: e?.target?.value }, index)}
                      color={errors?.[`price_after_discount`]?.required ? "danger" : ""}
                      errors={errors?.[`price_after_discount`]}
                    />
                    :
                    <p className='text-center'>
                      {row?.price_after_discount}
                    </p>
                  }
                </td>
              </tr>
            )
          }
        </tbody>

      </table>
    </div >

  )
}

