import TextField from "components/Form/TextField/TextField";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import {
	getGroupPermission,
	getPermissions,
	storeGroup,
	updateGroup,
} from "services/managementTeam";
import Locale from "translations";
import ListPermissions from "./ListPermissions";

export default function AddGroup() {
	const { backOffice, teamManagement } = Locale;
	let history = useHistory();
	const [permissions, setPermissions] = useState();
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const { type, id } = useParams();

	const [groupName, setGroupName] = useState();

	/* we should call api to get all permissions groups  */
	useEffect(async () => {
		const res = await getPermissions();
		setPermissions(res?.data);
		if (type === "edit") {
			const res = await getGroupPermission(id);
			setSelectedPermissions(res?.data?.permissions.map((per) => per.id));
			setGroupName(res?.data?.display_name);
		}
	}, []);

	/* add and edit group */
	const ActionOnGroup = async () => {
		let res;
		if (type === "add") {
			res = await storeGroup({
				permissions: selectedPermissions,
				display_name: groupName,
				name: groupName,
			});
		} else {
			res = await updateGroup(
				{
					permissions: selectedPermissions,
					display_name: groupName,
				},
				id
			);
		}

		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			history.push(`/management-team/groups`);
		}
	};

	return (
		<div>
			<p className="h5 border-bottom pb-3 mb-2 font-weight-bold w-90">
				{backOffice.addGroup}
			</p>
			<div className="w-50 mt-4">
				<TextField
					type="text"
					label={backOffice.groupName}
					value={groupName}
					placeholder={backOffice.groupNamePlaceholder}
					onChange={(e) => {
						setGroupName(e.target.value);
					}}
				/>
			</div>
			<p className="h5 border-bottom pb-3 font-weight-bold mt-4 w-90">
				{backOffice.permissions}
			</p>
			<ListPermissions
				permissions={permissions}
				selectedPermissions={selectedPermissions}
				setSelectedPermissions={setSelectedPermissions}
			/>

			<div className="d-flex justify-content-end mt-5">
				<button
					className="btn-sbs-secondary p-2 px-4 text-white"
					onClick={() => {
						history.push("/management-team/groups");
					}}
				>
					{teamManagement.cancel}
				</button>
				<button
					className="btn-sbs-primary p-2 px-4 text-white mx-1"
					onClick={ActionOnGroup}
				>
					{type === "add" ? backOffice.addGroup : backOffice.editGroup}
				</button>
			</div>
		</div>
	);
}
