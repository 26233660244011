import dumyImg from "assets/images/default-img.jpg";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import moment from "moment";
import { useState } from "react";
import { isInclusivelyBeforeDay } from "react-dates";
import { useParams } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
export default function ItemHotel(props) {
	const {
		item,
		index,
		setPopupAds,
		popupAds,
		setErrors,
		errors,
		handleItems,
		addOne,
		deleteOne,
		handleImageItem,
		deleteImageItem,
		AdsLink,
		AdSelected,
		setAdSelected,
	} = props;
	console.log("itemitem", item);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const backOfficeApi = process.env.REACT_APP_API_URL + "/api/files/fetch/";
	const { status } = useParams();
	const { backOffice } = Locale;
	console.log("AdSelected", AdSelected);
	return (
		<div className="item-destination ">
			<div className="py-2 mx-3">
				<div
					className={`row  m-0  align-items-center collapse-bg ${
						errors[`dateFrom${index}`]?.required ||
						errors[`dateTo${index}`]?.required ||
						errors[`destination${index}`]?.required ||
						errors[`hotelName${index}`]?.required ||
						errors[`priceStartFrom${index}`]?.required ||
						errors[`adsLink${index}`]?.required ||
						errors[`image${index}`]?.required
							? "border border-danger"
							: ""
					}`}
				>
					<button className="collapse-btn  px-3 py-2" onClick={() => toggle()}>
						{isOpen ? (
							<i class="fas fa-minus  text-white"></i>
						) : (
							<i class="fas fa-plus text-white"></i>
						)}
					</button>

					<div
						className="d-flex justify-content-between"
						style={{ width: "96%" }}
					>
						<p className="col-3 gName-f  font-weight-bold  m-0">
							{item.hotelName ? item.hotelName : "-"}
						</p>
						{index != 0 ? (
							<a
								class=" text-danger d-flex justify-content-end align-items-center  px-4"
								onClick={() => deleteOne(index)}
							>
								<i class="fa fa-trash mx-2"></i>
								{backOffice.Delete}
							</a>
						) : null}
					</div>
				</div>
				<Collapse isOpen={isOpen}>
					{/**backOfficeApi + item.image */}
					<div
						className="row pb-3 px-5 our-border py-0 bg-white"
						style={{ marginLeft: "44px" }}
					>
						<div className="col-md-4">
							<figure
								className={`w-75 mb-0 mt-2 ${
									errors[`image${index}`]?.required
										? "border border-danger"
										: ""
								}
							 `}
							>
								<img
									src={
										item?.displayImg
											? item?.displayImg
											: item?.image
											? backOfficeApi + item?.image
											: dumyImg
									}
									//src={item?.image ? backOfficeApi + item?.image : dumyImg}
									alt=""
									className="w-100"
									width="330"
									height="170"
								/>
							</figure>
							{!item.image && !item.displayImg ? (
								<div className=" shape-border mt-1 w-75 border-0">
									<button className="file-btn text-success w-100 mt-2">
										<i class="fas fa-cloud-upload-alt"></i>{" "}
										{backOffice.UpdateImage}
										<input
											type="file"
											className="custom-file"
											accept="image/png, image/gif, image/jpeg"
											onChange={(e) => {
												//handleItems(e.target.files[0], index, "image");
												handleItems(
													URL.createObjectURL(e.target.files[0]),
													index,
													"displayImg"
												);

												handleImageItem(e.target.files[0], index, "image");
											}}
										/>
									</button>
								</div>
							) : (
								<button
									className=" text-danger delete-file-buton mt-1 w-75 mt-2"
									onClick={() => deleteImageItem(index, "image")}
								>
									<i class="fas fa-trash"></i> {backOffice.DeleteImage}
								</button>
							)}
						</div>
						<div className="col-md-8">
							<div className="row">
								<div className="col-12">
									{/* <TextField
										type="text"
										label={"Ad Link"}
										placeholder={"Enter Ad Link"}
										value={item.adsLink}
										name={`adsLink${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											handleItems(e.target.value, index, "adsLink");
										}}
										color={errors[`adsLink${index}`]?.required ? "danger" : ""}
										errors={errors[`adsLink${index}`]}
									/> */}
									<SelectField
										label={backOffice.AdLink}
										placeholder={backOffice.AdLink}
										value={item.adsLink}
										name={`adsLink${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e, {
													required: true,
												}),
											});
											debugger;
											handleItems(e, index, "adsLink");

											setAdSelected(e);
										}}
										options={AdsLink}
										color={errors[`adsLink${index}`]?.required ? "danger" : ""}
										errors={errors[`adsLink${index}`]}
									/>
								</div>

								<div className="col-6">
									<TextField
										type="text"
										label={backOffice.hotelName}
										placeholder={backOffice.hotelName}
										value={item.hotelName}
										name={`hotelName${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											handleItems(e.target.value, index, "hotelName");
										}}
										color={
											errors[`hotelName${index}`]?.required ? "danger" : ""
										}
										errors={errors[`hotelName${index}`]}
									/>
								</div>
								<div className="col-6">
									<TextField
										type="text"
										label={backOffice.HotelNameArabic}
										placeholder={backOffice.HotelNameArabic}
										value={item.hotel_name_ar}
										name={`hotel_name_ar${index}`}
										onChange={(e) => {
											// setErrors({
											// 	...errors,
											// 	...validate(e.target, {
											// 		required: true,
											// 	}),
											// });
											handleItems(e.target.value, index, "hotel_name_ar");
										}}
										// color={
										// 	errors[`hotel_name_ar${index}`]?.required ? "danger" : ""
										// }
										// errors={errors[`hotel_name_ar${index}`]}
									/>
								</div>
								<div className="col-6 ads-date-field">
									<DatePickerField
										hasLabel={true}
										disabled={true}
										// date={
										// 	status == "add"
										// 		? item.dateFrom
										// 		: item.dateFrom
										// 		? moment(item.dateFrom)
										// 		: null
										// }

										date={
											item?.dateFrom && item.adsLink
												? moment(item?.dateFrom)
												: ""
										}
										placeholder={backOffice.datefrom}
										label={backOffice.datefrom}
										name="dateFrom"
										onChangeDate={(e) => {
											setErrors({
												...errors,
												...validate(
													{ name: "dateFrom", value: e },
													{ required: true }
												),
											});

											handleItems(e, index, "dateFrom");
										}}
										isOutsideRange={(day) =>
											isInclusivelyBeforeDay(day, moment())
										}
										color={errors[`dateFrom${index}`]?.required ? "danger" : ""}
										errors={errors[`dateFrom${index}`]}
									/>
								</div>
								<div className="col-6 ads-date-field">
									<DatePickerField
										hasLabel={true}
										disabled={true}
										// date={
										// 	status == "add"
										// 		? item.dateTo
										// 		: item.dateTo
										// 		? moment(item.dateTo)
										// 		: null
										// }
										date={
											item.dateTo && item.adsLink ? moment(item.dateTo) : ""
										}
										placeholder={backOffice.dateTo}
										label={backOffice.dateTo}
										name="dateTo"
										onChangeDate={(e) => {
											setErrors({
												...errors,
												...validate(
													{ name: "dateTo", value: e },
													{ required: true }
												),
											});
											handleItems(e, index, "dateTo");
										}}
										isOutsideRange={(day) =>
											isInclusivelyBeforeDay(day, moment())
										}
										color={errors[`dateTo${index}`]?.required ? "danger" : ""}
										errors={errors[`dateTo${index}`]}
									/>
								</div>
								<div className="col-6">
									<TextField
										type="text"
										label={backOffice.destination}
										disabled={true}
										placeholder={backOffice.destination}
										value={item.adsLink ? item?.destination : ""}
										name={`destination${index}`}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											handleItems(e.target.value, index, "destination");
										}}
										color={
											errors[`destination${index}`]?.required ? "danger" : ""
										}
										errors={errors[`destination${index}`]}
									/>
								</div>
								<div className="col-6 price_start_ads_field">
									<TextField
										type="text"
										label={backOffice.PriceStartFrom}
										placeholder={backOffice.PriceStartFrom}
										value={item.priceStartFrom}
										name={`priceStartFrom${index}`}
										extraTextPosition="append"
										extraText={"SAR / Night"}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													floatNumber: true,
												}),
											});
											handleItems(e.target.value, index, "priceStartFrom");
										}}
										color={
											errors[`priceStartFrom${index}`]?.required ||
											errors[`priceStartFrom${index}`]?.floatNumber
												? "danger"
												: ""
										}
										errors={errors[`priceStartFrom${index}`]}
									/>
								</div>

								<div className="col-6">
									<SelectField
										label={backOffice.rate}
										placeholder={backOffice.rate}
										value={
											typeof item.rating == "object"
												? item.rating
												: { value: item.rating, label: item.rating }
										}
										name={`rate${index}`}
										options={[
											{ value: 1, label: 1 },
											{ value: 2, label: 2 },
											{ value: 3, label: 3 },
											{ value: 4, label: 4 },
											{ value: 5, label: 5 },
										]}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e, {
													required: true,
												}),
											});
											handleItems(e, index, "rating");
										}}
										color={errors[`rating${index}`]?.required ? "danger" : ""}
										errors={errors[`rating${index}`]}
									/>
								</div>
								<div className="col-6 mt-5">
									<input
										type="Checkbox"
										name="special_offer"
										id="special_offer"
										checked={item.special_offer}
										onChange={(e) =>
											handleItems(e.target.checked, index, "special_offer")
										}
									/>
									<label
										htmlFor="hasSpacialOffer"
										className="text-muted text-14 my-0 mx-1 pointer user-select-none"
									>
										{backOffice.SpacialOffer}
									</label>
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		</div>
	);
}
