import arFLag from "assets/images/ar-flag.svg";
import enFLag from "assets/images/en-flag.svg";
import { useGlobalDispatch, useGlobalState } from "context/global";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
export default function LanguageSwitcher() {
	const { locale } = useGlobalState();
	Locale.setLanguage(locale);
	const dispatch = useGlobalDispatch();

	return (
		<UncontrolledDropdown className="lang-dropdown">
			<DropdownToggle caret className="btn-light">
				<img
					className="img-lang px-2"
					src={locale === "ar" ? arFLag : enFLag}
					width="30"
					alt="lang"
				/>
				{Locale[locale]}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						if (locale === "ar") {
							dispatch({ type: "setLocale", payload: "en" });
							dispatch({ type: "setLoading", payload: true });
						}
					}}
				>
					<img className="img-lang" src={enFLag} width="15" alt="lang" />
					<span className={`${locale === "en" ? "ms-2" : "me-2"}`}>
						English
					</span>
				</DropdownItem>
				<DropdownItem
					onClick={() => {
						if (locale === "en") {
							dispatch({ type: "setLocale", payload: "ar" });
							dispatch({ type: "setLoading", payload: true });
						}
					}}
				>
					<img className="img-lang" src={arFLag} width="15" alt="lang" />
					<span className={`${locale == "en" ? "ms-2" : "me-2"}`}>العربية</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
