import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import RadioGroup from 'components/shared/RadioGroup';
import SelectField from 'components/shared/SelectField';
import TextField from 'components/shared/TextField';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Context
import { useGlobalState } from 'context/global';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// -------------------------------------------------------------------
export default function DefaultPricingForm(props) {

  const {
    pricingData,
    setPricingData,
    setActiveTab,
    activeTab,
    allRequiredInputs,
    setAllRequiredInputs,
    tabs,
    setTabs,
    errors,
    setErrors
  } = props

  // *** Hook
  let history = useHistory();

  const { safaVisa } = Locale;

  // *** Custom Hook
  const {
    formattedCountries,
    formattedCurrencies,
    contractsOptions,
    agentsOptions,
    audienceTypeOptions,
  } = useSafaVisaData();

  //*** Context
  const { allCountries, allCurrencies } = useGlobalState();

  // *** State
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  // *** Functions
  const checkFormErrors = () => {
    let submitError = {};
    allRequiredInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: pricingData[key] },
          { required: true }
        )
      }
    })
    // setErrors((prev) => ({ ...prev, ...submitError }));
    setErrors(submitError);
    return submitError;
  }

  const handleChangeInputs = ({ key, value }) => {
    const pricingDataClone = { ...pricingData }
    pricingDataClone[`${key}`] = value
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setPricingData(pricingDataClone)

  }

  const handleNextButton = () => {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    if (activeTab?.index <= tabs?.length) {
      setActiveTab(tabs[Number(activeTab?.index + 1)])
      tabs[activeTab?.index + 1].isDisabled = true
      setTabs(tabs)
    }
  }

  useEffect(() => {
    setCountries(formattedCountries(allCountries));
    setCurrencies(formattedCurrencies(allCurrencies));
  }, [allCountries, allCurrencies]);

  useEffect(() => {
    if (pricingData?.with_contracts === 'contract-determined') {
      setAllRequiredInputs((prevStatus) => [...prevStatus, 'contract_count'])
    }
    if (pricingData?.with_contracts === 'contarct-indeterminate') {
      setAllRequiredInputs((prevStatus) => prevStatus?.filter(el => el !== 'contract_count'))
    }
  }, [pricingData?.with_contracts]);

  useEffect(() => {
    if (pricingData?.with_Agents === 'agents-determined') {
      setAllRequiredInputs((prevStatus) => [...prevStatus, 'agents_count'])
    }
    if (pricingData?.with_Agents === 'agents-indeterminate') {
      setAllRequiredInputs((prevStatus) => prevStatus?.filter(el => el !== 'agents_count'))
    }
  }, [pricingData?.with_Agents]);

  // ---------- JSX Code -----------
  return (
    <form
      // onSubmit={submit} 
      className="mt-4"
    >

      {/* Default Pricing Form  */}
      <div className='row mb-4'>

        {/* Pricing Name ar */}
        <div className="col-4 ">
          <TextField
            type="text"
            label={safaVisa?.titleAr}
            placeholder={safaVisa?.titleInAr}
            name="title_ar"
            id="title_ar"
            value={pricingData?.title_ar}
            onChange={(e) => handleChangeInputs({ key: 'title_ar', value: e?.target?.value })}
            color={errors?.title_ar?.required ? "danger" : ""}
            errors={errors?.title_ar}
          />
        </div>

        {/* Pricing Name en */}
        <div className="col-4  ">
          <TextField
            type="text"
            label={safaVisa?.titleEn}
            placeholder={safaVisa?.titleInEn}
            name="title_en"
            id="title_en"
            value={pricingData?.title_en}
            onChange={(e) => handleChangeInputs({ key: 'title_en', value: e?.target?.value })}
            color={errors?.title_en?.required ? "danger" : ""}
            errors={errors?.title_en}
          />
        </div>

        <div className="col-4" />

        {/* Pricing Countries  */}
        <div className={`col-4 `}>
          <SelectField
            multi
            id="countries_id"
            name="countries_id"
            label={safaVisa?.country}
            placeholder={safaVisa?.select}
            options={countries}
            value={pricingData?.countries_id}
            onChange={(values, { action }) => {
              if (action === "remove-value" && !values) {
                handleChangeInputs({ key: 'countries_id', value: [] })
                return;
              }
              handleChangeInputs({ key: 'countries_id', value: values })
              setErrors({
                ...errors,
                ...validate(
                  { name: "countries_id", value: values.length },
                  { required: true }
                ),
              });
            }}
            color={errors?.countries_id?.required ? "danger" : ""}
            errors={errors?.countries_id}
          />
        </div>

        {/* Pricing Currency  */}
        <div className={`col-4  `}>
          <SelectField
            hasLabel={true}
            label={safaVisa?.currency}
            placeholder={safaVisa?.select}
            id="currency"
            name="currency"
            value={pricingData?.currency}
            options={currencies}
            onChange={(e) => handleChangeInputs({ key: 'currency', value: e })}
            color={errors?.currency?.required ? "danger" : ""}
            errors={errors?.currency}
          />
        </div>

        {/* Pricing Audience Type  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.audienceType}
            name='audience_type'
            groupOptions={audienceTypeOptions}
            onChange={(e) => handleChangeInputs({ key: 'audience_type', value: e?.target?.value })}
            value={pricingData?.audience_type}
            color={errors?.audience_type?.required ? "danger" : ""}
            errors={errors?.audience_type}
          />
        </div>

        {/* Contracts Validation   */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.contractsValidation}
            name='contarct'
            groupOptions={contractsOptions}
            onChange={(e) => handleChangeInputs({ key: 'with_contracts', value: e?.target?.value })}
            value={pricingData?.with_contracts}
          />
        </div>

        {/* No Of Contracts   */}
        <div className="col-4 ">
          <TextField
            disabled={pricingData?.with_contracts === 'contarct-indeterminate'}
            type="number"
            label={safaVisa?.noOfContracts}
            placeholder={''}
            name="contract_count"
            id="contract_count"
            value={pricingData?.contract_count}
            onChange={(e) => handleChangeInputs({ key: 'contract_count', value: e?.target?.value })}
            color={errors?.contract_count?.required ? "danger" : ""}
            errors={errors?.contract_count}
          />
        </div>

        <div className="col-4" />

        {/* Agents Validation  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.agentsValidation}
            name='agents'
            groupOptions={agentsOptions}
            onChange={(e) => handleChangeInputs({ key: 'with_Agents', value: e?.target?.value })}
            value={pricingData?.with_Agents}
          />
        </div>

        {/*  Number Of Agents  */}
        <div className="col-4 ">
          <TextField
            disabled={pricingData?.with_Agents === 'agents-indeterminate'}
            type="number"
            label={safaVisa?.noOfAgents}
            placeholder={''}
            name="agents_count"
            id="agents_count"
            value={pricingData?.agents_count}
            onChange={(e) => handleChangeInputs({ key: 'agents_count', value: e?.target?.value })}
            color={errors?.agents_count?.required ? "danger" : ""}
            errors={errors?.agents_count}
          />
        </div>

        <div className="col-4" />

        {/* Pricing Description Ar */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.descriptionAr}
            placeholder={safaVisa?.descriptionInAr}
            value={pricingData?.description_ar}
            onChange={(e) => handleChangeInputs({ key: 'description_ar', value: e?.target?.value })}
            color={errors?.description_ar?.required ? "danger" : ""}
            errors={errors?.description_ar}
          />

        </div>

        {/* Pricing Description En */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.descriptionEn}
            placeholder={safaVisa?.descriptionInEn}
            value={pricingData?.description_en}
            onChange={(e) => handleChangeInputs({ key: 'description_en', value: e?.target?.value })}
            color={errors?.description_en?.required ? "danger" : ""}
            errors={errors?.description_en}
          />

        </div>

      </div>

      {/* Actions Button */}
      <div className="col-12 d-flex justify-content-between gap-10 mt-3">

        <button
          className="btn back-btn py-2 "
          onClick={() => history.push("/safaVisa-defaultPricing")}
        >
          <span >
            <LeftArrowIcon color='#1e85ff' width='24' height='24' />
          </span>
          {safaVisa.back}
        </button>

        <button type='button' className="btn add-btn py-2" onClick={handleNextButton}>
          {safaVisa.next}
        </button>

      </div>

    </form>
  )
}


