// Translation
import Locale from 'translations';
// Componets
import TextField from 'components/shared/TextField';
// Icons
import { DownArrowIcon, FilterIcon } from 'components/Icons';
//--------------------------------------------------------------
function QuickFilter({ quickfilters, onQuickFilter, toggleFilter, filterIsOpen }) {

  // ***  Hook
  const { safaVisa } = Locale

  const handleSearchFilter = ({ key, value }) => {
    onQuickFilter({
      action: "filter",
      key: key,
      payload: value,
    });
  }

  const tags = [
    { label: safaVisa.all, id: null },
    { label: safaVisa.active, id: 1 },
    { label: safaVisa.notActive, id: 0 },
  ]

  //---------- JSX Code ----------
  return (
    <div className='row align-items-center'>

      {/* Search Input */}
      <div className='col-4'>

        <div style={{ width: '100%', flex: 1 }}>
          <TextField
            hasLabel={false}
            label={safaVisa?.search}
            type="text"
            placeholder={safaVisa?.search}
            value={quickfilters?.title}
            // isImage={true}
            // image={SeachIcon}
            prependImage={true}
            onChange={(e) => handleSearchFilter({ key: 'title', value: e?.target?.value })}
          />
        </div>

      </div>

      {/* Tags Holder  */}
      <div className='col-5'>

        <div className='filter-tags-holder'>
          {tags?.map(tag =>
            <span
              key={tag?.id}
              className={`d-flex justify-content-center ${quickfilters?.is_active == tag?.id ? 'active-tag' : 'tag'} `}
              onClick={() => handleSearchFilter({ key: 'is_active', value: tag?.id })}
            >
              {tag?.label}
            </span>
          )}
        </div>

      </div>

      <div className='col-2 flex-grow-1'>

        <button
          className="btn filter-btn w-100"
          onClick={toggleFilter}
        >
          <FilterIcon color='#1E85FF' />
          {safaVisa.filterResults}
          <span style={{ transform: filterIsOpen && 'rotate(180deg)' }}>
            <DownArrowIcon color='#1E85FF' width="14" height="8" />
          </span>
        </button>

      </div>

    </div >
  )
}

export default QuickFilter