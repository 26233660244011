import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import validate from "helpers/validate";
import useShowPermission from "hooks/useShowPermission";
import Locale from "translations";

const HotelDetails = ({
	setSupplier,
	supplier,
	setErrors,
	errors,
	countries,
	cities,
	handleHotels,
	getHotelsCities,
	getHotelsArea,
	areas,
	deleteHotel,
	addOneHotel,
	ratingOptions,
}) => {
	const ManualReservationsManagePermission = useShowPermission({
		permission: "Manual-Suppliers-Manage",
	});
	const { backOffice, property } = Locale;
	return (
		<div className="supplier-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{backOffice.hotels}</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				{supplier?.hotel &&
					supplier?.hotel?.length >= 0 &&
					supplier?.hotel.map((item, index) => (
						<>
							<div className="col-3">
								<TextField
									type="text"
									label={backOffice.hotelName}
									placeholder={backOffice.hotelName}
									value={item.hotelName}
									name={`hotelName${index}`}
									disabled={!ManualReservationsManagePermission}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										handleHotels(e.target.value, index, "hotelName");
									}}
									color={errors[`hotelName${index}`]?.required ? "danger" : ""}
									errors={errors[`hotelName${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									label={property.rating}
									value={item.hotelRating}
									name={`hotelRating${index}`}
									options={ratingOptions}
									disabled={!ManualReservationsManagePermission}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelRating${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelRating");
									}}
									color={
										errors[`hotelRating${index}`]?.required ? "danger" : ""
									}
									errors={errors[`hotelRating${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									label={backOffice.country}
									value={item.hotelCountry}
									name={`hotelCountry${index}`}
									options={countries}
									disabled={!ManualReservationsManagePermission}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelCountry${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelCountry");
										getHotelsCities(item);
									}}
									color={
										errors[`hotelCountry${index}`]?.required ? "danger" : ""
									}
									errors={errors[`hotelCountry${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									disabled={item.hotelCountry == ""}
									label={backOffice.city}
									value={item.hotelCity}
									name={`hotelCity${index}`}
									options={cities}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelCity${index}`, value: e.value },
												{
													required: true,
												}
											),
										});

										handleHotels(e, index, "hotelCity");
										getHotelsArea(item);
									}}
									color={errors[`hotelCity${index}`]?.required ? "danger" : ""}
									errors={errors[`hotelCity${index}`]}
								/>
							</div>
							<div className="col-2">
								<SelectField
									disabled={item.hotelCity == ""}
									label={backOffice.area}
									value={item.area}
									name={`hotelArea${index}`}
									options={areas}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: `hotelArea${index}`, value: e.value },
												{
													required: false,
												}
											),
										});

										handleHotels(e, index, "hotelArea");
									}}
									// color={
									// 	errors[`hotelArea${index}`]?.required ? "danger" : ""
									// }
									// errors={errors[`hotelArea${index}`]}
								/>
							</div>
							<div className="col-3">
								<TextField
									type="text"
									label={backOffice.address}
									placeholder={backOffice.address}
									disabled={!ManualReservationsManagePermission}
									value={item.hotelAddress}
									name={`hotelAddress${index}`}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										handleHotels(e.target.value, index, "hotelAddress");
									}}
									color={
										errors[`hotelAddress${index}`]?.required ? "danger" : ""
									}
									errors={errors[`hotelAddress${index}`]}
								/>
							</div>
							<div className="col-2">
								<TextField
									type="text"
									label={backOffice.phoneNo}
									placeholder={backOffice.phoneNo}
									disabled={!ManualReservationsManagePermission}
									value={item.hotelPhone}
									name={`hotelPhone${index}`}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												phone: true,
											}),
										});
										handleHotels(e.target.value, index, "hotelPhone");
									}}
									color={
										errors[`hotelPhone${index}`]?.required ||
										errors[`hotelPhone${index}`]?.phone
											? "danger"
											: ""
									}
									errors={errors[`hotelPhone${index}`]}
								/>
							</div>
							<div className="col-6"></div>
							{index != 0 ? (
								<a
									className="col-1 mt-5 btn-link text-danger pointer"
									href={() => false}
									onClick={() => deleteHotel(index)}
								>
									<i class="fas fa-trash mx-1"></i>
								</a>
							) : (
								<span className="col-1"></span>
							)}
						</>
					))}
				<ShowForPermission permission="Manual-Suppliers-Manage">
					<button
						className="btn-link text-success d-flex justify-content-end w-90"
						onClick={addOneHotel}
					>
						<i class="fas fa-plus-circle text-success"></i> {backOffice.AddNew}
					</button>
				</ShowForPermission>
			</div>

			<div className="d-flex justify-content-end"></div>
		</div>
	);
};

export default HotelDetails;
