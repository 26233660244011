import Locale from "translations";
function QuestionsAnswers({ questions }) {
	const { backOffice } = Locale;
	return (
		<div className="bg-white">
			<p className="font-weight-bold text-dark-blue mt-2">
				{backOffice.QuestionsAndAnswers}
			</p>
			{questions?.map((res) => (
				<div key={res?.answer} className="border my-2 rounded p-2">
					<p>{res?.question?.text}</p>
					<p className="mt-2 font-weight-bold">{res?.answer}</p>
				</div>
			))}
		</div>
	);
}

export default QuestionsAnswers;
