import ShowForPermission from "helpers/showForPermission";
import { Link } from "react-router-dom";
import Locale from "translations";

function LatestGroupReservations({ latestitems }) {
	const { backOffice, booking } = Locale;

	return (
		<div className="col-6">
			<h4 className="p-1 pt-5">{backOffice.B2BGroupeReservations}</h4>
			<div className="our-border  px-4">
				<table className="custom-table reservation-table back-office-table table table-striped ">
					<thead>
						<tr>
							<th>{booking.Ref}</th>
							<th>{backOffice.agency}</th>
							<th>{backOffice.hotelName}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{latestitems?.group_reservations?.length > 0 ? (
							latestitems?.group_reservations?.map((res) => {
								return (
									<tr>
										<td>{res?.id}</td>
										<td>{res?.company?.name?.en}</td>
										<td>{res?.hotel_name}</td>
										<td>
											<ShowForPermission permission="SBS-Manual-Reservations-View">
												<Link
													to={`/manual-reservations/sbs-reservations/${res.id}/details`}
												>
													<i class="fas fa-angle-right"></i>
												</Link>
											</ShowForPermission>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default LatestGroupReservations;
