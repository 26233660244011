import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// ReactStrap
import { UncontrolledTooltip } from 'reactstrap';
// Translation
import Locale from 'translations';
// Icons
import { InfoIcon, LeftArrowIcon } from 'components/Icons';
// Services
import { getPackagePricingById } from 'services/safaVisa';
// Context
import { useGlobalState } from 'context/global';
// ------------------------------------------------------
export default function PackageDetails() {
  // *** Hooks
  let history = useHistory();

  let { id } = useParams();

  const { locale } = useGlobalState();

  const { backOffice, safaVisa } = Locale;

  // ** State
  const [packageDetails, setPricingDetails] = useState({})

  // *** Function
  const defineIsActive = (status) => {
    return status === 1 ? true : false
  }

  const defineSubscriptionType = (postpaid_price, prepaid_price) => {
    if (postpaid_price && prepaid_price) {
      return `${safaVisa.postpaid} + ${safaVisa.prepaid}`
    } if (postpaid_price) {
      return `${safaVisa.postpaid}`
    }
    if (prepaid_price) {
      return `${safaVisa.prepaid}`
    } else {
      return safaVisa?.noSubscription
    }
  }

  const fetchPackagePriceDetails = async () => {
    const response = await getPackagePricingById(id)
    if (response?.status === 200) {
      setPricingDetails(response?.data?.data)
    }
  }

  const packagePricing = [
    { name: safaVisa.postpaid, price: packageDetails?.postpaid_price },
    { name: safaVisa.prepaid, price: packageDetails?.prepaid_price },
  ]

  const visiblePricing = packagePricing?.filter(el => el?.price !== null)

  useEffect(() => {
    fetchPackagePriceDetails()
  }, [])

  // -------- JSX Code --------
  return (
    <div className='safaVisa-wrapper'>

      <div className='holder mb-3'>
        {/* Package Pricing Details Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.packageDetails}</h4>

          <div className='d-flex gap-2 align-items-center'>
            <p className={`${defineIsActive(packageDetails?.is_active) ? 'active-status ' : 'notActivated-status'}`}>
              {packageDetails?.is_active === 1 ? safaVisa.active : safaVisa?.notActive}
            </p>
          </div>
        </div>

        <hr />

        {/* Package Pricing Details Info */}
        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.packageTitle} :</p>
            <p className='subTitle'> {packageDetails?.title?.[locale]}</p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.packageCreationDate} :</p>
            <p className='subTitle'>{packageDetails?.created_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.packageUpdatingDate} :</p>
            <p className='subTitle' >{packageDetails?.updated_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.country} :</p>
            <p className='subTitle d-flex' >
              {packageDetails?.countries?.[0]?.name}
              {
                packageDetails?.countries?.length > 1 ?
                  <>
                    <span
                      href="#"
                      className='more-info-toolTip'
                      id="UncontrolledTooltipExample"
                    >
                      +{packageDetails?.countries?.length - 1}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="UncontrolledTooltipExample"
                    >
                      {packageDetails?.countries?.slice(1).map(item =>
                        <>
                          {item?.name} {' '}-
                        </>
                      )}
                    </UncontrolledTooltip>
                  </>
                  :
                  null
              }

            </p>
          </div>

        </div>

        <div className='pricing-info-holder mb-5'>

          <div>
            <p className='mb-2 title'>{safaVisa.currency} :</p>
            <p className='subTitle'>{packageDetails?.currency} </p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '> {safaVisa.packageType} :</p>
            <p className='subTitle' >{safaVisa[`${packageDetails?.duration_type}`]}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title'>
              <span href="#" id={`UncontrolledTooltipExample1`}>
                <InfoIcon color='#9C9FAB' width='20' height='20' />
              </span>
              {' '}{safaVisa.repurchasePackage} :
            </p>
            <UncontrolledTooltip
              placement="top"
              target={`UncontrolledTooltipExample1`}
            >
              {safaVisa.possibilityOfRepurchasing}
            </UncontrolledTooltip>
            <p className='subTitle' >
              {packageDetails?.repurchase === 0 ? safaVisa.notAvailable : safaVisa.available}
            </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title'>{safaVisa.packageValidity} :</p>
            <p className='subTitle'>{packageDetails?.duration_count || 0}</p>
          </div>

        </div>

        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.packageAppearanceDate} :</p>
            <p className='subTitle'>{packageDetails?.share_start_date}</p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.packageDisappearanceDate} :</p>
            <p className='subTitle'>{packageDetails?.share_end_date || safaVisa?.indeterminate}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.numberOfPurchases} :</p>
            <p className='subTitle' >{packageDetails?.subscription_count}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.subscriptionType} :</p>
            <p className='subTitle' >
              {defineSubscriptionType(packageDetails?.postpaid_price, packageDetails?.prepaid_price)}
            </p>
          </div>


        </div>

        <div className='pricing-info-holder mb-5'>

          <div className='left-border'>
            <p className='mb-2 title'>
              <span href="#" id={`UncontrolledTooltipExample`}>
                <InfoIcon color='#9C9FAB' width='20' height='20' />
              </span>
              {' '} {safaVisa.audience}
            </p>
            <UncontrolledTooltip
              placement="top"
              target={`UncontrolledTooltipExample`}
            >
              {safaVisa.audienceTypeDescription}
            </UncontrolledTooltip>
            <p className='subTitle' >{safaVisa[`${packageDetails?.audience_type}`]}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.numberOfContracts}</p>
            <p className='subTitle' >{packageDetails?.contract_count || safaVisa?.indeterminate}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title'>{safaVisa.numberOfAgents}</p>
            <p className='subTitle'>{packageDetails?.agents_count || safaVisa?.indeterminate}</p>
          </div>

        </div>

        <div className='pricing-info-holder'>
          <div>
            <p className='mb-2 title'>{safaVisa.description}</p>
            <p className='subTitle'> {packageDetails?.description?.[locale]}</p>
          </div>
        </div>

      </div>

      {/* Package Pricing Type List  Table */}
      <div className='holder mb-3'>
        <h4 className="main-title mb-4 ">{safaVisa.pricing}</h4>
        <div className="our-border">
          <table className="custom-table back-office-table table table-striped mb-0">

            <thead>
              <tr>
                <th>{safaVisa.pricingType}</th>
                <th> {safaVisa.price}</th>
                <th>{safaVisa.currency}</th>
              </tr>
            </thead>

            <tbody>
              {visiblePricing?.length > 0 ? (
                visiblePricing?.map((row) =>
                  <tr>
                    <td>{row?.name}</td>
                    <td> <span >{row?.price}</span></td>
                    <td > {packageDetails?.currency}</td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan="15">
                      <div className="product-no-data">
                        <i className="fas fa-info-circle fa-lg"></i>{" "}
                        <h4>{backOffice.noResult}</h4>
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>

          </table>
        </div >
      </div>

      {/* Package Visas List  Table */}
      <div className='holder'>
        <h4 className="main-title mb-4 ">{safaVisa.visas}</h4>
        <div className="our-border">
          <table className="custom-table back-office-table table table-striped mb-0">

            <thead>
              <tr>
                <th>{safaVisa.id}</th>
                <th> {safaVisa.visaType}</th>
                <th>{safaVisa.count}</th>
              </tr>
            </thead>

            <tbody>
              {packageDetails?.quotas?.length > 0 ? (
                packageDetails?.quotas?.map((row, index) =>
                  <tr>
                    <td><p className='id-text'> {index + 1} </p></td>
                    <td>{safaVisa?.[`${row?.visa_type}`]}</td>
                    <td> <span > {row?.amount}</span></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="product-no-data">
                      <i className="fas fa-info-circle fa-lg"></i>{" "}
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div >
      </div>

      <button
        className="btn back-btn py-2 mt-5"
        onClick={() => history.push("/safaVisa-packages")}
      >
        <span  >
          <LeftArrowIcon color='#1e85ff' width='24' height='24' />
        </span>
        {safaVisa.back}
      </button>

    </div>
  )
}

