// Transaltion
import Locale from 'translations';
// Components
import TextField from 'components/shared/TextField';
import validate from 'helpers/validate';
// --------------------------------------------------------------------------
export default function PackageSubscriptionsTypesList(props) {
  const {
    discountData,
    setDiscountData,
    errors,
    setErrors,
  } = props

  const { safaVisa } = Locale;

  // const visiblePricing = discountData?.package_pricing?.filter(el => el?.price !== null)

  const handleChangeInputs = ({ key, value }, index) => {
    // console.log(key)
    const dataClone = { ...discountData }
    const package_pricing = dataClone['package_pricing']
    if (key?.includes('discount_percentage') && value <= 100) {
      package_pricing[index][key] = value
      const subscriptionTypePrefix = key?.split('_')[0]
      package_pricing[index][`${subscriptionTypePrefix}_after_discount`] = (package_pricing[index]['price'] - (value / 100) * package_pricing[index]['price']).toFixed(2)
      setDiscountData(dataClone)
    }
    if (key?.includes('after_discount') && value <= package_pricing[index]['price']) {
      package_pricing[index][key] = value
      setDiscountData(dataClone)
    }
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
  }
  // console.log(discountData)
  // console.log(visiblePricing)

  // ------------ JSX Code ----------
  return (
    <div className='holder p-0 mb-3'>
      <h4 className="main-title mb-4 ">{safaVisa.pricing}</h4>
      <div className="our-border">
        <table className="custom-table back-office-table table table-striped mb-0">

          <thead>
            <tr>
              <th>{safaVisa.id}</th>
              <th>{safaVisa.pricingType}</th>
              <th> {safaVisa.price}</th>
              <th>{safaVisa.currency}</th>
              {discountData?.discount_type === 'percentage' &&
                <th>{safaVisa.discountPercentage}</th>
              }
              <th>{safaVisa.priceAfterDiscount}</th>
            </tr>
          </thead>

          <tbody>
            {
              discountData?.package_pricing?.map((row, index) => {
                const name = row?.name?.toLowerCase();
                return (
                  <tr>
                    <td><p className='id-text'> {index + 1} </p></td>
                    <td>{safaVisa[row?.name?.toLowerCase()]}</td>
                    <td> <span >{row?.price}</span></td>
                    <td > {row?.currency}</td>
                    {discountData?.discount_type === 'percentage' &&
                      <td >
                        <div className='d-flex align-items-center'>
                          <div className='col-12'>
                            <TextField
                              disabled={discountData?.subscription_type === name
                                || discountData?.subscription_type === 'all'
                                ?
                                false
                                :
                                true
                              }
                              type="number"
                              label={safaVisa.percentage}
                              hasLabel={false}
                              placeholder='10 %'
                              name={`${name}_discount_percentage`}
                              id={`${name}_discount_percentage`}
                              value={row?.[`${name}_discount_percentage`]}
                              max={"100"}
                              onChange={(e) => handleChangeInputs({ key: `${name}_discount_percentage`, value: e?.target?.value }, index)}
                              color={errors?.[`${name}_discount_percentage`]?.required ? "danger" : ""}
                              errors={errors?.[`${name}_discount_percentage`]}
                            />
                          </div>
                          %
                        </div>
                      </td>
                    }
                    <td >
                      {discountData?.discount_type === 'fixed'
                        ?
                        <TextField
                          disabled={discountData?.subscription_type === name
                            || discountData?.subscription_type === 'all'
                            ?
                            false
                            :
                            true
                          }
                          type="number"
                          label={safaVisa?.priceAfterDiscount}
                          hasLabel={false}
                          name={`${name}_after_discount`}
                          id={`${name}_after_discount`}
                          value={row?.[`${name}_after_discount`]}
                          onChange={(e) => handleChangeInputs({ key: `${name}_after_discount`, value: e?.target?.value }, index)}
                          color={errors?.[`${name}_after_discount`]?.required ? "danger" : ""}
                          errors={errors?.[`${name}_after_discount`]}
                        />
                        :
                        <p className='text-center'>
                          {row?.[`${name}_after_discount`]}
                        </p>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </div >
    </div>
  )
}

