import { Table } from "reactstrap";
import Locale from "translations";

const GENDER = { M: "Male", F: "Female" };

function PnrList({ list }) {
	const { backOffice, Companies } = Locale;
	return (
		<div className="table-container mt-3">
			<p className="mb-1">PNR List</p>
			<Table
				striped
				className="table-update p-2 bg-white flight-inventory-table border-light"
				style={{ verticalAlign: "center", borderRadius: "10px" }}
			>
				<thead>
					<tr>
						<th>{backOffice.Segment}</th>
						<th>{Companies.flight}</th>
						<th>{backOffice.PAXName}</th>
						<th>{backOffice.gender}</th>
						<th>{backOffice.PNR}</th>
						<th>{backOffice.AirlinePNR}</th>
						<th>{backOffice.TKTNumber}</th>
					</tr>
				</thead>
				<tbody>
					{list?.length > 0
						? list?.map((item) => {
								return (
									<tr>
										<td>{`${item?.departure?.code} - ${item?.arrival?.code}`}</td>
										<td>{item?.flight_num}</td>
										<td>{item?.passenger_name}</td>
										<td>{GENDER[item?.passenger_sex]}</td>
										<td>{item?.pnr}</td>
										<td>{item?.air_pnr}</td>
										<td>{item?.ticket_number}</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</Table>
		</div>
	);
}

export default PnrList;
