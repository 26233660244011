import TextField from "components/Form/TextField/TextField";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import validate from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fetchCities } from "services/lookups";
import Locale from "translations";
import RejectModal from "../RejectModal";
import ManagerPersonalInfo from "./ManagerPersonalInfo";

const ManagersDetails = ({ state, SetActiveTab, countries }) => {
	const { backOffice } = Locale;
	let locale = localStorage.getItem("currentLocale") || "en";
	const { allCountries } = useGlobalState();
	const { id } = useParams();

	const history = useHistory();

	const [propertyDetails, setPropertyDetails] = useState({
		manageProperty: "",
		full_name: "",
		alternative_name: "",
		address: "",
		unit_number: "",
		zip_code: "",
		country: "",
		city: "",
		spin: false,
		birth_date: null,
		managerPersonalInfo: [
			{
				first_name: "",
				last_name: "",
				email: "",
			},
		],
		governmentRepresent: true,
	});

	const setPersonal = (i, name, value) => {
		let personalClone = [...propertyDetails.managerPersonalInfo];
		personalClone[i] = {
			...personalClone[i],
			[name]: value,
		};
		propertyDetails.managerPersonalInfo = personalClone;
	};

	const [cities, setCities] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [rejected, setRejected] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "manageProperty", value: propertyDetails.manageProperty },
				{ required: true }
			),
			...validate(
				{ name: "full_name", value: propertyDetails.full_name },
				{ required: true }
			),
			...validate(
				{ name: "address", value: propertyDetails.address },
				{ required: true }
			),
			...validate(
				{ name: "unit_number", value: propertyDetails.unit_number },
				{ required: true }
			),
			...validate(
				{ name: "zip_code", value: propertyDetails.zip_code },
				{ required: true, min: 5, max: 5 }
			),
			...validate(
				{ name: "country", value: propertyDetails.country },
				{ required: true }
			),
			...validate(
				{ name: "city", value: propertyDetails.city },
				{ required: true }
			),
			...validate(
				{
					name: "first_name",
					value: propertyDetails.managerPersonalInfo[0].first_name,
				},
				{ required: true }
			),
			...validate(
				{ name: "last_name", value: propertyDetails.last_name },
				{ required: true }
			),
			...validate(
				{
					name: "governmentRepresent",
					value:
						propertyDetails.governmentRepresent == "0"
							? 1
							: propertyDetails.governmentRepresent,
				},
				{ required: true }
			),
		});
	};

	const submit = () => {
		setPropertyDetails({
			...propertyDetails,
			spin: true,
		});
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setPropertyDetails({ ...propertyDetails, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setPropertyDetails({
					...propertyDetails,
					city: "",
				});
			} else setPropertyDetails({ ...propertyDetails, [name]: value });
		}
	};

	// Fetch Cities
	useEffect(() => {
		async function fetchLookups() {
			if (propertyDetails.country?.value) {
				const res = await fetchCities(propertyDetails.country?.value);
				const formatted = res?.data?.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [locale, propertyDetails.country]);

	useEffect(() => {
		if (id && state) {
			setPropertyDetails({
				...propertyDetails,
				...state.property_details?.management_details?.manageable,
				country: {
					label: state.property_details?.country.names[locale],
					value: state.property_details?.country.id,
				},
				city: {
					label: state.property_details?.city?.names[locale],
					value: state.property_details?.city?.id,
				},
				manageProperty: {
					label: state.property_details?.manageable_type?.label,
					value: state.property_details?.manageable_type?.id,
				},
				// managerPersonalInfo: [
				//   {
				//     first_name:
				//       state.property_details?.manageable?.managers[0]?.first_name,
				//     last_name:
				//       state.property_details?.manageable?.managers[0]?.last_name,
				//     email: state.property_details?.manageable?.managers[0]?.email,
				//   },
				// ],
				managerPersonalInfo: state.property_details?.manageable?.managers,
			});
		}
	}, [id, state]);

	const addPersonalInfo = () => {
		let managerInfo = [...propertyDetails.managerPersonalInfo];
		managerInfo.push({});
		setPropertyDetails({
			...propertyDetails,
			managerPersonalInfo: managerInfo,
		});
	};

	const removePersonalInfo = (index) => {
		setPropertyDetails({
			...propertyDetails,
			managerPersonalInfo: propertyDetails.managerPersonalInfo.filter(
				(element, i) => i !== index
			),
		});
	};

	const Rejected = () => {
		setRejected(true);
	};

	return (
		<>
			<div className="property-details">
				<div className="row m-0">
					<div className="col-12 pr-4 p-0">
						<SelectField
							id="manageProperty"
							disabled={true}
							label={backOffice.whoManageProp}
							placeholder={backOffice.managePropertyPlaceholder}
							options={[
								{
									label: "Management Company",
									value: "1",
								},
								{
									label: "Property Manager",
									value: "2",
								},
							]}
							// value={{
							//   value:
							//     state.property_details?.management_details?.manageable_id,
							//   label: "Management Company",
							// }}
							name="manageProperty"
							onChange={(e) => {
								handleChange({ name: "manageProperty", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "manageProperty", value: e },
										{ required: true }
									),
								});
							}}
							value={propertyDetails.manageProperty}
							color={errors.manageProperty?.required ? "danger" : ""}
							errors={errors.manageProperty}
						/>

						{propertyDetails.manageProperty.value == 2 ? (
							<>
								<TextField
									id="full_name"
									disabled={true}
									label={backOffice.fullNameMangeCompany}
									placeholder={backOffice.manageCompanyName}
									options={[
										{ label: "Company Name 1", value: "0" },
										{ label: "Company Name 2", value: "1" },
									]}
									name="full_name"
									onChange={(e) => {
										handleChange({ name: "full_name", value: e });
										setErrors({
											...errors,
											...validate(
												{ name: "full_name", value: e },
												{ required: true }
											),
										});
									}}
									value={propertyDetails.full_name}
									color={errors.full_name?.required ? "danger" : ""}
									errors={errors.full_name}
								/>
								{/* <DatePickerField
                  label={backOffice.birthDate}
                  disabled={true}
                  date={
                    propertyDetails.birth_date
                      ? moment(propertyDetails.birth_date)
                      : moment("2000-01-01")
                  }
                  onChangeDate={(date) =>
                    setPropertyDetails({
                      ...propertyDetails,
                      birth_date: date._d
                    })
                  }
                  isOutsideRange={(day) => {
                    return false;
                  }}
                /> */}

								{/* <div className="personal-data">
                  <div className="col-md-12 head-manager p-0">
                    <p>{backOffice.governmentRepresent}</p>
                  </div>

                  <p className="py-2 text-headline text-gray-300">
                    {backOffice.governmentText}
                  </p>

                  <div className="font-weight-bold">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment_policy11"
                        id="governmentRepresent"
                        value="1"
                        checked={propertyDetails.governmentRepresent == "1"}
                        onChange={(e) => {
                          setPropertyDetails({
                            ...propertyDetails,
                            governmentRepresent: e.target.value,
                          });
                          setErrors({
                            ...errors,
                            ...validate(
                              {
                                name: "governmentRepresent",
                                value: e.target.value,
                              },
                              { required: true }
                            ),
                          });
                        }}
                      />
                      <label className="form-check-label" for="governmentRepresent">
                        {backOffice.yes}
                      </label>
                    </div>
                    <div className="form-check form-check-inline px-5">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment_policy11"
                        id="governmentRepresent2"
                        value="0"
                        checked={propertyDetails.governmentRepresent == "0"}
                        onChange={(e) => {
                          setPropertyDetails({
                            ...propertyDetails,
                            governmentRepresent: e.target.value,
                          });
                          setErrors({
                            ...errors,
                            ...validate(
                              {
                                name: "governmentRepresent",
                                value:
                                  e.target.value == "0" ? 1 : e.target.value,
                              },
                              { required: true }
                            ),
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="governmentRepresent2"
                      >
                        {backOffice.no}
                      </label>
                    </div>

                    {propertyDetails.governmentRepresent === "0" ? (
                      <div className="col-md-12 p-0">
                        <div className="row m-0 px-2">
                          {backOffice.loremIpsum}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              */}
							</>
						) : (
							<>
								<TextField
									type="text"
									id="full_name"
									disabled={true}
									label={backOffice.fullNameMangeCompany}
									placeholder={backOffice.manageCompanyName}
									name="full_name"
									onChange={(e) => {
										setPropertyDetails({
											...propertyDetails,
											full_name: e.target.value,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "full_name", value: e },
												{ required: true }
											),
										});
									}}
									value={propertyDetails.full_name}
									color={errors.full_name?.required ? "danger" : ""}
									errors={errors.full_name}
								/>
								<TextField
									type="text"
									id="alternative_name"
									disabled={true}
									label={backOffice.alternativeNameMangeCompany}
									placeholder={backOffice.alternativeNameMangeCompany}
									name="alternative_name"
									onChange={(e) => {
										setPropertyDetails({
											...propertyDetails,
											alternative_name: e.target.value,
										});
										setErrors({
											...errors,
											...validate(
												{ name: "alternative_name", value: e },
												{ required: true }
											),
										});
									}}
									value={propertyDetails.alternative_name}
								/>
								<TextField
									type="text"
									disabled={true}
									label={backOffice.addressBusiness}
									placeholder={backOffice.addressBusinessPlaceholder}
									value={propertyDetails.address}
									name="address"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
										setPropertyDetails({
											...propertyDetails,
											address: e.target.value,
										});
									}}
									color={errors?.address?.required ? "danger" : ""}
									errors={errors?.address}
								/>

								<div className="row m-0">
									<div className="col-md-6 pl-0">
										<TextField
											type="text"
											disabled={true}
											label={backOffice.unitNumber}
											placeholder={backOffice.unitNumberPlaceholder}
											value={propertyDetails.unit_number}
											name="unit_number"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
													}),
												});
												setPropertyDetails({
													...propertyDetails,
													unit_number: e.target.value,
												});
											}}
											color={errors?.unit_number?.required ? "danger" : ""}
											errors={errors?.unit_number}
										/>
									</div>

									<div className="col-md-6 pr-0">
										<TextField
											type={"number"}
											disabled={true}
											label={backOffice.zipCode}
											placeholder={backOffice.zipCodePlaceholder}
											value={propertyDetails.zip_code}
											name="zip_code"
											min={5}
											max={5}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														min: 5,
														max: 5,
													}),
												});
												setPropertyDetails({
													...propertyDetails,
													zip_code: e.target.value,
												});
											}}
											color={
												errors?.zip_code?.required ||
												errors?.zip_code?.min ||
												errors?.zip_code?.max
													? "danger"
													: ""
											}
											errors={errors?.zip_code}
										/>
									</div>

									{/* Country */}
									<div className="col-6 pl-0">
										<SelectField
											label={backOffice.country}
											disabled={true}
											placeholder={backOffice.country}
											errors={errors.country}
											color={errors.country?.required ? "danger" : ""}
											options={countries}
											id="basic-countries"
											onChange={(e) => {
												handleChange({ name: "country", value: e });
												setErrors({
													...errors,
													...validate(
														{ name: "country", value: e },
														{
															required: true,
														}
													),
												});
											}}
											value={propertyDetails.country}
										/>
									</div>

									{/* City */}
									<div className="col-6 pr-0">
										<SelectField
											label={backOffice.city}
											disabled={true}
											placeholder={backOffice.cityPlaceholder}
											errors={errors.city}
											color={errors.city?.required ? "danger" : ""}
											options={cities}
											id="basic-cities"
											onChange={(e) => {
												handleChange({ name: "city", value: e });
												setErrors({
													...errors,
													...validate(
														{ name: "city", value: e },
														{
															required: true,
														}
													),
												});
											}}
											value={propertyDetails.city}
										/>
									</div>
								</div>

								<div className="personal-data">
									{backOffice.provideJustFullNames}
									<div className="col-md-12 head-manager p-0">
										<p>{backOffice.provideFullNames}</p>
									</div>
									{propertyDetails.managerPersonalInfo?.map((personal, i) => {
										return (
											<ManagerPersonalInfo
												key={i}
												personal={personal}
												index={i}
												errors={errors}
												setErrors={setErrors}
												propertyDetails={propertyDetails}
												setPropertyDetails={setPropertyDetails}
												removePersonalInfo={removePersonalInfo}
												setPersonal={setPersonal}
											/>
										);
									})}
									{/* <a
                    className="link-add text-success d-inline-block my-2"
                    href={() => false}
                    onClick={addPersonalInfo}
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    {backOffice.addAnother}
                    {propertyDetails.managerPersonalInfo.length === 0
                      ? backOffice.addNew
                      : backOffice.addAnother}
                  </a> */}
								</div>
							</>
						)}

						<RejectModal isOpen={rejected} toggle={setRejected} ID={id} />

						<div className="row flex-row-reverse my-4 px-2">
							{state?.property_details?.status !== "Rejected" ? (
								<React.Fragment>
									<div className="col-md-2">
										<button
											className="btn btn-primary w-100 Reject-Button"
											id="basic-submit"
											onClick={() => Rejected()}
										>
											{backOffice.reject}
										</button>
									</div>
									<div className="col-md-2">
										<button
											className="btn btn-primary w-100"
											onClick={() => SetActiveTab("4")}
										>
											{backOffice.save}
										</button>
									</div>
								</React.Fragment>
							) : null}
							<div className="col-md-2">
								<button
									onClick={() => history.push("/contract-mangment?page=1")}
									className="btn btn-secondary w-100"
								>
									{backOffice.back}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManagersDetails;
