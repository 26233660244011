import moment from "moment";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";

export default function IssueProcessing({ issueData }) {
	const { marketPlace, booking, backOffice } = Locale;
	const history = useHistory();
	const { id } = useParams();

	return (
		<>
			<div className="flight_item my-3 d-flex justify-content-between align-items-center py-2 px-3  border-light">
				{issueData.status ? (
					<>
						<div className="d-flex justify-content-between align-items-center w-100 ">
							<div className="text-success">
								<i class="far fa-check-square text-success me-1"></i>
								<span className="fw-bold">{booking.messages.issued}</span>
							</div>
							{/* <div className="d-flex justify-content-between">
								<button className="btn btn-light terms-color bold ms-2 px-4 py-2">
									Change
								</button>
								<button
									className="btn btn-light terms-color bold ms-2 px-4 py-2"
									onClick={() =>
										history.push(`/inventory/flight/reservation/${id}/refund`)
									}
								>
									Refund
								</button>
								<button
									className="btn btn-light terms-color bold ms-2 px-4 py-2"
									onClick={() =>
										history.push(`/inventory/flight/reservation/${id}/void`)
									}
								>
									Void
								</button>
							</div> */}
						</div>
					</>
				) : (
					<>
						<p className="bold terms-color">
							<i class="fas fa-hourglass-half me-2 terms-color"></i>
							{booking.messages.IssueProcessing}
						</p>
						<div className="d-flex">
							<p className="bold">
								{moment(issueData?.orderDate).format("HH:mm")}
							</p>
							<div className="text-center p-1 px-3">
								<p className="spent mt-2" style={{ borderColor: "#EDEDED" }}>
									<span>
										{backOffice.TicketWillBeIssuedWitinHoursAfterPayment}
									</span>
								</p>
							</div>
							<p className="bold">
								{moment(issueData?.orderDate).add(2, "hours").format("HH:mm")}
							</p>
						</div>
					</>
				)}
			</div>
		</>
	);
}
