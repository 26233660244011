import Pagination from "components/shared/Pagination";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "reactstrap";
import Locale from "translations";
export default function OfflineTable({
	setProperties,
	properties,
	goTo,
	meta,
}) {
	const { manualSupplier, backOffice, booking } = Locale;
	let history = useHistory();
	const [tooltip, setTooltip] = useState(false);
	const AllProducts = properties?.map((property) => (
		<tr key={property?.id}>
			<td>{property?.name}</td>
			<td>{property?.country.name}</td>
			<td>{property?.city.name}</td>
			<td>{property?.responsible_name}</td>
			<td>{property?.responsible_phone}</td>
			<td>{property?.hotels_count}</td>
			<td width={"12%"}>
				<i
					class="fas fa-edit pointer"
					style={{ color: "#00AAEB" }}
					onClick={() => {
						history.push({
							pathname: `/manual-suppliers/edit`,
							state: { detail: property },
						});
					}}
				></i>
				<Link
					to={`/manual-suppliers/${property?.name}/${property?.id}/reservation`}
				>
					<i
						class="fas fa-calendar-alt mx-1 pointer"
						style={{ color: "#B17A00" }}
					></i>
				</Link>

				<i
					role="button"
					class="fas fa-wallet mx-1 pointer"
					id={!property?.has_currencies ? `tooltip-wallet-${property?.id}` : ""}
					style={{ color: property?.has_currencies ? "#00B545" : "gray" }}
					onClick={() => {
						if (property?.has_currencies) {
							history.push(`/manual-suppliers/view/payment/${property?.id}`);
						}
					}}
					disabled={property?.has_currencies ? false : true}
				></i>

				{!property?.has_currencies && (
					<Tooltip
						placement="bottom"
						autohide={true}
						isOpen={tooltip}
						target={`tooltip-wallet-${property?.id}`}
						toggle={() => setTooltip(!tooltip)}
					>
						No currency till now
					</Tooltip>
				)}
			</td>
		</tr>
	));

	return (
		<>
			<div className="our-border  px-4">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>{backOffice.supplierName}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.city}</th>
							<th>{booking.ContactPerson}</th>
							<th>{backOffice.MobileNo}</th>
							<th>{backOffice.No_ofHotels}</th>
							<th>{backOffice.Action}</th>
						</tr>
					</thead>
					<tbody>
						{properties?.length > 0 ? (
							AllProducts
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{properties?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
			</div>
		</>
	);
}
