import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import useShowPermission from "hooks/useShowPermission";
import Locale from "translations";

const ResponsibleDetails = ({ setSupplier, supplier, setErrors, errors }) => {
	const ManualReservationsManagePermission = useShowPermission({
		permission: "Manual-Suppliers-Manage",
	});
	const { backOffice, teamManagement } = Locale;
	return (
		<div className="supplier-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{backOffice.ResponsibleDetails}</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={teamManagement.fullName}
						placeholder={"Enter Full Name"}
						value={supplier.responsibleName}
						disabled={!ManualReservationsManagePermission}
						name="responsibleName"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setSupplier({
								...supplier,
								responsibleName: e.target.value,
							});
						}}
						color={errors?.responsibleName?.required ? "danger" : ""}
						errors={errors?.responsibleName}
					/>
				</div>

				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={backOffice.title}
						placeholder={backOffice.title}
						value={supplier.responsibleTitle}
						disabled={!ManualReservationsManagePermission}
						name="responsibleTitle"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setSupplier({
								...supplier,
								responsibleTitle: e.target.value,
							});
						}}
						color={errors?.responsibleTitle?.required ? "danger" : ""}
						errors={errors?.responsibleTitle}
					/>
				</div>

				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={teamManagement.emailAddress}
						placeholder={teamManagement.emailAddress}
						value={supplier.responsibleEmail}
						disabled={!ManualReservationsManagePermission}
						name="responsibleEmail"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
									email: true,
								}),
							});
							setSupplier({
								...supplier,
								responsibleEmail: e.target.value,
							});
						}}
						color={
							errors?.responsibleEmail?.required ||
							errors?.responsibleEmail?.email
								? "danger"
								: ""
						}
						errors={errors?.responsibleEmail}
					/>
				</div>
				<div className="col-4 mt-2">
					<TextField
						type="text"
						label={teamManagement.phoneNumber}
						placeholder={"Enter Phone Number"}
						value={supplier.responsiblePhone}
						disabled={!ManualReservationsManagePermission}
						name="responsiblePhone"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setSupplier({
								...supplier,
								responsiblePhone: e.target.value,
							});
						}}
						color={errors?.responsiblePhone?.required ? "danger" : ""}
						errors={errors?.responsiblePhone}
					/>
				</div>
			</div>
		</div>
	);
};

export default ResponsibleDetails;
