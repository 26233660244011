import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { fetchCities, fetchCountries } from "services/lookups";
import { listHotels } from "services/staticRequests";
import Locale from "translations";
import { Edit, Eye } from "../../package/icons";
import { REQUEST_STATUS } from "../constants";
import { DetailsOverlay } from "../shared/DetailsOverlay";
import useFilters from "../shared/hooks/useFilters";
import useListData from "../shared/hooks/useListData";
import useLookups from "../shared/hooks/useLookups";
import { formatFilter } from "../shared/utils";

const INITIAL_FILTERS = {
	search: "",
	country: "",
	city: "",
	date: "",
	page: 1,
	status: "all",
};

export default function Hotels() {
	const { filters, setFilters, handleFilterChange, resetFilters } =
		useFilters(INITIAL_FILTERS);

	const formattedFilters = useMemo(() => formatFilter(filters), [filters]);

	const { list: hotelsList, meta } = useListData(listHotels, formattedFilters);

	const { lookups, loadCities } = useLookups(fetchCountries, fetchCities);

	const goToPage = (page) => {
		setFilters((prev) => ({ ...prev, page }));
	};

	const handleStatusFilter = (status) => {
		setFilters((prev) => ({ ...prev, status }));
	};
	const { backOffice, requests, booking } = Locale;

	return (
		<div className="static-requests">
			<div className="static-requests-hotels">
				<h1>{backOffice.hotels}</h1>
				<hr />

				<div className="hotel-filter">
					<div className="row align-items-end w-100">
						<div className="col-6 col-md-2 p-0">
							<TextField
								label={backOffice.search}
								placeholder={backOffice.TypeProductName}
								name="search"
								id="search"
								value={filters.search}
								onChange={(e) => handleFilterChange("search", e.target.value)}
							/>
						</div>
						<div className="col-6 col-md-2 p-0">
							<SelectField
								label={backOffice.country}
								placeholder={backOffice.country}
								name="country"
								id="country"
								options={lookups.countries.map((country) => ({
									...country,
									value: country.id,
									label: country.names?.en,
								}))}
								value={filters.country}
								onChange={(country) => {
									handleFilterChange("country", country);
									loadCities(country?.id);
								}}
							/>
						</div>
						<div className="col-6 col-md-2 p-0">
							<SelectField
								label={backOffice.city}
								placeholder={backOffice.city}
								name="city"
								id="city"
								value={filters.city}
								options={lookups.cities.map((city) => ({
									...city,
									value: city.id,
									label: city.names?.en,
								}))}
								onChange={(city) => handleFilterChange("city", city)}
							/>
						</div>
						<div className="col-6 col-md-2 mb-2">
							<DatePickerField
								label={backOffice.date}
								placeholder={backOffice.date}
								name="date"
								id="date"
								date={filters.date}
								onDateChange={(date) => handleFilterChange("date", date)}
							/>
						</div>
						<div
							className="col-6 col-md-2 px-2"
							style={{ marginBottom: "10px" }}
						>
							<button className="btn btn-primary" onClick={resetFilters}>
								{requests.clear}
							</button>
						</div>
					</div>
				</div>

				<hr />

				<ul className="filter-tabs">
					{Object.keys(REQUEST_STATUS).map((status) => (
						<li key={status}>
							<button
								className={`rounded-filter-tab ${
									filters.status === status ? "active" : ""
								}`}
								onClick={() => handleStatusFilter(status)}
							>
								{REQUEST_STATUS[status]}
							</button>
						</li>
					))}
				</ul>

				<div className="table-wrapper mt-4">
					<table className="request-table">
						<thead>
							<tr>
								<th scope="col">ID</th>
								<th scope="col">{backOffice.RequestedBy}</th>
								<th scope="col">{backOffice.hotelName}</th>
								<th scope="col">{backOffice.country}</th>
								<th scope="col">{backOffice.requestDate}</th>
								<th scope="col">{booking.Status}</th>
								<th scope="col">{backOffice.actions}</th>
							</tr>
						</thead>
						<tbody>
							{hotelsList.map((hotel) => {
								let status = hotel.status;

								if (status === "new") {
									status = "pending";
								}

								return (
									<tr key={hotel.id}>
										<td className="request-id">#{hotel.id}</td>
										<td>
											<div>
												<span>{hotel?.company?.name?.en || "--"}</span>
												<DetailsOverlay
													phoneNumber={`${hotel?.company?.phone?.phone_code} ${hotel?.company?.phone?.phone}`}
													country={hotel?.company?.country.name || "--"}
												/>
											</div>
										</td>
										<td>{hotel?.hotel_name || "--"}</td>
										<td>
											<div>{hotel?.country?.name || "--"}</div>
											<span className="request-city">
												{hotel?.city?.name || "--"}
											</span>
										</td>
										<td>{moment(hotel.created_at).format("DD MMM YYYY")}</td>
										<td>
											<span className={`request-status ${status}-request`}>
												{status || "--"}
											</span>
										</td>
										<td>
											{hotel.status === "new" ? (
												<Link to={`/static-requests/hotels/edit/${hotel.id}`}>
													<Edit />
												</Link>
											) : (
												<Link to={`/static-requests/hotels/view/${hotel.id}`}>
													<Eye />
												</Link>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				<div className="mt-3">
					<Pagination goTo={goToPage} meta={meta} />
				</div>
			</div>
		</div>
	);
}
