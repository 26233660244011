import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listCommericialDist } from "services/commercialDistribution";
import Locale from "translations";
import CommercialDistributionFilter from "./Filter";
import ServicesImages from "./ManageCommercialDistribution/Configuration/ServiceImg";

export default function CommercialDistribution() {
	const { backOffice, booking, Companies } = Locale;
	const [consumersList, setConsumersList] = useState([]);
	const [meta, setMeta] = useState(null);

	const [filters, setFilters] = useState({
		search: "",
		country: null,
		contract_start: null,
		contract_end: null,
		services: null,
		page: 1,
	});

	function handleFiltersInputs(value, filterKey) {
		setFilters({
			...filters,
			[filterKey]: value,
			page: null,
		});
	}

	function resetFilters() {
		setFilters({
			search: "",
			country: null,
			contract_start: null,
			contract_end: null,
			services: null,
			page: 1,
		});
	}

	function goTo(page) {
		setFilters({ ...filters, page: +page });
	}

	useEffect(() => {
		async function fetchCommircialDistList() {
			const filtersData = {
				name: filters?.search,
				country_id: filters?.country?.value,
				contract_date_from: filters?.contract_start
					? moment(filters?.contract_start).format("YYYY-MM-DD")
					: "",
				contract_date_to: filters?.contract_end
					? moment(filters?.contract_end).format("YYYY-MM-DD")
					: "",
				services: filters?.services?.label,
				page: filters?.page,
			};
			const res = await listCommericialDist(filtersData);
			if (res?.status === 200) {
				setConsumersList(res?.data?.data);
				setMeta(res?.data?.meta);
			}
		}
		fetchCommircialDistList();
	}, [filters]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h1 className="main-title ">{backOffice.CommercialDistribution}</h1>
				<ShowForPermission permission="Commercial-Distribution-Manage">
					<Link className="accept-btn" to="/add-commercial-distribution">
						{backOffice.addNew}
					</Link>
				</ShowForPermission>
			</div>

			<CommercialDistributionFilter
				filters={filters}
				handleFiltersInputs={handleFiltersInputs}
				resetFilters={resetFilters}
			/>
			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>ID</th>
							<th>{backOffice.Name}</th>
							<th>{backOffice.country}</th>
							<th>{backOffice.ContractDate}</th>
							<th>{backOffice.services}</th>
							<th>{booking.Status}</th>
							<th>{Companies.Control} </th>
						</tr>
					</thead>

					<tbody>
						{consumersList?.length > 0 ? (
							consumersList?.map((consumer) => {
								return (
									<tr key={consumer?.id}>
										<td>{consumer?.id}</td>
										<td>{consumer?.name}</td>
										<td>{consumer?.country_id?.name}</td>
										<td>
											{consumer?.contract_date_from
												? moment(consumer?.contract_date_from).format(
														"DD/MM/YYYY"
												  )
												: ""}
											{" - "}
											{consumer?.contract_date_to
												? moment(consumer?.contract_date_to).format(
														"DD/MM/YYYY"
												  )
												: ""}
										</td>
										<td>
											{consumer?.services?.length > 0
												? consumer?.services?.map((service) => (
														<ServicesImages
															key={service}
															name={service}
															className={"mx-2"}
														/>
												  ))
												: "-"}
										</td>
										<td>
											<span
												className={`${
													consumer?.status
														? "commercial-dist-active-pill"
														: "commercial-dist-inactive-pill"
												} `}
											>
												{consumer?.status ? "Active" : "In-Active"}
											</span>
										</td>

										<td>
											{" "}
											{/* <ShowForPermission permission="Commercial-Distribution-Manage"> */}
											<Link
												className="d-inline-block action--btn font-bold text-success"
												to={`/manage-commercial-distribution/${consumer.id}/details`}
											>
												{backOffice.Manage}
											</Link>
											{/* </ShowForPermission> */}
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</>
	);
}
