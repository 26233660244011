import { downloadFileWithUUID } from "helpers/downloadFileWithUUID";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { fetchPackage, fetchPaymentList } from "services/package";
import Locale from "translations";
import { Download } from "./icons";
export default function PackageView() {
	const [safaPackage, setSafaPackage] = useState(null);
	const [paymentList, setPaymentList] = useState([]);
	const { id } = useParams();
	const [noteModal, setNoteModal] = useState({
		isOpen: false,
		note: "",
	});
	const { backOffice, booking } = Locale;
	const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

	const toggleNoteModal = () => {
		setNoteModal({ isOpen: !noteModal.isOpen, note: "" });
	};

	const visaCountSum = () => {
		const data = safaPackage?.transfer_from || [];
		if (data.length === 0) return;

		return data.reduce((acc, curr) => {
			return acc + curr.visa_count;
		}, 0);
	};

	const shouldShowNote =
		safaPackage?.transfer_to?.length > 0 ||
		safaPackage?.transfer_from?.length > 0;

	useEffect(() => {
		(async () => {
			const packageRes = await fetchPackage(id);
			setSafaPackage(packageRes.data.data);

			const paymentListRes = await fetchPaymentList({ package_id: id });
			setPaymentList(paymentListRes.data.data);
		})();
	}, [id]);

	return (
		<div>
			<h1>{backOffice.PackageDetails}</h1>

			{safaPackage && (
				<>
					<table
						style={{
							width: "100%",
							color: "#4B465C",
							borderCollapse: "separate",
							borderSpacing: "0 20px",
						}}
					>
						<tbody>
							<tr>
								<td className="fs-5">{backOffice.companyName}:</td>
								<td className="fs-5">{safaPackage?.company}</td>
							</tr>
							<tr>
								<td className="fs-5">{backOffice.country}:</td>
								<td className="fs-5">{safaPackage?.country}</td>
							</tr>
							<tr>
								<td className="fs-5">{backOffice.SafaKey}:</td>
								<td className="fs-5">{safaPackage?.safa_key}</td>
							</tr>
							<tr>
								<td className="fs-5">{backOffice.Package}:</td>
								<td className="fs-5">{safaPackage?.package}</td>
							</tr>
							<tr>
								<td className="fs-5">{backOffice.PackagePrice}:</td>
								<td className="fs-5">
									{safaPackage?.package_price} {safaPackage?.currency?.symbol}
								</td>
							</tr>
							{shouldShowNote ? (
								<tr>
									<td className="fs-5">{backOffice.Notes}:</td>
									<td className="fs-5">
										{safaPackage?.transfer_to?.length > 0
											? `${safaPackage?.visa_count} visas were transferred from ${safaPackage?.transfer_to[0]?.from_package?.company}, Package Number ${safaPackage?.from_package?.id}`
											: `${safaPackage?.transfer_from[0]?.visa_count} visas were transferred to ${safaPackage?.transfer_from[0]?.to_package?.to_company?.name}, Package Number ${safaPackage?.transfer_from[0]?.to_package?.id}`}
										{safaPackage?.transfer_from?.length > 0 && (
											<button
												className="bg-transparent text-info fs-6"
												onClick={() => setIsTransferModalOpen(true)}
											>
												{backOffice.ShowMore}
											</button>
										)}
									</td>
								</tr>
							) : null}
							<tr>
								<td className="fs-5">{booking.Payments}:</td>
							</tr>
						</tbody>
					</table>

					<div className="px-3 our-border border-top-0 overflow-auto mt-3">
						<table
							className="custom-table back-office-table table table-striped mb-0"
							style={{ minWidth: 1250 }}
						>
							<thead>
								<tr>
									<th>NO</th>
									<th>{backOffice.PaidAmount}</th>
									<th>{backOffice.currency}</th>
									<th>{backOffice.AddedOn}</th>
									<th>{backOffice.paymentMethod}</th>
									<th>{backOffice.InstallmentDate}</th>
									<th>{backOffice.Document}</th>
									<th>{backOffice.AddBy}</th>
									<th>{backOffice.Notes}</th>
								</tr>
							</thead>

							<tbody>
								{paymentList?.map((payment, index) => (
									<tr key={payment.id}>
										<td>{index + 1}</td>
										<td>{payment?.amount}</td>
										<td>{payment?.currency?.symbol}</td>
										<td>{payment?.created_at}</td>
										<td>{payment?.payment_method?.name}</td>
										<td>{payment?.batch_date || "-"}</td>
										<td>
											{payment?.file_id ? (
												<button
													className="bg-transparent"
													onClick={() => downloadFileWithUUID(payment?.file_id)}
												>
													<Download />
												</button>
											) : null}
										</td>
										<td>{payment?.safa_visa_package?.creator?.name}</td>
										<td>
											{payment?.note && (
												<>
													<span className="mr-2">
														{payment?.note.split(" ").slice(0, 3).join(" ")}
														...
													</span>

													<button
														className="bg-transparent text-info fs-6"
														onClick={() =>
															setNoteModal({
																isOpen: true,
																note: payment?.note,
															})
														}
													>
														Show More
													</button>
												</>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			)}

			<Modal
				isOpen={isTransferModalOpen}
				toggle={() => setIsTransferModalOpen(!isTransferModalOpen)}
			>
				<ModalHeader
					toggle={() => setIsTransferModalOpen(!isTransferModalOpen)}
				>
					Visa Transfer
				</ModalHeader>

				<ModalBody>
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Transferd Visa Count</th>
								<th>To Company</th>
								<th>Package Id</th>
							</tr>
						</thead>

						<tbody>
							{safaPackage?.transfer_from?.map((transfer, index) => (
								<tr key={index}>
									<td>{transfer.visa_count}</td>
									<td>{transfer?.to_package?.to_company?.name}</td>
									<td>{transfer?.to_package?.id}</td>
								</tr>
							))}
						</tbody>
					</table>
				</ModalBody>

				<ModalFooter>
					<button
						className="btn btn-primary"
						onClick={() => setIsTransferModalOpen(false)}
					>
						Close
					</button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={noteModal.isOpen} toggle={toggleNoteModal}>
				<ModalHeader toggle={toggleNoteModal}>Payment Note</ModalHeader>

				<ModalBody>
					<p>{noteModal.note}</p>
				</ModalBody>

				<ModalFooter>
					<button className="btn btn-primary" onClick={toggleNoteModal}>
						Close
					</button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
