import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { listTransfers, transfersExportToExcel } from "services/Transfers";
import Locale from "translations";
import FilterTransfers from "./FilterTransfer";
import ListTransfers from "./ListTransfers";

function Transfers() {
	const [TransfersList, setTransfersList] = useState([]);
	const [meta, setmeta] = useState([]);
	const { backOffice } = Locale;
	const [filter, setFilter] = useState({
		search: "",
		/* 		FreeCancelationDate: null,
		 */
		Status: null,
		page: 1,
		booking_date_from: null,
		booking_date_to: null,
		source: null,
	});

	async function exportBookingExcel() {
		const filteredData = {
			...filter,
			status: filter?.Status?.label,
			booking_date_from: filter.booking_date_from
				? moment(filter.booking_date_from).format("YYYY-MM-DD")
				: null,
			booking_date_to: filter.booking_date_to
				? moment(filter.booking_date_to).format("YYYY-MM-DD")
				: null,
			source: filter?.source?.value,
		};
		const res = await transfersExportToExcel({
			...filteredData,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					//type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					type: res.headers["content-type"],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Bookings Transfers-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	useEffect(() => {
		const datafilter = {
			search: filter.search,
			booking_date_from: filter.booking_date_from
				? moment(filter?.booking_date_from).format("YYYY-MM-DD")
				: null,
			/* FreeCancelationDate: filter.FreeCancelationDate
				? moment(filter?.FreeCancelationDate).format("YYYY-MM-DD")
				: null, */
			booking_date_to: filter.booking_date_to
				? moment(filter?.booking_date_to).format("YYYY-MM-DD")
				: null,
			status: filter?.Status?.label,
			source: filter.source?.value,
		};
		const fetchTransfers = async (filter, page) => {
			const data = await listTransfers(filter, page);
			if (data.status === 200) {
				setTransfersList(data.data.data);
				setmeta(data.data.meta);
			}
		};
		fetchTransfers(datafilter, filter.page);
	}, [filter]);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<>
			<div className="d-flex justify-content-between">
				<h3 className="mb-2">{backOffice.TransferReservations}</h3>

				<button
					className="btn btn-primary"
					onClick={() => exportBookingExcel()}
				>
					{backOffice.ExportToExcel}
				</button>
			</div>
			<FilterTransfers setFilter={setFilter} filter={filter} />
			<ListTransfers TransfersList={TransfersList} meta={meta} goTo={goTo} />
		</>
	);
}

export default Transfers;
