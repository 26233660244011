
import { useState } from 'react';
// React-Router-Dom
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Icons
import { TrashIcon } from 'components/Icons';
// Module Components
import ConfirmModal from './ConfirmModal';
// Custom Hook
import AddCompanyModal from './AddCompanyModal';
// --------------------------------------------------------------------------------
export default function CompaniesList(props) {

  const {
    data,
    setData,
    companiesOptions,
  } = props

  // *** Hooks
  const { backOffice, safaVisa } = Locale;

  // *** State
  const [companyInfo, setCompanyInfo] = useState({
    company_id: '',
  });

  const [isOpen, setIsOpen] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  // *** Functions
  const toggle = (data) => {
    setIsOpen((prev) => !prev);
    setCompanyInfo({ ...data });
  };

  const handleSaveCompany = () => {
    let dataClone = { ...data }
    // to handle if selected any company from DropDown
    console.log(dataClone?.companies_id)
    const existedItem = dataClone?.companies_id?.find(item => item?.id === companyInfo?.company_id?.id)
    if (existedItem) {
      store.addNotification({
        message: safaVisa?.itemAlreadyExist,
        title: safaVisa?.itemAlreadyExist,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    if (!existedItem) {
      dataClone['companies_id'].push(companyInfo.company_id)
      setData(dataClone)
    }
    toggle(null)
  }

  const handleToggleConfirmModal = (data) => {
    setIsOpenConfirm(!isOpenConfirm)
    setCompanyInfo({ ...data })
  }

  const handleConfirmAction = () => {
    let pricingDataClone = { ...data }
    setData({
      ...pricingDataClone,
      companies_id: pricingDataClone['companies_id']?.filter(item => item?.id !== companyInfo?.id)
    })
    handleToggleConfirmModal(null)
  }

  const handleCancelAction = () => handleToggleConfirmModal()

  // -------- JSX Code -------
  return (
    <>
      <div className=''  >
        <div className="col-12 d-flex justify-content-between align-items-center gap-10 mt-5">
          <h4 className="main-title mb-0">{safaVisa.company}</h4>
          <button
            disabled={!data?.countries_id?.length}
            className="btn  add-btn py-2"
            onClick={() => setIsOpen(true)}
          >
            <i className="fas fa-plus text-white fa-md"></i>{" "}
            {safaVisa.add}
          </button>
        </div>

        {/* Table */}
        <div className="our-border table-responsive  mt-4">
          <table className="custom-table back-office-table table table-striped mb-0">
            <thead>
              <tr>
                <th>{safaVisa.id}</th>
                <th> {safaVisa.title}</th>
                <th className='text-center'>{safaVisa.actions}</th>
              </tr>
            </thead>

            <tbody>
              {data?.companies_id?.length > 0 ? (
                data?.companies_id?.map((row) =>
                  <tr>

                    <td><p className='id-text'> {row?.id} </p></td>

                    <td> {row?.name}  </td>

                    <td >
                      <div className='d-flex justify-content-center gap-2 align-items-center'>
                        <span className='pointer' onClick={() => handleToggleConfirmModal(row)} >
                          <TrashIcon color='#EA5455' />
                        </span>
                      </div>
                    </td>

                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="product-no-data">
                      <i className="fas fa-info-circle fa-lg"></i>{" "}
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div >
      </div>

      <AddCompanyModal
        isOpen={isOpen}
        toggle={toggle}
        companiesOptions={companiesOptions}
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        onSave={handleSaveCompany}
      />

      <ConfirmModal
        isOpen={isOpenConfirm}
        toggle={handleToggleConfirmModal}
        confirmBtnText={safaVisa?.confirm}
        messageText={safaVisa?.cannotGoBack}
        icon={<TrashIcon color='#EA5455' />}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </>
  )
}

