import { useState } from "react";
// Transaltion
import Locale from "translations";
// React Router Dom
import { useRouteMatch } from "react-router-dom";
// Icons
import { ReactComponent as CountryIcon } from "assets/images/country.svg";
import {
	ActivityLogs,
	AddNewIcon,
	AirlineIcon,
	AirlineRequestIcon,
	AirlineReservationIcon,
	AllBookings,
	AllIcon,
	BankTransferIcon,
	BookingsIcon,
	CheckMarkIcon,
	CommissionsIcon,
	ContractManagementIcon,
	DatePickerIcon,
	DeviceMessageIcon,
	FinancialIcon,
	GeneralConfigurationIcon,
	GroundServiceIcon,
	ManualReservationIcon,
	ManualSupplier,
	MarketingOfferIcon,
	PlatformCommissionsIcon,
	PlusIcon,
	PointIcon,
	PropertyIcon,
	SafaVisaIcon,
	SeasonsIcon,
	StatisticsIcon,
	SubscriptionPlanIcon,
	SuppliersIcon,
	TeamIcon,
	ToolsIcon,
	TourismCompanyIcon,
	TransportationIcon,
	TravelerIcon,
	VisaIcon,
} from "./Icons";
// Custom Hooks
import useShowPermission from "hooks/useShowPermission";

// -------------------------------------------
function useSideBarRoutes() {
	let { url } = useRouteMatch();

	const { backOffice, financial, booking, services, vendor } = Locale;

	const teamManagementUsersViewPermission = useShowPermission({
		permission: ["Team-Management-Users-View", "Team-Management-Users-Manage"],
	});

	const [routes, setRoutes] = useState([
		{
			id: 1,
			icon: <StatisticsIcon />,
			text: backOffice.Dashboard,
			url: "/dashboard",
			permission: ["Bookings-View", "Bookings-Manage"],
			show: url.includes("dashboard"),
			active: url.includes("dashboard"),
		},
		{
			id: 24,
			url: "/umrah-forum",
			icon: <AllBookings />,
			text: backOffice.UmrahForum,
			subNavs: [
				{
					id: 192,
					url: "/umrah-forum-applicants",
					text: backOffice.applicants,
					show: url.includes("/umrah-forum-applicants"),
					active: url.includes("umrah-forum-applicants"),
				},
				{
					id: 193,
					url: "/umrah-forum-recommendations",
					text: backOffice.recommendations,
					show: url.includes("/umrah-forum-recommendations "),
					active: url.includes("umrah-forum-recommendations "),
				},
				{
					id: 194,
					url: "/umrah-forum-accepted",
					text: backOffice.accepted,
					show: url.includes("/umrah-forum-accepted  "),
					active: url.includes("umrah-forum-accepted  "),
				},
			],
		},
		{
			id: 2,
			icon: <TeamIcon />,
			text: `${backOffice.teamManagement}`,
			url: `/management-team/${
				teamManagementUsersViewPermission ? "users" : "groups"
			}`,
			permission: [
				"Team-Management-Users-View",
				"Team-Management-Users-Manage",
				"Team-Management-Groups-View",
				"Team-Management-Groups-Manage",
			],
			show: url.includes("management-team"),
			active: url.includes("management-team"),
		},
		{
			id: 4,
			icon: <TourismCompanyIcon />,
			text: `${backOffice.tourismCompany}`,
			url: "tourism-company",
			permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
			show: url.includes("tourism-company"),
			active: url.includes("tourism-company"),
			subNavs: [
				{
					id: 44,
					url: "/tourism-company",
					text: `${backOffice.allTourismCompanies}`,
					icon: <AllIcon />,
					permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
				},
				{
					id: 45,
					url: "/tourism-company/add-company",
					text: `${backOffice.newTourismCompany}`,
					icon: <AddNewIcon />,
					permission: ["Tourism-Company-Manage"],
				},
			],
		},
		// Safa Visa Module
		{
			id: 30,
			icon: <SafaVisaIcon />,
			text: `${backOffice.safaVisa}`,
			url: "safa-visa",
			// permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
			show: url.includes("safa-visa"),
			active: url.includes("safa-visa"),
			subNavs: [
				{
					id: 300,
					url: "/pricing",
					text: `${backOffice.pricingManagement}`,
					icon: null,
					// permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
					children: [
						{
							id: 305,
							url: "/safaVisa-defaultPricing",
							text: `${backOffice.defaultPricing}`,
							icon: <PointIcon />,
							// permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
						},
						{
							id: 307,
							url: "/safaVisa-packages",
							text: `${backOffice.packages}`,
							icon: <PointIcon />,
							// permission: ["Tourism-Company-Manage"],
						},
					],
				},
				{
					id: 301,
					url: "markting",
					text: `${backOffice.marktingManagement}`,
					icon: null,
					// permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
					children: [
						{
							id: 304,
							url: "/safaVisa-discount",
							text: `${backOffice.discount}`,
							icon: <PointIcon />,
							// permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
						},
						{
							id: 306,
							url: "/safaVisa-targetedCampaigns",
							text: `${backOffice.targetedCampaigns}`,
							icon: <PointIcon />,
							// permission: ["Tourism-Company-Manage"],
						},
						{
							id: 308,
							url: "/safaVisa-promoCode",
							text: `${backOffice.promoCode}`,
							icon: <PointIcon />,
							// permission: ["Tourism-Company-Manage"],
						},
					],
				},
			],
		},
		{
			id: 5,
			icon: <BookingsIcon />,
			text: `${backOffice.Bookings}`,
			url: "/bookings",
			permission: [
				"Hotels-Bookings-View",
				"Hotels-Bookings-Manage",
				"Tours-Bookings-View",
				"Tours-Bookings-Manage",
				"Transfers-Bookings-View",
				"Transfers-Bookings-Manage",
				"View-Sold-Visas",
				"Manage-Sold-Visas",
			],
			show: url.includes("bookings"),
			active: url.includes("bookings"),
			subNavs: [
				{
					id: 55,
					url: "/bookings",
					text: `Hotels`,
					icon: <BookingsIcon />,
					permission: ["Hotels-Bookings-View", "Hotels-Bookings-Manage"],
				},
				{
					id: 56,
					url: "/bookings-tours",
					text: `Tours`,
					icon: <BookingsIcon />,
					permission: ["Tours-Bookings-View", "Tours-Bookings-Manage"],
				},
				{
					id: 57,
					url: "/bookings-transfers",
					text: `Transfers`,
					icon: <BookingsIcon />,
					permission: ["Transfers-Bookings-View", "Transfers-Bookings-Manage"],
				},
				{
					id: 58,
					url: "/bookings-sold-visas",
					text: `Sold Visas`,
					icon: <BookingsIcon />,
					permission: ["View-Sold-Visas", "Manage-Sold-Visas"],
				},
			],
		},
		{
			id: 16,
			icon: <ManualReservationIcon />,
			text: `${backOffice.manualReservation}`,
			url: "manual-reservations",
			permission: [
				"SBS-Manual-Reservations-View",
				"SBS-Manual-Reservations-Manage",
				"Offline-Manual-Reservations-View",
				"Offline-Manual-Reservations-Manage",
				"Sudan-Manual-Reservations-View",
				"Sudan-Manual-Reservations-Manage",
			],
			show: url.includes("manual-reservations"),
			active: url.includes("manual-reservations"),
			subNavs: [
				{
					id: 160,
					url: "/manual-reservations/sbs-reservations",
					text: `${backOffice.sbsReservation}`,
					icon: <DatePickerIcon />,
					permission: [
						"SBS-Manual-Reservations-View",
						"SBS-Manual-Reservations-Manage",
					],
				},
				{
					id: 161,
					url: "/manual-reservations/offline-reservations?page=1",
					text: `${backOffice.offlineReservation}`,
					icon: <DatePickerIcon />,
					permission: [
						"Offline-Manual-Reservations-View",
						"Offline-Manual-Reservations-Manage",
					],
				},
				{
					id: 162,
					url: "/manual-reservations/sudan-reservations",
					text: `${"Sudan"}`,
					icon: <DatePickerIcon />,
					permission: [
						"Sudan-Manual-Reservations-View",
						"Sudan-Manual-Reservations-Manage",
					],
				},
				{
					id: 200,
					url: "/manual-reservations/Saudi-reservations",
					text: `${"Saudi"}`,
					icon: <DatePickerIcon />,
					permission: [
						"Saudi-Manual-Reservations-View",
						"Saudi-Manual-Reservations-Manage",
					],
				},
			],
		},
		{
			id: 6,
			icon: <AllIcon />,
			text: `${backOffice.requests}`,
			url: "/requests",
			permission: [
				"Visa-Requests-View",
				"Visa-Requests-Manage",
				"View-All-Visas",
				"Transportation-Requests-View",
				"Transportation-Requests-Manage",
				"Ground-Service-Requests-View",
				"Ground-Service-Requests-Manage",
			],
			show: url.includes("/requests"),
			active: url.includes("/requests"),
			subNavs: [
				{
					id: 60,
					url: "/online-visa/requests/waiting",
					text: `${backOffice.allVisaRequests}`,
					icon: <VisaIcon />,
					permission: ["Visa-Requests-View", "Visa-Requests-Manage"],
				},
				{
					id: 61,
					url: "/online-visa/requests/all-visas",
					text: `${backOffice.allVisas}`,
					icon: <VisaIcon />,
					permission: ["View-All-Visas"],
				},
				{
					id: 62,
					url: "/requests/transportation/waiting",
					text: `${backOffice.Transportation}`,
					icon: <TransportationIcon />,
					permission: [
						"Transportation-Requests-View",
						"Transportation-Requests-Manage",
					],
				},
				{
					id: 63,
					url: "/requests/ground/waiting",
					text: `${backOffice.GroundService}`,
					icon: <GroundServiceIcon />,
					permission: [
						"Ground-Service-Requests-View",
						"Ground-Service-Requests-Manage",
					],
				},
			],
		},

		/* 		{
			id: 7,
			icon: <ContractManagementIcon />,
			text: `${backOffice.contractManagement}`,
			url: "/contract",
			permission: [
				"Contract-Requests-View",
				"Contract-Requests-Manage",
				"Contract-List-View",
				"Contract-List-Manage",
			],
			show: url.includes("contract"),
			active: url.includes("contract"),
			subNavs: [
				{
					id: 70,
					url: "/contract-request?page=1",
					text: `${backOffice.contractRequest}`,
					icon: <ContractRequestIcon />,
					permission: ["Contract-Requests-View", "Contract-Requests-Manage"],
				},
				{
					id: 71,
					url: "/contract-list?page=1",
					text: `${backOffice.contractList}`,
					icon: <AllIcon />,
					permission: ["Contract-List-View", "Contract-List-Manage"],
				},
			],
		}, */

		{
			id: 8,
			icon: <CommissionsIcon />,
			text: `${backOffice.commissions}`,
			url: "-commissions-",
			permission: [
				"Platform-Commission-View",
				"Platform-Commission-Manage",
				"Hotel-Commission-View",
				"Hotel-Commission-Manage",
			],
			show: url.includes("-commissions-"),
			active: url.includes("-commissions-"),
			subNavs: [
				{
					id: 80,
					url: "/platform-commissions-list",
					text: `${backOffice.platformCommissions}`,
					icon: <PlatformCommissionsIcon />,
					permission: [
						"Platform-Commission-View",
						"Platform-Commission-Manage",
					],
				},
				// {
				// 	id: 81,
				// 	url: "/hotel-commissions-list",
				// 	text: `${backOffice.hotelCommissions}`,
				// 	icon: <HotelCommissionsIcon />,
				// 	permission: ["Hotel-Commission-View", "Hotel-Commission-Manage"],
				// },
			],
		},
		{
			id: 9,
			icon: <SeasonsIcon />,
			text: `${backOffice.seasons}`,
			url: "/seasons?page=1",
			permission: ["Season-View", "Season-Manage"],
			show: url.includes("/seasons"),
			active: url.includes("/seasons"),
		},
		{
			id: 10,
			icon: <GeneralConfigurationIcon />,
			text: `${backOffice.generalConfiguration}`,
			url: "-configuration",
			permission: [
				"Visa-Configuration-View",
				"Visa-Configuration-Manage",
				"Ground-Configuration-View",
				"Ground-Configuration-Manage",
				"Transportation-Configuration-View",
				"Transportation-Configuration-Manage",
				"Bank-Transfer-Details-View",
				"Bank-Transfer-Details-Manage",
				"Exchange-Rate-View",
				"Exchange-Rate-Manage",
				"View-Countries-Configuration",
				"Manage-Countries-Configuration",
				"View-Attraction",
				"Manage-Attraction",
			],
			show: url.includes("-configuration"),
			active: url.includes("-configuration"),
			subNavs: [
				{
					id: 100,
					url: "/visa-configuration",
					icon: <VisaIcon />,
					text: `${backOffice.visaConfiguration}`,
					permission: ["Visa-Configuration-View", "Visa-Configuration-Manage"],
				},
				{
					id: 101,
					url: "/Ground-configuration",
					icon: <GroundServiceIcon />,
					text: `${backOffice.Groundconfiguration}`,
					permission: [
						"Ground-Configuration-View",
						"Ground-Configuration-Manage",
					],
				},
				{
					id: 102,
					url: "/Transportation-configuration",
					icon: <TransportationIcon />,
					text: `${services.transportation}`,
					permission: [
						"Transportation-Configuration-View",
						"Transportation-Configuration-Manage",
					],
				},
				{
					id: 103,
					url: "/bank-configuration",
					icon: <BankTransferIcon />,
					text: `${backOffice.BankTransferDetails}`,
					permission: [
						"Bank-Transfer-Details-View",
						"Bank-Transfer-Details-Manage",
					],
				},
				{
					id: 104,
					url: "/country-configuration",
					icon: <CountryIcon fill="#99a4b1" />,
					text: `${backOffice.country}`,
					permission: [
						"View-Countries-Configuration",
						"Manage-Countries-Configuration",
					],
				},
				{
					id: 105,
					url: "/exchange-rate",
					icon: <CommissionsIcon />,
					text: `${backOffice.exchangeRate}`,
					permission: ["Exchange-Rate-View", "Exchange-Rate-Manage"],
				},
				{
					id: 106,
					icon: <AirlineIcon />,
					text: backOffice.Attractions,
					url: "/attractions",
					permission: ["View-Attraction", "Manage-Attraction"],
					// show: url.includes("/attractions"),
					// active: url.includes("/attractions"),
				},
			],
		},
		{
			id: 11,
			icon: <SuppliersIcon />,
			text: `${backOffice.suppliers}`,
			url: "/suppliers",
			permission: [
				"Suppliers-View",
				"Suppliers-Manage",
				"Property-Management-View",
				"Property-Management-Manage",
				"Contract-Requests-View",
				"Contract-Requests-Manage",
				"Contract-List-View",
				"Contract-List-Manage",
				"Manual-Suppliers-View",
				"Manual-Suppliers-Manage",
			],
			show: url.includes("/suppliers"),
			active: url.includes("/suppliers"),
			subNavs: [
				{
					id: 100,
					url: "/suppliers",
					icon: <SuppliersIcon />,
					text: backOffice.OnlineSupplierCompanies,
					permission: ["Suppliers-View", "Suppliers-Manage"],
				},
				{
					id: 3,
					icon: <PropertyIcon />,
					text: `${backOffice.propertyManagement}`,
					url: "/property-management?page=1",
					permission: [
						"Property-Management-View",
						"Property-Management-Manage",
					],
					show: url.includes("property-managemen"),
					active: url.includes("property-managemen"),
				},
				{
					id: 7,
					icon: <ContractManagementIcon />,
					text: `${backOffice.contractManagement}`,
					url: "/contract-mangment",
					permission: [
						"Contract-Requests-View",
						"Contract-Requests-Manage",
						"Contract-List-View",
						"Contract-List-Manage",
					],
					show: url.includes("contract"),
					active: url.includes("contract"),
				},
				{
					id: 15,
					icon: <ManualSupplier />,
					text: backOffice.ManualSupplierCompanies,
					url: "/manual-suppliers",
					permission: ["Manual-Suppliers-View", "Manual-Suppliers-Manage"],
					show: url.includes("manual-suppliers"),
					active: url.includes("manual-suppliers"),
				},
			],
		},
		{
			id: 12,
			icon: <ActivityLogs />,
			text: `${backOffice.activityLogs}`,
			url: "/activityLogs",
			permission: ["Activity-Logs-Management-View"],
			show: url.includes("/activityLogs"),
			active: url.includes("/activityLogs"),
		},
		{
			id: 13,
			icon: <CheckMarkIcon />,
			text: `${backOffice.vendor}`,
			url: "/vendor",
			permission: ["Vendors-Management-View", "Vendors-Management-Manage"],
			show: url.includes("/vendor"),
			active: url.includes("/vendor"),
		},
		{
			id: 14,
			icon: <ToolsIcon />,
			text: `${backOffice.tools}`,
			url: "/marketing-offers-list",
			permission: [
				"Marketing-Offers-Management-View",
				"Marketing-Offers-Management-Manage",
				"Popup-Ads-View",
				"Popup-Ads-Manage",
				"promo-View",
				"promo-Manage",
			],
			show: url.includes("/marketing-offers-list"),
			active: url.includes("/marketing-offers-list"),
			subNavs: [
				{
					id: 140,
					url: "/marketing-offers-list",
					icon: <MarketingOfferIcon />,
					text: backOffice.MarketingOffers,
					permission: [
						"Marketing-Offers-Management-View",
						"Marketing-Offers-Management-Manage",
					],
				},
				{
					id: 141,
					url: "/popup-ads",
					icon: <MarketingOfferIcon />,
					text: backOffice.PopupAds,
					permission: ["Popup-Ads-View", "Popup-Ads-Manage"],
				},
				{
					id: 142,
					url: "/promo",
					icon: <MarketingOfferIcon />,
					text: backOffice.Promo,
					permission: ["promo-View", "promo-Manage"],
				},
			],
		},

		{
			id: 17,
			icon: <TeamIcon />,
			text: "B2C",
			url: "/b2c-users",
			permission: [
				"B2C-Users-View",
				"B2C-Users-Manage",
				"B2C-Bookings-View",
				"B2C-Bookings-Manage",
				"B2C-Transactions-View",
				"B2C-Transactions-Manage",
				"B2C-Commission-View",
				"B2C-Commission-Manage",
				"B2C-Package-View",
				"B2C-Package-Manage",
			],
			show: false,
			active: false,
			subNavs: [
				{
					id: 170,
					url: "/b2c-users",
					icon: <TravelerIcon fill="#99a4b1" />,
					text: `${backOffice.users}`,
					permission: ["B2C-Users-View", "B2C-Users-Manage"],
				},
				{
					id: 171,
					url: "/all-bookings",
					icon: <AllBookings />,
					text: `${backOffice.allBooking}`,
					permission: ["B2C-Bookings-View", "B2C-Bookings-Manage"],
				},
				{
					id: 172,
					url: "/all-transactions",
					icon: <CommissionsIcon />,
					text: backOffice.AllTransctions,
					permission: ["B2C-Transactions-View", "B2C-Transactions-Manage"],
				},
				{
					id: 173,
					url: "/b2c-Commission",
					icon: <CommissionsIcon />,
					text: backOffice.commission,
					permission: ["B2C-Commission-View", "B2C-Commission-Manage"],
				},
				{
					id: 174,
					url: "/b2c-packages",
					icon: <CommissionsIcon />,
					text: backOffice.PackagesBooking,
					permission: ["B2C-Package-View", "B2C-Package-Manage"],
				},
				{
					id: 175,
					url: "/b2c-ota-requests",
					icon: <AirlineRequestIcon />,
					text: backOffice.OTARequests,
					permission: ["B2C-Requests-View", "B2C-Requests-Manage"],
				},
			],
		},
		{
			id: 18,
			icon: <FinancialIcon />,
			text: booking.Financial,
			url: "/Financial",
			permission: [
				"Financial-Online-Transactions-View",
				"Financial-Companies-Wallets-View",
				"Financial-Companies-Wallets-Manage",
			],
			show: false,
			active: false,
			subNavs: [
				// {
				// 	id: 180,
				// 	permission: [],
				// 	url: "/Financial-onlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OnlineSuppliers}`,
				// },
				// {
				// 	id: 181,
				// 	permission: [],
				// 	url: "/Financial-offlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OfflineSuppliers}`,
				// },
				// {
				// 	id: 182,
				// 	permission: [],
				// 	url: "/Financial-platforms",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.Platforms}`,
				// },
				{
					id: 183,
					url: "/online-transactions",
					icon: <FinancialIcon />,
					text: `${financial.onlineTranactions}`,
					permission: ["Financial-Online-Transactions-View"],
				},
				// {
				// 	id: 181,
				// 	permission: [],
				// 	url: "/Financial-onlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OnlineSuppliers}`,
				// },
				// {
				// 	id: 182,
				// 	permission: [],
				// 	url: "/Financial-offlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OfflineSuppliers}`,
				// },
				// {
				// 	id: 172,
				// 	permission: [],
				// 	url: "/Financial-platforms",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.Platforms}`,
				// },
				{
					id: 184,
					url: "/company-wallet",
					icon: <FinancialIcon />,
					text: `${backOffice.ComapnyWallet}`,
					permission: [
						"Financial-Companies-Wallets-View",
						"Financial-Companies-Wallets-Manage",
					],
				},
			],
		},

		{
			id: 19,
			icon: <AirlineIcon />,
			text: backOffice.Airline,
			url: "/airline-reservations",
			permission: [
				"View-Airlines-Reservations",
				"View-Airlines-Requests",
				"Manage-Airlines-Reservations",
				"Manage-Airlines-Requests",
			],

			subNavs: [
				{
					id: 190,
					url: "/airline-reservations",
					icon: <AirlineReservationIcon />,
					text: backOffice.reservations,
					permission: [
						"View-Airlines-Reservations",
						"Manage-Airlines-Reservations",
					],
					show: url.includes("/airline-reservations"),
					active: url.includes("/airline-reservations"),
				},
				{
					id: 191,
					url: "/airline-requests",
					icon: <AirlineRequestIcon />,
					text: backOffice.requests,
					permission: ["View-Airlines-Requests", "Manage-Airlines-Requests"],
					show: url.includes("/airline-requests"),
					active: url.includes("/airline-requests"),
				},
			],
		},

		{
			id: 20,
			icon: <CheckMarkIcon />,
			text: backOffice.CommercialDistribution,
			url: "/commercial-distribution",
			permission: [
				"Commercial-Distribution-View",
				"Commercial-Distribution-Manage",
			],
			show: url.includes("/commercial-distribution"),
			active:
				url.includes("/commercial-distribution") ||
				url.includes("/manage-commercial-distribution"),
		},
		{
			id: 21,
			icon: <SubscriptionPlanIcon />,
			text: backOffice.SubscriptionPlan,
			url: "/subscription-package-plan",
			permission: ["Subscription-Plan-Manage", "Subscription-Plan-View"],
			show: url.includes("/subscription-plan"),
			active: url.includes("/subscription-plan"),
		},
		{
			id: 22,
			icon: <PlusIcon />,
			text: backOffice.Package,
			url: "/package",
			show: url.includes("/package"),
			permission: [
				"SafaVisa-Packages-List",
				"SafaVisa-Packages-View",
				"SafaVisa-Packages-Add",
				"SafaVisa-Packages-Edit",
				"SafaVisa-Packages-Delete",
				"SafaVisa-Package-Payment-Add",
				"SafaVisa-Package-Payment-Edit",
			],
			active: url.includes("/package"),
		},
		{
			id: 23,
			icon: <DeviceMessageIcon />,
			text: backOffice.StaticRequests,
			url: "/static-requests",
			show: url.includes("/static-requests"),
			permission: [
				"SafaVisa-Packages-List",
				"SafaVisa-Packages-View",
				"SafaVisa-Packages-Add",
				"SafaVisa-Packages-Edit",
				"SafaVisa-Packages-Delete",
				"SafaVisa-Package-Payment-Add",
				"SafaVisa-Package-Payment-Edit",
			],
			active: url.includes("/static-requests"),
			subNavs: [
				{
					id: 192,
					url: "/static-requests/hotels",
					icon: <PointIcon />,
					text: backOffice.hotels,
					show: url.includes("/static-requests/hotels"),
					permission: [
						"SafaVisa-Packages-List",
						"SafaVisa-Packages-View",
						"SafaVisa-Packages-Add",
						"SafaVisa-Packages-Edit",
						"SafaVisa-Packages-Delete",
						"SafaVisa-Package-Payment-Add",
						"SafaVisa-Package-Payment-Edit",
					],
					active: url.includes("/static-requests/hotels"),
				},
				{
					id: 193,
					url: "/static-requests/flights",
					icon: <PointIcon />,
					text: backOffice.Flights,
					show: url.includes("/static-requests/flights"),
					permission: [
						"SafaVisa-Packages-List",
						"SafaVisa-Packages-View",
						"SafaVisa-Packages-Add",
						"SafaVisa-Packages-Edit",
						"SafaVisa-Packages-Delete",
						"SafaVisa-Package-Payment-Add",
						"SafaVisa-Package-Payment-Edit",
					],
					active: url.includes("/static-requests/flights"),
				},
				{
					id: 194,
					url: "/static-requests/transporters",
					icon: <PointIcon />,
					text: backOffice.Transporters,
					show: url.includes("/static-requests/transporters"),
					permission: [
						"SafaVisa-Packages-List",
						"SafaVisa-Packages-View",
						"SafaVisa-Packages-Add",
						"SafaVisa-Packages-Edit",
						"SafaVisa-Packages-Delete",
						"SafaVisa-Package-Payment-Add",
						"SafaVisa-Package-Payment-Edit",
					],
					active: url.includes("/static-requests/transporters"),
				},
			],
		},
	]);

	return {
		routes,
		setRoutes,
	};
}

export default useSideBarRoutes;
