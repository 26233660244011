
import { useLayoutEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Icons
import { EditIcon, LeftArrowIcon, TrashIcon } from 'components/Icons';
// Module Components
import ConfirmModal from '../../shared/ConfirmModal';
// Services
import { addPackagePricing, editPackagePricing } from 'services/safaVisa';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
import AddEditVisaTypeModal from './AddEditVisaTypeModal';
// Moment
import moment from 'moment';
// -------------------------------------------------------------------
export default function PackagesVisaList(props) {
  const {
    packageData,
    setPackageData,
    setActiveTab,
    activeTab,
    tabs,
    allRequiredInputs,
    setErrors
    // setAllRequiredInputs
    // setTabs
  } = props

  // *** Hooks
  let history = useHistory();
  let { id, status } = useParams();

  const { initialPricingData, visaTypesOptions } = useSafaVisaData();

  const { backOffice, safaVisa } = Locale;

  const [visaInfo, setVisaInfo] = useState({
    visa_type: '',
    amount: ''
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(packageData?.quotas?.length === 0);
  // const [errors, setErrors] = useState();

  // const prevFormRequiredInputs = allRequiredInputs.filter(el => el.includes('paid_price'))

  // *** Functions
  const checkSubscriptionTypesFormErrors = () => {
    let submitError = {};
    allRequiredInputs?.requiredPricingInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    // setErrors((prev) => ({ ...prev, ...submitError }));
    setErrors(submitError);
    return submitError;
  }

  const handleBackButton = () => {
    if (activeTab?.index <= tabs?.length) {
      setActiveTab(tabs[Number(activeTab?.index - 1)])
    }
  }

  const toggle = (data) => {
    setIsOpen((prev) => !prev);
    setVisaInfo({ ...data });
  };

  // handle save if dropdown IS Not multibale
  const handleSaveVisaPrice = () => {
    let pricingDataClone = { ...packageData }
    // to handle if selected all visa Type from DropDown
    console.log(visaInfo?.visa_type?.value)
    if (visaInfo?.visa_type?.value === 'all' && pricingDataClone?.quotas?.length <= 3) {
      const allVisaTypes = visaTypesOptions?.filter(item => item?.value !== 'all')?.map((item, index) => ({
        id: index + 1,
        visa_type: { value: item?.value, label: item?.label },
        amount: visaInfo?.amount,
      }))
      setPackageData({ ...pricingDataClone, quotas: allVisaTypes })
    }
    // to handle if selected any visa Type from DropDown
    if (visaInfo?.visa_type?.value !== 'all') {
      const existedItem = pricingDataClone?.quotas?.find(item => item?.visa_type?.value === visaInfo?.visa_type?.value)
      const existedItemIndex = pricingDataClone?.quotas?.findIndex(item => item?.visa_type?.value === existedItem?.visa_type?.value)
      if (existedItem && visaInfo?.editMode === undefined) {
        store.addNotification({
          message: safaVisa?.itemAlreadyExist,
          title: safaVisa?.itemAlreadyExist,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } if (existedItem && visaInfo?.editMode) {
        pricingDataClone['quotas'][existedItemIndex] = visaInfo
        setPackageData(pricingDataClone)
      } if (!existedItem) {
        pricingDataClone['quotas'].push(visaInfo)
        setPackageData(pricingDataClone)
      }
    }
    setIsDisabledBtn(false)
    toggle(null)
  }

  const handleToggleConfirmModal = (data) => {
    setIsOpenConfirm(!isOpenConfirm)
    setVisaInfo({ ...data })
  }

  const handleConfirmAction = () => {
    // console.log(visaInfo?.id)
    let pricingDataClone = { ...packageData }
    setPackageData({
      ...pricingDataClone,
      quotas: pricingDataClone['quotas']?.filter(item => item?.id !== visaInfo?.id)
    })
    handleToggleConfirmModal(null)
  }

  const handleCancelAction = () => {
    handleToggleConfirmModal()
  }


  const handleSubmitPricing = async () => {
    let requestBody = {
      title: {
        ar: packageData?.title_ar,
        en: packageData?.title_en,
      },
      description: {
        ar: packageData?.description_ar,
        en: packageData?.description_en,
      },
      countries_id: packageData?.countries_id?.map((item) => Number(item?.value)),
      currency: packageData?.currency?.label,
      audience_type: packageData?.audience_type?.value,
      contract_count: (packageData?.contract_count && packageData?.with_contracts === 'contract-determined')
        ? Number(packageData?.contract_count)
        : null,
      agents_count: (packageData?.agents_count && packageData?.with_Agents === 'agents-determined')
        ? Number(packageData?.agents_count)
        :
        null,
      quotas: packageData?.quotas?.length ?
        packageData?.quotas?.map(item => ({
          visa_type: item?.visa_type?.value,
          amount: item?.amount
        }))
        :
        null
      ,
      date_type: packageData?.date_type,
      share_start_date: moment(packageData?.share_start_date).format('YYYY-MM-DD'),
      share_end_date: packageData?.share_end_date ? moment(packageData?.share_end_date).format('YYYY-MM-DD') : null,
      duration_type: packageData?.package_type?.value,
      duration_count: Number(packageData?.duration_count),
      season_start_date: packageData?.season_start_date ? moment(packageData?.season_start_date).format('YYYY-MM-DD') : '',
      season_end_date: packageData?.season_end_date ? moment(packageData?.season_end_date).format('YYYY-MM-DD') : '',
      start_date: packageData?.start_date ? moment(packageData?.start_date).format('YYYY-MM-DD') : null,
      end_date: packageData?.end_date ? moment(packageData?.end_date).format('YYYY-MM-DD') : null,
      repurchase: packageData?.repurchase === 'repurchase-available' ? true : false,
      postpaid_price: packageData?.postpaid_price || null,
      prepaid_price: packageData?.prepaid_price || null,
    }
    let response;

    if (status === 'add' || status === 'duplicate') {
      response = await addPackagePricing(requestBody);
    }
    if (status === 'edit' && id) {
      response = await editPackagePricing(id, requestBody);
    }

    if ((response?.status === 200 || response?.status === 201)) {
      store.addNotification({
        message: safaVisa?.packagePricingAddedSuccessfully,
        title: safaVisa?.success,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push('/safaVisa-packages')
      setActiveTab(tabs[Number(activeTab?.index - 1)])
      setPackageData(initialPricingData)
    }
  }

  useLayoutEffect(() => {
    let formErrors = checkSubscriptionTypesFormErrors();
    // console.log(formErrors)
    if (isFormValid(formErrors) === false) {
      setActiveTab(tabs[1])
      return
    }
  }, [])

  // console.log(packageData)
  // -------- JSX Code -------
  return (
    <>
      <div className='position-relative'  >

        <button className="btn relative-btn add-btn py-2" onClick={() => setIsOpen(true)} >
          <i className="fas fa-plus text-white fa-md"></i>{" "}
          {safaVisa.add}
        </button>

        {/* Table */}
        <div className="our-border table-responsive  mt-4">
          <table className="custom-table back-office-table table table-striped mb-0">

            <thead>
              <tr>
                <th>{safaVisa.id}</th>
                <th> {safaVisa.visaType}</th>
                <th>{safaVisa.count}</th>
                <th>{safaVisa.actions}</th>
              </tr>
            </thead>

            <tbody>
              {packageData?.quotas?.length > 0 ? (
                packageData?.quotas?.map((row) =>
                  <tr>

                    <td><p className='id-text'> {row?.id} </p></td>

                    <td> {row?.visa_type?.label}  </td>

                    <td> {row?.amount} </td>

                    <td >
                      <div className='d-flex justify-content-start gap-2 align-items-center'>
                        <span
                          className='pointer'
                          onClick={() => toggle({ ...row, editMode: true })}
                        >
                          <EditIcon color='#9C9FAB' />
                        </span>
                        <span className='pointer' onClick={() => handleToggleConfirmModal(row)} >
                          <TrashIcon color='#EA5455' />
                        </span>
                      </div>
                    </td>

                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="product-no-data">
                      <i className="fas fa-info-circle fa-lg"></i>{" "}
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div >

        {/* Actions Button */}
        <div className="col-12 d-flex justify-content-between gap-10 mt-5">

          <button
            className="btn back-btn py-2 "
            onClick={handleBackButton}
          >
            <span >
              <LeftArrowIcon color='#1e85ff' width='24' height='24' />
            </span>
            {safaVisa.back}
          </button>

          <button
            // disabled={pricingData?.prices?.length === 0}
            disabled={isDisabledBtn}
            type='button'
            className="btn add-btn py-2"
            onClick={handleSubmitPricing}
          >
            {safaVisa.save}
          </button>

        </div>

      </div>

      <AddEditVisaTypeModal
        isOpen={isOpen}
        toggle={toggle}
        visaInfo={visaInfo}
        setVisaInfo={setVisaInfo}
        onSave={handleSaveVisaPrice}
        listLength={packageData?.quotas?.length}
      />

      <ConfirmModal
        isOpen={isOpenConfirm}
        toggle={handleToggleConfirmModal}
        confirmBtnText={safaVisa?.confirm}
        messageText={safaVisa?.cannotGoBack}
        icon={<TrashIcon color='#EA5455' />}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </>
  )
}

