import { useEffect, useState } from 'react';
// React-Router-Dom
import { useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import MultiDatePicker from 'components/shared/MultiDatePicker';
import RadioGroup from 'components/shared/RadioGroup';
import SelectField from 'components/shared/SelectField';
// Custom Hooks
import useSafaVisaData from '../../shared/useSafaVisaData';
// Context
import { useGlobalState } from 'context/global';
// Helper
import validate from 'helpers/validate';
// Moment
import moment from "moment-hijri";
// ---------------------------------------------------------

export default function DiscountDetailsForm(props) {

  const {
    discountData,
    setDiscountData,
    dicountItemsOptions,
    errors,
    setErrors,
    setAllRequiredInputs,
  } = props

  // *** Hook
  const { safaVisa } = Locale;

  let { id, status } = useParams();

  // *** Custom Hook
  const {
    formattedCountries,
    pricingTypesOptions,
    calendarTypesOprtion,
    endDateVisiabiltyOptions,
    maxHijriDate
  } = useSafaVisaData();

  const { allCountries } = useGlobalState();

  // *** State
  const [countries, setCountries] = useState([]);

  const handleChangeInputs = ({ key, value }) => {
    const dataClone = { ...discountData }
    dataClone[`${key}`] = value

    // to change related inputs when change pricing type
    if (key === 'pricing_type') {
      // to empty dropdown if change pricing type
      if (!id) {
        dataClone[`discounted_item_id`] = ''
        dataClone[`countries_id`] = []
      }
      dataClone[`quota_discounts`] = []
      dataClone[`package_pricing`] = []
      // dataClone[`price_discounts`] = []
    }

    // to change  End date input  required
    if (key === 'visible_end_date' && value === 'end-date-determined') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: [...prevStatus?.requiredDetailsInputs, 'end_date'],
      }))
    }

    // to change  End date input not required
    if (key === 'visible_end_date' && value === 'end-date-indeterminate') {
      setAllRequiredInputs((prevStatus) => ({
        ...prevStatus,
        requiredDetailsInputs: prevStatus?.requiredDetailsInputs?.filter(el => el !== 'end_date'),
      }))
    }

    // to fill all inputs which related with selected discount item
    if (key === 'discounted_item_id') {
      const packagePricingToShow = [
        {
          name: 'postpaid',
          price: value?.postpaid_price,
          currency: value?.currency,
          postpaid_discount_percentage: '',
          postpaid_after_discount: '',
        },
        {
          name: 'prepaid',
          price: value?.prepaid_price,
          currency: value?.currency,
          prepaid_discount_percentage: '',
          prepaid_after_discount: '',
        },
      ].filter(el => el?.price !== null);

      const countries = value?.countries?.map((t) => ({
        value: t.id,
        label: t.name,
        code: t.country_code,
      }));

      dataClone[`countries_id`] = value?.countries?.map((item) => ({ value: item?.id, label: item?.name, ...item }))
      dataClone[`quota_discounts`] = value?.quotas?.map(item => ({ ...item, visa_type: item?.visa_type, units: '' }))

      dataClone[`package_pricing`] = packagePricingToShow

      dataClone[`price_discounts`] = value?.prices?.map((item) => ({
        visa_type: item?.visa_type,
        price: item?.price,
        currency: value?.currency,
        discount_percentage: '',
        price_after_discount: item?.price_after_discount || '',
      }))

      dataClone[`subscription_type`] =
        (packagePricingToShow?.some(e => e?.name === 'prepaid') && packagePricingToShow?.some(e => e?.name === 'postpaid'))
          ?
          'all'
          :
          packagePricingToShow?.some(e => e?.name === 'postpaid') ?
            'postpaid'
            :
            'prepaid'


      setCountries(countries)
    }

    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });

    setDiscountData(dataClone)
  }

  useEffect(() => {
    if (status === 'edit' && id) {
      // to set Countries options which came from selected package in edit mode 
      setCountries(discountData?.countries_id);
    } if (status === 'add') {
      // to set Countries options with all countries in add or duplicate mode
      setCountries(formattedCountries(allCountries));
    }
  }, [allCountries]);

  // --------- JSX Code --------
  return (
    <form className="mt-4">

      <div className='d-flex justify-content-between align-items-center mb-2'>
        <p >{safaVisa?.pricingTypes} *</p>
        {/* Pricing Type */}
        <div className="col-5" >
          <RadioGroup
            disabled={status === 'edit' || status === 'duplicate'}
            hasLabel={false}
            label={safaVisa?.pricingTypes}
            name='pricing_type'
            groupOptions={pricingTypesOptions}
            onChange={(e) => handleChangeInputs({ key: 'pricing_type', value: e?.target?.value })}
            value={discountData?.pricing_type}
            color={errors?.pricing_type?.required ? "danger" : ""}
            errors={errors?.pricing_type}
          />
        </div>
      </div>

      {/* Discount Details Form  */}
      <div className='row mb-4'>

        {/* Discount Types  */}
        <div className={`col-4`}>
          <SelectField
            hasLabel={true}
            label={safaVisa?.package}
            placeholder={safaVisa?.select}
            id="discounted_item_id"
            name="discounted_item_id"
            value={discountData?.discounted_item_id}
            options={dicountItemsOptions}
            onChange={(e) => handleChangeInputs({ key: 'discounted_item_id', value: e })}
            color={errors?.discounted_item_id?.required ? "danger" : ""}
            errors={errors?.discounted_item_id}
          />
        </div>

        {/* Discount Countries  */}
        <div className={`col-4 `}>
          <SelectField
            multi
            id="countries_id"
            name="countries_id"
            label={safaVisa?.country}
            // disabled={discountData?.discounted_item_id?.id}
            placeholder={safaVisa?.select}
            options={countries}
            value={discountData?.countries_id}
            onChange={(values, { action }) => {
              if (action === "remove-value" && !values) {
                handleChangeInputs({ key: 'countries_id', value: [] })
                return;
              }
              handleChangeInputs({ key: 'countries_id', value: values })
              setErrors({
                ...errors,
                ...validate(
                  { name: "countries_id", value: values.length },
                  { required: true }
                ),
              });
            }}
            color={errors?.countries_id?.required ? "danger" : ""}
            errors={errors?.countries_id}
          />
        </div>

        {/* Date Type  */}
        <div className="col-4" >
          <RadioGroup
            label={safaVisa?.calendarType}
            hasLabel={true}
            name='date_type'
            groupOptions={calendarTypesOprtion}
            onChange={(e) => handleChangeInputs({ key: 'date_type', value: e?.target?.value })}
            value={discountData?.date_type}
          />
        </div>


        {/* Discount Start Date   */}
        <div className="col-4">
          <MultiDatePicker
            label={safaVisa.discountStartDate}
            placeholder={safaVisa?.discountStartDate}
            hasLabel={true}
            type={discountData?.date_type}
            // value={discountData?.start_date}
            value={
              discountData?.date_type === 'gregorian' ?
                discountData?.start_date
                :
                moment(discountData?.start_date).format("iYYYY/iMM/iDD")
            }
            onChangeDate={(e) => handleChangeInputs({ key: 'start_date', value: e?.toDate() })}
            format={'YYYY/MM/DD'}
            minDate={discountData?.date_type === 'gregorian' ?
              moment().format('YYYY/MM/DD')
              :
              moment().format("iYYYY/iMM/iDD")
            }
            maxDate={
              (discountData?.date_type === 'gregorian' && discountData?.visible_end_date === 'end-date-determined') ?
                moment(discountData?.end_date).format('YYYY/MM/DD')
                :
                (discountData?.date_type === 'hijri' && discountData?.visible_end_date === 'end-date-determined') ?
                  moment(discountData?.end_date).format("iYYYY/iMM/iDD")
                  :
                  (discountData?.date_type === 'gregorian' && discountData?.visible_end_date === 'end-date-indeterminate') ?
                    "2100/12/31"
                    :
                    maxHijriDate
            }
            color={errors?.start_date?.required ? "danger" : ""}
            errors={errors?.start_date}
          />
        </div>

        {/* Discount End-date Visiablity  */}
        <div className="col-4" >
          <RadioGroup
            hasLabel={true}
            label={safaVisa?.discountValidity}
            name='visible_end_date'
            groupOptions={endDateVisiabiltyOptions}
            onChange={(e) => handleChangeInputs({ key: 'visible_end_date', value: e?.target?.value })}
            value={discountData?.visible_end_date}
            color={errors?.visible_end_date?.required ? "danger" : ""}
            errors={errors?.visible_end_date}
          />
        </div>

        {/* Discount End Date   */}
        <div className="col-4" >
          <MultiDatePicker
            label={safaVisa.discountEndDate}
            placeholder={safaVisa?.discountEndDate}
            hasLabel={true}
            type={discountData?.date_type}
            // value={discountData?.end_date}
            value={
              discountData?.date_type === 'gregorian' ?
                discountData?.end_date
                :
                moment(discountData?.end_date).format("iYYYY/iMM/iDD")
            }
            onChangeDate={(e) => handleChangeInputs({ key: 'end_date', value: e?.toDate() })}
            format={'YYYY/MM/DD'}
            // minDate={moment(discountData?.start_date).add(1, 'days').format('YYYY/MM/DD')}
            // maxDate="2100-12-31"
            // onOpen={() => {
            //   handleChangeInputs({
            //     key: 'end_date',
            //     value: discountData?.date_type === 'gregorian' ?
            //       moment(discountData?.start_date).add(1, 'days').format('YYYY/MM/DD')
            //       :
            //       moment(discountData?.start_date).add(1, "days").format("iYYYY/iMM/iDD")
            //   })
            // }}
            minDate={
              discountData?.date_type === 'gregorian' ?
                moment(discountData?.start_date).add(1, 'days').format('YYYY/MM/DD')
                :
                moment(discountData?.start_date).add(1, "days").format("iYYYY/iMM/iDD")
            }
            maxDate={discountData?.date_type === 'gregorian' ? "2100/12/31" : maxHijriDate}
            disabled={discountData?.visible_end_date === 'end-date-indeterminate'}
            color={errors?.end_date?.required ? "danger" : ""}
            errors={errors?.end_date}
          />
        </div>

        {/* Discount Notification Ar */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.discountNotificationAr}
            placeholder={safaVisa?.discountNotificationAr}
            value={discountData?.notification_text_ar}
            onChange={(e) => handleChangeInputs({ key: 'notification_text_ar', value: e?.target?.value })}
            color={errors?.notification_text_ar?.required ? "danger" : ""}
            errors={errors?.notification_text_ar}
          />

        </div>

        {/* Discount Notification En */}
        <div className="col-6 custom-field mt-3">
          <TextAreaField
            rows={5}
            label={safaVisa?.discountNotificationEn}
            placeholder={safaVisa?.discountNotificationEn}
            value={discountData?.notification_text_en}
            onChange={(e) => handleChangeInputs({ key: 'notification_text_en', value: e?.target?.value })}
            color={errors?.notification_text_en?.required ? "danger" : ""}
            errors={errors?.notification_text_en}
          />
        </div>

      </div>

    </form >
  )
}

