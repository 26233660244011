import CheckBox from "components/chekbox";
import Locale from "translations";
import ModuleRow from "./ModuleRow";
import TotalPrice from "./TotalPrice";

export default function ModuleList({
	subscriptionData,
	selectedPlan,
	setSelectedPlan,
	handleCheck,
	handleCheckAll,
	handlePaidType,
	totalPrice,
	handleCheckAllModules,
	checkAllModules,
}) {
	const modules = subscriptionData?.modules;

	const allModulesRow =
		modules?.length > 0 &&
		modules.map((module, index) => (
			<ModuleRow
				moduleData={module}
				key={module.id}
				selectedPlan={selectedPlan}
				setSelectedPlan={setSelectedPlan}
				handleCheck={handleCheck}
				handleCheckAll={handleCheckAll}
				handlePaidType={handlePaidType}
				currency={subscriptionData.currency}
			/>
		));
	const { backOffice } = Locale;
	return (
		<div className="module-list">
			<div className="module-list-header">
				<div className="row mx-0">
					<div className="col-md-7 module-list">
						<div className="d-flex mt-1" style={{ gap: "8px" }}>
							<CheckBox
								type="Checkbox"
								name={"All Modules"}
								id={"allModules"}
								// checked={
								// 	subModule?.status === "active" ||
								// 	selectedPlan?.includes(subModule.id)
								// }
								checked={checkAllModules}
								onChange={(e) => {
									handleCheckAllModules(e.target.checked);
								}}
							/>
							<p className="ml-2">{backOffice.ModuleList}</p>
						</div>
					</div>
					<div className="col-md-1 modul-column">
						<p>{backOffice.Free}</p>
					</div>
					<div className="col-md-1 modul-column">
						<p>{backOffice.Paid}</p>
					</div>
					<div className="col-md-3 modul-column">
						<p>{backOffice.ServicePrice}</p>
					</div>
				</div>
			</div>
			<div className="module-list-body">
				{/** loop for all module */}
				{allModulesRow}
			</div>
			<TotalPrice
				currency={subscriptionData?.currency}
				totalPrice={totalPrice}
			/>
		</div>
	);
}
