
function Tabs({ tabs, activeTab, setActiveTab, onClickTab }) {
  // ---------- JSX Code --------
  return (
    <div className='tabs-holder'  >
      {tabs?.map((tab) =>
        <div
          key={tab?.value}
          // className={`${activeTab?.value === tab?.value ? 'active-tab' : 'tab'} 
          //             ${tab?.isDisabled ? 'disabled-tab' : ''}
          //              `}
          className={`${activeTab?.value === tab?.value ? 'active-tab' : 'tab'}`}
          onClick={() => {
            onClickTab()
            setActiveTab(tab)
          }}
        >
          <span className='ms-2 me-2'>{tab?.icon}</span>
          {tab?.label}
        </div>
      )}
    </div>

  )
}

export default Tabs