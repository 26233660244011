import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import validate from "helpers/validate";
import { useState } from "react";
import { uploadFile } from "services/auth";
import { fetchCitiesSearch } from "services/lookups";
import Locale from "translations";

export default function PromoCityDetails({
	promoDataState,
	setPromoDataState,
	errors,
	setErrors,
	initalHotelsObj,
	isEditMode,
}) {
	const { backOffice } = Locale;
	const { allCurrencies } = useGlobalState();
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const cities = await fetchCitiesSearch(inputValue);
			let result = cities.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAuto(result);
		}
	};
	const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	// upload city image and banner
	async function uploadImages(file, stateKey) {
		let formData = new FormData();
		formData.append("file", file);
		formData.append("name", file);
		formData.append("bucket", "promo");
		const uploadRes = await uploadFile(formData);
		if (uploadRes.status === 200) {
			setPromoDataState({
				...promoDataState,
				[stateKey]: uploadRes?.data?.uuid,
			});
			setErrors({
				...errors,
				...validate(
					{ name: stateKey, value: uploadRes?.data?.uuid },
					{ required: true }
				),
			});
		}
	}

	return (
		<div className="promo-city-container">
			<h3 className="promo-city-header">{backOffice.details}</h3>
			<div className="promo-city-content">
				<div className="d-flex flex-wrap justify-content-center align-items-center w-100">
					<div className="col-md-6 col-12 mt-1 no-padding">
						<AutoCompleteField
							label={backOffice.CityName}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
							value={promoDataState?.cityName?.label}
							id="promo-city-name"
							name="promo-city-name"
							onChange={(e) => {
								setPromoDataState({ ...promoDataState, cityName: e });
							}}
							onSelectValue={(e) => {
								setPromoDataState({
									...promoDataState,
									cityName: e,
									hotels: [initalHotelsObj],
								});
								setErrors({
									...errors,
									...validate(
										{ name: "cityName", value: e },
										{ required: true }
									),
								});
							}}
							errors={errors?.cityName}
							color={errors?.cityName?.required ? "danger" : ""}
							disabled={isEditMode}
						/>
					</div>
					<div className="col-md-6 col-12 no-padding">
						<SelectField
							label={backOffice.currency}
							id="promo-currency"
							name="promo-currency"
							value={promoDataState?.currency}
							options={allCurrencies?.map((currency) => {
								return {
									...currency,
									value: currency.id,
									label: currency.currency_code,
								};
							})}
							onChange={(e) => {
								setPromoDataState({ ...promoDataState, currency: e });
								setErrors({
									...errors,
									...validate(
										{ name: "currency", value: e },
										{ required: true }
									),
								});
							}}
							errors={errors?.currency}
							color={errors?.currency?.required ? "danger" : ""}
						/>
					</div>
				</div>

				{/* promo images */}
				<div className="d-flex flex-wrap justify-content-center w-100 my-3">
					<div className="col-md-5 col-12 promo-image">
						{backOffice.Smallpicture}
						<div className="promo-image-preview">
							{promoDataState?.cityImage ? (
								<img
									src={`${fetchFilesURL}/${promoDataState?.cityImage}`}
									alt="promo city"
								/>
							) : null}
						</div>
						<div className="upload-image-container">
							<input
								type="file"
								name="small-image"
								id="small-image"
								className="upload-image-input"
								accept=".jpg,.png"
								onChange={(e) => uploadImages(e.target.files[0], "cityImage")}
								errors={errors?.cityImage}
								color={errors?.cityImage?.required ? "danger" : ""}
							/>
							<button className="upload-image-btn">
								<i className="fas fa-cloud-upload-alt"></i>
								<span className="mx-1">{backOffice.UploadImage}</span>
							</button>
						</div>
						{errors?.cityImage?.required && (
							<p className="text-danger text-center m-0">
								{backOffice.SmallPictureIsRequired}
							</p>
						)}
					</div>

					{/* banner */}
					<div className="col-md-7 col-12 promo-banner">
						{backOffice.Banner}
						<div className="promo-image-preview">
							{promoDataState?.cityBanner ? (
								<img
									src={`${fetchFilesURL}/${promoDataState?.cityBanner}`}
									alt="promo banner"
									className="img-fluid"
								/>
							) : null}
						</div>
						<div className="upload-image-container">
							<input
								type="file"
								name="banner-image"
								id="banner-image"
								className="upload-image-input"
								accept=".jpg,.png"
								onChange={(e) => uploadImages(e.target.files[0], "cityBanner")}
							/>
							<button className="upload-image-btn">
								<i className="fas fa-cloud-upload-alt"></i>
								<span className="mx-1">{backOffice.UploadImage}</span>
							</button>
						</div>
						{errors?.cityBanner?.required && (
							<p className="text-danger text-center m-0">
								{backOffice.BannerIsRequired}
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
