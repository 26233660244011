import DatePickerField from "components/shared/DatePickerField";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function Share(props) {
	const { backOffice, booking } = Locale;
	const { isOpen, toggle, state, setState, shareAttraction, selectAttraction } =
		props;
	const disabledShareBtn = () => {
		if (state.from !== "" && state.to !== "" && state.payment_type !== "")
			return false;
		return true;
	};
	console.log(disabledShareBtn());

	if (selectAttraction?.operation_day == null) {
		return (
			<Modal isOpen={isOpen} toggle={toggle} className="share-attraction-modal">
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					<div className="w-90 title">
						<p className=" text-secondary text-center">{backOffice.Share}</p>
					</div>
					<i
						className="far fa-times-circle fa-lg text-secondary "
						onClick={toggle}
					></i>
				</div>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3 ">
					<h5 className="my-5">
						{backOffice.PleaseSelectTourDatesInOrderToShare}
					</h5>
				</ModalBody>
			</Modal>
		);
	}

	return (
		<Modal isOpen={isOpen} toggle={toggle} className="share-attraction-modal">
			<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
				<div className="w-90 title">
					<p className=" text-secondary text-center">{backOffice.Share}</p>
				</div>
				<i
					className="far fa-times-circle fa-lg text-secondary "
					onClick={toggle}
				></i>
			</div>

			<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3 ">
				<div className=" m-0 px-4 w-100">
					<div className="mx-1">
						<DatePickerField
							date={state.from ? moment(state.from) : state.from}
							placeholder={"DD/MM/YYYY"}
							label={backOffice.From}
							onChangeDate={(date) => {
								setState({
									...state,
									from: date,
								});
							}}
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
						/>
					</div>
					<div className="mx-1">
						<DatePickerField
							date={state.to ? moment(state.to) : state.to}
							placeholder={"DD/MM/YYYY"}
							label={backOffice.To}
							onChangeDate={(date) => {
								setState({
									...state,
									to: date,
								});
							}}
							isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
						/>
					</div>
					<div className="mx-1 payment_type">
						<span className="my-2 d-block">{booking.messages.paymentType}</span>
						<div className="radio-container mb-2">
							<input
								type="radio"
								name="paymentType"
								id="auto"
								value="auto"
								checked={state.payment_type === "auto"}
								onChange={(e) =>
									setState({ ...state, payment_type: e.target.value })
								}
							/>
							<label
								htmlFor="auto"
								className="my-0 mx-1 pointer user-select-none"
							>
								{backOffice.AutoPayment}
							</label>
						</div>
						<div>
							<input
								type="radio"
								name="paymentType"
								id="manual"
								value="manual"
								checked={state.payment_type === "manual"}
								onChange={(e) =>
									setState({ ...state, payment_type: e.target.value })
								}
							/>
							<label
								htmlFor="manual"
								className="my-0 mx-1 pointer user-select-none"
							>
								{backOffice.ManualPayment}
							</label>
						</div>
					</div>
				</div>
			</ModalBody>
			<div className="w-100 pb-5 border-top-0  text-center px-4 mt-2">
				<button
					className="btn rounded companies-btn  w-100"
					onClick={shareAttraction}
					disabled={disabledShareBtn() ? true : false}
				>
					{backOffice.Share}
				</button>
			</div>
		</Modal>
	);
}
