import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice, booking } = Locale;
	const statusOptions = [
		{ value: "pending", label: "Pending" },
		// { value: "confirmed", label: "Confirmed" },
		{ value: "issued", label: "Issued" },
		{ value: "canceled", label: "Canceled" },
		// { value: "expired", label: "Expired" },
	];
	const sourceOption = [
		{ value: "website", label: "Website" },
		{ value: "marketplace", label: "Marketplace" },
	];
	return (
		<div className=" main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						value={filter.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col-3 date-range-input">
					<p className="mb-0 mt-2">{backOffice.bookingDate}</p>
					<DateRangeField
						startDate={filter.booking_date_start}
						endDate={filter.booking_date_end}
						startDatePlaceholder={booking.StartDate}
						endDatePlaceholder={booking.EndDate}
						readOnly={true}
						onDatesChange={({ startDate, endDate }) => {
							endDate = endDate ? moment(endDate)._d : moment(startDate)._d;
							setFilter({
								...filter,
								booking_date_start: moment(startDate)._d,
								booking_date_end: endDate,
							});
						}}
						isOutsideRange={(day) => {
							return false;
						}}
					/>
				</div>

				<div className="col">
					<DatePickerField
						type="text"
						placeholder={"DD/MM/YYYY"}
						label={backOffice.travelDate}
						onOutsideClick={false}
						date={filter?.travel_date}
						onChangeDate={(e) => {
							setFilter({
								...filter,
								travel_date: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={booking.Source}
						label={booking.Source}
						name="source"
						options={sourceOption}
						value={filter.source}
						onChange={(e) => {
							setFilter({
								...filter,
								source: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<SelectField
						placeholder={booking.Status}
						label={booking.Status}
						name="status"
						options={statusOptions}
						value={filter.status}
						onChange={(e) => {
							setFilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={() => {
							setFilter({
								search: "",
								booking_date: null,
								travel_date: null,
								status: null,
								source: null,
								page: 1,
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
