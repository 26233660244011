import { useState } from 'react';
// Transaltion
import Locale from 'translations';
// ReactStrap
import { Modal, ModalBody } from "reactstrap";
// Components
import SelectField from 'components/shared/SelectField';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// --------------------------------------------------------------------------
function AddCompanyModal(props) {

  const {
    isOpen,
    companiesOptions,
    companyInfo,
    setCompanyInfo,
    toggle,
    onSave } = props

  // Hooks
  const { safaVisa } = Locale;

  // ** State
  const [errors, setErrors] = useState();

  // *** Functions 
  const checkFormErrors = () => {
    let submitError = {};
    ['company_id'].forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: companyInfo[key] },
          { required: true }
        )
      }
    })
    setErrors((prev) => ({ ...prev, ...submitError }));
    return submitError;
  }

  const handleChangeInputs = ({ key, value }) => {
    const dataClone = { ...companyInfo }
    dataClone[`${key}`] = value
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
    setCompanyInfo({ ...dataClone })
  }

  async function submit(e) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }
    onSave()
  }

  // --------- JSX Code -------
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      className='safaVisa-wrapper'
    >
      <div className="lan d-flex justify-content-between align-items-baseline p-4 ">
        <h4 className="main-title mb-0 ms-auto me-auto"> {safaVisa?.addCompany} </h4>
        <i
          className="fas fa-times text-black p-1 pointer"
          onClick={() => toggle(null)}
        ></i>
      </div>
      <ModalBody className="lan d-flex flex-column justify-content-center align-items-center ">

        <form onSubmit={submit} className="w-100 ps-2 pe-2">

          <div className="w-100" >

            <div className='mb-1 d-flex justify-content-between align-items-center'>

              <p>{safaVisa?.company} *</p>

              <div className={`flex-grow-1 control-field__feedback--${errors?.visa_type?.required ? 'danger' : ''}  mx-2`}>
                <SelectField
                  hasLabel={false}
                  label={safaVisa?.comapny}
                  placeholder={safaVisa?.select}
                  id="visa_type"
                  name="visa_type"
                  value={companyInfo?.companies_id}
                  options={companiesOptions}
                  onChange={(e) => handleChangeInputs({ key: 'company_id', value: e })}
                  color={errors?.company_id?.required ? "danger" : ""}
                  errors={errors?.company_id}
                />
              </div>
            </div>

          </div>

          <div className="w-100 pt-0 pb-4 border-top-0 text-center ">
            <button
              type='submit'
              className="btn rounded add-btn mt-3 w-100"
              onClick={onSave}
              disabled={!companyInfo?.company_id}
            >
              {safaVisa.save}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AddCompanyModal