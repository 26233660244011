import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import moment from "moment";
import { isInclusivelyBeforeDay } from "react-dates";
import { useParams } from "react-router-dom";
import Locale from "translations";

export default function AdsDetails(props) {
	const { setPopupAds, popupAds, setErrors, errors } = props;
	const { status } = useParams();
	const { ads, backOffice } = Locale;
	return (
		<div className="ads-details">
			<div className="bankConfigrationHeader p-3 text-visa-header font-weight-bold mt-3">
				<p className="m-0">{backOffice.AdDetails}</p>
			</div>
			<div className="row pb-3 px-5 our-border py-0 m-0">
				<div className="col-8">
					<TextField
						type="text"
						label={ads.Name}
						placeholder={ads.Name}
						value={popupAds.adName}
						name="adName"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setPopupAds({
								...popupAds,
								adName: e.target.value,
							});
						}}
						color={errors?.adName?.required ? "danger" : ""}
						errors={errors?.adName}
					/>
				</div>

				<div className="col-4">
					<SelectField
						label={ads.Type}
						value={popupAds.type}
						name="type"
						options={[
							{ value: 1, label: "destinations" },
							{ value: 2, label: "hotels" },
						]}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "type", value: e.value },
									{
										required: true,
									}
								),
							});
							setPopupAds({
								...popupAds,
								type: e,
							});
						}}
						color={errors?.type?.required ? "danger" : ""}
						errors={errors?.type}
					/>
				</div>
				<div className="col-12">
					<TextField
						type="text"
						label={ads.AdTitle}
						placeholder={ads.AdTitle}
						value={popupAds.adTitle}
						name="adTitle"
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(e.target, {
									required: true,
								}),
							});
							setPopupAds({
								...popupAds,
								adTitle: e.target.value,
							});
						}}
						color={errors?.adTitle?.required ? "danger" : ""}
						errors={errors?.adTitle}
					/>
				</div>
				{popupAds?.type?.label == "destinations" ? (
					<>
						<div className="col-4 ads-date-field ">
							<DatePickerField
								hasLabel={true}
								date={
									status == "add"
										? popupAds.from
										: popupAds.from == null
										? ""
										: moment(popupAds.from)
								}
								placeholder={backOffice.From}
								label={backOffice.From}
								name="from"
								onChangeDate={(e) => {
									setErrors({
										...errors,
										...validate({ name: "from", value: e }, { required: true }),
									});
									setPopupAds({
										...popupAds,
										from: e,
									});
								}}
								isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
								color={errors?.from?.required ? "danger" : ""}
								errors={errors?.from}
							/>
						</div>
						<div className="col-4 ads-date-field">
							<DatePickerField
								hasLabel={true}
								date={
									status == "add"
										? popupAds.to
										: popupAds.to == null
										? ""
										: moment(popupAds.to)
								}
								placeholder={backOffice.To}
								label={backOffice.To}
								name="to"
								onChangeDate={(e) => {
									setErrors({
										...errors,
										...validate({ name: "to", value: e }, { required: true }),
									});
									setPopupAds({
										...popupAds,
										to: e,
									});
								}}
								isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
								color={errors?.to?.required ? "danger" : ""}
								errors={errors?.to}
							/>
						</div>
					</>
				) : null}

				<div className="col-4 ads-date-field">
					<DatePickerField
						hasLabel={true}
						date={
							status == "add"
								? popupAds.expiryDate
								: moment(popupAds.expiryDate)
						}
						placeholder={ads.ExpiryDate}
						label={ads.ExpiryDate}
						name="expiryDate"
						onChangeDate={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "expiryDate", value: e },
									{ required: true }
								),
							});
							setPopupAds({
								...popupAds,
								expiryDate: e,
							});
						}}
						isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
						color={errors?.expiryDate?.required ? "danger" : ""}
						errors={errors?.expiryDate}
					/>
				</div>
			</div>
		</div>
	);
}
