import SafaLogo from "assets/images/hotelbeds.png";
import phone from "assets/images/phone.png";
import safaLogo from "assets/images/safa_logo.png";
import { TransferInvoicePrint } from "assets/prints/TransferInvoicePrint";
import { transferVoucher } from "assets/prints/TransferPrint";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	TransfersView,
	getTransferInvoice,
	getTransferVoucher,
} from "services/Transfers";
import Locale from "translations";
import PassengerDetailsTransfer from "./PassengerDetails";
import PricesBox from "./PricesBox";
import CancelAllModel from "./cancelAllModel";
import TransferItem from "./transferItem";
function TransferView() {
	const { backOffice, booking, translate_companies } = Locale;
	let history = useHistory();
	const [isOpen, setisOpen] = useState(false);
	const toggle = () => {
		setisOpen(!isOpen);
	};
	const { id } = useParams();
	const [Transfer, setTransfer] = useState({});
	useEffect(() => {
		const fetchView = async () => {
			const res = await TransfersView(id);
			if (res.status == 200) {
				setTransfer(res?.data?.data);
			}
		};
		fetchView();
	}, []);
	const dawnloadInvoice = async () => {
		const invoice = await getTransferInvoice(id);
		if (invoice) {
			let respos = TransferInvoicePrint;
			const keyValuePair = {
				"[ref]": invoice?.data?.data?.reference,
				"[name]": invoice?.data?.data?.name,
				"[agency_ref]": invoice?.data?.data?.agency_ref,
				"[booking_time]": invoice?.data?.data?.booking_date,
			};

			for (const key in keyValuePair) {
				respos = respos.replace(key, keyValuePair[key]);
			}

			let serviceContent = "";
			invoice?.data?.data?.items.forEach((item) => {
				if (item.status === "CANCELED") return;
				serviceContent += `<div class="border" style="margin:8px;padding:12px">
					<h5 style="background: #F3F5F5;">TICKETS AND EXCURSIONS</h5>
					<h6 class="fw-700">${item.from_name}, ${item.to_name}</h6>
					<p>From: ${moment(item.pickup_time).format("DD/MM/YYYY (dddd)")} </p>
					<hr class="hr-lg" />
					<h6 class="fw-600 border-dots">Tickets</h6>
					<p class="border-dots">${item.adults} Adults , ${
					item.children ? item.children + " child" : ""
				} </p>
					<h5
						style="background: #F3F5F5; display: flex; flex-direction: row-reverse;padding: 5px;font-weight: 800;margin-top: 9px;">
						Total net amount : ${item.price} ${item.currency}</h5>

				</div>`;
			});

			respos = respos.replace("[service_content]", serviceContent);

			let cancelPolicy = "";
			invoice?.data?.data?.items?.forEach((item) => {
				item.cancellation_policies?.forEach((policy, index) => {
					if (item.status === "CANCELED") return;
					const freeCancel = `${moment(policy?.from)
						.subtract(1, "day")
						.format("DD/MM/YYYY")} at ${moment(policy?.from)
						.subtract(1, "minute")
						.format("hh:mm A")}`;

					cancelPolicy += `<div class="box-containt">
							<div class="row" style="width: 100%; margin: 5px;">
								<div class="col-5">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Concept</h2>
										<p class="border-dots">
											${item?.from_name}, ${item?.to_name}
										</p>
									</div>
								</div>
								<div class="col-3">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">From</h2>
										<p class="border-dots">
											${moment(policy?.from).format("DD/MM/YYYY")}
										</p>
									</div>
								</div>
								<div class="col-2">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Units</h2>
										<p class="border-dots">1</p>
									</div>
								</div>
								<div class="col-2">
									<div style="display: flex; flex-direction: column;">
										<h2 class="border-dots fw-700">Value</h2>
										<p class="border-dots">
											${policy?.amount}
											${policy?.currencyId}
										</p>
									</div>
								</div>
							</div>
							<div style="width:100%;margin: 5px;">
								<p class="border-dots">
									Date and time is calculated based on local time of
									destination.
								</p>
							</div>

							<div class="border-dots" style="width:100%;margin: 15px;">
								<div
									class="fw-600"
									style="padding:12px;line-height: 1.2;border:2px solid #222"
								>
									PAYMENT DEADLINE BY CREDIT CARD: Please ensure you pay for
									this booking before ${freeCancel} (local time of
									destination) or your booking will be cancelled
									automatically. PAYMENT DEADLINE BY BANK TRANSFER: Please
									ensure you pay for this booking before ${freeCancel}
									sending us a payment proof or your booking will be cancelled
									automatically
								</div>
							</div>
						</div>`;
				});
			});

			respos = respos.replace("[cancle_policy]", cancelPolicy);
			respos = respos.replace("[SafaLogo]", SafaLogo);

			var popupWin = window.open(
				"",
				" ",
				"top=0,left=0,height=100%,width=auto"
			);
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	const printVoucher = async (itemId = null) => {
		const voucherRes = await getTransferVoucher(id, itemId);
		if (voucherRes.status !== 200) return;
		const voucherData = voucherRes?.data?.data;
		let printVar = "";
		voucherData?.forEach((item) => {
			if (item.status === "CANCELED") return;
			const keyValuePair = {
				"[reference_number_key]": "Reference Number",
				"[reference_number_value]": item?.ref,
				"[supplier_reference_key]": "Supplier Reference",
				"[supplier_reference_value]": item?.supplier_reference,
				"[passenger_name_key]": "Passenger Name",
				"[passenger_name_value]": `${item?.passenger_name?.name} ${item?.passenger_name?.surname}`,
				"[product_type_key]": "Product Type",
				"[product_type_value]": `${item?.type} ${item?.category} ${item?.vehicle} `,
				"[product_image]": item?.image,
				"[from_key]": "From",
				"[from_value]": item?.from,
				"[to_key]": "To",
				"[to_value]": item?.to,
				"[passengers_key]": "Passengers",
				"[adults_value]": item?.adults,
				"[children_value]": item?.children,
				"[infants_value]": item?.infants,
				"[service_date_key]": "Service Date",
				"[service_date_value]": item?.pickup_date,
				"[pickUp_time_key]": "pick-up Time",
				"[pickUp_time_value]": item?.pickup_time
					? moment(item?.pickup_time).format("HH:mm")
					: "",
				"[pickUp_point_key]": "Pick-up Point",
				"[pickUp_point_value]": item?.pickup_information,
				"[travel_information]": "Travel Information",
				"[arrival_flight_key]": "Arrival Flight",
				"[arrival_flight_value]": item?.flight_number ?? "",
				"[arrival_time_key]": "Arrival Time",
				"[arrival_time_value]": item?.flight_time
					? moment(item?.flight_time).format("HH:mm")
					: "",
				"[included_services_key]": "Included Services",
				"[telephone]": "Telephone",
				"[voucher_date]": moment().format("DD/MM/YYYY"),
			};

			printVar += transferVoucher;
			for (const key in keyValuePair) {
				printVar = printVar.replace(key, keyValuePair[key]);
			}
			let included_services = "";
			item?.transfer_detail_info?.forEach(
				(info) => (included_services += `<p>${info}</p>`)
			);
			printVar = printVar.replace(
				"[included_services_value]",
				included_services
			);
			printVar = printVar.replace(
				"[logo]",
				`<img width={200} src="${safaLogo}" alt="safa logo" />`
			);
			printVar = printVar.replace(
				"[phone_img]",
				`<img src="${phone}" alt="phone" />`
			);
		});
		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
        <head>
        <title> print Voucher</title>
        <style>

        </style>
        </head>
        <body onLoad="{()=>window.print()}">${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	return (
		<>
			<h2>{backOffice.TransferReservations}</h2>
			<div className="row me-5 transfer">
				<div className="col-8">
					<div className="py-2 mt-2">
						<p className="pointer" onClick={history.goBack}>
							<span
								style={{
									color: "#0372BC",
									fontSize: "16px",
									padding: "4px 2px",
								}}
							>
								<i class="fas fa-arrow-left mx-2"></i>
								{backOffice.BackToReservations}
							</span>
						</p>
					</div>
					<div className="box-info">
						<div>
							<p>{backOffice.companyName} </p>
							<p className="fw-bold">{Transfer?.company_name}</p>
						</div>
						<div>
							<p>{backOffice.contactName}</p>
							<p className="fw-bold">{Transfer?.contact_name}</p>
						</div>
						<div>
							<p>{translate_companies.email} </p>
							<p className="fw-bold">{Transfer?.contact_email}</p>
						</div>
						<div>
							<p>{booking.Phone} </p>
							<p className="fw-bold">{Transfer?.contact_phone}</p>
						</div>
					</div>
					<p className="trans-dark-light">{backOffice.reservation}</p>
					{Transfer.items?.map((res) => (
						<TransferItem item={res} printVoucher={printVoucher} />
					))}
					<p className="trans-dark-light">{backOffice.PassengerDetails}</p>
					<PassengerDetailsTransfer Transfer={Transfer} />
					<p className="trans-dark-light">{backOffice.AgencyReference}</p>
					<div className="box-info justify-content-start">
						<span>{Transfer?.agency_ref}</span>
					</div>
				</div>
				{/*                       right col                  */}
				<div className="col-4">
					<div className="box-invoice-btn mb-2 pt-2">
						<ShowForPermission permission="Transfers-Bookings-Manage">
							{Transfer?.status !== "CANCELED" &&
							Transfer?.status !== "expired " ? (
								<>
									<button
										className="btn btn-invoice"
										onClick={() => {
											dawnloadInvoice();
										}}
									>
										<i class="fas fa-download"></i> {backOffice.Invoice}
									</button>
									<button
										className="btn btn-invoice"
										onClick={() => {
											printVoucher("");
										}}
									>
										<i class="fas fa-download"></i> {backOffice.Vouchers}
									</button>
								</>
							) : null}
						</ShowForPermission>
					</div>
					<div className="box-info" style={{ fontSize: "16px" }}>
						<p className="trans-dark">{backOffice.ReferenceNo}</p>
						<p>{Transfer?.reference}</p>
					</div>
					<p className="trans-dark-light">{booking.messages.bookingSummary}</p>
					{Transfer?.items?.length > 0 && (
						<PricesBox
							Transfer={Transfer?.items}
							currency={Transfer?.currency}
						/>
					)}
					<ShowForPermission permission="Transfers-Bookings-Manage">
						{Transfer?.status !== "CANCELED" &&
						Transfer?.status !== "expired " ? (
							<p className="text-danger py-2 pointer" onClick={toggle}>
								<i class="fas fa-times-circle pe-1"></i>
								{backOffice.CancelAllTransfer}
							</p>
						) : null}
					</ShowForPermission>
				</div>
			</div>{" "}
			{Transfer?.items?.length > 0 && (
				<CancelAllModel
					toggle={toggle}
					isOpen={isOpen}
					items={Transfer?.items}
					currency={Transfer?.currency}
				/>
			)}
		</>
	);
}

export default TransferView;
