import { useLayoutEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// React-notifications
import { store } from "react-notifications-component";
// Translation
import Locale from 'translations';
// Icons
import { EditIcon, LeftArrowIcon, TrashIcon } from 'components/Icons';
// Module Components
import ConfirmModal from '../../shared/ConfirmModal';
import AddEditVisaPriceModal from './AddEditVisaPriceModal';
// Services
import { addDefaultPricing, editDefaultPricing } from 'services/safaVisa';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// ------------------------------------------------------------

export default function VisaPricingList(props) {

  const {
    pricingData,
    setPricingData,
    setActiveTab,
    activeTab,
    allRequiredInputs,
    // setAllRequiredInputs,
    tabs,
    // setTabs,
    errors,
    setErrors
  } = props

  // *** Hooks
  let history = useHistory();
  let { id, status } = useParams();

  const { initialPricingData, visaTypesOptions } = useSafaVisaData();

  const { backOffice, safaVisa } = Locale;

  const [visaPriceInfo, setVisaPriceInfo] = useState({
    visa_type: '',
    price: ''
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(pricingData?.prices?.length === 0);

  // *** Functions
  const checkFormErrors = () => {
    let submitError = {};
    allRequiredInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: pricingData[key] },
          { required: true }
        )
      }
    })
    setErrors(submitError);
    return submitError;
  }

  const handleBackButton = () => {
    if (activeTab?.index <= tabs?.length) {
      setActiveTab(tabs[Number(activeTab?.index - 1)])
    }
  }

  const toggle = (data) => {
    setIsOpen((prev) => !prev);
    // console.log([data?.visa_type])
    // console.log({ visa_type: [{ ...data?.visa_type }], price: data?.price, editMode: data?.editMode })
    // setVisaPriceInfo({ visa_type: [{ ...data?.visa_type }], price: data?.price, editMode: data?.editMode });
    setVisaPriceInfo({ ...data });
  };

  // handle save if dropdown IS Not multibale
  const handleSaveVisaPrice = () => {
    let pricingDataClone = { ...pricingData }
    // to handle if selected all visa Type from DropDown
    console.log(visaPriceInfo?.visa_type?.value)
    if (visaPriceInfo?.visa_type?.value === 'all' && pricingDataClone?.prices?.length <= 3) {
      const allVisaTypes = visaTypesOptions?.filter(item => item?.value !== 'all')?.map((item, index) => ({
        id: index + 1,
        currency: pricingData?.currency?.label,
        visa_type: { value: item?.value, label: item?.label },
        price: visaPriceInfo?.price,
      }))
      setPricingData({ ...pricingDataClone, prices: allVisaTypes })
    }
    // to handle if selected any visa Type from DropDown
    if (visaPriceInfo?.visa_type?.value !== 'all') {
      const existedItem = pricingDataClone?.prices?.find(item => item?.visa_type?.value === visaPriceInfo?.visa_type?.value)
      const existedItemIndex = pricingDataClone?.prices?.findIndex(item => item?.visa_type?.value === existedItem?.visa_type?.value)

      if (existedItem && visaPriceInfo?.editMode === undefined) {
        store.addNotification({
          message: safaVisa?.itemAlreadyExist,
          title: safaVisa?.itemAlreadyExist,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } if (existedItem && visaPriceInfo?.editMode) {
        pricingDataClone['prices'][existedItemIndex] = visaPriceInfo
        setPricingData(pricingDataClone)
      } if (!existedItem) {
        pricingDataClone['prices'].push(visaPriceInfo)
        setPricingData(pricingDataClone)
      }
    }
    setIsDisabledBtn(false)
    toggle(null)
  }


  // const handleSaveVisaPrice = () => {
  //   let pricingDataClone = { ...pricingData }
  //   // to handle if selected all visa Type from DropDown
  //   console.log(visaPriceInfo)
  //   if (visaPriceInfo?.visa_type?.length < 3 ) {
  //     const allVisaTypes = visaPriceInfo?.visa_type?.filter(item => item?.value !== 'all')?.map((item, index) => ({
  //       id: index + 1,
  //       currency: pricingData?.currency?.label,
  //       visa_type: { value: item?.value, label: item?.label },
  //       price: visaPriceInfo?.price,
  //     }))
  //     // console.log(allVisaTypes)
  //     setPricingData({ ...pricingDataClone, prices: allVisaTypes })
  //   }
  //   // to handle if selected any visa Type from DropDown
  //   if (visaPriceInfo?.visa_type?.length < 3) {
  //     console.log(visaPriceInfo)
  //     const existedItem = pricingDataClone?.prices?.find(item => item?.visa_type?.value === visaPriceInfo?.visa_type?.value)
  //     const existedItemIndex = pricingDataClone?.prices?.findIndex(item => item?.visa_type?.value === existedItem?.visa_type?.value)
  //     // if (existedItem && visaPriceInfo?.editMode === undefined) {
  //     //   store.addNotification({
  //     //     message: safaVisa?.itemAlreadyExist,
  //     //     title: safaVisa?.itemAlreadyExist,
  //     //     type: "warning",
  //     //     insert: "top",
  //     //     container: "top-right",
  //     //     animationIn: ["animated", "fadeIn"],
  //     //     animationOut: ["animated", "fadeOut"],
  //     //     dismiss: {
  //     //       duration: 3000,
  //     //       onScreen: true,
  //     //       pauseOnHover: true,
  //     //     },
  //     //   });
  //     // } if (existedItem && visaPriceInfo?.editMode) {
  //     //   pricingDataClone['prices'][existedItemIndex] = visaPriceInfo
  //     //   setPricingData(pricingDataClone)
  //     // } if (!existedItem) {
  //     //   pricingDataClone['prices'].push(visaPriceInfo)
  //     //   setPricingData(pricingDataClone)
  //     // }
  //   }

  //   setIsDisabledBtn(false)
  //   toggle(null)
  // }

  const handleToggleConfirmModal = (data) => {
    setIsOpenConfirm(!isOpenConfirm)
    setVisaPriceInfo({ ...data })
  }

  const handleConfirmAction = () => {
    // console.log(visaPriceInfo?.id)
    let pricingDataClone = { ...pricingData }
    // pricingDataClone['prices']?.filter(item => item?.id !== visaPriceInfo?.id)
    // console.log(pricingDataClone['prices']?.filter(item => item?.id !== visaPriceInfo?.id))
    setPricingData({
      ...pricingDataClone,
      prices: pricingDataClone['prices']?.filter(item => item?.id !== visaPriceInfo?.id)
    })
    handleToggleConfirmModal(null)
  }

  const handleCancelAction = () => {
    // console.log('cancel')
    handleToggleConfirmModal()
  }


  const handleSubmitPricing = async () => {
    let requestBody = {
      title: {
        ar: pricingData?.title_ar,
        en: pricingData?.title_en,
      },
      description: {
        ar: pricingData?.description_ar,
        en: pricingData?.description_en,
      },
      countries_id: pricingData?.countries_id?.map((item) => Number(item?.value)),
      currency: pricingData?.currency?.label,
      audience_type: pricingData?.audience_type,
      contract_count: (pricingData?.with_contracts === 'contract-determined' && pricingData?.contract_count) ? Number(pricingData?.contract_count) : null,
      agents_count: (pricingData?.with_Agents === 'agents-determined' && pricingData?.agents_count) ? Number(pricingData?.agents_count) : null,
      prices: pricingData?.prices?.length ?
        pricingData?.prices?.map(item => ({
          visa_type: item?.visa_type?.value,
          price: item?.price
        }))
        :
        null
      ,
    }
    let response;

    if (status === 'add' || status === 'duplicate') {
      response = await addDefaultPricing(requestBody);
    }
    if (status === 'edit' && id) {
      response = await editDefaultPricing(id, requestBody);
    }

    if ((response?.status === 200 || response?.status === 201)) {
      store.addNotification({
        message: safaVisa?.defaultPricingAddedSuccessfully,
        title: safaVisa?.success,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push('/safaVisa-defaultPricing')
      setActiveTab(tabs[Number(activeTab?.index - 1)])
      setPricingData(initialPricingData)
    }
  }

  useLayoutEffect(() => {
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      setActiveTab(tabs[activeTab?.index - 1])
      return
    }
  }, [])

  // ---------- JSX Code ----------
  return (
    <>
      <div className='position-relative'  >

        <button className="btn relative-btn add-btn py-2" onClick={() => setIsOpen(true)} >
          <i className="fas fa-plus text-white fa-md"></i>{" "}
          {safaVisa.add}
        </button>

        {/* Table */}
        <div className="our-border table-responsive  mt-4">
          <table className="custom-table back-office-table table table-striped mb-0">

            <thead>
              <tr>
                <th>{safaVisa.id}</th>
                <th> {safaVisa.visaType}</th>
                <th>{safaVisa.pricePerVisa}</th>
                <th>{safaVisa.currency}</th>
                <th>{safaVisa.actions}</th>
              </tr>
            </thead>

            <tbody>
              {pricingData?.prices?.length > 0 ? (
                pricingData?.prices?.map((row) =>
                  <tr>

                    <td><p className='id-text'> {row?.id} </p></td>

                    <td> {row?.visa_type?.label}  </td>

                    <td> {row?.price} </td>

                    <td>{row?.currency}</td>

                    <td >
                      <div className='d-flex justify-content-start gap-2 align-items-center'>
                        <span
                          className='pointer'
                          onClick={() => toggle({ ...row, editMode: true })}
                        >
                          <EditIcon color='#9C9FAB' />
                        </span>
                        <span className='pointer' onClick={() => handleToggleConfirmModal(row)} >
                          <TrashIcon color='#EA5455' />
                        </span>
                      </div>
                    </td>

                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="product-no-data">
                      <i className="fas fa-info-circle fa-lg"></i>{" "}
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div >

        {/* Actions Button */}
        <div className="col-12 d-flex justify-content-between gap-10 mt-5">

          <button
            className="btn back-btn py-2 "
            onClick={handleBackButton}
          >
            <span >
              <LeftArrowIcon color='#1e85ff' width='24' height='24' />
            </span>
            {safaVisa.back}
          </button>

          <button
            // disabled={pricingData?.prices?.length === 0}
            disabled={isDisabledBtn}
            type='button'
            className="btn add-btn py-2"
            onClick={handleSubmitPricing}
          >
            {safaVisa.save}
          </button>

        </div>

      </div>

      <AddEditVisaPriceModal
        isOpen={isOpen}
        toggle={toggle}
        visaPriceInfo={visaPriceInfo}
        setVisaPriceInfo={setVisaPriceInfo}
        onSave={handleSaveVisaPrice}
        currency={pricingData?.currency}
        listLength={pricingData?.prices?.length}
      />

      <ConfirmModal
        isOpen={isOpenConfirm}
        toggle={handleToggleConfirmModal}
        confirmBtnText={safaVisa?.confirm}
        messageText={safaVisa?.cannotGoBack}
        icon={<TrashIcon color='#EA5455' />}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
    </>
  )
}

