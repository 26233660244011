import Locale from "translations";
import Statistics from "../Components/Statistics";

export default function Home() {
	const { backOffice } = Locale;

	return (
		<>
			<h3>{backOffice.Dashboard}</h3>
			<Statistics />
		</>
	);
}
