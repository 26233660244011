import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Locale from "translations";

export default function PromoTable({
	promosList,
	changePublishStatus,
	meta,
	goTo,
	toggleConfiramationModal,
	setSelectedPromo,
}) {
	const { ads, backOffice, manualSupplier } = Locale;

	return (
		<div className="our-border  px-4">
			<table className="custom-table back-office-table table table-striped">
				<thead>
					<tr>
						<th>{backOffice.city}</th>
						<th>{manualSupplier.No_ofHotels}</th>
						<ShowForPermission permission="Promo-Manage">
							<th>{ads.Publish}</th>
						</ShowForPermission>
						<ShowForPermission permission="Promo-Manage">
							<th>{ads.Actions}</th>
						</ShowForPermission>
					</tr>
				</thead>
				<tbody>
					{promosList?.length > 0 ? (
						promosList?.map((promo) => {
							return (
								<tr key={promo?.id}>
									<td>{promo?.city_name}</td>
									<td>{promo?.no_of_hotels}</td>
									<td>
										<Switch
											height={24}
											width={45}
											handleDiameter={10}
											uncheckedIcon={false}
											checkedIcon={false}
											className="mx-2 align-middle"
											onColor="#00AAEB"
											offColor="#C3C3C3"
											onChange={() => {
												changePublishStatus(promo.id);
											}}
											checked={promo?.publish}
										/>
									</td>
									<td>
										<Link
											to={`/promo/edit/${promo?.id}`}
											className="btn shadow-none p-0 mx-2"
										>
											<i className="fas fa-edit text-primary pointer m-0 h5"></i>
										</Link>
										<button
											className="btn shadow-none p-0 mx-2"
											onClick={() => {
												toggleConfiramationModal();
												setSelectedPromo(promo);
											}}
										>
											<i className="fas fa-trash text-danger pointer m-0 h5"></i>
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<Pagination info={meta} goTo={goTo} />
			{promosList?.length < 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}
