import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getOtaRequestsView } from "services/b2c";
import Locale from "translations";
import RequestFeild from "./RequestFeild";

export default function OtaRequestsView() {
	const { id } = useParams();
	const [requestDetails, setRequestDetails] = useState({});
	const [Rooms, setRooms] = useState({});
	const { backOffice, booking, teamManagement } = Locale;
	const { client, request, service } = requestDetails;

	async function fetchView() {
		const res = await getOtaRequestsView(id);
		if (res?.status === 200) {
			const { request } = res?.data?.data;
			setRequestDetails(res?.data?.data);
			if (request?.rooms?.length === 0) return;
			let roomsData = {
				single: null,
				double: null,
				truple: null,
				adult: 0,
				child: 0,
			};
			for (let i = 0; request?.rooms?.length >= i; i++) {
				switch (request?.rooms[i]?.room_type) {
					case 1:
						roomsData = {
							...roomsData,
							single: roomsData?.single + 1,
							adult: roomsData?.adult + request?.rooms[i]?.adults_count,
							child: roomsData?.child + request?.rooms[i]?.children_count,
						};
						break;
					case 2:
						roomsData = {
							...roomsData,
							double: roomsData?.double + 1,
							adult: roomsData?.adult + request?.rooms[i]?.adults_count,
							child: roomsData?.child + request?.rooms[i]?.children_count,
						};
						break;
					case 3:
						roomsData = {
							...roomsData,
							truple: roomsData?.truple + 1,
							adult: roomsData?.adult + request?.rooms[i]?.adults_count,
							child: roomsData?.child + request?.rooms[i]?.children_count,
						};
						break;
					default:
						break;
				}
			}
			setRooms(roomsData);
			console.log(roomsData);
		}
	}

	useEffect(() => {
		fetchView();
	}, [id]);

	return (
		<div
			className="d-flex flex-column  gap-4 "
			style={{ backgroundColor: "#f7f6f6" }}
		>
			<div className="bg-white rounded p-3 d-flex  gap-3">
				<RequestFeild
					labelValue={backOffice.companyName}
					inputValue={requestDetails?.company_name}
				/>
				<RequestFeild
					labelValue={backOffice.requestDate}
					inputValue={moment(requestDetails?.created_at).format("DD/MM/YYYY")}
				/>
				<RequestFeild
					labelValue={backOffice.requestStatus}
					inputValue={requestDetails?.status}
				/>
			</div>
			<div className="bg-white rounded  d-flex flex-column p-3 gap-3">
				<h1 className="h5  px-2 mb-0">{backOffice.ClientDetails}</h1>
				<div className=" d-flex gap-3">
					<RequestFeild
						width="24%"
						labelValue={backOffice.ClientName}
						inputValue={client?.name}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.email}
						inputValue={client?.email}
					/>
					<RequestFeild
						width="24%"
						labelValue={teamManagement.phoneNumber}
						inputValue={client?.phone}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.WhatsNumber}
						inputValue={client?.whatsapp_number}
					/>
				</div>
			</div>
			<div className="bg-white rounded  d-flex flex-column p-3 gap-3">
				<h1 className="h5  px-2 mb-0">{backOffice.RequestDetails}</h1>
				<div className=" d-flex flex-wrap gap-2">
					<RequestFeild
						width="24%"
						labelValue={booking.messages.serviceType}
						inputValue={requestDetails?.type}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.ServiceName}
						inputValue={service?.name?.en}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.Rooms}
						inputValue={
							requestDetails?.type === "Hotel" ||
							requestDetails?.type === "Package"
								? `${Rooms?.single ? Rooms?.single + " Single Room -" : ""}
						${Rooms?.double ? Rooms?.double + " Double Room -" : ""}
						${Rooms?.truple ? Rooms?.truple + " Truple Room" : ""}`
								: null
						}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.pax}
						inputValue={
							requestDetails?.type === "Hotel" ||
							requestDetails?.type === "Package"
								? `${
										Rooms?.adult || Rooms?.child
											? ` ${Rooms?.adult} Adults - ${Rooms?.child} Children`
											: "--"
								  }`
								: request?.adults_count || request?.children_count
								? `${request?.adults_count}
										 Adults -
										${request?.children_count}
										Children
								  `
								: "--"
						}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.checkIn}
						inputValue={moment(request?.date_from).format("DD/MM/YYYY")}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.checkOut}
						inputValue={moment(request?.date_to).format("DD/MM/YYYY")}
					/>
					<RequestFeild
						width="24%"
						labelValue={backOffice.Duration}
						inputValue={
							service?.nights_count
								? `${service?.nights_count + 1} days ${
										service?.nights_count
								  } nights`
								: "-"
						}
					/>
				</div>
			</div>
			<div className="bg-white rounded  d-flex flex-column p-3 gap-3">
				<h1 className="h5  px-2 mb-0">{backOffice.Notes}</h1>
				<p
					style={{ fontSize: "16px" }}
					className="w-100 request-feild  p-3  rounded "
				>
					{requestDetails?.notes ? requestDetails?.notes : "-"}
				</p>
			</div>
		</div>
	);
}
