import DatePickerField from "components/shared/DatePickerField";
import moment from "moment";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function RequestModal({ isOpen, toggle, submit, typeRequest }) {
	const { backOffice, translate_companies, companyDetails } = Locale;
	const [rejectReason, setRejectReason] = useState("");
	const [expiration_date, setExpiration_date] = useState(null);

	return (
		<Modal isOpen={isOpen} centered={true}>
			<ModalHeader toggle={toggle}>
				<span>
					{typeRequest === "accept"
						? "Accept Request"
						: backOffice.rejectRequest}
				</span>
			</ModalHeader>

			<ModalBody>
				{typeRequest !== "accept" ? (
					<>
						<p className="m-0">{backOffice.provideRejectReason}</p>

						<textarea
							placeholder={`${backOffice.reasonForRejection}...`}
							name="reject-reason"
							id="reject-reason"
							rows="5"
							className="w-100 control-field__input h-auto"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
						/>
					</>
				) : (
					<h6 className="text-center mb-4">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Expiration Date"}
							isOutsideRange={(day) => {
								return !day.isAfter(moment(), "day");
							}}
							date={expiration_date}
							onChangeDate={(e) => {
								setExpiration_date(e);
							}}
						/>
					</h6>
				)}

				<div className="d-flex" style={{ gap: "20px" }}>
					<button
						className={` w-100 ${
							typeRequest !== "accept" ? "reject-btn" : "bg-success text-white"
						}`}
						onClick={() => {
							if (typeRequest === "accept") {
								return submit({ expiration_date });
							} else {
								submit({ reject_reason: rejectReason });
							}
						}}
						disabled={
							typeRequest === "accept"
								? expiration_date == null
								: rejectReason.length === 0
						}
					>
						{typeRequest == "accept" ? "Accept " : "Reject "}
					</button>
					<button className="btn btn-secondary w-100" onClick={toggle}>
						{companyDetails.cancleBtn}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
