import SelectField from "components/shared/SelectField";
// import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { listSuppliersOnlineVisa } from "services/Request";
import { cleanEmpty } from "services/general";
import {
	addTransportationConfigurations,
	vehicleModelLookup,
	vehicleTypeLookup,
	viewTransportationConfigurations,
} from "services/ground";
import { fetchNewCountries } from "services/lookups";
import Locale from "translations";
import AddTransportation from "./addTransportation/AddTransportation";

export default function TransportationConfiguration() {
	const { backOffice } = Locale;
	const [arrayCountry, setArrayCountry] = useState([]);
	let locale = localStorage.getItem("currentLocale") || "en";
	// const { allCountries } = useGlobalState();
	const [destination, setDestination] = useState([]);
	const [indexConfiguration, setIndexConfiguration] = useState(-1);
	const [ShowAdd, setShowAdd] = useState(false);
	const [vehicleType, setVehicleType] = useState([]);
	const [vehicleModel, setVehicleModel] = useState([
		{ id: 1, year: 2000 },
		{ id: 2, year: 2001 },
		{ id: 3, year: 2002 },
		{ id: 4, year: 2003 },
	]);
	const [listSuppliers, setListSuppliers] = useState([]);

	useEffect(() => {
		async function vehicleLookups() {
			const res = await vehicleTypeLookup();
			const format = res?.data?.data?.map((t) => ({
				value: t.id,
				label: t.name,
			}));

			const res1 = await vehicleModelLookup();
			const format1 = res1?.data?.data?.map((t) => ({
				value: t.id,
				label: t.year,
			}));
			// const format1 = vehicleModel.map((t) => ({
			// 	value: t.id,
			// 	label: t.year,
			// }));
			setVehicleType(format);
			setVehicleModel(format1);
		}
		vehicleLookups();
	}, []);
	const [inputsState, setInputsState] = useState([]);

	const add = () => {
		add2();
	};
	const [viewConfiguration, setviewConfiguration] = useState([]);
	const [country, setCountry] = useState({});
	const add2 = async () => {
		if (viewConfiguration?.length > 0) {
			let x = viewConfiguration.map((res, index) => {
				return {
					destination_id: res.country ? res.country_code : res.destination?.id,
					residences: res.residences.map((residence) => {
						return {
							currency_code:
								residence.currency_code == ""
									? residence.currency?.label
									: residence.currency?.label ?? residence?.currency_code,

							price: residence.price,
							// cycle_type_id: !residence?.cycleTypes
							// 	? residence?.cycle_type?.id
							// 	: residence?.cycleTypes?.value,
							cycle_type_id:
								residence?.cycle_type &&
								residence?.cycle_type.hasOwnProperty("id")
									? residence?.cycle_type?.id
									: residence?.cycle_type?.value,
							residences: residence?.residences?.map((c) => c?.value || c?.id),
							residence_id:
								residence?.residence &&
								residence?.residence.hasOwnProperty("id")
									? residence?.residence?.id
									: residence?.residence?.value,
							vehicle_type_id: residence?.vehicle_type?.hasOwnProperty("id")
								? residence?.vehicle_type?.id
								: residence?.vehicle_type?.value,
							vehicle_model_id: residence?.vehicle_model?.hasOwnProperty("id")
								? residence?.vehicle_model?.id
								: +residence?.vehicle_model?.value,
							seats_count: +residence.seats_count,
							expiration_days: +residence.expiration_days,
							include_ground: residence.include_ground ? 1 : 0,
							start_date: residence?.start_date
								? moment(residence?.start_date).format("YYYY-MM-DD")
								: residence?.start_date,
							end_date: residence?.end_date
								? moment(residence?.end_date).format("YYYY-MM-DD")
								: residence?.end_date,
							supplier_id:
								residence?.supplier?.id || residence?.supplier?.value,
							supplier_name:
								residence?.supplier?.name || residence?.supplier?.label,
							photo: residence?.photo,
						};
					}),
				};
			});
			const res = await addTransportationConfigurations({
				destinations: cleanEmpty(x),
			});
			if (res.status == 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			const res = await addTransportationConfigurations({
				destinations: [],
				reset_config: 1,
			});
			if (res.status == 200) {
				view();
				store.addNotification({
					title: "Info!",
					message: res?.msg ? res?.msg : "done successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	{
		/********* View Configuration  ********* */
	}

	useEffect(() => {
		view();
	}, []);
	const view = async () => {
		const res = await viewTransportationConfigurations();

		if (res.status == 200) {
			setviewConfiguration(res?.data.destinations);
			setInputsState(res?.data.destinations);
		}
	};
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function countriesLookups() {
			const res = await fetchNewCountries();
			const format = res?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, []);

	useEffect(() => {
		const fetchSuppliers = async () => {
			const res = await listSuppliersOnlineVisa();
			if (res.status === 200 || res.status === 201) {
				const formatted = res.data.data.map((item) => {
					return { value: item.id, label: item.name };
				});
				setListSuppliers(formatted);
			}
		};
		fetchSuppliers();
	}, []);

	const AddCountry = () => {
		let x = viewConfiguration != undefined ? viewConfiguration : [];
		x.push({
			country: country.label,
			country_code: country.value,
			residences: [{}],
		});
		setShowAdd(false);
		setviewConfiguration([...x]);
	};
	return (
		<div className="visa-configuration">
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					<AddTransportation
						// country={country}
						destination={destination}
						setDestination={setDestination}
						indexConfiguration={indexConfiguration}
						setIndexConfiguration={setIndexConfiguration}
						inputsState={inputsState}
						setInputsState={setInputsState}
						arrayCountry={arrayCountry}
						setArrayCountry={setArrayCountry}
						viewConfiguration={viewConfiguration}
						setviewConfiguration={setviewConfiguration}
						country={country}
						countries={countries}
						vehicleModel={vehicleModel}
						vehicleType={vehicleType}
						listSuppliers={listSuppliers}
					/>
				</>
			) : null}
			<div className="d-flex justify-content-between align-items-start pb-5">
				<ShowForPermission permission="Transportation-Configuration-Manage">
					{ShowAdd ? (
						<div className="row  w-75">
							<div className="col-4 add-country-field-config  ">
								<SelectField
									label={backOffice.country}
									placeholder={backOffice.country}
									options={countries}
									id="basic-countries"
									name="country"
									onChange={(e) => {
										setCountry(e);
									}}
									value={country}
								/>
							</div>
							<div className="col-2 mt-4">
								<button
									className="btn btn-success   px-5"
									onClick={() => AddCountry()}
								>
									Add
								</button>
							</div>
						</div>
					) : (
						<button
							className="btn btn-success p-2 my-2"
							onClick={() => {
								setShowAdd(true);
							}}
						>
							<i class="fas fa-plus-circle text-white mx-1"></i>
							{backOffice.AddCountry}
						</button>
					)}
				</ShowForPermission>

				<div className="row custom--row mt-2 flex-row-reverse pb-5 mb-5">
					<ShowForPermission permission="Transportation-Configuration-Manage">
						<div className="mx-1">
							<button className="btn btn-primary w-100 px-5" onClick={add}>
								{backOffice.save}
							</button>
						</div>
					</ShowForPermission>
					<div className="mx-1">
						<Link className={`btn btn-sbs-secondary w-100`} to={`/`}>
							{backOffice.back}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
