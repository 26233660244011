import Model from "components/model";
import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import PayForm from "../../../Financial/paymentform";

function PackagesBookinglist({ packagesList, goTo, meta, select, setselect }) {
	const { backOffice, booking } = Locale;
	// const { locale } = useGlobalState();

	const [isOpen, setIsopen] = useState(false);
	const toggle = () => {
		setIsopen(!isOpen);
	};
	// const selectItem = document.getElementsByClassName("select-item");

	// useEffect(() => {
	// 	console.log(select);
	// }, [select]);

	// function selectAll(event) {
	// 	const isSelectorChecked = event.target.checked;
	// 	if (isSelectorChecked) {
	// 		let allselect = packagesList.map((res) => res.reservation_num);
	// 		for (var item of selectItem) {
	// 			item.checked = true;
	// 			setselect([allselect]);
	// 		}
	// 	} else {
	// 		for (let item of selectItem) {
	// 			item.checked = false;
	// 			setselect([]);
	// 		}
	// 	}
	// }
	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "paid":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};
	const priorityBackground = (status) => {
		switch (status) {
			case "high":
				return "bg-high-papriority";
			case "medium":
				return "bg-meduim-papriority";
			case "low":
				return "bg-low-papriority";
			default:
				return "";
		}
	};
	function selectAll(event) {
		const selectItem = document.getElementsByClassName("select-item");
		const isSelectorChecked = event.target.checked;
		if (isSelectorChecked) {
			for (var item of selectItem) {
				item.checked = true;
				setselect([
					...packagesList.map((item, index) => item?.reservation_num),
				]);
			}
		} else {
			for (let item of selectItem) {
				item.checked = false;
				setselect([]);
			}
		}
	}
	const handleCheckboxChange = (event, member) => {
		if (event?.target.checked === true) {
			setselect([...select, member?.reservation_num]);
		} else {
			let newListChecked = select.filter(
				(ref) => ref !== member.reservation_num
			);
			setselect(newListChecked);
		}
	};

	return (
		<>
			<div className="our-border ">
				<table className="financialTable  align-middle table table-striped">
					<thead>
						<tr>
							<th>
								<input
									name="selectItem"
									className="select-all mx-2"
									onChange={selectAll}
									checked={select.length < 10 ? false : true}
									type="checkbox"
								/>
							</th>
							<th className="bg-sea">{backOffice.RefNo}</th>
							<th className="bg-sea" width="9%">
								{backOffice.Owner}
							</th>
							<th className="bg-sea" width="8%">
								{backOffice.DepartureNights}
							</th>
							<th className="bg-sea">{backOffice.PackagePax}</th>
							<th className="bg-army">{backOffice.TravelAgent}</th>
							<th className="bg-army">{booking.Status}</th>
							<th className="bg-pinky">{backOffice.Total}</th>
							<th className="bg-pinky" width="8%">
								{backOffice.PaidOutstanding}
							</th>
							<th className="bg-border" width="8%">
								{backOffice.RequestDate}
							</th>
							<th className="bg-border" width="7%">
								{backOffice.RequestType}
							</th>
							<th className="bg-border" width="12%">
								{backOffice.Operations}
							</th>
						</tr>
					</thead>
					<tbody>
						{packagesList?.length > 0 &&
							packagesList.map((item, index) => (
								<tr key={item.id}>
									<td className={priorityBackground(item?.priority)}>
										<input
											className="select-item mx-2"
											type="checkbox"
											checked={select.includes(item?.reservation_num)}
											onChange={(e) => {
												handleCheckboxChange(e, item);
											}}
											id="1"
										/>{" "}
									</td>
									<td className={priorityBackground(item?.priority)}>
										{item.reference_num}
									</td>
									<td>
										<i class="fas fa-user-circle"></i> {item?.owner_name}
									</td>
									<td>
										{moment(item?.departure_date).format("DD-MMM-YY")}
										<br />
										{item?.nights} {backOffice.nights}
									</td>
									<td>
										<p className="mb-0">
											{item.trip_type} {backOffice.Package}
										</p>
										<i class="fas fa-users"></i> {item.pax}
									</td>
									<td>
										<i class="fas fa-user-circle"></i> {item?.travel_agent}
									</td>
									<td className={statusClasses(item?.reservation_status)}>
										{item?.reservation_status}
									</td>
									<td>
										{+item?.total_price} {item?.currency}
									</td>
									<td>
										<p className="mb-0" style={{ color: "rgb(112 215 11)" }}>
											{+item?.payments_total_paid} {item?.currency}
										</p>
										<p className="mb-0" style={{ color: "#B17A00" }}>
											{+item?.payments_outstanding} {item?.currency}
										</p>
									</td>
									<td>
										{moment(item?.created_at).format("DD-MMM-YY")}
										<br />
										{moment(item?.created_at).format("HH:mm")}
									</td>
									<td>
										{" "}
										<p>{item?.reservation_from}</p>
									</td>
									<td>
										<div className="operations d-flex justify-content-around text-black">
											<Link
												className="btn btn-header pointer"
												to={`/b2c-redirect/${item?.reservation_num}`}
												title={backOffice.Redirect}
											>
												<i class="fas fa-random"></i>
											</Link>
											<Link
												className="btn btn-header pointer"
												to={`/b2c-change/${item?.reservation_num}`}
												title={backOffice.ChangePackage}
											>
												<i class="fas fa-exchange-alt"></i>
											</Link>
											{/* 	<button className="btn btn-header pointer">
												<i class="far fa-times-circle" />
											</button> */}
											<Link
												to={`/b2c-viewpackages/${item.reservation_num}`}
												className="btn btn-header pointer"
												title={backOffice.viewPackage}
											>
												<i class="fas fa-eye "></i>
											</Link>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<Pagination info={meta} goTo={goTo} />
			</div>
			<Model
				isOpen={isOpen}
				title={"Financial payment"}
				toggle={toggle}
				size="lg"
				hasFooter={false}
			>
				<PayForm />
			</Model>
		</>
	);
}

export default PackagesBookinglist;
