import { ReactComponent as ArrowIcon } from "assets/images/arrow-up.svg";
import imagePlaceholder from "assets/images/country-placeholder.png";
import { ReactComponent as CloseIcon } from "assets/images/galleryremove.svg";
import { ReactComponent as SearchIcon } from "assets/images/searchnormal.svg";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { uploadFile } from "services/auth";
import { listCountry, updateCountryIcon } from "services/countryConfiguration";
import Locale from "translations";
function CountryConfiguration() {
	const [filter, setFilter] = useState("");
	const [list, setlList] = useState([]);
	const [modalState, setModalState] = useState({
		isOpen: false,
		data: null,
	});
	const { backOffice } = Locale;
	const closeModal = () => setModalState({ data: null, isOpen: false });
	const fetchListCountry = async () => {
		const res = await listCountry();
		if (res.status === 200) {
			setlList(res.data.data);
		}
	};
	useEffect(() => {
		fetchListCountry();
		return () => {};
	}, []);
	const imageUrl = process.env.REACT_APP_API_URL + "/api/files/fetch/";
	const onChangeIcon = async (e, type) => {
		const file = e.target.files[0];
		let formData = new FormData();
		formData.append("file", file);

		const data = await uploadFile(formData);
		if (data.status === 200) {
			debugger;
			setModalState((prev) => ({
				...prev,
				data: {
					...prev.data,
					...(type === "flag"
						? { flag: data?.data?.uuid }
						: { image: data?.data?.uuid }),
				},
			}));
		}
	};
	console.log(modalState, "modalState");

	const onEdit = async () => {
		const res = await updateCountryIcon(modalState.data.country_id, {
			image: modalState.data.image,
			flag: modalState.data.flag,
		});
		if (res.status === 200) {
			closeModal();
			fetchListCountry();
		}
	};
	return (
		<div className="country-configuration-container">
			<p className="country-title">{backOffice.UploadCountryIcon}</p>
			<div className="country-header">
				<div className="filter-input">
					<SearchIcon className="search-icon" />
					<input
						name="filter"
						id="filter"
						placeholder={backOffice.SearchAnything}
						hasLabel={false}
						value={filter}
						onChange={(e) => {
							setFilter(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="country-list">
				{list.map((country) =>
					country.name
						.toLocaleLowerCase()
						.includes(filter.toLocaleLowerCase()) ? (
						<CountryCard
							key={country.country_id}
							details={country}
							onReplace={() => setModalState({ isOpen: true, data: country })}
						/>
					) : null
				)}
			</div>

			<Modal
				isOpen={modalState.isOpen}
				// onRequestClose={props.closeModal}
				size="sm"
				style={{ minWidth: "398px" }}
			>
				<ModalBody>
					<div className="country-configuration-modal">
						<div className="country-configuration-modal-header">
							<p>{backOffice.EditCountry}</p>
							<button className="close-icon" onClick={closeModal}>
								<CloseIcon />
							</button>
						</div>
						<hr />
						<p className="country-name">{modalState.data?.name}</p>
						<div className="country-configuration-modal-body">
							<img
								src={
									modalState.data?.image
										? `${imageUrl}${modalState.data?.image}`
										: imagePlaceholder
								}
								alt="country icon"
								width={60}
								height={60}
							/>
							<div className="country-configuration-modal-upload">
								<label htmlFor="country-configuration-modal-input">
									<ArrowIcon />
									<span>{backOffice.UploadImage}</span>
								</label>
								<input
									type="file"
									id="country-configuration-modal-input"
									className="country-configuration-modal-input"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => onChangeIcon(e, "image")}
								/>
							</div>
						</div>
						{/* flag */}
						<div className="country-configuration-modal-body mt-3">
							<img
								src={
									modalState.data?.flag
										? `${imageUrl}${modalState.data?.flag}`
										: imagePlaceholder
								}
								alt="flag icon"
								width={modalState.data?.flag ? 160 : 60}
								height={modalState.data?.flag ? 90 : 60}
							/>
							<div className="country-configuration-modal-upload">
								<label htmlFor="country-configuration-modal-inputaaaa">
									<ArrowIcon />
									<span>{backOffice.UploadFlag}</span>
								</label>
								<input
									type="file"
									id="country-configuration-modal-inputaaaa"
									className="country-configuration-modal-input"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => onChangeIcon(e, "flag")}
								/>
							</div>
						</div>

						<hr />
						<button
							className={`country-configuration-modal-edit ${
								modalState.data?.image ? "" : "bg-gray"
							}`}
							onClick={onEdit}
							disabled={!modalState.data?.image}
						>
							{backOffice.edit}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default CountryConfiguration;
const { backOffice } = Locale;
const CountryCard = ({ details, onReplace }) => {
	const imageUrl = process.env.REACT_APP_API_URL + "/api/files/fetch/";
	const { backOffice } = Locale;
	return (
		<div className="country-card">
			<p className="m-0">{details.name}</p>
			<img
				src={details?.image ? `${imageUrl}${details?.image}` : imagePlaceholder}
				alt="country icon"
				width={60}
				height={60}
			/>
			<ShowForPermission permission="Manage-Countries-Configuration">
				<button className="replace-image" onClick={onReplace}>
					<ArrowIcon />
					<span className="ms-1">{backOffice.ReplaceImage}</span>
				</button>
			</ShowForPermission>
		</div>
	);
};
