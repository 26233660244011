import { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import AddCompanyDetails from "./addCompanyDetails";

export default function AddCompany() {
	const { backOffice } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	return (
		<div className="edit-supplier container-fluid ">
			<div className="">
				<div className="text-black">
					<div className="d-flex  ">
						<p className="h4 text-black"> {backOffice.AddCompany}</p>
						{/* 	<Nav tabs>
							<NavItem>
								<NavLink
									className={`h4 text-black ${
										activeTab === "1" ? "is-active" : ""
									}`}
									onClick={() => {
										toggle("1");
									}}
								>
									Add New Company
								</NavLink>
							</NavItem>
						</Nav> */}
					</div>
				</div>
			</div>
			<div className="mt-3">
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<AddCompanyDetails />
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
}
