import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function IssueVisaModal({
	isOpen,
	toggle,
	issueVisa,
	setIssueVisa,
	setErrors,
	errors,
	addIssueVisa,
}) {
	const { backOffice } = Locale;
	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<div className="lan d-flex justify-content-between align-items-baseline p-2 border-bottom vendor-wallet-view-popup ">
				<p className="  h4  font-weight-bold">{backOffice.IssueVisa}</p>
				<i className="far fa-times-circle fa-lg  pointer" onClick={toggle}></i>
			</div>
			<ModalBody className="lan d-flex flex-column justify-content-center align-items-center p-0 py-3">
				<div className=" m-0 p-0 w-75">
					<div className="col-md-12 ">
						<TextField
							name="visaNumber"
							placeholder={backOffice.visaNumber}
							label={backOffice.visaNumber}
							value={issueVisa.visaNumber}
							onChange={(e) => {
								setIssueVisa({
									...issueVisa,
									visaNumber: e.target.value,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "visaNumber", value: e.target.value },
										{
											required: true,
											number: true,
										}
									),
								});
							}}
							errors={errors?.visaNumber}
							color={
								errors?.visaNumber?.required || errors?.visaNumber?.number
									? "danger"
									: ""
							}
						/>
					</div>

					<div className=" our-border-dashed mx-3">
						<h6 className="p-2 span">{backOffice.UploadVisaDocument}</h6>
						<div
							className={`py-3  text-center d-flex flex-column justify-content-center ${
								errors?.logo?.required ? "border border-danger" : ""
							}`}
						>
							{issueVisa.displayImg ? (
								<figure className="d-flex  justify-content-center ">
									{issueVisa?.logo?.type !== "application/pdf" ? (
										<img
											src={`${issueVisa.displayImg}`}
											width={150}
											height={150}
											alt="attachment"
										/>
									) : (
										<>
											<i className="fas fa-paperclip file-attach" />
											{issueVisa?.logo?.name}
										</>
									)}
								</figure>
							) : null}
							<button className="file-btn shape-border w-50 m-auto pointer">
								{backOffice.AddAttachment}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif,image/jpeg,application/pdf"
									onChange={(e) => {
										setIssueVisa({
											...issueVisa,
											displayImg: URL.createObjectURL(e.target.files[0]),
											logo: e.target.files[0],
										});
										setErrors({
											...errors,
											...validate(
												{ name: "logo", value: e.target.value },
												{
													required: true,
												}
											),
										});
									}}
								/>
							</button>
						</div>
					</div>

					<div className=" our-border-dashed mx-3 mt-3">
						<h6 className="p-2 span">{backOffice.UploadInsuranceFile}</h6>
						<div
							className={`py-3  text-center d-flex flex-column justify-content-center`}
						>
							<div
								className={`py-3  text-center d-flex flex-column justify-content-center `}
							>
								{issueVisa?.insurance && (
									<figure className="d-flex  justify-content-center ">
										<i className="fas fa-paperclip file-attach" />
										{issueVisa?.insurance?.name || issueVisa?.insurance}
									</figure>
								)}
								<button className="file-btn shape-border w-50 m-auto pointer">
									{backOffice.AddAttachment}
									<input
										type="file"
										className="custom-file"
										accept="application/pdf"
										onChange={(e) => {
											setIssueVisa({
												...issueVisa,
												insurance: e.target.files[0],
											});
										}}
									/>
								</button>
							</div>
						</div>
					</div>

					<button
						className="btn companies-btn w-100 my-3"
						onClick={addIssueVisa}
						disabled={!issueVisa.visaNumber || !issueVisa.displayImg}
					>
						{backOffice.Send}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
