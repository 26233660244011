import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { fetchCompaniesLookups } from "services/sbsReservations";
import Locale from "translations";

export default function FilterWallet({ Filter, setFilter }) {
	const { backOffice } = Locale;

	const { allCountries, allCurrencies } = useGlobalState();

	const [selected, setSelected] = useState({});
	const [compamiesLookups, setCompamiesLookups] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function fetchLookups() {
			const companiesLookupsResponse = await fetchCompaniesLookups();
			if (companiesLookupsResponse.status === 200) {
				const formatCompaniesLookups =
					companiesLookupsResponse?.data?.data?.map((company) => {
						return { ...company, label: company?.name, value: company?.id };
					});
				setCompamiesLookups(formatCompaniesLookups);
			}
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);

	useEffect(() => {
		async function fetch() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names["en"],
				code: t.country_code,
			}));
			setCountries(format);
		}
		fetch();
	}, [allCountries]);

	return (
		<>
			<div className="main-filter bg-white-blue py-2 mt-2">
				<div className="row align-items-center  w-100 no-gutter m-0">
					<div className="col-md-3">
						<SelectField
							label={backOffice.company}
							placeholder={backOffice.company}
							value={selected.search}
							options={compamiesLookups}
							onChange={(e) => {
								setFilter({
									...Filter,
									company_id: e.value,
								});
								setSelected({
									...selected,
									search: e,
								});
							}}
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							label={backOffice.currency}
							placeholder={backOffice.select}
							value={selected.currency}
							options={currencies}
							onChange={(e) => {
								setFilter({
									...Filter,
									currency: e.label,
								});
								setSelected({
									...selected,
									currency: e,
								});
							}}
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							label={backOffice.country}
							placeholder={backOffice.select}
							value={selected.country}
							options={countries}
							onChange={(e) => {
								setFilter({
									...Filter,
									country_id: e.value,
								});
								setSelected({
									...selected,
									country: e,
								});
							}}
						/>
					</div>
					<div className="col-md d-flex align-items-center gap-2 ">
						<button
							onClick={(e) => {
								setSelected({ search: "", currency: "", country: "" });
								setFilter({});
							}}
							className="d-flex align-items-center  text-secondary pointer mt-4 reset-btn"
						>
							<img src={resetIcon} alt="" srcset="" />
							<p className="text-caption">{backOffice.resetFilter}</p>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
