import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// ReactStrap
import { UncontrolledTooltip } from 'reactstrap';
// Translation
import Locale from 'translations';
// Icons
import { InfoIcon, LeftArrowIcon } from 'components/Icons';
// Services
import { getDefaultPricingById } from 'services/safaVisa';
// Context
import { useGlobalState } from 'context/global';
// ------------------------------------------------------------------

export default function DefaultPricingDetails() {

  // *** Hooks
  let history = useHistory();

  let { id } = useParams();

  const { locale } = useGlobalState();

  const { backOffice, safaVisa } = Locale;

  // ** State
  const [pricingDetails, setPricingDetails] = useState({})

  // *** Function
  const defineIsActive = (status) => {
    return status === 1 ? true : false
  }

  const fetchDefaultPriceDetails = async () => {
    const response = await getDefaultPricingById(id)
    if (response?.status === 200) {
      setPricingDetails(response?.data?.data)
    }
  }

  useEffect(() => {
    fetchDefaultPriceDetails()
  }, [])

  // console.log(id)

  // ----------- JSX Code ---------
  return (
    <div className='safaVisa-wrapper'>

      <div className='holder mb-3'>

        {/* Default Pricing Details Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.pricingInformation}</h4>

          <div className='d-flex gap-2 align-items-center'>
            <p className={`${defineIsActive(pricingDetails?.is_active) ? 'active-status ' : 'notActivated-status'}`}>
              {pricingDetails?.is_active === 1 ? safaVisa.active : safaVisa?.notActive}
            </p>
          </div>
        </div>

        <hr />

        {/* Default Pricing Details Info */}
        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.pricing}</p>
            <p className='subTitle'> {pricingDetails?.title?.[locale]}</p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.creationDate}</p>
            <p className='subTitle'>{pricingDetails?.created_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.updatingDate}</p>
            <p className='subTitle' >{pricingDetails?.updated_at}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.country}</p>
            <p className='subTitle d-flex' >
              {pricingDetails?.countries?.[0]?.name}
              {
                pricingDetails?.countries?.length > 1 ?
                  <>
                    <span
                      href="#"
                      className='more-info-toolTip'
                      id="UncontrolledTooltipExample"
                    >
                      +{pricingDetails?.countries?.length - 1}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="UncontrolledTooltipExample"
                    >
                      {pricingDetails?.countries?.slice(1).map(item =>
                        <>
                          {item?.name} {' '}-
                        </>
                      )}
                    </UncontrolledTooltip>
                  </>
                  :
                  null
              }

            </p>
          </div>

        </div>

        <div className='pricing-info-holder mb-5'>

          <div>
            <p className='mb-2 title'>{safaVisa.currency}</p>
            <p className='subTitle'>{pricingDetails?.currency} </p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title'>
              <span href="#" id={`UncontrolledTooltipExample_audience`}>
                <InfoIcon color='#9C9FAB' width='20' height='20' />
              </span>
              {' '} {safaVisa.audience}
            </p>
            <UncontrolledTooltip
              placement="top"
              target={`UncontrolledTooltipExample_audience`}
            >
              {safaVisa.audienceTypeDescription}
            </UncontrolledTooltip>
            <p className='subTitle' >{safaVisa[`${pricingDetails?.audience_type}`]}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.numberOfContracts}</p>
            <p className='subTitle' >{pricingDetails?.contract_count || safaVisa?.indeterminate}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title'>{safaVisa.numberOfAgents}</p>
            <p className='subTitle'>{pricingDetails?.agents_count || safaVisa?.indeterminate}</p>
          </div>

        </div>

        {/* Pricing Description */}
        <div className='pricing-info-holder'>
          <div>
            <p className='mb-2 title'>{safaVisa.description}</p>
            <p className='subTitle'> {pricingDetails?.description?.[locale]}</p>
          </div>
        </div>
      </div>

      {/* Default Pricing List  Table */}
      <div className='holder'>
        <h4 className="main-title mb-4 ">{safaVisa.pricing}</h4>
        <div className="our-border">
          <table className="custom-table back-office-table table table-striped mb-0">

            <thead>
              <tr>
                <th>{safaVisa.id}</th>
                <th> {safaVisa.visaType}</th>
                <th>{safaVisa.pricePerVisa}</th>
                <th>{safaVisa.currency}</th>
              </tr>
            </thead>

            <tbody>
              {pricingDetails?.prices?.length > 0 ? (
                pricingDetails?.prices?.map((row, index) =>
                  <tr>
                    <td><p className='id-text'> {index + 1} </p></td>
                    <td>{safaVisa?.[`${row?.visa_type}`]}</td>
                    <td> <span > {row?.price}</span></td>
                    <td > {pricingDetails?.currency}</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="product-no-data">
                      <i className="fas fa-info-circle fa-lg"></i>{" "}
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div >
      </div >

      {/* Default Pricing Details Pagination */}
      {/* <Pagination info={meta} goTo={goTo} items={10} /> */}

      <button
        className="btn back-btn py-2 mt-5"
        onClick={() => history.push("/safaVisa-defaultPricing")}
      >
        <span  >
          <LeftArrowIcon color='#1e85ff' width='24' height='24' />
        </span>
        {safaVisa.back}
      </button>

    </div>
  )
}

