import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	CreateSubscripionPackgePlan,
	FetchSubscripionConfigurations,
	UpdateSubscripionPackagePlan,
	ViewSubscripionConfigurationsPackage,
} from "services/subscriptionPlan";
// import ChangeStatus from "../Models/changeStatus";
import { fetchPackageCountries } from "services/lookups";
import Locale from "translations";
import Discount from "./Discount";
import ModuleList from "./ModuleList";
import PackageHeader from "./PackageHeader";
import TotalPrice from "./TotalPrice";
import { formateSubscriptionConfig } from "./shared/formateSubscriptionConfig";

export const CATEGORIES_OPTIONS = [
	{ value: 1, label: "Bravo", id: "bravo" },
	{ value: 2, label: "Website", id: "website" },
	{ value: 3, label: "Booking Engine & CRM", id: "booking-engine-crm" },
	{ value: 4, label: "API Integration", id: "api-integration" },
];

export default function AddEditSubscriptionPackagePlan() {
	const { company_id, plan_id } = useParams();
	const history = useHistory();
	const { allCurrencies, allCountries } = useGlobalState();
	const location = useLocation();
	const { pathname } = location;
	const isPackage = pathname.includes("package") && !pathname.includes("add");
	const isEditPackage = pathname.includes("edit");
	// ** hooks

	const [subscriptionState, setSubscriptionState] = useState();
	const [currenciesOptions, setCurrenciesOptions] = useState([]);
	const [countriesOptions, setCountriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] =
		useState(CATEGORIES_OPTIONS);
	const [selectedCurrency, setSelectedCurrency] = useState();
	const [selectedPlan, setSelectedPlan] = useState([]);

	const locale = localStorage.getItem("currentLocale") || "en";

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrenciesOptions(format);
		}
		async function countriesLookup() {
			const format = allCountries?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountriesOptions([...format]);
		}
		currenciesLookup();
		// countriesLookup();
	}, [allCurrencies, allCountries]);

	useEffect(() => {
		async function fetchCountries() {
			const res = await fetchPackageCountries();
			const format = res?.data?.data?.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountriesOptions([...format]);
		}
		fetchCountries();
	}, []);

	useEffect(() => {
		async function viewConfigurations() {
			const res = await FetchSubscripionConfigurations();
			const data = formateSubscriptionConfig(
				res?.data?.data,
				"add",
				countriesOptions
			);
			setSubscriptionState(data);
			setSelectedCurrency({
				value: data?.currency,
				label: data?.currency,
			});
		}
		async function viewPlanConfigurations() {
			const res = await ViewSubscripionConfigurationsPackage(plan_id);
			const data = formateSubscriptionConfig(
				res?.data?.data,
				"edit",
				countriesOptions
			);
			setSubscriptionState(data);
			setSelectedCurrency({
				value: data?.currency,
				label: data?.currency,
			});
		}

		if (!plan_id) {
			viewConfigurations();
		} else {
			viewPlanConfigurations();
		}
	}, [countriesOptions]);

	/*** All Subscription Function ***/

	const handleCheck = (itemId, flag) => {
		if (flag) {
			setSelectedPlan([...selectedPlan, itemId]);
		} else {
			setSelectedPlan([...selectedPlan.filter((id) => id != itemId)]);
		}
		disabledaction();
	};

	const handleCheckAll = (collectionPlan, flag, id) => {
		let clone = subscriptionState;
		let selectedSubItem;

		let selectedItem = clone.modules.find((item) => item.id === id);
		selectedSubItem =
			selectedItem.subModules?.length > 0 ? selectedItem.subModules : [];

		if (flag) {
			setSelectedPlan([...new Set([...selectedPlan, ...collectionPlan])]);

			selectedSubItem =
				selectedSubItem?.length > 0 &&
				selectedSubItem.map((subItem) => ({ ...subItem, status: "active" }));
		} else {
			setSelectedPlan(
				selectedPlan.filter((per) => !collectionPlan.includes(per))
			);

			selectedSubItem =
				selectedSubItem?.length > 0 &&
				selectedSubItem.map((subItem) => ({ ...subItem, status: "inactive" }));
		}
		setSubscriptionState({ ...clone });
	};
	const handleCheckAllModules = (flag) => {
		const clone = subscriptionState;
		let cloneModules;
		if (flag) {
			cloneModules = clone?.modules?.map((module) => ({
				...module,
				status: "active",
				subModules:
					module.subModules.length > 0
						? module.subModules.map((subItem) => ({
								...subItem,
								status: "active",
						  }))
						: [],
			}));
		} else {
			cloneModules = clone?.modules?.map((module) => ({
				...module,
				status: "inactive",
				subModules:
					module.subModules.length > 0
						? module.subModules.map((subItem) => ({
								...subItem,
								status: "inactive",
						  }))
						: [],
			}));
		}
		setSubscriptionState({ ...clone, modules: cloneModules });
	};
	const handlePaidType = (
		typeInput,
		id,
		value,
		issub = false,
		idSubModule = null,
		SubModuleArr = [],
		parentModuleId = null
	) => {
		let clone = subscriptionState;
		let selectedItem;
		if (!issub) {
			selectedItem = clone.modules.find((item) => item.id === id);
		} else {
			selectedItem = clone.modules.find((item) => item.id === id);
			selectedItem = selectedItem.subModules.find(
				(item) => item.id === idSubModule
			);
		}
		if (typeInput === "type") {
			selectedItem.type = value;
			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				if (parentModuleId !== null) {
					selectedItem = clone.modules.find(
						(item) => item.id === parentModuleId
					);
					selectedItem.price = +sumSubModule;
				} else {
					selectedItem = clone.modules.find((item) => item.id === id);
					selectedItem.price = +sumSubModule;
				}
			}
		} else if (typeInput === "price") {
			selectedItem.price = +value;

			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				selectedItem = clone.modules.find((item) => item.id === parentModuleId);

				selectedItem.price = +sumSubModule;
			}
		} else {
			selectedItem.status = value;
			if (SubModuleArr.length > 0) {
				const sumSubModule = SubModuleArr?.filter(
					(module) => module.status === "active" && module.type === "paid"
				)?.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue?.price;
				}, 0);
				if (typeof parentModuleId == "number") {
					selectedItem = clone.modules.find(
						(item) => item.id === parentModuleId
					);
					selectedItem.price = +sumSubModule;
					let SubModuleIsActive = SubModuleArr.some(
						(item) => item.status === "active"
					);
					if (SubModuleIsActive) {
						selectedItem.status = "active";
					} else {
						selectedItem.status = "inactive";
					}
				}
				if (parentModuleId === "parent") {
					selectedItem.subModules = SubModuleArr.map((item) => ({
						...item,
						status: value,
					}));
				}
			}
		}
		// TotalPrice();
		disabledaction();
		setSubscriptionState({ ...clone });
	};
	const handleCurrency = ({ value, label }) => {
		let clone = subscriptionState;
		clone.currency = label;
		setSubscriptionState({ ...clone });
	};
	const addPlan = async (isTrial) => {
		const modules = subscriptionState?.modules.map((module) => ({
			module_id: module.id,
			status: module.status,
			type: module.type,
			price: module.price,
		}));
		const sub_modules = subscriptionState?.modules.map((module) => {
			if (module.subModules?.length > 0) {
				return module.subModules.map((sub_module) => ({
					module_id: sub_module.id,
					status: sub_module.status,
					type: sub_module.type,
					price: sub_module.price,
				}));
			}
		});
		const data = {
			...subscriptionState,
			modules: [...modules, ...sub_modules?.flat()],
			countries:
				subscriptionState?.countries?.length === countriesOptions?.length
					? []
					: subscriptionState?.countries?.map((country) => country.value),
			title: {
				ar: subscriptionState?.title?.ar,
				en: subscriptionState?.title?.en,
			},
			discount_value:
				subscriptionState?.discount_type === "percentage"
					? subscriptionState?.discount_value
					: subscriptionState?.fixed_value,
			category: subscriptionState?.category?.id,
			description: {
				ar: subscriptionState?.description?.ar,
				en: subscriptionState?.description?.en,
			},
		};
		if (!plan_id) {
			const res = await CreateSubscripionPackgePlan(data);
			if (res?.status == 200 || res?.status == 201) {
				store.addNotification({
					title: "Info!",
					message: "Add Subscription Package Plan Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push(`/subscription-package-plan`);
					},
				});
			}
		} else {
			const res = await UpdateSubscripionPackagePlan(data, plan_id);
			if (res?.status == 200 || res?.status == 201) {
				store.addNotification({
					title: "Info!",
					message: "Edit Subscription Package Plan Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						if (company_id) {
							history.push(`/tourism-company/${company_id}/subscription-plan`);
						} else {
							history.push(`/subscription-package-plan`);
						}
					},
				});
			}
		}
	};

	useEffect(() => {
		// TotalPrice();
		disabledaction();
	}, [subscriptionState?.modules]);

	const disabledaction = () => {
		let clone = subscriptionState;
		// console.log({ clone });
		let allModules = clone?.modules?.map((module) => {
			if (module.status === "inactive" && module.subModules.length === 0) {
				return true;
			} else if (module.subModules.length > 0) {
				return module.subModules.map((subModule) => {
					if (subModule.status === "inactive" && module.status === "inactive") {
						return true;
					}
				});
			}
		});
		const allInputs =
			clone?.price > 0 &&
			clone?.start_date !== undefined &&
			clone?.duration !== "" &&
			clone?.category &&
			clone?.title &&
			clone?.title.ar !== "" &&
			clone?.title.en !== "" &&
			clone?.description &&
			clone?.description.ar !== "" &&
			clone?.description.en !== "";
		//allModules?.flat().every((element) => element === true) &&
		return allInputs;
	};

	console.log("subscriptionState", subscriptionState);
	const { backOffice } = Locale;
	return (
		<>
			<h3
				style={{
					color: "#707170",
					fontSize: "20px",
					marginBottom: "16px",
					fontWeight: 500,
				}}
			>
				{backOffice.PackagePlan}
			</h3>
			<PackageHeader
				state={subscriptionState}
				setState={setSubscriptionState}
				countriesOptions={countriesOptions}
				categoriesOptions={categoriesOptions}
				isPackage={isPackage}
				isEditPackage={isEditPackage}
			/>

			<div className="subscription-plan add-edit-plan">
				<div className="subscription-header">
					<h4>{backOffice.AvailablePackagePlan}</h4>
					<div className="currency" style={{ width: "auto", gap: "10px" }}>
						<p style={{ width: "auto" }}>{backOffice.SelectCurrency}</p>
						<div className="w-90p mt-2">
							<SelectField
								hasLabel={false}
								value={selectedCurrency}
								options={currenciesOptions}
								onChange={(e) => {
									setSelectedCurrency(e);
									handleCurrency(e);
								}}
								disabled={
									(subscriptionState?.status === "active" && plan_id) ||
									isPackage
								}
							/>
						</div>
					</div>
				</div>

				<ModuleList
					subscriptionData={subscriptionState}
					setSubscription={setSubscriptionState}
					selectedPlan={selectedPlan}
					setSelectedPlan={setSelectedPlan}
					handleCheck={handleCheck}
					handleCheckAll={handleCheckAll}
					handlePaidType={handlePaidType}
					handleCheckAllModules={handleCheckAllModules}
					isPackage={isPackage}
				/>
				<TotalPrice
					state={subscriptionState}
					setState={setSubscriptionState}
					isPackage={isPackage}
				/>
			</div>
			<Discount
				state={subscriptionState}
				setState={setSubscriptionState}
				isPackage={isPackage}
			/>
			<div className="d-flex justify-content-end">
				<button
					className="btn btn-primary no-shadow"
					onClick={() => {
						addPlan(false);
					}}
					disabled={
						!disabledaction() || (isPackage && !isEditPackage) ? true : false
					}
				>
					{backOffice.SaveApply}
				</button>
			</div>
		</>
	);
}
