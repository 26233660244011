import ConfirmModal from "components/Modals/ConfirmModal";
import Pagination from "components/shared/Pagination";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
	deleteAttraction,
	fetchAttraction,
	ShareAttraction,
} from "services/attraction";
import Locale from "translations";
import Filter from "./Filter";
import { CalenderIcon } from "./Icons/calenderIcon";
import { DeleteIcon } from "./Icons/deleteIcon";
import DuplicateIcon from "./Icons/duplicateIcon";
import EditIcon from "./Icons/editIcon";
import { MoreIcon } from "./Icons/moreIcon";
import { PlusIcon } from "./Icons/plusIcon";
import { ShareIcon } from "./Icons/shareIcon";
import { ViewIcon } from "./Icons/viewIcon";
import Share from "./models/share";

export default function Attractions() {
	const { backOffice, booking } = Locale;
	const history = useHistory();
	const [filterState, setFilterState] = useState({
		search: "",
		date: null,
		sort: "", //price, status, date
		sort_by: "desc", //asc
		page: 1,
	});
	const [attractionsLis, setAttractionsLis] = useState([]);

	const [meta, setMeta] = useState(null);
	const [moreIsOpen, setMoreIsOpen] = useState(null);
	const moreActionsRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectAttraction, setSelectAttraction] = useState({});
	const [selectedId, setSelectedId] = useState(null);
	const [shareState, setShareState] = useState({
		to: "",
		from: "",
		payment_type: "",
	});
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);

	const toggle = (data = {}) => {
		setSelectAttraction(data);
		setIsOpen((prev) => !prev);
	};

	const toggleConfirm = (id = null) => {
		setSelectedId(id);
		setConfirmIsOpen(!confirmIsOpen);
	};
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				moreActionsRef.current &&
				!moreActionsRef.current.contains(event.target)
			) {
				setMoreIsOpen(null);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
	}, []);
	useEffect(() => {
		const getAttractionList = async () => {
			const response = await fetchAttraction({
				...filterState,
				date: filterState?.date
					? moment(filterState?.date).format("YYYY-MM-DD")
					: "",
			});
			if (response.status === 200 || response.status === 200) {
				setAttractionsLis(response.data.data);
				setMeta(response.data.meta);
			}
		};
		getAttractionList();
	}, [filterState]);
	useEffect(() => {
		setShareState({
			to: selectAttraction?.share_to
				? moment(selectAttraction?.share_to).format("YYYY-MM-DD")
				: "",
			from: selectAttraction?.share_from
				? moment(selectAttraction?.share_from).format("YYYY-MM-DD")
				: "",
			payment_type: selectAttraction?.book_type ?? "",
		});
		return () => {};
	}, [selectAttraction]);

	const allAttractions = attractionsLis.map((attraction, index) => (
		<tr key={attraction.id}>
			<td>{attraction.name}</td>
			<td>{attraction.operation_day?.day ?? "-"}</td>
			<td>
				<div className="d-flex flex-column">
					<p>{attraction.country?.name}</p>
					<span style={{ color: "#707170", fontSize: "13px", fontWeight: 400 }}>
						{attraction.city?.name}
					</span>
				</div>
			</td>
			<td>{attraction.operation_day?.price_per_pax ?? "-"}</td>
			<td>{attraction.operation_day?.max_pax ?? "-"}</td>
			<td>
				<span
					className={`${
						attraction?.is_shared ? "status-active" : "status-inactive"
					}`}
				>
					{attraction?.is_shared ? "Active" : "Inactive"}
				</span>
			</td>
			<td>
				<div className="actions">
					<ShowForPermission permission="Manage-Attraction">
						<div>
							<button
								className="btn btn-link p-0 m-0"
								onClick={() => toggle(attraction)}
							>
								<ShareIcon />
							</button>
						</div>
						<div>
							<button
								className="btn btn-link p-0 m-0"
								onClick={() =>
									history.push(`/attractions/calender/${attraction.id}`)
								}
							>
								<CalenderIcon />
							</button>
						</div>
					</ShowForPermission>
					<ShowForPermission
						permission={["Manage-Attraction", "View-Attraction"]}
					>
						<div>
							<button
								className="btn btn-link p-0 m-0"
								onClick={() =>
									history.push(`/attractions/view/${attraction.id}`)
								}
							>
								<ViewIcon />
							</button>
						</div>
					</ShowForPermission>
					<ShowForPermission permission="Manage-Attraction">
						<div style={{ position: "relative" }}>
							<button
								className="btn btn-link p-0 m-0"
								onClick={() => setMoreIsOpen(attraction.id)}
							>
								<MoreIcon />
							</button>
							{moreIsOpen == attraction.id && (
								<div
									style={{
										position: "absolute",
										bottom: "4px",
										left: "-165px",
										zIndex: 6,
									}}
								>
									<div className="more-popup" ref={moreActionsRef}>
										<p
											role="button"
											onClick={() =>
												history.push(`/attractions/edit/${attraction.id}`)
											}
										>
											<EditIcon /> Edit
										</p>
										<p role="button">
											<DuplicateIcon /> Duplicate{" "}
										</p>
										<p
											role="button"
											onClick={() => {
												toggleConfirm(attraction.id);
											}}
										>
											<DeleteIcon color={"#292D32"} /> Delete{" "}
										</p>
									</div>
								</div>
							)}
						</div>
					</ShowForPermission>
				</div>
			</td>
		</tr>
	));
	const goTo = (page) => {
		setFilterState((prev) => ({ ...prev, page: page }));
	};

	const share = async () => {
		const data = {
			share_from: moment(shareState.from).format("YYYY-MM-DD"),
			share_to: moment(shareState.to).format("YYYY-MM-DD"),
			book_type: shareState.payment_type,
		};
		const response = await ShareAttraction(selectAttraction?.id, data);
		if (response.status === 200 || response.status === 200) {
			store.addNotification({
				title: "Info!",
				message: "Share Attraction Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setFilterState((prev) => ({ ...prev }));
					setSelectAttraction({});
					toggle();
					setShareState({
						to: "",
						from: "",
						payment_type: "",
					});
				},
			});
		}
	};
	const handleSort = (key) => {
		setFilterState({
			...filterState,
			sort: key,
			sort_by: filterState.sort_by == "desc" ? "asc" : "desc",
		});
	};
	const removeAttraction = async (seasonID) => {
		const res = await deleteAttraction(selectedId);
		if (res.status === 200) {
			store.addNotification({
				title: "Done!",
				message: res.data.message ?? "Deleted Attraction successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setFilterState((prev) => ({ ...prev }));
					setSelectedId(null);
					toggleConfirm();
				},
			});
			setConfirmIsOpen(false);
		}
	};
	return (
		<div className="attractions">
			<div className="attraction-head mx-2">
				<h5>{backOffice.Attractions}</h5>
				<div>
					{/* <button className="btn  commission-btn">
						<PlusIcon color={"#019D4C"} /> Commission
					</button> */}
					<ShowForPermission permission="Manage-Attraction">
						<button
							className="btn btn-success add"
							onClick={() => history.push("/attractions/add")}
						>
							<PlusIcon color={"#fff"} /> {backOffice.AddAttraction}
						</button>
					</ShowForPermission>
				</div>
			</div>
			<Filter filter={filterState} setFilter={setFilterState} />
			<div className="attraction-table  mx-2">
				<table className="custom-table back-office-table table ">
					<thead>
						<tr>
							<th>{backOffice.TourName}</th>
							<th>
								<div className="d-flex align-items-center ">
									{backOffice.TourDate}
									<span
										className="mx-2"
										role="button"
										onClick={() => handleSort("date")}
									>
										{/* <i
										className={`${
											filterState.sort == "date"
												? filterState?.sort_by === "asc"
													? "fa fa-sort-amount-up"
													: "fa fa-sort-amount-down"
												: null
										}  mx-1`}
									></i> */}

										<p className="d-flex flex-column">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="#4B465C"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="white"
													stroke-opacity="0.1"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>

											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M3.375 5.5625L6.75 8.9375L10.125 5.5625"
													stroke="#707170"
													stroke-width="1.125"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</p>
									</span>
								</div>
							</th>
							<th>{backOffice.country}</th>
							<th>
								<div className="d-flex align-items-center ">
									{backOffice.PricePerPax}
									<span
										className="mx-2"
										role="button"
										onClick={() => handleSort("price")}
									>
										<p className="d-flex flex-column">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="#4B465C"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="white"
													stroke-opacity="0.1"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>

											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M3.375 5.5625L6.75 8.9375L10.125 5.5625"
													stroke="#707170"
													stroke-width="1.125"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</p>
										{/* <i
										className={`${
											filterState.sort == "price"
												? filterState?.sort_by === "asc"
													? "fa fa-sort-amount-up"
													: "fa fa-sort-amount-down"
												: null
										}  mx-1`}
									></i> */}
									</span>
								</div>
							</th>
							<th>{backOffice.Pax}</th>
							<th>
								<div className="d-flex align-items-center ">
									{booking.Status}
									<span
										className="mx-2"
										role="button"
										onClick={() => handleSort("status")}
									>
										{/* <i
										className={`${
											filterState.sort == "status"
												? filterState?.sort_by === "asc"
													? "fa fa-sort-amount-up"
													: "fa fa-sort-amount-down"
												: null
										}  mx-1`}
									></i> */}

										<p className="d-flex flex-column">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="#4B465C"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M10.125 8.4375L6.75 5.0625L3.375 8.4375"
													stroke="white"
													stroke-opacity="0.1"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>

											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
											>
												<path
													d="M3.375 5.5625L6.75 8.9375L10.125 5.5625"
													stroke="#707170"
													stroke-width="1.125"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</p>
									</span>
								</div>
							</th>
							<th>{backOffice.actions}</th>
						</tr>
					</thead>
					<tbody>
						{allAttractions?.length > 0 ? (
							allAttractions
						) : (
							<tr>
								<td colSpan="7">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				{allAttractions?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>

			<Share
				isOpen={isOpen}
				toggle={toggle}
				state={shareState}
				setState={setShareState}
				shareAttraction={share}
				selectAttraction={selectAttraction}
			/>
			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={"Are you sure you want to delete this Attraction  ?"}
				onConfirm={() => removeAttraction()}
			/>
		</div>
	);
}
