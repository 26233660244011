import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";
import Locale from "translations";

export default function CounsumerDetails({
	commercialDistDetails,
	lookups,
	editMode,
	isManage,
	handleCommerialDistDetailsInputs,
	enableEditData,
	errors,
}) {
	const { backOffice } = Locale;
	return (
		<div className="border">
			<div className="d-flex justify-content-between align-items-center border p-3">
				<h3 className="h6">{backOffice.details}</h3>
				<ShowForPermission permission="Commercial-Distribution-Manage">
					{isManage ? (
						<button
							className="accept-btn"
							onClick={enableEditData}
							disabled={editMode}
						>
							{backOffice.edit}
						</button>
					) : null}
				</ShowForPermission>
			</div>

			<div className="row m-0 px-3 align-items-center">
				{/* name */}
				<div className="col-md-4 col-12">
					<TextField
						label={backOffice.name}
						placeholder={backOffice.name}
						name="consumer_name"
						id="consumer_name"
						disabled={isManage && !editMode}
						value={commercialDistDetails?.name}
						onChange={(e) =>
							handleCommerialDistDetailsInputs(e.target.value, "name")
						}
						errors={errors.name}
						color={errors.name}
					/>
				</div>
				{/* country */}
				<div className="col-md-4 col-12">
					<SelectField
						label={backOffice.country}
						name="consumer_country"
						id="consumer_country"
						disabled={isManage && !editMode}
						value={commercialDistDetails?.country}
						options={lookups?.countries}
						onChange={(e) => handleCommerialDistDetailsInputs(e, "country")}
						errors={errors.country}
						color={errors.country}
					/>
				</div>
				{/* city */}
				<div className="col-md-4 col-12">
					<SelectField
						label={backOffice.city}
						name="consumer_city"
						id="consumer_city"
						value={commercialDistDetails?.city}
						options={lookups?.cities}
						disabled={
							!commercialDistDetails?.country || (isManage && !editMode)
						}
						onChange={(e) => handleCommerialDistDetailsInputs(e, "city")}
						errors={errors.city}
						color={errors.city}
					/>
				</div>
				{/* currency */}
				<div className="col-md-4 col-12">
					<SelectField
						label={backOffice.currency}
						name="consumer_currency"
						id="consumer_currency"
						disabled={isManage && !editMode}
						value={commercialDistDetails?.currency}
						options={lookups?.currencies}
						onChange={(e) => handleCommerialDistDetailsInputs(e, "currency")}
						errors={errors.currency}
						color={errors.currency}
					/>
				</div>
				{/* contract start date */}
				<div className="col-md-4 col-12 px-3">
					<DatePickerField
						label={backOffice.ContractDateFrom}
						placeholder="DD/MM/YYYY"
						name="consumer_start_date"
						id="consumer_start_date"
						disabled={isManage && !editMode}
						date={commercialDistDetails?.date_from}
						onChangeDate={(e) =>
							handleCommerialDistDetailsInputs(e, "date_from")
						}
						isOutsideRange={(day) =>
							!isInclusivelyAfterDay(day, moment().add(0, "day"))
						}
						errors={errors.date_from}
						color={errors.date_from}
					/>
				</div>
				{/* contract end date */}
				<div className="col-md-4 col-12 px-3">
					<DatePickerField
						label={backOffice.ContractDateTo}
						placeholder="DD/MM/YYYY"
						name="consumer_end_date"
						id="consumer_end_date"
						disabled={isManage && !editMode}
						date={commercialDistDetails?.date_to}
						onChangeDate={(e) => handleCommerialDistDetailsInputs(e, "date_to")}
						isOutsideRange={(day) =>
							!day.isAfter(moment(new Date()).add(1, "d"), "day") ||
							!day.isAfter(moment(commercialDistDetails?.date_from), "day")
						}
						errors={errors.date_to}
						color={errors.date_to}
					/>
				</div>
				{/* website link */}
				<div className="col-md-4 col-12">
					<TextField
						label={backOffice.Link}
						placeholder={backOffice.Link}
						name="consumer_link"
						id="consumer_link"
						disabled={isManage && !editMode}
						value={commercialDistDetails?.link}
						onChange={(e) =>
							handleCommerialDistDetailsInputs(e.target.value, "link")
						}
						errors={errors.link}
						color={errors.link}
					/>
				</div>
			</div>
		</div>
	);
}
