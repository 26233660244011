import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextAreaField from "components/shared/TextAreaField";
import TextField from "components/shared/TextField";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";
import { MultiSelect } from "react-multi-select-component";
import Locale from "translations";

export default function PackageHeader({
	state,
	setState,
	countriesOptions,
	categoriesOptions,
	isPackage,
	isEditPackage,
}) {
	console.log("asdasdasd", isEditPackage);

	const valueRenderer = (selected) => {
		if (!selected?.length) {
			return "No Item Selected";
		}
		return "Item Selected  " + selected?.length;
	};
	const handleChangeinArray = (value) => {
		setState({
			...state,
			countries: value,
		});
	};

	const { backOffice, booking } = Locale;

	return (
		<div className="package-plan-header">
			<div className="row mx-0">
				<div className="col-6 col-md-4">
					<TextField
						type="text"
						label={backOffice.PackageNameAR}
						placeholder={backOffice.PackageNameAR}
						name="package_name"
						value={state?.title?.ar}
						onChange={(e) => {
							setState({
								...state,
								title: {
									...state.title,
									ar: e.target.value,
								},
							});
						}}
					/>
				</div>

				<div className="col-6 col-md-4">
					<TextField
						type="text"
						label={backOffice.PackageNameEN}
						placeholder={backOffice.PackageNameEN}
						name="package_name"
						value={state?.title?.en}
						onChange={(e) => {
							setState({
								...state,
								title: {
									...state.title,
									en: e.target.value,
								},
							});
						}}
					/>
				</div>

				<div className="col-6 col-md-4">
					<TextAreaField
						type="text"
						label={backOffice.PackageDescriptionAR}
						placeholder={backOffice.PackageDescriptionAR}
						name="package_description"
						value={state?.description?.ar}
						onChange={(e) => {
							setState({
								...state,
								description: {
									...state.description,
									ar: e.target.value,
								},
							});
						}}
					/>
				</div>

				<div className="col-6 col-md-4">
					<TextAreaField
						type="text"
						label={backOffice.PackageDescriptionEN}
						placeholder={backOffice.PackageDescriptionEN}
						name="package_description"
						value={state?.description?.en}
						onChange={(e) => {
							setState({
								...state,
								description: {
									...state.description,
									en: e.target.value,
								},
							});
						}}
					/>
				</div>

				{/* <div className="col-6 col-md-4 countries-input">
					<SelectField
						multi
						label={"country"}
						placeholder={"country"}
						options={
							state?.countries?.filter((item) => item.value === "all")?.length >
							0
								? []
								: countriesOptions
						}
						name="country"
						value={
							state?.countries?.filter((item) => item.value === "all")?.length >
							0
								? [{ value: "all", label: "All" }]
								: state?.countries
						}
						onChange={(e) => {
							setState({
								...state,
								countries: e,
							});
						}}
					/>
				</div> */}
				<div
					className="col-6 col-md-4 date-input"
					style={{ padding: "0px 0.625rem 0px 0.625rem" }}
				>
					<label className="mt-3">{backOffice.country}</label>
					<MultiSelect
						placeholder={backOffice.country}
						options={countriesOptions}
						id="country"
						name="country"
						disabled={isPackage && !isEditPackage}
						isMulti
						valueRenderer={valueRenderer}
						onChange={(e) => {
							if (e == null || e.length === 0) {
								handleChangeinArray(null);
								return;
							}
							if (
								e &&
								e.length > 0 &&
								e.filter((res) => res.label === "All")?.length > 0
							) {
								handleChangeinArray(countriesOptions.slice(1));
							} else {
								setState({
									...state,
									countries: e,
								});
							}
						}}
						value={
							state?.countries == null
								? []
								: state?.countries?.length === 0
								? { label: "All", value: "all" }
								: state?.countries?.length > 0 && state?.countries[0]?.value
								? state?.countries?.map((item) => {
										return {
											value: item.value,
											label: item.label,
										};
								  })
								: state?.countries?.map((item) => {
										return {
											value: item?.value,
											label: item?.label,
										};
								  })
						}
					/>
				</div>

				<div
					className="col-6 col-md-4 date-input"
					style={{ padding: "0px 0.625rem 0px 0.625rem" }}
				>
					<label className="mt-2">{backOffice.ValidFromTo}</label>
					<DateRangeField
						hasLabel={false}
						startDatePlaceholder={booking.StartDate}
						endDatePlaceholder={booking.EndDate}
						startDate={state?.start_date}
						disabled={isPackage && !isEditPackage}
						// readOnly={true}
						endDate={state?.end_date}
						onDatesChange={({ startDate, endDate }) => {
							//.add(1, "d")
							endDate = endDate
								? moment(endDate).format("YYYY-MM-DD")
								: moment(startDate).format("YYYY-MM-DD");
							setState({
								...state,
								start_date: moment(startDate).format("YYYY-MM-DD"),
								end_date: endDate,
							});
						}}
						isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
					/>
				</div>
				<div className="col-6 col-md-4">
					<TextField
						type="text"
						label={backOffice.Duration}
						placeholder={backOffice.Duration}
						disabled={isPackage && !isEditPackage}
						name="duration"
						value={state?.duration}
						onChange={(e) => {
							setState({
								...state,
								duration: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-6 col-md-4">
					<SelectField
						className="w-100"
						label={backOffice.category}
						placeholder={backOffice.category}
						name="category"
						options={categoriesOptions}
						value={state?.category || null}
						onChange={(e) => {
							setState({
								...state,
								category: e,
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
}
