import { useEffect, useState } from 'react';
// React-Router-dom
import { useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Shared Component
import Tabs from '../../shared/Tabs';
import DefaultPricingForm from './DefaultPricingForm';
import VisaPricingList from './VisaPricingList';
// Helper
import validate from 'helpers/validate';
// Custom Hook
import useSafaVisaData from '../../shared/useSafaVisaData';
// Services
import { getDefaultPricingById } from 'services/safaVisa';
// ----------------------------------------------------------------------

export default function AddEditDefaultPricing() {

  // Hooks
  const { safaVisa } = Locale;

  let { id, status } = useParams();

  // *** Custom Hook
  const { defaultPricingTabs, requiredPricingInputs, initialPricingData } = useSafaVisaData();

  // *** State
  const [pricingData, setPricingData] = useState(initialPricingData)
  const [tabs, setTabs] = useState(defaultPricingTabs)
  const [activeTab, setActiveTab] = useState(defaultPricingTabs[0])
  const [errors, setErrors] = useState();
  const [allRequiredInputs, setAllRequiredInputs] = useState(requiredPricingInputs)

  // ** function 
  const checkFormErrors = () => {
    let submitError = {};
    requiredPricingInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: pricingData[key] },
          { required: true }
        )
      }
    })
    setErrors((prev) => ({ ...prev, ...submitError }));
    // console.log(submitError)
    return submitError;
  }

  const handleCheckValidation = () => {
    checkFormErrors()
  }

  const tranformDataToShow = (data) => {
    return {
      title_ar: data?.title?.ar,
      title_en: data?.title?.en,
      description_ar: data?.description?.ar,
      description_en: data?.description?.en,
      countries_id: data?.countries?.map(item => ({
        value: item.id,
        label: item.name,
      })),
      currency: { value: data?.currency, label: data?.currency },
      audience_type: data?.audience_type,
      with_contracts: data?.contract_count > 0 ? 'contract-determined' : 'contarct-indeterminate',
      contract_count: data?.contract_count,
      with_Agents: data?.agents_count > 0 ? 'agents-determined' : 'agents-indeterminate',
      agents_count: data?.agents_count,
      prices: data?.prices?.map((item, index) => ({
        ...item,
        visa_type: { label: safaVisa?.[`${item?.visa_type}`], value: item?.visa_type },
        id: index + 1,
        currency: data?.currency
      })),
    }
  }

  const fetchDefaultPriceDetails = async () => {
    const response = await getDefaultPricingById(id)
    if (response?.status === 200) {
      const transformedData = tranformDataToShow(response?.data?.data)
      setPricingData(transformedData)
    }
  }

  useEffect(() => {
    if ((status === 'edit' || status === 'duplicate') && id) {
      fetchDefaultPriceDetails()
    }
  }, [])

  // console.log(pricingData)
  // -------- JSX Code ---------
  return (
    <div className='safaVisa-wrapper'>

      <div className='safa-visa-container'>

        {/* Header */}
        <div >
          <h4 className="main-title mb-0">{safaVisa.addPricing}</h4>
          <hr />
        </div>


        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onClickTab={handleCheckValidation}
        />

        {activeTab?.value === 'pricing-details' &&
          <DefaultPricingForm
            pricingData={pricingData}
            setPricingData={setPricingData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            allRequiredInputs={allRequiredInputs}
            setAllRequiredInputs={setAllRequiredInputs}
            tabs={tabs}
            setTabs={setTabs}
            errors={errors}
            setErrors={setErrors}
          />
        }


        {activeTab?.value === 'pricing-list' &&
          <VisaPricingList
            pricingData={pricingData}
            setPricingData={setPricingData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            allRequiredInputs={allRequiredInputs}
            setAllRequiredInputs={setAllRequiredInputs}
            tabs={tabs}
            setTabs={setTabs}
            errors={errors}
            setErrors={setErrors}
          />
        }

      </div>

    </div>
  )
}

