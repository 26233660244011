import resetIcon from "assets/images/wallet/resetIcon.png";
import DateRangeField from "components/shared/DateRangeField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import moment from "moment";
import Locale from "translations";

export default function Filter({ filter, setFilter, payableTypeLookups }) {
	const { backOffice, financial, booking } = Locale;
	const statusOptions = [
		{ value: "paid", label: financial.paid },
		{ value: "failed", label: financial.failed },
		{ value: "pending", label: financial.pending },
	];
	const Vendors = [
		{ value: null, label: "Sbs" },
		{ value: "saudi", label: "Saudi" },
		{ value: "sudan", label: "Sudan" },
	];

	const handleOnChange = ({ name, value }) => {
		setFilter({
			...filter,
			// page: 1,
			[name]: value,
		});
	};
	return (
		<>
			<div className="main-filter  our-border bg-white-blue py-2 mt-2 w-100">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col">
						<TextField
							type="text"
							placeholder={backOffice.search}
							label={backOffice.search}
							value={filter?.company_name}
							onChange={(e) => {
								setFilter({
									...filter,
									company_name: e.target.value,
								});
							}}
						/>
					</div>

					{/* <div className="col-2">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={backOffice.date}
							date={filter.created_at}
							name="created_at"
							onDateChange={(e) => {
								handleOnChange({
									name: "created_at",
									value: e,
								});
							}}
						/>
					</div> */}

					<div className="col-3 date-range-input">
						<p className="mb-0 mt-2">{backOffice.CreateAtDate}</p>
						<DateRangeField
							startDate={filter.created_at_from}
							endDate={filter.created_at_to}
							startDatePlaceholder={booking.StartDate}
							endDatePlaceholder={booking.EndDate}
							readOnly={true}
							onDatesChange={({ startDate, endDate }) => {
								endDate = endDate ? moment(endDate)._d : moment(startDate)._d;
								setFilter({
									...filter,
									created_at_from: moment(startDate)._d,
									created_at_to: endDate,
								});
							}}
							isOutsideRange={(day) => {
								return false;
							}}
						/>
					</div>
					<div className="col">
						<SelectField
							label={financial.payableType}
							placeholder={financial.payableType}
							name="payableType"
							options={payableTypeLookups}
							value={filter.payable_type}
							onChange={(e) => {
								handleOnChange({
									name: "payable_type",
									value: e,
								});
							}}
						/>
					</div>

					<div className="col">
						<SelectField
							label={backOffice.status}
							placeholder={backOffice.status}
							name="payment_status"
							options={statusOptions}
							value={filter.payment_status}
							onChange={(e) => {
								handleOnChange({
									name: "payment_status",
									value: e,
								});
							}}
						/>
					</div>
					<div className="col">
						<SelectField
							label={financial.vendors}
							placeholder={financial.vendors}
							name="Vendors"
							options={Vendors}
							value={filter.vendor}
							onChange={(e) => {
								handleOnChange({
									name: "vendor",
									value: e,
								});
							}}
						/>
					</div>

					<div
						className="col"
						onClick={() =>
							setFilter({
								search: "",
								created_at: null,
								payable_type: "",
								payment_status: "",
								page: 1,
							})
						}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />

							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
