import { useGlobalState } from "context/global";

export default function CheckBox(props) {
	const { locale } = useGlobalState();
	return (
		<>
			<input
				type="Checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				onChange={props.onChange}
				id={props.id}
				className={locale == "en" ? "me-1" : "ms-1"}
			/>

			<label
				className="form-check-label pointer text-caption mx-2"
				htmlFor={props.id}
			>
				{props.text}
			</label>
		</>
	);
}
