import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	exportToExcelSupplier,
	fetchSupplierReservations,
} from "services/suppliers";
import Locale from "translations";
import FilterReservation from "./FilterReserviton";
import ReservationTable from "./ReservationTable";
function OnlineSupReservation() {
	const [Reservation, setReservation] = useState(null);
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [meta, setmeta] = useState([]);
	const { name, id } = useParams();

	const [filters, setFilters] = useState({
		search: "",
		check_in: "",
		checkin_to: undefined,
		checkin_from: undefined,
		destination: "",
		status: "",
	});
	const { backOffice } = Locale;
	// filter
	useEffect(() => {
		async function fetchReservationsFilterdData() {
			const data = {
				...filters,
				search: filters.search,
				destination: filters.destination?.value,
				check_in_to: filters?.check_in_to
					? moment(filters?.check_in_to).format("YYYY-MM-DD")
					: "",
				check_in_from: filters?.check_in_from
					? moment(filters?.check_in_from).format("YYYY-MM-DD")
					: "",
				status: filters.status?.value,
			};
			const response = await fetchSupplierReservations(id, data);
			setReservation(response?.data.data);
			setmeta(response?.data?.meta);
		}
		fetchReservationsFilterdData();
	}, [filters]);

	const goto = (page) => {
		setFilters({ ...filters, page: page });
	};
	const exportToFile = async () => {
		const res = await exportToExcelSupplier(id, {
			...filters,
			search: filters.search,
			destination: filters.destination?.value,
			check_in_to: filters?.check_in_to
				? moment(filters?.check_in_to).format("YYYY-MM-DD")
				: "",
			check_in_from: filters?.check_in_from
				? moment(filters?.check_in_from).format("YYYY-MM-DD")
				: "",
			status: filters.status?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Online-Supplier-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex justify-content-between">
				<h3>
					{backOffice.reservations}{" "}
					<span className="tentative">{`(${name})`}</span>
				</h3>
				<button className="btn btn-primary" onClick={() => exportToFile()}>
					{backOffice.ExportToExcel}
				</button>
			</div>
			<FilterReservation setFilters={setFilters} filters={filters} />
			<ReservationTable
				Reservation={Reservation}
				setReservation={setReservation}
				meta={meta}
				goto={goto}
			/>
		</>
	);
}
export default OnlineSupReservation;
