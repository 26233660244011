import SelectField from "components/shared/SelectField";
import { useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { uploadFile } from "services/companies";
import { fetchType } from "services/visa";
import Locale from "translations";
import VisaRow from "./visaRow";

export default function AddVisa({
	country,
	destination,
	setDestination,
	indexConfiguration,
	setIndexConfiguration,
	consumers,
	inputsState,
	setInputsState,

	arrayCountry,
	setArrayCountry,
	viewConfiguration,
	setviewConfiguration,
	countries,
	addToggle,
	isSelectCounter,
}) {
	const { allCurrencies } = useGlobalState();
	const { backOffice } = Locale;

	//state no main
	const [VisaRowState, setVisaRowState] = useState([]);
	const [alertToggle, setAlertToggle] = useState(false);
	const [selctVisaType, setSelctVisaType] = useState(false);
	const [isAddNewRow, setIsAddNewRow] = useState(false);

	const selectRef = useRef();
	const handleVisa = (e, data) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == data.country)[0];

		let isRepetVisaType = counrtyX.visa_types.filter(
			(item) => item.name === e.label
		);
		if (isRepetVisaType?.length == 0) {
			counrtyX.visa_types.push({
				id: e.value,
				name: e.label,
				residences: [{ residence: " ", currency: "", package_plans: [] }],
			});
			setviewConfiguration([...newData]);
		} else {
			setAlertToggle(true);
		}
		setIsAddNewRow((prev) => !prev);
	};

	//Fetch currencies
	const [currencies, setCurrencies] = useState([]);
	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);
	const handleDelete = (index, ind = 0) => {
		let clone = [...viewConfiguration];
		clone = clone.filter((res, index) => ind != index);
		setviewConfiguration(clone);
	};
	//Collapse
	const [isOpen, setIsOpen] = useState(true);
	const toggleButton = () => setIsOpen(!isOpen);

	//api
	const [visaType, setVisaType] = useState([]);
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchType(
				typeof selctVisaType == Boolean
					? country?.value
					: viewConfiguration[selctVisaType]?.country_code
			);

			if (res?.status == 200) {
				const format = res?.data?.data?.map((t) => ({
					value: t.id,
					label: t.name,
				}));
				setVisaType(format);
			}
		}
		fetchLookups();
	}, [isSelectCounter, selctVisaType]);

	const handleSelectVisaType = (index) => {
		setSelctVisaType(index);
		if (selectRef.current) {
			selectRef.current.focus();
		}
	};

	const onlineVisaURL = process.env.REACT_APP_API_URL + "/api/files/fetch/";
	async function updateCountryPhoto(e, index) {
		let formData = new FormData();
		let imgFile = e.target.files[0];
		formData.append("file", imgFile);
		let fileUUID = "";
		let allConfigurationClone = [...viewConfiguration];

		let imgSelectedRes = await uploadFile(formData);
		if (imgSelectedRes.status === 200) {
			fileUUID = imgSelectedRes.data.uuid;
			let updatedConfiguration = {
				...allConfigurationClone[index],
				country_photo_preview: `${onlineVisaURL}${fileUUID}`,
				country_photo_uuid: fileUUID,
			};
			allConfigurationClone[index] = updatedConfiguration;
			setviewConfiguration(allConfigurationClone);
		}
	}
	// newCountryPhoto, setNewCountryPhoto
	console.log("viewConfigurationviewConfiguration", viewConfiguration);
	const updateTerms = (e, type, index) => {
		debugger;
		let value = e.target.value;
		let allConfigurationClone = [...viewConfiguration];
		let updatedConfiguration = {
			...allConfigurationClone[index],
			terms: {
				...allConfigurationClone[index].terms,
				[type]: value,
			},
		};
		allConfigurationClone[index] = updatedConfiguration;
		setviewConfiguration(allConfigurationClone);
	};
	return (
		<>
			{viewConfiguration && viewConfiguration?.length > 0 ? (
				<>
					{viewConfiguration?.map((configData, index) => (
						<div className="p-0 border mb-2" key={configData?.country_code}>
							<div className=" header-add-visa mt-2 px-4 d-flex justify-content-between">
								<div className="d-flex flex-column">
									<div className="d-flex align-items-center">
										<img
											src={
												configData?.country_photo_preview
													? `${configData?.country_photo_preview}`
													: `${onlineVisaURL}${configData?.country_photo}`
											}
											alt=""
											width={40}
											height={40}
											style={{ borderRadius: "50%" }}
										/>
										<p className="country-visa mx-2">{configData?.country}</p>
									</div>
									<ShowForPermission permission="Visa-Configuration-Manage">
										<button className="btn w-100 mx-auto my-2 p-1 position-relative text-primary">
											{backOffice.ChangeCountryPhoto}
											<input
												type="file"
												className="custom-file"
												accept="image/png, image/gif,image/jpeg"
												onChange={(e) => updateCountryPhoto(e, index)}
											/>
										</button>
									</ShowForPermission>
									{/* <button className='btn shadow-none px-0 text-primary'>Change Country Photo</button> */}
								</div>
								<ShowForPermission permission="Visa-Configuration-Manage">
									<div className="w-25 visa-select d-flex align-items-center">
										<div
											className="w-100"
											onClick={() => handleSelectVisaType(index)}
										>
											<SelectField
												ref={selectRef}
												openMenuOnFocus={true}
												label={backOffice.visa}
												placeholder={"visa Type"}
												options={visaType}
												id="basic-countries"
												onChange={(e) => {
													handleVisa(e, configData);
												}}
												value={configData?.visa_types[0]?.name}
											/>
										</div>
										<span
											className="btn-link text-danger mt-4 w-50 pointer"
											onClick={() => handleDelete(indexConfiguration, index)}
										>
											<i class="fas fa-trash"></i> {backOffice.Remove}
										</span>
									</div>
								</ShowForPermission>
							</div>
							<Collapse isOpen={true}>
								<VisaRow
									index={index}
									country={configData?.country}
									consumers={consumers}
									//visa={item}
									handleDelete={handleDelete}
									destination={destination}
									setDestination={setDestination}
									indexConfiguration={index}
									setIndexConfiguration={setIndexConfiguration}
									setviewConfiguration={setviewConfiguration}
									viewConfiguration={viewConfiguration}
									inputsState={(inputsState = configData?.visa_types)}
									setInputsState={setInputsState}
									arrayCountry={arrayCountry}
									setArrayCountry={setArrayCountry}
									configData={configData?.visa_types}
									allConfigData={configData}
									setVisaRowState={setVisaRowState}
									VisaRowState={VisaRowState}
									countries={countries}
									currencies={currencies}
									addToggle={addToggle}
									alertToggle={alertToggle}
									setAlertToggle={setAlertToggle}
									countryOfVisa={country}
									isAddNewRow={isAddNewRow}
									setIsAddNewRow={setIsAddNewRow}
								/>
							</Collapse>

							{/***** Statt Terms  */}
							<div className="row m-2">
								<div className="col-md-6">
									<h6>{backOffice.EnglishTermsAndConditions}</h6>
									<textarea
										placeholder="Enter Terms and conditions"
										name={`termsConditions-${index}`}
										id={`termsConditions-${index}`}
										className="w-100 p-1"
										value={configData?.terms?.en}
										onChange={(e) => {
											updateTerms(e, "en", index);
										}}
									></textarea>
								</div>
								<div className="col-md-6">
									<h6>{backOffice.ArabicTermsAndConditions}</h6>
									<textarea
										placeholder="Enter Terms and conditions"
										name={`termsConditions-${index}`}
										id={`termsConditions-${index}`}
										className="w-100 p-1"
										value={configData?.terms?.ar}
										onChange={(e) => {
											updateTerms(e, "ar", index);
										}}
									></textarea>
								</div>
							</div>

							{/***** End Terms  */}
						</div>
					))}
				</>
			) : (
				""
			)}
		</>
	);
}
