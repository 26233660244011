import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { listAccepted } from "services/umrahForum";
import Locale from "translations";

export default function Accepted() {
	const { backOffice } = Locale;
	const [allAccepted, setallAccepted] = useState([]);
	const [meta, setMeta] = useState(null);
	const [page, setpage] = useState(null);
	useEffect(() => {
		async function fetchlistAccepted() {
			const res = await listAccepted({ page });
			if (res.status === 200) {
				setallAccepted(res.data?.data);
				setMeta(res?.data?.meta);
			}
		}
		fetchlistAccepted();
	}, [page]);
	const goTo = (page) => {
		setpage(+page);
	};
	return (
		<div className=" table-responsive  our-border  px-3">
			<table className="table table-striped">
				<thead>
					<tr>
						<th>{backOffice.name}</th>
						<th>{backOffice.companyName}</th>
						<th>{backOffice.title}</th>
						<th>{backOffice.country}</th>
						<th>{backOffice.phone}</th>
						<th>{backOffice.email}</th>
					</tr>
				</thead>
				<tbody>
					{allAccepted.length > 0 ? (
						<>
							{allAccepted.map((data, index) => (
								<tr key={index}>
									<td>{data.name}</td>
									<td>{data.company_name}</td>
									<td>{data.job_title}</td>
									<td>{data.country_name}</td>
									<td>
										{data?.phone_code}
										{data.phone_number}
									</td>
									<td>{data.email}</td>
								</tr>
							))}
						</>
					) : (
						<tr>
							<td colSpan="11">
								<div className="product-build__product-no-data">
									<i className="fas fa-info-circle fa-lg"></i>{" "}
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<Pagination info={meta} goTo={goTo} items={10} />
		</div>
	);
}
