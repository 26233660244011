import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import { usePropertyDispatch, usePropertyState } from "context/property";
import { formatGetServices, formatPostServices } from "data/property"; //formatGetServices,
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import {
	fetchFacilities,
	fetchLanguages,
	fetchRoomViews,
} from "services/lookups";
import { addServices, EditServices } from "services/property";
import Locale from "translations";
import CancelModal from "./CancelModal";

function Services(statusNum) {
	const { id, tab } = useParams();
	const { current } = useGlobalState();
	const history = useHistory();

	const { propertyDetails } = usePropertyState();
	const dispatch = useGlobalDispatch();
	const propertyDispatch = usePropertyDispatch();
	const { services, property, login, backOffice } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const [errors, setErrors] = useState({});
	const [canceled, setCanceled] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [facilities, setFacilities] = useState({});
	const [languages, setLanguages] = useState([]);
	const [views, setViews] = useState([]);
	const [service, setService] = useState({
		parkingAvailable: "",
		parkingType: "",
		parkingSite: "",
		parkingReservation: "",
		parkingPrice: "",
		languages: [],
		views: [],
		facilities: [],
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "parkingAvailable", value: service.parkingAvailable },
				{ required: true }
			),

			...validate(
				{ name: "languages", value: service.languages },
				{ required: true }
			),
			...validate({ name: "views", value: service.views }, { required: true }),
			...validate(
				{ name: "facilities", value: service.facilities },
				{ required: true }
			),
		});
	};

	const formatArray = (array) => {
		console.log(array);
		return array?.map((item) => (item = { label: item.name, value: item.id }));
	};

	useEffect(() => {
		async function fetchLookups() {
			const [languages, roomViews, facilities] = await Promise.all([
				fetchLanguages(locale),
				fetchRoomViews(locale),
				fetchFacilities(locale),
			]);
			setLanguages(formatArray(languages.data.data.data));
			setViews(formatArray(roomViews.data.data.data));
			setFacilities(formatArray(facilities.data.data.data));
		}
		fetchLookups();
	}, []);
	console.log("propertyDetails.property", propertyDetails?.property?.service);
	useEffect(() => {
		if (id && propertyDetails?.property?.facilities) {
			//if(propertyDetails?.status !== "Pending to verify"){
			const formatted = formatGetServices(propertyDetails?.property);
			setService(formatted);
			// }else{
			// setService({
			//   parkingAvailable: {label: "Yes Not Paid", value: "Yes Not Paid"},
			//   facilities: [1, 2, 3],
			//   languages:{label: "English", value: 2},
			//   parkingPrice: "12.00",
			//   parkingReservation: {label: "Needed", value: "Needed"},
			//   parkingSite: {label: "External", value: "External"},
			//   parkingType: {label: "Private", value: "Private"},
			//   views: [1]

			// });

			//}
		}
	}, [propertyDetails]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setService({ ...service, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setService({ ...service, city: "", [name]: value });
			} else setService({ ...service, [name]: value });
		}
	};

	const handleCheckboxChange = (e) => {
		const name = e.target.name;
		if (e.target.checked === true) {
			setService({
				...service,
				[name]: [...service[name], Number(e.target.value)],
			});
		} else {
			setService({
				...service,
				[name]: [
					...service[name].filter(
						(serviceItem) => serviceItem !== Number(e.target.value)
					),
				],
			});
		}
	};
	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && propertyDetails?.property.facilities) {
				const data = formatPostServices(service);
				console.log("dataa", data);
				const res = await EditServices(id, data);
				if (res.status === 200) {
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					dispatch({
						type: "progress",
						step: "3",
						current: "3",
					});
					store.addNotification({
						title: "Facilities & Services",
						message: "Facilities & Services Info Succussfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostServices(service);
				const res = await addServices(id, data);
				if (res.status === 201) {
					propertyDispatch({ type: "propertyDetails", payload: res.data });
					dispatch({ type: "progress", current: "3", step: "3" });
				}
			}
		} else if (JSON.stringify(errors) !== "{}") {
			console.log("JSON.stringify(errors)", errors);
			store.addNotification({
				title: "info!",
				message: "Please fill all fields",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const reset = () => {
		setCanceled(true);
	};
	console.log("service", service);
	return (
		<div>
			<CancelModal isOpen={canceled} toggle={setCanceled} />
			<div className="head">
				<h6 className="font-weight-bold">{property.services}</h6>
				<p className="text-caption">{property.serviceText}</p>
			</div>
			<div className="header-box my-3">{services.parking}</div>
			<div className="row m-0">
				{/* parkingAvailable */}
				<div className="col-4">
					<SelectField
						// disabled
						id="services-parking"
						disabled={tab === "view"}
						label={services.parkingAvailable}
						customLabel={services.parkingAvailability}
						placeholder={services.parkingAvailable}
						options={[
							{ label: "Yes Paid", value: "Yes Paid" },
							{ label: "Yes Not Paid", value: "Yes Not Paid" },
							{ label: "No Availability", value: "No Availability" },
						]}
						name="parkingAvailable"
						onChange={(e) => {
							handleChange({ name: "parkingAvailable", value: e });
							setErrors({
								...errors,
								...validate(
									{ name: "parkingAvailable", value: e },
									{ required: true }
								),
							});
						}}
						value={service.parkingAvailable}
						color={errors.parkingAvailable?.required ? "danger" : ""}
						errors={errors.parkingAvailable}
					/>
				</div>
				{service.parkingAvailable?.value == "Yes Paid" ? (
					<div className="col-4">
						<TextField
							label={services.price}
							placeholder={services.price}
							value={service.parkingPrice}
							disabled={tab === "view"}
							name="parkingPrice"
							id="services-price"
							min={1}
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										minNumber: 1,
									}),
								});
							}}
							color={errors?.parkingPrice?.required ? "danger" : ""}
							errors={errors?.parkingPrice}
						/>
					</div>
				) : (
					""
				)}
				{service.parkingAvailable?.value == "Yes Paid" ||
				service.parkingAvailable?.value == "Yes Not Paid" ? (
					<>
						{/* parkingType */}
						<div className="col-4">
							<SelectField
								id="services-type"
								disabled={tab === "view"}
								label={services.type}
								placeholder={services.type}
								options={[
									{ label: "Public", value: "Public" },
									{ label: "Private", value: "Private" },
								]}
								name="parkingType"
								onChange={(e) => {
									handleChange({ name: "parkingType", value: e });
									setErrors({
										...errors,
										...validate(
											{ name: "parkingType", value: e },
											{ required: true }
										),
									});
								}}
								value={service.parkingType}
								color={errors.parkingType?.required ? "danger" : ""}
								errors={errors.parkingType}
							/>
						</div>
						{/* parkingSite */}
						<div className="col-4">
							<SelectField
								label={services.site}
								disabled={tab === "view"}
								placeholder={services.site}
								id="services-site"
								options={[
									{ label: "On Site", value: "On Site" },
									{ label: "External", value: "External" },
								]}
								name="parkingSite"
								onChange={(e) => {
									handleChange({ name: "parkingSite", value: e });
									setErrors({
										...errors,
										...validate(
											{ name: "parkingSite", value: e },
											{ required: true }
										),
									});
								}}
								value={service.parkingSite}
								color={errors.parkingSite?.required ? "danger" : ""}
								errors={errors.parkingSite}
							/>
						</div>
						{/* parkingReservation */}
						<div className="col-4">
							<SelectField
								label={services.reservation}
								disabled={tab === "view"}
								customLabel={services.parkingSpace}
								placeholder={services.reservation}
								id="services-space"
								options={[
									{ label: "Needed", value: "Needed" },
									{ label: "No Need", value: "No Need" },
								]}
								name="parkingReservation"
								onChange={(e) => {
									handleChange({ name: "parkingReservation", value: e });
									setErrors({
										...errors,
										...validate(
											{ name: "parkingReservation", value: e },
											{ required: true }
										),
									});
								}}
								value={service.parkingReservation}
								color={errors.parkingReservation?.required ? "danger" : ""}
								errors={errors.parkingReservation}
							/>
						</div>
					</>
				) : (
					""
				)}
			</div>
			<div className="header-box my-3">{services.languages}</div>
			<p className="text-dark">{services.languagesSpokenTitle}</p>
			<div className="col-md-4">
				<SelectField
					multi
					label={services.languages}
					disabled={tab === "view"}
					options={languages}
					placeholder={services.languages}
					id="services-languages"
					// placeholder={
					// 	service.languages?.length > 0
					// 		? service.languages
					// 		: services.languages
					// }
					value={service.languages}
					onChange={(values) => {
						setService({ ...service, languages: values });
					}}
				/>
				{errors?.languages?.required ? (
					<p className="text-danger">*languages Required</p>
				) : (
					""
				)}
			</div>
			<div className="header-box my-3">{services.views}</div>
			<div>
				{views?.length > 0 &&
					views?.map((view, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									name="views"
									disabled={tab === "view"}
									className="form-check-input"
									onChange={handleCheckboxChange}
									value={view.value}
									checked={service.views?.includes(view.value)}
									id={`room-views-${i + 1}`}
								/>
								<label
									className="form-check-label text-capitalize text-white"
									htmlFor={`room-views-${i + 1}`}
								>
									{view.label}
								</label>
								<br />
								{errors?.views?.required ? (
									<p className="text-danger">*Room Views Required</p>
								) : (
									""
								)}
							</div>
						);
					})}
			</div>
			<div className="header-box my-3">{services.popular}</div>
			<p className={"text-dark"}>{services.popularTitle}</p>
			<div style={{ columnCount: "3" }}>
				{facilities.length > 0 &&
					facilities.map((facility, i) => {
						return (
							<div className="form-group form-check" key={i}>
								<input
									type="checkbox"
									className="form-check-input"
									name="facilities"
									disabled={tab === "view"}
									value={facility.value}
									onChange={handleCheckboxChange}
									checked={service.facilities?.includes(facility.value)}
									id={`service-check-${i + 1}`}
								/>
								<label
									className="form-check-label text-capitalize text-white pointer"
									htmlFor={`service-check-${i + 1}`}
								>
									{facility.label}
								</label>
							</div>
						);
					})}
				<br />
				<br />
				<br />
			</div>
			{errors?.facilities?.required ? (
				<p className="text-danger">*Facilities Required</p>
			) : (
				""
			)}

			{/* <ActionButton ID={id} StatusNum={statusNum} /> */}

			{/* <div className="row my-5 mx-0 justify-content-end">
        <button className="btn mx-2 cancel" onClick={reset} id="service-cancel">
          cancel
        </button>
        <button
          className="btn btn-primary px-5"
          onClick={submit}
          id="service-submit"
        >
          continue
        </button>
      </div> */}
			<div className="row my-5 mx-0 justify-content-between">
				<button
					onClick={() => history.push("/property-management")}
					className="btn cancel mx-2 "
					id="basic-cancel"
				>
					{backOffice.back}
				</button>

				{tab !== "view" && (
					<button
						className="btn btn-primary px-5"
						id="basic-submit"
						onClick={submit}
					>
						{login.continue}
					</button>
				)}
			</div>
		</div>
	);
}

export default Services;
