import { Link } from "react-router-dom";
import Locale from "translations";

export default function BookItem({
	data,
	checkedColums,
	toggleExtend,
	setSelectItem,
}) {
	const { backOffice } = Locale;
	return (
		<tr>
			{/* {checkedColums.includes("referral_code") ? <td>{data?.referral_code}</td> : null} */}
			{checkedColums.includes("brn") ? <td>{data.brn}</td> : null}
			{checkedColums.includes("sbs_ref") ? <td>{data.sbs_ref}</td> : null}
			{checkedColums.includes("agency") ? <td>{data.agency}</td> : null}
			{checkedColums.includes("hotel_name") ? <td>{data.hotel_name}</td> : null}
			{checkedColums.includes("source") ? <td>{data.source}</td> : null}

			{checkedColums.includes("rooms_count") ? (
				<td>{data.rooms_number}</td>
			) : null}
			{checkedColums.includes("booking_date") ? (
				<td>{data.created_at}</td>
			) : null}
			{checkedColums.includes("checkin_date") ? (
				<td>{data.start_date}</td>
			) : null}
			{checkedColums.includes("nights_count") ? (
				<td>{data.nights_count}</td>
			) : null}

			{checkedColums.includes("paid_later") ? (
				<td>
					{data.paid_later == 0
						? `${backOffice.disabled}`
						: `${backOffice.Enabled}`}
				</td>
			) : null}
			{checkedColums.includes("reservation_status") ? (
				<td>{data.reservation_status} </td>
			) : null}
			{checkedColums.includes("destination") ? (
				<td>{data.destination ? data.destination : "-"}</td>
			) : null}
			{checkedColums.includes("selling_rate") ? (
				<td>{data.total_price}</td>
			) : null}
			{checkedColums.includes("total_refund") ? (
				<td>
					{data.total_refund}
					{/* {data.currency} */}
				</td>
			) : null}
			{checkedColums.includes("total_paid") ? (
				<td>
					{data.total_paid}
					{/* {data.currency} */}
				</td>
			) : null}
			{checkedColums.includes("total_net_price") ? (
				<td>
					{data.total_net_price?.toFixed(2)}
					{/* {data.currency} */}
				</td>
			) : null}

			{checkedColums.includes("pax") ? <td>{data.pax}</td> : null}

			{checkedColums.includes("customer_charges") ? (
				<td>{data.customer_charges?.toFixed(2)}</td>
			) : null}
			{checkedColums.includes("earnings") ? (
				<td>{data.earnings?.toFixed(2)}</td>
			) : null}
			{checkedColums.includes("payment_method") ? (
				<td>{data.payment_method}</td>
			) : null}
			{checkedColums.includes("payment_expiration") ? (
				<td>{data.paid_later == 1 ? data.payment_expiration : "-"}</td>
			) : null}
			{/* {checkedColums.includes("payment_document_number") ? <td>{data.payment_document_number}</td> : null} */}
			{checkedColums.includes("provider_name") ? (
				<td>{data.provider_name}</td>
			) : null}
			{checkedColums.includes("online_payments") ? (
				<td>{data.online_payments}</td>
			) : null}
			{/* {checkedColums.includes("price") ? <td>{data?.total_price}</td> : null} */}
			{checkedColums.includes("currency") ? <td>{data.currency}</td> : null}

			{checkedColums.length >= 1 ? (
				<td>
					{/* <Link to={"booking/view"}>
          <i class="fas fa-chevron-right"></i>
        </Link> */}

					{data?.can_extend_date &&
					data?.reservation_status !== "canceled" &&
					data?.reservation_status !== "paid" ? (
						<button
							class="btn  btn-primary mx-2"
							onClick={() => {
								setSelectItem({ brn: data.brn });
								toggleExtend();
							}}
						>
							<i class="fas fa-edit"></i>
						</button>
					) : null}
					{/* <ShowForPermission permission="Hotels-Bookings-View"> */}
					<Link
						to={{
							pathname: `/booking/view/${data.id}/bookings`,
							state: {
								BookingInfo: data,
							},
						}}
					>
						<i class="fas fa-chevron-right"></i>
					</Link>
					{/* </ShowForPermission> */}
				</td>
			) : null}
		</tr>
	);
}
