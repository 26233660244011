/* eslint-disable eqeqeq */
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
import { useEffect, useState } from "react";
import Locale from "translations";
import WarningModal from "../../share/warningModal";
import CollapseVisa from "./CollapseVisa";
import IncludeVisaModal from "./IncludeVisaModal";
import VisaRowDetails from "./VisaRowDetails";
import VisaRowPackagePlans from "./VisaRowPackagePlans";

export default function VisaRow({
	country: countryBase,
	visa,
	setviewConfiguration,
	viewConfiguration,
	inputsState,
	arrayCountry,
	setArrayCountry,
	allConfigData,
	currencies,
	countries,
	addToggle,
	alertToggle,
	consumers,
	setAlertToggle,
	setIsAddNewRow,
}) {
	console.log("inputsState", inputsState);
	const { backOffice } = Locale;

	// const transportationType = [
	// 	{
	// 		value: "maritime",
	// 		label: "maritime",
	// 	},
	// 	{
	// 		value: "air",
	// 		label: "air",
	// 	},
	// 	{
	// 		value: "land",
	// 		label: "land",
	// 	},
	// ];

	const [incudeVisaIndexs, setIncudeVisaIndexs] = useState({
		index_visa_type: "",
		index_residance: "",
		index_package: "",
	});

	const [visaIncludeState, setVisaIncludeState] = useState("");

	const [isOpen, setIsOpen] = useState(false);

	const visaPermissionManage = useShowPermission({
		permission: "Visa-Configuration-Manage",
	});

	const toggle = (
		indexResidance = null,
		indexVisaType = null,
		indexPackage = null
	) => {
		setIsOpen(!isOpen);
		setIncudeVisaIndexs({
			index_residance: indexResidance,
			index_visa_type: indexVisaType,
			index_package: indexPackage,
		});
	};

	// handle add new visa same type
	const addEdit = (ind) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences.push({
			residence: "",
			currency: "",
			package_plans: [],
		});
		setviewConfiguration([...newData]);
		setIsAddNewRow((prev) => !prev);
	};

	// handle change in visa details
	const handleChangeinArray = (name, value, index, ind = 0) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[index].residences[ind][name] = value;
		setviewConfiguration([...newData]);
	};

	// handle changes in package plans
	const handleChenageinPackages = (value, index, ind, indPga, namepa) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		const selectedPackage =
			counrtyX?.visa_types?.[index]?.residences?.[ind]?.package_plans?.[indPga];
		if (namepa === "visa_include") {
			// check if visa include key is array
			Array.isArray(selectedPackage?.["visa_include"])
				? selectedPackage?.["visa_include"].push({ name: value })
				: (selectedPackage["visa_include"] = [{ name: value }]);
		} else {
			counrtyX.visa_types[index].residences[ind].package_plans[indPga][namepa] =
				value;
		}
		setviewConfiguration([...newData]);
	};

	// handle duplicate visa type
	const handleCopy = (ind, index) => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		)[0];
		let configureRowCopied = selectedConfig.visa_types[ind]?.residences;
		let duplicatedConfiguration = configureRowCopied[index];
		duplicatedConfiguration = {
			...duplicatedConfiguration,
			uuid: null,
			package_plans: duplicatedConfiguration?.package_plans?.map((plan) => ({
				...plan,
				package_uuid: null,
			})),
		};
		configureRowCopied?.push({ ...duplicatedConfiguration });

		setviewConfiguration([...allConfigurationClone]);
	};

	//handle Adding new Package plan
	const handleAddPackage = (visaTypeIndex, residenceIndex) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];

		counrtyX.visa_types[visaTypeIndex].residences[
			residenceIndex
		].package_plans.push({
			plan_name: "",
			range_from: "",
			range_to: "",
			package_price: "",
			visa_include: [],
			entry_stamp: false,
			residence_data: false,
		});
		setviewConfiguration([...newData]);
		setIsAddNewRow((prev) => !prev);
	};

	// add new package plan row
	const handleAddPackageRow = (index, ind) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences[index].package_plans.push({
			plan_name: "",
			range_from: "",
			range_to: "",
			package_price: "",
			visa_include: [],
		});
		setviewConfiguration([...newData]);
	};

	const handleAddIncludeVisa = (index, ind, indexPackage) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences[index].package_plans[indexPackage][
			"visa_include"
		].push({
			name: "",
		});
		setviewConfiguration([...newData]);
	};

	const handleRemovenIcludeVisa = (
		index,
		ind,
		indexPackage,
		indexIncludeVisa
	) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences[index].package_plans[indexPackage][
			"visa_include"
		].splice(indexIncludeVisa, 1);
		setviewConfiguration([...newData]);
	};

	const deleteVisa = (ind, indexs) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[ind].residences = counrtyX.visa_types[
			ind
		].residences.filter((res, index) => indexs != index);
		if (counrtyX.visa_types[ind].residences.length == 0) {
			//newData = newData.filter((res) => res.country != countryBase);
			counrtyX.visa_types = counrtyX.visa_types.filter(
				(res, index) => res?.residences?.length != 0
			);
			if (counrtyX.visa_types?.length == 0) {
				newData = newData.filter((res) => res.country != countryBase);
			}
		}
		setviewConfiguration([...newData]);
	};

	const handleRemovePackageRow = (ind, indexs, indexPackage) => {
		let newData = viewConfiguration;
		let counrtyX = newData.filter((res) => res.country == countryBase)[0];
		counrtyX.visa_types[indexs].residences[ind].package_plans =
			counrtyX.visa_types[indexs].residences[ind]?.package_plans.filter(
				(res, index) => index != indexPackage
			);
		setviewConfiguration([...newData]);
	};

	useEffect(() => {
		let allConfigurationClone = viewConfiguration;
		let selectedConfig = allConfigurationClone.filter(
			(res) => res.country == countryBase
		);

		if (selectedConfig?.length > 1) {
			setAlertToggle(true);
			selectedConfig = selectedConfig[0];
			allConfigurationClone.pop(selectedConfig[1]);
		}
		setviewConfiguration([...allConfigurationClone]);
	}, [addToggle]);

	useEffect(() => {
		if (!arrayCountry.includes(countryBase?.value)) {
			setArrayCountry([...arrayCountry, countryBase?.value]);
		}
	}, []);

	return (
		<>
			{!visa && inputsState && inputsState?.length > 0
				? inputsState.map((visaType, visaTypeIndex) => {
						return (
							<div className="visa-row" key={visaTypeIndex}>
								{visaType.residences.map((configDataItem, residenceIndex) => {
									return (
										<div className="m-auto" key={residenceIndex}>
											<div className="row visa-row-content d-flex flex-wrap">
												<CollapseVisa
													collapseName={visaType?.name}
													index={residenceIndex}
												>
													{/* details */}
													<VisaRowDetails
														allConfigData={allConfigData}
														configDataItem={configDataItem}
														countries={countries}
														visaPermissionManage={visaPermissionManage}
														handleChangeinArray={handleChangeinArray}
														visaTypeIndex={visaTypeIndex}
														residenceIndex={residenceIndex}
														currencies={currencies}
														consumers={consumers}
														handleCopy={handleCopy}
														handleAddPackage={handleAddPackage}
														countryBase={countryBase}
														deleteVisa={deleteVisa}
													/>

													{/* plans */}

													{configDataItem?.package_plans?.length > 0 && (
														<div className="w-100 package_plan_container mt-4">
															<div className="Package_Plan">
																<h4>{backOffice.PackagePlans}</h4>
															</div>

															{configDataItem?.package_plans.map(
																(packageItem, packageIndex) => {
																	return (
																		<VisaRowPackagePlans
																			key={
																				packageItem?.package_uuid ||
																				packageIndex
																			}
																			countryBase={countryBase}
																			configDataItem={configDataItem}
																			packageItem={packageItem}
																			packageIndex={packageIndex}
																			visaTypeIndex={visaTypeIndex}
																			residenceIndex={residenceIndex}
																			visaPermissionManage={
																				visaPermissionManage
																			}
																			handleChenageinPackages={
																				handleChenageinPackages
																			}
																			handleAddPackageRow={handleAddPackageRow}
																			handleRemovenIcludeVisa={
																				handleRemovenIcludeVisa
																			}
																			handleRemovePackageRow={
																				handleRemovePackageRow
																			}
																			visaIncludeState={visaIncludeState}
																			setVisaIncludeState={setVisaIncludeState}
																		/>
																	);
																}
															)}
														</div>
													)}
												</CollapseVisa>
											</div>
										</div>
									);
								})}
								{visaType.residences?.length == 0 ? (
									""
								) : (
									<ShowForPermission permission="Visa-Configuration-Manage">
										<button
											className="btn btn-success p-2 my-2"
											onClick={() => addEdit(visaTypeIndex)}
										>
											<i class="fas fa-plus-circle mx-1"></i>
											{backOffice.add}
										</button>
									</ShowForPermission>
								)}
							</div>
						);
				  })
				: ""}
			{alertToggle ? (
				<WarningModal isOpen={alertToggle} toggle={setAlertToggle} />
			) : (
				""
			)}

			<IncludeVisaModal
				isOpen={isOpen}
				toggle={toggle}
				incudeVisaIndexs={incudeVisaIndexs}
				setviewConfiguration={setviewConfiguration}
				viewConfiguration={viewConfiguration}
				handleAddIncludeVisa={handleAddIncludeVisa}
				handleChenageinPackages={handleChenageinPackages}
				handleAddPackageRow={handleAddPackageRow}
				countryBase={countryBase}
				handleRemovenIcludeVisa={handleRemovenIcludeVisa}
			/>
		</>
	);
}
