import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import Locale from "translations";
export default function GroundServiceDetails({
	detailsDialogStatus,
	setDetailsDialogStatus,
	details,
	terms,
}) {
	const handleDialogClose = () => {
		setDetailsDialogStatus(false);
	};
	const { backOffice } = Locale;
	return (
		<>
			<Dialog
				open={detailsDialogStatus}
				onClose={handleDialogClose}
				scroll="body"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				fullWidth
				maxWidth="md"
			>
				<DialogTitle id="scroll-dialog-title">
					<div className="d-flex justify-content-between">
						<p className={`b2c_secondary_color fw-bold m-0`}>
							{backOffice.GroundServiceDetails}
						</p>
						<IconButton className={`close_icon`} onClick={handleDialogClose}>
							<i class="fas fa-times" />
						</IconButton>
					</div>
				</DialogTitle>

				<DialogContent>
					{details}
					{terms?.length > 0 && (
						<>
							<h5 className="mt-3 fw-bold">{backOffice.termsAndConditions}</h5>
							<p> {terms}</p>
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
