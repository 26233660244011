import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const [currencies, setCurrencies] = useState([]);
	const [countries, setCountries] = useState([]);
	const { locale, allCountries, allCurrencies } = useGlobalState();

	const { requests, backOffice } = Locale;

	useEffect(() => {
		async function currenciesLookup() {
			const format = allCurrencies?.map((t) => ({
				value: t.id,
				label: t.currency_code,
			}));
			setCurrencies(format);
		}
		currenciesLookup();
	}, [allCurrencies]);

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	return (
		<div className=" py-2 my-2 px-3  main-filter ">
			<div className="row align-items-center w-100 no-gutter m-0">
				<div className="col">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						/* 						hasLabel={false}
						 */ value={filter?.search}
						onChange={(e) => {
							setFilter({
								...filter,
								search: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						placeholder={backOffice.COUNTRY}
						label={backOffice.COUNTRY}
						name="status"
						options={countries}
						value={filter?.country_id}
						onChange={(e) => {
							setFilter({
								...filter,
								country_id: e,
							});
						}}
					/>
				</div>

				<div className="col">
					<SelectField
						/* 						hasLabel={false}
						 */ placeholder={backOffice.currency}
						label={backOffice.currency}
						name="status"
						options={currencies}
						value={filter?.currency}
						onChange={(e) => {
							setFilter({
								...filter,
								currency: e,
							});
						}}
					/>
				</div>

				<div className="col">
					<div
						className="d-flex align-items-center text-secondary pointer mt-4 reset-btn"
						onClick={() => {
							setFilter({
								country_id: null,
								currency: null,
								search: "",
							});
						}}
					>
						<img src={resetIcon} alt="" srcset="" />
						{/* 						<i className="fas fa-retweet position-static px-1 text-secondary text-caption"></i>
						 */}{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
