

// Translation
import Locale from 'translations';
// Components
import TextField from 'components/shared/TextField';
// Helper
import validate from 'helpers/validate';
// ----------------------------------------------------------------------------

export default function PackageQuotasList(props) {

  const {
    discountData,
    setDiscountData,
    errors,
    setErrors,
  } = props

  // *** Hooks
  const { safaVisa } = Locale;

  // *** Functions
  const handleChangeInputs = ({ key, value }, index) => {
    const dataClone = { ...discountData }
    const quota_discounts = dataClone['quota_discounts']
    if (value <= quota_discounts[index]['amount']) {
      quota_discounts[index]['units'] = value
      setDiscountData(dataClone)
    }
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
  }

  // --------- JSX Code --------
  return (
    <div className='position-relative'  >

      {/* Table */}
      <div className="our-border table-responsive  mt-4">
        <table className="custom-table back-office-table table table-striped mb-0">

          <thead>
            <tr>
              <th>{safaVisa.id}</th>
              <th> {safaVisa.visaType}</th>
              <th>{safaVisa.count}</th>
              <th>{safaVisa.extraUnitsCount}</th>
            </tr>
          </thead>

          <tbody>
            {discountData?.quota_discounts?.map((row, index) =>
              <tr>
                <td><p className='id-text'> {index + 1} </p></td>

                <td> {safaVisa[row?.visa_type?.toLowerCase()]}  </td>

                <td> {row?.amount} </td>

                <td>
                  <div className='col-10'>
                    <TextField
                      type="number"
                      label={safaVisa.units}
                      hasLabel={false}
                      name="extra_units"
                      id="extra_units"
                      value={row?.units}
                      onChange={(e) => handleChangeInputs({ key: 'units', value: e?.target?.value }, index)}
                      color={errors?.units?.required ? "danger" : ""}
                      errors={errors?.units}
                    />
                  </div>
                </td>
              </tr>

            )}
          </tbody>

        </table>
      </div >

    </div>
  )
}

