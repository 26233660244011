


import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// ReactStrap
import { UncontrolledTooltip } from 'reactstrap';
// Translation
import Locale from 'translations';
// Icons
import { LeftArrowIcon } from 'components/Icons';
// Services
import { getDiscountDetailsById } from 'services/safaVisa';
// import { getPackagePricingById } from '/safaVisa';
// Context
import { useGlobalState } from 'context/global';

// ----------------------------------------------------------------------------------
function DiscountDetails() {
  // *** Hooks
  let history = useHistory();

  let { id } = useParams();

  const { locale } = useGlobalState();

  const { safaVisa, backOffice } = Locale;

  // ** State
  const [discountDetails, setDiscountDetails] = useState({})

  // *** Function
  const defineIsActive = (status) => {
    return status === 1 ? true : false
  }

  // const defineSubscriptionType = (postpaid_price, prepaid_price) => {
  //   if (postpaid_price && prepaid_price) {
  //     return `${safaVisa.postpaid} + ${safaVisa.prepaid}`
  //   } if (postpaid_price) {
  //     return `${safaVisa.postpaid}`
  //   }
  //   if (prepaid_price) {
  //     return `${safaVisa.prepaid}`
  //   } else {
  //     return safaVisa?.noSubscription
  //   }
  // }

  const defineOfferType = (data) => {
    const { pricing_type, extraUnits, prepaid_discount, postpaid_discount } = data
    if (pricing_type === 'package') {
      if ((prepaid_discount || postpaid_discount) && extraUnits === 1) {
        return safaVisa?.extraUnitsAndPrice
      }
      if (prepaid_discount || postpaid_discount) {
        return safaVisa?.price
      }
    } if (pricing_type === 'default_price') {
      return safaVisa?.price
    }
  }

  const fetchDiscountDetails = async () => {
    const response = await getDiscountDetailsById(id)
    if (response?.status === 200) {
      setDiscountDetails(response?.data?.data)
    }
  }

  const packageQuotasList = discountDetails?.package?.quotas

  const quotaDiscountsList = discountDetails?.quota_discounts

  const pricingDiscountsList = discountDetails?.price_discounts
  const defaultPricingList = discountDetails?.default_pricing?.prices

  // const qoutasDiscountToShow = discountDetails?.package?.quotas?.filter((item, index) => item?.visa_type === quotaDiscounts?.find(e => e?.visa_type === item?.visa_type).visa_type)
  //   ?.map((item) => ({ ...item, units: quotaDiscounts?.find(e => e.visa_type === item?.visa_type)?.amount }))

  // to get list of quotas which contains extra units (diff between packageQuotas & quotaDiscounts )
  const displayedQuotesList = packageQuotasList?.filter((item) => item?.visa_type === quotaDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.visa_type)

  const displayedDefaultPricingList = defaultPricingList?.filter((item) => item?.visa_type === pricingDiscountsList?.find(e => e?.visa_type === item?.visa_type)?.visa_type)



  const packageSubscriptionPricing = [
    {
      name: safaVisa.postpaid,
      price: discountDetails?.package?.postpaid_price,
      currency: discountDetails?.package?.currency,
      price_after_discount: discountDetails?.postpaid_discount
    },
    {
      name: safaVisa.prepaid,
      price: discountDetails?.package?.prepaid_price,
      currency: discountDetails?.package?.currency,
      price_after_discount: discountDetails?.prepaid_discount
    },
  ]

  const displayedPackageSubscriptionList = packageSubscriptionPricing?.filter(el => el?.price_after_discount !== null)


  useEffect(() => {
    fetchDiscountDetails()
  }, [])

  // console.log(discountDetails)

  // -------- JSX Code -------
  return (
    <div className='safaVisa-wrapper'>

      <div className='holder mb-3'>

        {/* Discount Details Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.discountDetails}</h4>

          <div className='d-flex gap-2 align-items-center'>
            <p className={`${defineIsActive(discountDetails?.is_active) ? 'active-status ' : 'notActivated-status'}`}>
              {discountDetails?.is_active === 1 ? safaVisa.active : safaVisa?.notActive}
            </p>
          </div>
        </div>

        <hr />

        {/* Discount Details Info */}
        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.pricingType} :</p>
            <p className='subTitle'> {safaVisa[discountDetails?.pricing_type]}</p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.package} :</p>
            <p className='subTitle'>
              {discountDetails?.pricing_type === 'package' ?
                discountDetails?.package?.title?.[locale]
                :
                discountDetails?.default_pricing?.title?.[locale]
              }
            </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.packageCode} :</p>
            <p className='subTitle' >
              {discountDetails?.pricing_type === 'package' ?
                discountDetails?.package?.code
                :
                discountDetails?.default_pricing?.code
              }
            </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.discountCreactionDate} :</p>
            <p className='subTitle' >{discountDetails?.created_at}</p>
          </div>

        </div>

        <div className='pricing-info-holder mb-5'>

          <div>
            <p className='mb-2 title'>{safaVisa.numberOfPurchases} :</p>
            <p className='subTitle'>{discountDetails?.visa_issued} </p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.country} :</p>
            <p className='subTitle d-flex' >
              {discountDetails?.countries?.[0]?.name}
              {
                discountDetails?.countries?.length > 1 ?
                  <>
                    <span
                      href="#"
                      className='more-info-toolTip'
                      id="UncontrolledTooltipExample"
                    >
                      +{discountDetails?.countries?.length - 1}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="UncontrolledTooltipExample"
                    >
                      {discountDetails?.countries?.slice(1).map(item =>
                        <>
                          {item?.name} {' '}-
                        </>
                      )}
                    </UncontrolledTooltip>
                  </>
                  :
                  null
              }
            </p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '> {safaVisa.discountStartDate} :</p>
            <p className='subTitle' >{discountDetails?.start_date}</p>
          </div>


          <div className='left-border'>
            <p className='mb-2 title '> {safaVisa.discountEndDate} :</p>
            <p className='subTitle' >{discountDetails?.end_date || safaVisa?.indeterminate}</p>
          </div>

        </div>

        <div className='pricing-info-holder mb-4'>

          <div>
            <p className='mb-2 title'>{safaVisa.discountType} :</p>
            {/* <p className='subTitle'>{defineOfferType(discountDetails?.is_price_offer, discountDetails?.is_quota_offer)}</p> */}
            <p className='subTitle'>{safaVisa[discountDetails?.discount_type]}</p>
          </div>

          <div className='left-border'>
            <p className='mb-2 title '>{safaVisa.offerType} :</p>
            <p className='subTitle' >
              {defineOfferType({
                pricing_type: discountDetails?.pricing_type,
                extraUnits: discountDetails?.is_quota_offer,
                prepaid_discount: discountDetails?.prepaid_discount,
                postpaid_discount: discountDetails?.postpaid_discount,

              })}
            </p>
          </div>


        </div>

        <div className='pricing-info-holder'>
          <div>
            <p className='mb-2 title'>{safaVisa.dicountNotification}</p>
            <p className='subTitle'> {discountDetails?.notification_text?.[locale]}</p>
          </div>
        </div>

      </div>

      {/* Discount Defualt Pricing Type List  Table */}
      {(displayedDefaultPricingList?.length > 0 && discountDetails?.pricing_type === 'default_price') &&
        <div className='holder mb-3'>
          <h4 className="main-title mb-4 ">{safaVisa.defaultPricing}</h4>
          <div className="our-border">
            <table className="custom-table back-office-table table table-striped mb-0">

              <thead>
                <tr>
                  <th>{safaVisa.id}</th>
                  <th> {safaVisa.visaType}</th>
                  <th>{safaVisa.currency}</th>
                  <th> {safaVisa.price}</th>
                  <th>{safaVisa.priceAfterDiscount}</th>
                </tr>
              </thead>

              <tbody>
                {displayedDefaultPricingList?.map((row, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row?.visa_type}</td>
                    <td> <span >{discountDetails?.default_pricing?.currency}</span></td>
                    <td > {row?.price}</td>
                    <td > {/* to find corresponding price in defaultPriceDiscount */}
                      {pricingDiscountsList?.find(e => e.visa_type === row?.visa_type)?.price}</td>
                  </tr>
                )}
              </tbody>

            </table>
          </div >
        </div>
      }

      {/* Discount Package Pricing Type List  Table */}
      {(displayedPackageSubscriptionList?.length > 0 && discountDetails?.pricing_type === 'package') &&
        <div className='holder mb-3'>
          <h4 className="main-title mb-4 ">{safaVisa.pricing}</h4>
          <div className="our-border">
            <table className="custom-table back-office-table table table-striped mb-0">

              <thead>
                <tr>
                  <th>{safaVisa.id}</th>
                  <th>{safaVisa.pricingType}</th>
                  <th>{safaVisa.currency}</th>
                  <th> {safaVisa.price}</th>
                  <th>{safaVisa.priceAfterDiscount}</th>
                </tr>
              </thead>

              <tbody>
                {displayedPackageSubscriptionList?.map((row, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row?.name}</td>
                    <td> <span >{row?.currency}</span></td>
                    <td > {row?.price}</td>
                    <td > {row?.price_after_discount || row?.price}</td>
                  </tr>
                )}
              </tbody>

            </table>
          </div >
        </div>
      }

      {/* Package Quotas Discount  List  Table */}
      {(displayedQuotesList?.length > 0 && discountDetails?.pricing_type === 'package') &&
        < div className='holder'>
          <h4 className="main-title mb-4 ">{safaVisa.packageUnits}</h4>
          <div className="our-border">
            <table className="custom-table back-office-table table table-striped mb-0">

              <thead>
                <tr>
                  <th>{safaVisa.id}</th>
                  <th> {safaVisa.visaType}</th>
                  <th>{safaVisa.unitsAmount}</th>
                  <th>{safaVisa.extraUnits}</th>
                </tr>
              </thead>

              <tbody>
                {displayedQuotesList?.length > 0 ? (
                  displayedQuotesList?.map((row, index) =>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{safaVisa[row?.visa_type]}</td>
                      <td> <span >{row?.amount}</span></td>
                      <td >
                        {/* to find corresponding amount in quotaDiscount */}
                        {quotaDiscountsList?.find(e => e.visa_type === row?.visa_type)?.amount}
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="product-no-data">
                        <i className="fas fa-info-circle fa-lg"></i>{" "}
                        <h4>{backOffice.noResult}</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
          </div >
        </div>
      }

      <button
        className="btn back-btn py-2 mt-5"
        onClick={() => history.push("/safaVisa-discount")}
      >
        <span  >
          <LeftArrowIcon color='#1e85ff' width='24' height='24' />
        </span>
        {safaVisa.back}
      </button>

    </div >
  )
}

export default DiscountDetails