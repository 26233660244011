import DatePickerField from "components/shared/DatePickerField";
import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { isInclusivelyAfterDay } from "react-dates";
import { store } from "react-notifications-component";
import { fetchCitiesSearch } from "services/lookups";
import {
	bookingExportToExcel,
	listBooking,
	UpdatePaymentExpirationDate,
} from "services/Request";

import ShowForPermission from "helpers/showForPermission";
import Locale from "translations";
import BookItem from "./bookItem";
import Colums from "./Colums";
import MainFilter from "./MainFilter";
import CustomModal from "./Modal";
import SideFilter from "./SideFilter";

export default function Bookings() {
	const { backOffice, booking, payment, requests } = Locale;
	const { locale, allCountries } = useGlobalState();
	const [bookings, setBookings] = useState([]);
	const [selectItem, setSelectItem] = useState(null);

	const [extendIsOpen, setExtendIsOpen] = useState(false);
	const toggleExtend = () => {
		setExtendIsOpen(!extendIsOpen);
	};
	const colums = [
		// { id: "referral_code", label: booking?.Ref },
		{ id: "brn", label: booking.SRN },
		{ id: "sbs_ref", label: "SBS Ref." },
		{ id: "agency", label: booking.Agency },
		{ id: "hotel_name", label: booking.HotelName },
		{ id: "source", label: booking.source },
		{ id: "rooms_count", label: booking.RoomCount },
		{ id: "booking_date", label: booking.BookingDate },
		{ id: "checkin_date", label: booking.CheckinDate },
		{ id: "nights_count", label: booking.Nights },
		{ id: "paid_later", label: booking.PaidLater },
		{ id: "reservation_status", label: booking.Status },
		{ id: "destination", label: booking.Destination },
		{ id: "selling_rate", label: backOffice.SellingRate },
		{ id: "total_refund", label: "Total Refund" },
		{ id: "total_paid", label: "Total Paid" },
		{ id: "total_net_price", label: booking.totalNetPrice },
		{ id: "pax", label: booking.pax },
		{ id: "customer_charges", label: booking.customerCharges },
		{ id: "earnings", label: backOffice.earnings },
		{ id: "payment_method", label: payment.messages.paymentMethod },
		{ id: "payment_expiration", label: "Payment Expiration" },
		// { id: "payment_document_number", label: payment.messages.paymentDocumentNumber },
		{ id: "provider_name", label: requests.supplier },
		{ id: "online_payments", label: booking.onlinePayments },
		// { id: "price", label: "Price" },
		{ id: "currency", label: "Currency" },
		// { id: "brn", label: booking.LeaderName, },
		// { id: "brn", label: booking.SupplierRef, },
	];
	const [meta, setMeta] = useState(null);

	const [filter, setFilter] = useState({
		page: null,
		search: "",
		destination_id: null,
		visa_type_id: null,
		start_date: null,
		cancellation_date: null,
		// booking_date: null,
		booking_date_start: undefined,
		booking_date_end: undefined,
		goingTo: null,
		nights_count: "",
		reference_no: "",
		brn: "",
		hotel_name: "",
		destination: "",
		leader_name: "",
		reservation_status: "",
		source: null,
	});
	const [dateExpiration, setDateExpiration] = useState(null);
	useEffect(() => {
		const fetchBookings = async () => {
			const res = await listBooking({
				...filter,
				visa_type_id: filter.visa_type_id?.label,
				reservation_status: filter.reservation_status?.label,
				destination: filter.destination,
				start_date: filter.start_date
					? moment(filter.start_date).format("YYYY-MM-DD")
					: null,
				cancellation_date: filter.cancellation_date
					? moment(filter.cancellation_date).format("YYYY-MM-DD")
					: null,
				// booking_date: filter.booking_date
				// 	? moment(filter.booking_date).format("YYYY-MM-DD")
				// 	: null,
				booking_date_start: filter.booking_date_start
					? moment(filter.booking_date_start).format("YYYY-MM-DD")
					: null,
				booking_date_end: filter.booking_date_end
					? moment(filter.booking_date_end).format("YYYY-MM-DD")
					: null,
				source: filter?.source?.value,
			});
			setBookings(res.data.data);
			setMeta(res?.data?.meta);
		};
		fetchBookings();
	}, [filter]);

	const columsList = colums.map((item, index) => {
		return {
			id: item.id,
			name: item.label,
		};
	});

	const [show, setShow] = useState({
		filter: false,
		colums: false,
	});

	const toggleShow = (active) => {
		setShow(active);
	};

	const [checkedColums, setCheckedColums] = useState(
		columsList.map((colum) => colum.id).slice(0, 10)
	);

	const handelCheck = (colum, flag) => {
		if (flag) {
			setCheckedColums([...checkedColums, colum.id]);
		} else {
			let newListChecked = checkedColums.filter((id) => id != colum.id);
			setCheckedColums(newListChecked);
		}
	};

	const tableHeaders = useMemo(() => {
		return columsList.map((colum) => {
			return checkedColums.includes(colum.id) ? (
				colum.name == "Hotel Name" ? (
					<th style={{ width: "250px", minWidth: "250px" }} key={colum.id}>
						{colum.name}
					</th>
				) : (
					<th key={colum.id}>{colum.name}</th>
				)
			) : null;
		});
	}, [checkedColums]);

	const allBooking = useMemo(() => {
		return bookings?.map((data) => {
			return (
				<BookItem
					data={data}
					checkedColums={checkedColums}
					toggleExtend={toggleExtend}
					setSelectItem={setSelectItem}
				/>
			);
		});
	}, [checkedColums, bookings]);

	const goTo = (page) => {
		setFilter({ ...filter, page: +page });
		console.log("setFilter", filter);
	};
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		//const value = e.target?.value;
		const value = e.value?.name;

		if (flag) {
			setFilter({
				...filter,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setFilter({ ...filter, destination: value });
		setFilter({ ...filter, destination: value });
	};
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch(inputValue);
			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					//name: item.name + " - " + item.country.name[locale],
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});

			setListAuto(result);
		}
	};

	async function editExpirationDate() {
		const response = await UpdatePaymentExpirationDate({
			brn: selectItem.brn,
			payment_expiration: moment(dateExpiration).format("YYYY-MM-DD"),
		});
		if (response.status === 200 || response.status === 200) {
			toggleExtend();
			store.addNotification({
				title: "Info!",
				message: response?.message
					? response?.message
					: "Payment Expiration Date Updated successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	async function exportBookingExcel() {
		const filteredData = {
			...filter,

			visa_type_id: filter.visa_type_id?.label,
			reservation_status: filter.reservation_status?.label,
			destination: filter.destination,
			start_date: filter.start_date
				? moment(filter.start_date).format("YYYY-MM-DD")
				: null,
			cancellation_date: filter.cancellation_date
				? moment(filter.cancellation_date).format("YYYY-MM-DD")
				: null,
			booking_date_start: filter.booking_date_start
				? moment(filter.booking_date_start).format("YYYY-MM-DD")
				: null,
			booking_date_end: filter.booking_date_end
				? moment(filter.booking_date_end).format("YYYY-MM-DD")
				: null,
			source: filter?.source?.value,
		};
		const res = await bookingExportToExcel({
			export_columns: checkedColums,
			...filteredData,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					//type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					type: res.headers["content-type"],
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Hotels Booking-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
		// console.log(checkedColums);
	}

	return (
		<>
			{show.filter || show.colums ? (
				<div
					className="booking-close-dropdown"
					onClick={() => {
						toggleShow({
							filter: false,
							colums: false,
						});
					}}
				/>
			) : null}

			<div className="row justify-content-between m-0 p-0 px-3">
				<h3 className="main-title col">{booking?.booking}</h3>

				<div className="row m-0 p-0 col-8   justify-content-end">
					<div className="col-xl-2  col-3">
						<SideFilter
							toggleShow={toggleShow}
							show={show}
							filter={filter}
							setFilter={setFilter}
							countries={countries}
							handleChange={handleChange}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
						/>
					</div>

					<div className="col-xl-2 col-3">
						<Colums
							toggleShow={toggleShow}
							show={show}
							columsList={columsList}
							handelCheck={handelCheck}
							checkedColums={checkedColums}
						/>
					</div>

					<div className="col-xl-2 col-3">
						<div className="border rounded filter-col">
							<ShowForPermission permission="Hotels-Bookings-Manage">
								<button
									className="btn btn-primary w-100"
									style={{ color: "#fff" }}
									onClick={exportBookingExcel}
								>
									{booking.ExportExcel}
								</button>
							</ShowForPermission>
						</div>
					</div>
				</div>

				<div className="col-12 px-0">
					<MainFilter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						handleChange={handleChange}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
					/>
				</div>

				<div className=" table-responsive px-4 our-border">
					<table className="custom-table table-responsive back-office-table table table-striped">
						<thead>
							<tr className="text-nowrap">{tableHeaders}</tr>
						</thead>

						<tbody>
							{allBooking?.length > 0 ? (
								allBooking
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<div className="px-2">
						<Pagination info={meta} goTo={goTo} />
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={extendIsOpen}
				header={"Extend Date"}
				toggle={toggleExtend}
				size={"md"}
				centered={true}
			>
				<div className=" col-md-12">
					<div className="p-2 row">
						<div className="col-md-12 mt-2 px-3">
							<DatePickerField
								type="text"
								placeholder={"Expiration Date"}
								onOutsideClick={false}
								hasLabel={true}
								label={"Expiration Date"}
								date={dateExpiration}
								onChangeDate={(e) => {
									setDateExpiration(e);
								}}
								isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
							/>
						</div>

						<button
							disabled={!dateExpiration}
							style={{ backgroundColor: "#FF6868" }}
							className="btn companies-btn w-100 mx-3"
							onClick={() => editExpirationDate()}
						>
							Extend Date
						</button>
					</div>
				</div>
			</CustomModal>
		</>
	);
}
