const dHeight = '24';
const dWidth = '24';
const dColor = 'white';

export function ExportIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4405 8.9C20.0405 9.21 21.5105 11.06 21.5105 15.11V15.24C21.5105 19.71 19.7205 21.5 15.2505 21.5H8.74047C4.27047 21.5 2.48047 19.71 2.48047 15.24V15.11C2.48047 11.09 3.93047 9.24 7.47047 8.91" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 15V3.62" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function MoreIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.832 13C13.3843 13 13.832 12.5523 13.832 12C13.832 11.4477 13.3843 11 12.832 11C12.2797 11 11.832 11.4477 11.832 12C11.832 12.5523 12.2797 13 12.832 13Z" fill="#9C9FAB" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.832 6C13.3843 6 13.832 5.55228 13.832 5C13.832 4.44772 13.3843 4 12.832 4C12.2797 4 11.832 4.44772 11.832 5C11.832 5.55228 12.2797 6 12.832 6Z" fill="#9C9FAB" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.832 20C13.3843 20 13.832 19.5523 13.832 19C13.832 18.4477 13.3843 18 12.832 18C12.2797 18 11.832 18.4477 11.832 19C11.832 19.5523 12.2797 20 12.832 20Z" fill="#9C9FAB" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function EditIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0914 3.60022L5.88139 12.2902C5.57139 12.6202 5.27139 13.2702 5.21139 13.7202L4.84139 16.9602C4.71139 18.1302 5.55139 18.9302 6.71139 18.7302L9.93139 18.1802C10.3814 18.1002 11.0114 17.7702 11.3214 17.4302L19.5314 8.74022C20.9514 7.24022 21.5914 5.53022 19.3814 3.44022C17.1814 1.37022 15.5114 2.10022 14.0914 3.60022Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.7227 5.0498C13.1527 7.8098 15.3927 9.9198 18.1727 10.1998" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.83203 22H21.832" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function CopyIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function DocumentIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 13H13" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 17H11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function DownLoadIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4405 8.9C20.0405 9.21 21.5105 11.06 21.5105 15.11V15.24C21.5105 19.71 19.7205 21.5 15.2505 21.5H8.74047C4.27047 21.5 2.48047 19.71 2.48047 15.24V15.11C2.48047 11.09 3.93047 9.24 7.47047 8.91" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 2V14.88" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.3504 12.65L12.0004 16L8.65039 12.65" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function PrintIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.25 7H16.75V5C16.75 3 16 2 13.75 2H10.25C8 2 7.25 3 7.25 5V7Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 15V19C16 21 15 22 13 22H11C9 22 8 21 8 19V15H16Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 10V15C21 17 20 18 18 18H16V15H8V18H6C4 18 3 17 3 15V10C3 8 4 7 6 7H18C20 7 21 8 21 10Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 15H15.79H7" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 11H10" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function LeftArrowIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50016 19.92L16.0202 13.4C16.7902 12.63 16.7902 11.37 16.0202 10.6L9.50016 4.07999" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function InfoIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7493 18.3333C15.3327 18.3333 19.0827 14.5833 19.0827 10C19.0827 5.41667 15.3327 1.66667 10.7493 1.66667C6.16602 1.66667 2.41602 5.41667 2.41602 10C2.41602 14.5833 6.16602 18.3333 10.7493 18.3333Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.75 6.66667V10.8333" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.7461 13.3333H10.7536" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function FilterIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3206 19.07C14.3206 19.68 13.9205 20.48 13.4105 20.79L12.0005 21.7C10.6905 22.51 8.87054 21.6 8.87054 19.98V14.63C8.87054 13.92 8.47055 13.01 8.06055 12.51L4.22052 8.47C3.71052 7.96 3.31055 7.06001 3.31055 6.45001V4.13C3.31055 2.92 4.22057 2.01001 5.33057 2.01001H18.6705C19.7805 2.01001 20.6906 2.92 20.6906 4.03V6.25C20.6906 7.06 20.1805 8.07001 19.6805 8.57001" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.0692 16.52C17.8365 16.52 19.2692 15.0873 19.2692 13.32C19.2692 11.5527 17.8365 10.12 16.0692 10.12C14.3018 10.12 12.8691 11.5527 12.8691 13.32C12.8691 15.0873 14.3018 16.52 16.0692 16.52Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.8691 17.12L18.8691 16.12" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function DownArrowIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7L13 1" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function ResetIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.375 22C17.8978 22 22.375 17.5228 22.375 12C22.375 6.47715 17.8978 2 12.375 2C6.85215 2 2.375 6.47715 2.375 12C2.375 17.5228 6.85215 22 12.375 22Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.38477 14.5099C8.56477 14.8099 8.78476 15.09 9.03476 15.34C10.8748 17.18 13.8648 17.18 15.7148 15.34C16.4648 14.59 16.8948 13.6399 17.0348 12.6699" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.71484 11.3298C7.85484 10.3498 8.28485 9.40979 9.03485 8.65979C10.8749 6.81979 13.8648 6.81979 15.7148 8.65979C15.9748 8.91979 16.1848 9.1998 16.3648 9.4898" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.19531 17.1797V14.5098H10.8653" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.5547 6.81982V9.48981H13.8848" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
}
export function DollarIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 6V18" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function TrashIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} ox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.125 5.98C17.795 5.65 14.445 5.48 11.105 5.48C9.125 5.48 7.145 5.58 5.165 5.78L3.125 5.98" stroke={color} strok-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.625 4.97L8.845 3.66C9.005 2.71 9.125 2 10.815 2H13.435C15.125 2 15.255 2.75 15.405 3.67L15.625 4.97" stroke={color} strok-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9754 9.14L18.3254 19.21C18.2154 20.78 18.1254 22 15.3354 22H8.91539C6.12539 22 6.03539 20.78 5.92539 19.21L5.27539 9.14" stroke={color} strok-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.4551 16.5H13.7851" stroke={color} strok-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.625 12.5H14.625" stroke={color} strok-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
export function VisasIcon({ color = dColor, width = dWidth, height = dHeight }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5020_142250)">
        <path d="M17.8257 23.5H3.48242V3.43095H17.8257C19.3134 3.43095 20.5195 4.60467 20.5195 6.05248V20.8785C20.5195 22.3263 19.3134 23.5 17.8257 23.5Z" stroke="#9C9FAB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.48242 3.43066L14.7181 0.598511C16.5011 0.0870711 18.2917 1.64 18.0637 3.43066" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.4779 12.3903C17.4779 15.3347 15.0253 17.7217 11.9997 17.7217C8.97413 17.7217 6.52148 15.3347 6.52148 12.3903C6.52148 9.44582 8.97413 7.05888 11.9997 7.05888C15.0253 7.05888 17.4779 9.44582 17.4779 12.3903Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.5332 7.10187C9.49979 11.0397 9.48395 13.7555 11.5004 17.6777" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.4911 17.6777C14.5094 13.749 14.5102 11.0332 12.4785 7.10187" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.68555 12.3901H17.3074" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.72461 20.6321H9.26782" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.2148 20.6321H17.4798" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5020_142250">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}