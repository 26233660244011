import Pagination from "components/shared/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { commercialDistTransferList } from "services/commercialDistribution";
import Locale from "translations";
import CommercialDistTransferFilters from "./Filters";

export default function CommercialDistTransferReservation() {
	const { backOffice, vendor, booking } = Locale;
	const { id } = useParams();
	const [transferList, setTransferList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [filters, setFilters] = useState({
		search: "",
		confirmation_date: null,
		free_cancellation_date: null,
		status: null,
		page: 1,
	});

	function goTo(page) {
		setFilters({ ...filters, page: +page });
	}

	async function fetchTransferReservations() {
		const filtersData = {
			search: filters?.search,
			status: filters?.status?.label,
			created_at: filters?.confirmation_date
				? moment(filters?.confirmation_date).format("YYYY-MM-DD")
				: null,
			cancellation_date: filters?.free_cancellation_date
				? moment(filters?.free_cancellation_date).format("YYYY-MM-DD")
				: null,
			page: filters?.page,
		};
		const transferRes = await commercialDistTransferList(id, filtersData);
		if (transferRes?.status === 200) {
			setTransferList(transferRes?.data?.data);
			setMeta(transferRes?.data?.meta);
		}
	}

	useEffect(() => {
		fetchTransferReservations();
	}, [filters]);

	const statusClasses = (status) => {
		switch (status?.toLowerCase()) {
			case "pending":
				return "text-light-yellow";
			case "confirmed":
			case "approved":
				return "text-success";
			default:
				return "text-danger";
		}
	};

	return (
		<div>
			<CommercialDistTransferFilters
				filters={filters}
				setFilters={setFilters}
			/>

			<div className=" table-responsive px-4 our-border">
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr>
							<th>Ref.</th>
							{/* <th>{vendor.agency}</th> */}
							<th>{booking.LeaderName}</th>
							<th>{booking.messages.confirmationDate}</th>
							<th>{booking.messages.freeCancelationChargeDate}</th>
							<th>{backOffice.TotalPrice}</th>
							<th>{backOffice.status}</th>
						</tr>
					</thead>
					<tbody>
						{transferList?.length > 0 ? (
							transferList?.map((transfer) => {
								return (
									<tr key={transfer?.id}>
										<td>{transfer?.reference_number}</td>
										<td>
											{transfer?.lead_name} {transfer?.holder_info?.surname}
										</td>
										<td>
											{transfer?.booking_date
												? moment(transfer?.booking_date).format("DD-MM-YYYY")
												: "-"}
										</td>
										<td>
											{transfer?.cancellation_date
												? moment(transfer?.cancellation_date).format(
														"DD-MM-YYYY"
												  )
												: "-"}
										</td>
										<td>
											{transfer?.total_amount} {transfer?.currency}
										</td>
										<td>
											<span
												className={`${statusClasses(
													transfer.status
												)} text-lowercase`}
											>
												{transfer?.status}
											</span>
										</td>
										<td>
											<Link
												className="d-inline-block action--btn"
												to={`/manage-commercial-distribution/${id}/view/transfer/${transfer.id}`}
											>
												<i class="fas fa-chevron-right mx-1"></i>
											</Link>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="10">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</div>
	);
}
