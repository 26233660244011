import Model from "components/model";
import { usePropertyState } from "context/property";
import { useState } from "react";
import { Button, ModalFooter } from "react-bootstrap";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { DeletePhoto, EditPhoto } from "services/property";
import Locale from "translations";

function Photo({ photo, gallery, setGallery, setReload }) {
	const { id, tab } = useParams();
	// const dispatch = useGlobalDispatch();
	const { propertyDetails } = usePropertyState();
	const { backOffice } = Locale;
	// const [photo, setPhoto] = useState([]);
	const [checked, setChecked] = useState(false);
	console.log("photophoto", photo);
	const [checkedIsMain, setCheckedIsMain] = useState(
		photo.is_main == "1" ? true : false
	);

	const [rooms, setRooms] = useState([]);

	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
	});

	const toggle = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "gallery",
			title: "Edit Photo",
		});
	};

	const removePhoto = async (photoId) => {
		if (photo.uploaded) {
			const res = await DeletePhoto(id, {
				photo_id: photoId,
				_method: "delete",
			});
			if (res.status === 200) {
				setGallery(gallery.filter((img) => img.id !== photoId));
				store.addNotification({
					title: "info!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			setGallery([...gallery].filter((photo) => photo.id !== photoId));
		}
	};

	const handleCheckbox = (event) => {
		if (event.target.checked === true) {
			setRooms([...rooms, Number(event.target.value)]);
		} else {
			setRooms([...rooms.filter((e) => e !== Number(event.target.value))]);
		}
	};

	const editRoomPhotos = async () => {
		debugger;
		console.log(photo);
		const data = {
			photo_id: photo.id,
			rooms,
			is_main: checkedIsMain ? 1 : 0,
			_method: "put",
		};
		// if (rooms.length > 0) {
		const res = await EditPhoto(id, data);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
				},
			});
		}
		// }
	};

	// useEffect(() => {
	//   if (rooms.length > 0) {
	//     editRoomPhotos();
	//   }
	// }, [rooms]);

	//

	return (
		<div>
			<Model
				isOpen={modal.isOpen}
				toggle={() => toggle(photo)}
				type={modal.type}
				title={modal.title}
				size="lg"
				className="customModal"
				hasFooter={false}
			>
				<div className="d-flex justify-content-between w-100 ">
					<div className="data-gallery col-md-4 mt-3">
						<div className="form-group form-check">
							<input
								type="checkbox"
								disabled={tab}
								className="form-check-input"
								onChange={(e) => {
									setChecked(!checked);
								}}
								checked={checked ? true : false}
								id="included"
							/>
							<label
								className="form-check-label text-caption pointer text-blue-black font-weight-bold"
								for="included"
							>
								{backOffice.IncludeInYourPhotoGallery}
							</label>
							<p className="text-caption3">
								{backOffice.ThiswillAlsoBeIncludedInYourPropertyPage}
							</p>
						</div>

						<div className="form-group form-check mb-3 mt-0">
							<input
								type="checkbox"
								disabled={tab}
								className="form-check-input"
								onChange={(e) => {
									setCheckedIsMain(!checkedIsMain);
								}}
								checked={checkedIsMain ? true : false}
								id="is_main"
							/>
							<label
								className="form-check-label text-caption pointer text-blue-black font-weight-bold"
								for="is_main"
							>
								{backOffice.SetasMainImage}
							</label>
						</div>

						<div>
							<p className="text-capitalize text-caption font-weight-bold">
								{backOffice.IndicateOfThisIsARoomPhoto}:
							</p>
							{propertyDetails?.property?.rooms?.map((room, i) => {
								return (
									<div className="form-group form-check" key={room.id}>
										<input
											type="checkbox"
											disabled={false}
											className="form-check-input"
											onChange={handleCheckbox}
											value={room.id}
											defaultChecked={rooms.includes(room.id) ? true : false}
											id="room1"
										/>
										<label
											className="form-check-label text-caption3 pointer"
											htmlFor="room1"
										>
											{room.custom_name || room.name.name}
										</label>
									</div>
								);
							})}
						</div>
					</div>
					<div className="photo-gallery text-center col-md-8">
						<img src={photo.image} alt="gallery" className="img-fluid" />
						{/* <div
              className="py-2 text-center bg-trash"
              onClick={() => removePhoto(photo.id)}
            >
              <i className="fa fa-trash text-white pointer"></i>
            </div> */}
					</div>
				</div>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							editRoomPhotos();
							setTimeout(() => {
								toggle();
							}, 100);
						}}
						//disabled={props.disabled}
					>
						{backOffice.save}
					</Button>
					<Button color="secondary" onClick={toggle}>
						{backOffice.cancel}
					</Button>
				</ModalFooter>
			</Model>

			<div className="gallery-item T_image_sbs_container">
				{checkedIsMain && <i class="fas fa-check T_faCheck"></i>}
				<img src={photo.image} alt="gallery" className="img-fluid" />
				<ul className="bg-white d-flex justify-content-center py-1">
					{tab !== "view" && photo.uploaded && (
						<li
							className="mx-2 text-blue-black pointer"
							onClick={toggle}
							id="edit-photo"
						>
							<i className="fa fa-eye fa-fw"></i>
							{backOffice.view}
						</li>
					)}
					{tab !== "view" && (
						<li
							className="mx-2 text-danger pointer"
							onClick={() => removePhoto(photo.id)}
							id="delete-photo"
						>
							<i className="fa fa-trash fa-fw"></i>
							{backOffice.delete}
						</li>
					)}
				</ul>
			</div>
		</div>
	);
}

export default Photo;
