import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function DirectVisaFilter({
	countries,
	filters,
	handleFilters,
	resetAllFilters,
	exportToFile,
	offlineSuppliers,
}) {
	const { backOffice, requests } = Locale;

	return (
		<div className="main-filter py-2 my-2 px-3 border-bottom">
			<div className="row align-items-center w-100 no-gutter m-0">
				{/* passport_number */}
				<div className="col-3">
					<TextField
						type="text"
						placeholder={backOffice.search}
						label={backOffice.search}
						id="search"
						name="search"
						value={filters?.search}
						onChange={(e) => handleFilters(e.target.value, "search")}
					/>
				</div>
				{/* nationality */}
				{/* <div className="col-3">
					<SelectField
						placeholder={backOffice.nationality}
						label={backOffice.nationality}
						name="status"
						options={countries}
						value={filters?.nationality}
						onChange={(e) => handleFilters(e, "nationality")}
					/>
				</div> */}

				<div className="col-3">
					<SelectField
						label={requests.SelectSupplier}
						placeholder={requests.SelectSupplier}
						name="supplier"
						options={offlineSuppliers}
						value={filters.offline_supplier_id}
						onChange={(e) => {
							handleFilters(e, "offline_supplier_id");
						}}
					/>
				</div>

				<div className="col">
					<div
						className="d-flex mt-4 align-items-center text-secondary pointer reset-btn"
						onClick={resetAllFilters}
					>
						<img src={resetIcon} alt="" srcset="" />{" "}
						<p className="text-caption">{backOffice.resetFilter}</p>
					</div>
				</div>

				<div className="col-2 mt-4">
					<button className="btn btn-success w-100 py-2" onClick={exportToFile}>
						<span class="fas fa-cloud-download-alt mx-1"></span>
						{backOffice.ExportFile}
					</button>
				</div>
			</div>
		</div>
	);
}
