import resetIcon from "assets/images/wallet/resetIcon.png";
import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";

import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Filter({ filter, setFilter }) {
	const { backOffice, teamManagement } = Locale;
	const { allCountries, locale } = useGlobalState();
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		function countriesLookups() {
			const format = allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	const servicesLookup = [
		{
			value: 2,
			label: "hotels",
		},
		{
			value: 3,
			label: "flight",
		},
		{
			value: 4,
			label: "transfers",
		},
		{
			value: 5,
			label: "tours",
		},
	];

	const resetFilters = () => {
		setFilter({
			search: "",
			country: null,
			contract_start: null,
			contract_end: null,
			service: null,
		});
	};
	return (
		<>
			<>
				<div className="main-filter bg-white-blue py-2 my-2">
					<div className="row align-items-center w-100 no-gutter m-0">
						<div className="col-2">
							<div className="main-label">
								<TextField
									type="text"
									placeholder={backOffice.search}
									label={backOffice.search}
									hasLabel={true}
									value={filter.search}
									onChange={(e) => {
										setFilter({
											...filter,
											search: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<SelectField
									placeholder={teamManagement.country}
									label={teamManagement.country}
									name="country"
									options={countries}
									value={filter.country}
									onChange={(e) => {
										setFilter({
											...filter,
											country: e,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-2">
							<div className="main-label">
								<DatePickerField
									type="text"
									placeholder={"DD/MM/YYYY"}
									label={teamManagement.ContractFrom}
									onOutsideClick={false}
									date={filter?.contract_start}
									onChangeDate={(e) => {
										setFilter({
											...filter,
											contract_start: e,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<DatePickerField
									type="text"
									placeholder={"DD/MM/YYYY"}
									label={teamManagement.ContractTo}
									onOutsideClick={false}
									date={filter?.contract_end}
									onChangeDate={(e) => {
										setFilter({
											...filter,
											contract_end: e,
										});
									}}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="main-label">
								<SelectField
									placeholder={backOffice.service}
									label={backOffice.service}
									name="services"
									options={servicesLookup}
									value={filter.service}
									onChange={(e) => {
										setFilter({
											...filter,
											service: e,
										});
									}}
								/>
							</div>
						</div>

						<div className="col-md">
							<div
								onClick={resetFilters}
								className="d-flex align-items-center text-secondary pointer mt-4 reset-btn bg-transparent"
							>
								<img src={resetIcon} alt="" srcset="" />
								<p className="text-caption">{backOffice.resetFilter}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
}
