import Pagination from "components/shared/Pagination";
import { useGlobalState } from "context/global";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { exportToAllVisas, fetchAllVisasList } from "services/Request";
import Locale from "translations";
import AllVisasFilter from "./Filter";

export default function AllVisas() {
	const { requests, backOffice, booking, wallet } = Locale;
	const { allCountries, locale } = useGlobalState();
	const [allVisasList, setAllVisasList] = useState([]);
	const [meta, setMeta] = useState(null);
	const [lookupsList, setLookupsList] = useState({
		visaTypes: [],
		sources: [],
		agency: [],
		status: [
			{ label: "Issued", value: "issued" },
			{ label: "Rejected", value: "rejected" },
			{ label: "Waiting", value: "waiting" },
			{ label: "Invalid", value: "invalid" },
		],
	});
	const [filters, setFilters] = useState({
		passport_number: "",
		country: null,
		visa_type: null,
		date: null,
		source: null,
		agency_name: null,
		status: null,
		page: null,
	});
	const [countriesLookup, setCountriesLookup] = useState(null);

	// get all visas list
	async function getAllVisasList() {
		const data = {
			...filters,
			search: filters?.passport_number,
			country: filters?.country?.value,
			visa_type: filters.visa_type?.value,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : "",
			source: filters?.source?.value,
			status: filters?.status?.value,
			agency_name: filters?.agency_name?.value,
		};
		const res = await fetchAllVisasList(data);
		if (res?.status === 200) {
			setAllVisasList(res?.data?.data);
			setMeta(res?.data?.meta);
			const formatVisaTypes = await res?.data?.visa_types.map((visaType) => ({
				value: visaType.id,
				label: visaType.name,
			}));
			const formatSources = await res?.data?.sources.map((source) => ({
				value: source,
				label: source,
			}));
			const formatAgency = await res?.data?.agency_names.map((agency) => ({
				value: agency.id,
				label: agency.name,
			}));
			setLookupsList({
				...lookupsList,
				visaTypes: formatVisaTypes,
				sources: formatSources,
				agency: formatAgency,
			});
		}
	}

	// handle list pagination
	function goTo(page) {
		setFilters({ ...filters, page: page });
	}

	// handle changes in filters
	function handleFiltersChanges(value, filterkeyName) {
		setFilters({
			...filters,
			[filterkeyName]: value,
			page: null,
		});
	}

	// handle reset filters
	function resetAllFilters() {
		setFilters({
			page: filters.page,
			passport_number: "",
			country: null,
			visa_type: null,
			date: null,
			source: null,
			agency_name: null,
		});
	}

	// get
	useEffect(() => {
		getAllVisasList();
	}, [filters]);

	// format countries lookup
	useEffect(() => {
		const format = allCountries.map((t) => ({
			value: t.id,
			label: t.names[locale],
			code: t.country_code,
		}));
		setCountriesLookup(format);
	}, [allCountries, locale]);

	function statusColors(status) {
		switch (status) {
			case "issued":
				return "text-success";
			case "waiting":
				return "text-warning";
			case "expired":
			case "rejected":
			case "incomplete":
			case "invalid":
				return "text-danger";
			default:
				return "text-dark";
		}
	}
	function isJson(string) {
		try {
			JSON.parse(string);
			return true;
		} catch (error) {
			return false;
		}
	}

	const exportToFile = async () => {
		const res = await exportToAllVisas({
			...filters,
			search: filters?.passport_number,
			country: filters?.country?.value,
			visa_type: filters.visa_type?.value,
			date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : "",
			source: filters?.source?.value,
			status: filters?.status?.value,
			agency_name: filters?.agency_name?.value,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`All Visas-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};
	return (
		<div className="">
			<div className=" table-responsive  px-4 our-border ">
				{/* filters */}
				<AllVisasFilter
					filters={filters}
					visaTypesLookup={lookupsList.visaTypes}
					sourcesLookup={lookupsList.sources}
					agencyLookup={lookupsList.agency}
					statusLookup={lookupsList.status}
					handleFiltersChanges={handleFiltersChanges}
					countriesLookup={countriesLookup}
					resetAllFilters={resetAllFilters}
					exportToFileFn={exportToFile}
				/>

				{/* list */}
				<table className="custom-table back-office-table table table-striped">
					<thead>
						<tr className="text-nowrap">
							<th>{booking.messages.passport}</th>
							<th>{backOffice.source}</th>
							<th>{requests.date}</th>
							<th>{requests.VisaType}</th>
							<th>{requests.country}</th>
							<th>{booking.PassengerName}</th>
							<th>{booking.AgencyName}</th>
							<th>{requests.nationality}</th>
							<th>{booking.Price}</th>
							<th>{wallet.Currency}</th>
							<th>{requests.status}</th>
						</tr>
					</thead>

					<tbody>
						{allVisasList?.length > 0 ? (
							allVisasList.map((visaData, index) => {
								return (
									<tr
										key={`${visaData?.id}-${visaData?.visa_request?.id}-${index}`}
									>
										<td>{visaData?.passport_number}</td>
										<td className="text-uppercase">
											{visaData?.visa_request?.source}
										</td>
										<td>{visaData?.visa_request?.departure_date || "-"}</td>
										<td>{visaData?.visa_request?.visa_type?.name?.[locale]}</td>
										<td>{visaData?.country || "-"}</td>
										<td>
											{isJson(visaData?.full_name_en)
												? JSON.parse(visaData?.full_name_en)?.en ||
												  JSON.parse(visaData?.full_name_en)?.ar
												: visaData?.full_name_en}
										</td>
										<td>{visaData?.agency_name}</td>
										<td>{visaData?.nationality}</td>
										<td>{visaData?.visa_request?.price_per_one}</td>
										<td>{visaData?.visa_request?.currency}</td>
										<td
											className={`${statusColors(
												visaData.status
											)} text-capitalize`}
										>
											{visaData.status}
										</td>
										<td>
											<Link
												to={`/online-visa/requests/all-visas/passenger-profile/${visaData?.visa_request?.id}/${visaData.id}`}
											>
												<i class="fas fa-angle-right"></i>
											</Link>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="15">
									<div className="product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<div className="px-2">
					<Pagination info={meta} goTo={goTo} />
				</div>
			</div>
		</div>
	);
}
