import ConfirmModal from "components/Modals/ConfirmModal";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { deleteAds, fetchAllAds, publishAds } from "services/ads";
import Locale from "translations";
import AdsFilter from "./AdsFilter";
import AdsTable from "./AdsTable";
function PopupAds() {
	const { id } = useParams();
	const { search } = useLocation();
	const history = useHistory();
	const [_, pages] = search.split("=");
	const [publish, setpublish] = useState({});
	const [selectAd, setSelectAd] = useState(null);
	const [isLoader, setIsLoader] = useState(false);
	const [Ads, setAds] = useState([]);
	const [meta, setmeta] = useState();
	const [isopen, setisopen] = useState(false);
	const [filters, setFilters] = useState({
		search: "",
		expiry_date: "",
		type: "",
		publish: "",
	});

	const [deleteIsOpen, setDeleteIsOpen] = useState(false);
	const toggleDelete = () => {
		setDeleteIsOpen(!deleteIsOpen);
	};
	const { backOffice } = Locale;
	async function fetchReservationsFilterdData() {
		const data = {
			search: filters.search,
			type: filters.type?.value,
			expiry_date: filters?.expiry_date
				? moment(filters?.expiry_date).format("YYYY-MM-DD")
				: "",
			publish: filters.publish?.value,
		};
		const response = await fetchAllAds(id, data, pages);
		setAds(response?.data.data);
		setmeta(response?.data?.meta);
	}
	useEffect(() => {
		fetchReservationsFilterdData();
	}, [
		filters.search,
		filters.expiry_date,
		filters.type,
		filters.publish,
		pages,
		isLoader,
	]);

	const goto = (page) => {
		const params = new URLSearchParams();
		if (page) {
			params.append("page", page);
		} else {
			params.delete("page", page);
		}
		history.push({ search: params.toString() });
	};

	const deleteAdsFn = async (adId) => {
		const res = await deleteAds(id, adId);
		if (res.status === 200) {
			store.addNotification({
				title: "Info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			setDeleteIsOpen(false);
			setIsLoader((prev) => !prev);
		}
	};
	// change publish status
	const toggleConfirm = (idAd, statusAd) => {
		setisopen(!isopen);
		setpublish({
			country_id: id,
			ad_id: idAd,
			publish: statusAd == 0 ? 1 : 0,
		});
	};
	const updatapublish = async () => {
		const res = await publishAds(publish);
		if (res.status == 200) {
			fetchReservationsFilterdData();
			setisopen(!isopen);
			store.addNotification({
				title: "success",
				type: "success",
				message: "publish status has been changed successfully",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<h4>{backOffice.PopupAds}</h4>
				<ShowForPermission permission="Popup-Ads-Manage">
					<button
						className="btn btn-info px-4"
						onClick={() => history.push(`/popup-ads/${id}/add`)}
					>
						{backOffice.AddNew}
					</button>
				</ShowForPermission>
			</div>

			<AdsFilter setFilters={setFilters} filters={filters} />
			<AdsTable
				Ads={Ads}
				setAds={setAds}
				meta={meta}
				goTo={goto}
				toggleConfirm={toggleConfirm}
				setSelectAd={setSelectAd}
				toggleDelete={toggleDelete}
			/>

			<Modal isOpen={isopen}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 pt-3">
					<h4 className="py-4 model-msg font-weight-bolder">{`are you sure you want to ${
						publish?.publish != 0 ? "Publish" : "unPublish"
					}  this ad`}</h4>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
					<button
						onClick={updatapublish}
						className={`btn rounded font-weight-bolder   text-white mx-1 w-25 ${
							publish?.publish != 0 ? "btn-success" : "btn-danger"
						}`}
					>
						{`${publish?.publish != 0 ? "Publish" : "unPublish"}`}
					</button>
					<button
						onClick={() => {
							setisopen(!isopen);
						}}
						className="btn btn-dark  text-white rounded font-weight-bolder   mx-1  w-25"
					>
						{"cancel"}
					</button>
				</div>
			</Modal>

			{/******Start Delete Modal***** */}
			<ConfirmModal
				IsOpen={deleteIsOpen}
				toggle={toggleDelete}
				message={"Are you sure you want to Delete ?"}
				onConfirm={() => deleteAdsFn(selectAd?.id)}
			/>
			{/******End Delete Modal***** */}
		</>
	);
}
export default PopupAds;
