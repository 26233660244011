import { useCallback, useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// React-notifications
import { store } from "react-notifications-component";
// Components
import Pagination from 'components/shared/Pagination';
// Module Components
import QuickFilter from '../../shared/QuickFilter';
import DefaultPricingFilter from './DefaultPricingFilter';
import DefaultPricingList from './DefaultPricingList';
// Helper
import ShowForPermission from 'helpers/showForPermission';
// Moment
import moment from 'moment';
// Services
import { exportExcelDefaultPricingList, getDefaultPricingList } from 'services/safaVisa';
// Icons
import { ExportIcon } from 'components/Icons';
import useDebounce from 'hooks/useDebounce';
import useSafaVisaData from '../../shared/useSafaVisaData';
// *** Custom Hook
// import useSafaVisaData from '../../shared/useSafaVisaData';
// ----------------------------------------------------------------------


function DefaultPricing() {

  // *** Hooks
  let history = useHistory();

  const { safaVisa } = Locale;

  // *** Custom Hook
  const { initialQuickFilter } = useSafaVisaData();

  const initialFilter = {
    country_id: '',
    currency: '',
    calendar_type: { value: 'gregorian', label: safaVisa.gregorian },
    date_from: '',
    date_to: '',
  }

  // ** State
  const [quickfilters, setQuickFilter] = useState(initialQuickFilter);
  const [filters, setFilters] = useState(initialFilter);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const [defaultPricing, setDefaultPricing] = useState({
    list: [],
    meta: {}
  });

  //** Function
  const goTo = (page) => {
    setQuickFilter({ ...quickfilters, page: page });
  };

  const fetctDefaultPricingList = async (params) => {
    const response = await getDefaultPricingList(params)
    if (response?.status === 200) {
      setDefaultPricing({
        list: response?.data?.data,
        meta: response?.data?.meta
      })
    }
  }

  const depounceSearchByTitle = useDebounce((params) =>
    fetctDefaultPricingList({ ...params })
    , 300)

  const handleQuickFilter = useCallback(({ action, key, payload }) => {
    if (action === "reset") {
      setQuickFilter(initialQuickFilter)
    }
    if (action === "filter") {
      setQuickFilter((prevFilter) => ({ ...prevFilter, [key]: payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(({ action, key, payload }) => {
    if (action === "reset") {
      setFilters({ ...initialFilter });
      setQuickFilter({ ...initialQuickFilter, title: '' })
    }
    if (action === "filter") {
      setFilters((prevFilter) => ({ ...prevFilter, [key]: payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyFilter = () => {
    fetctDefaultPricingList({
      ...quickfilters,
      title: quickfilters?.title || null,
      country_id: filters?.country_id?.value ? Number(filters?.country_id?.value) : null,
      currency: filters?.currency?.label,
      date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
      date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
    })
  }

  const handleToggleFilter = () => {
    setFilterIsOpen(!filterIsOpen)
  };

  const exportExcel = async () => {
    const res = await exportExcelDefaultPricingList({
      ...quickfilters,
      title: quickfilters?.title || null,
      country_id: filters?.country_id?.value ? Number(filters?.country_id?.value) : null,
      currency: filters?.currency?.label,
      date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
      date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
    });
    if (res?.status === 200 || res?.status === 201) {
      if (res.data) {
        const blob = new Blob([res.data], {
          type: 'xlsx',
        });
        const objectUrl = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `defaultPricing-${moment().format("DD-MM-YYYY")}.xlsx`
        );
        anchor.click();
        //window.open(objectUrl);
      }
      store.addNotification({
        title: "Info!",
        message: `Export To Excel Successful`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  useEffect(() => {
    if (quickfilters?.title) {
      depounceSearchByTitle({
        ...quickfilters,
        title: quickfilters?.title || null,
        country_id: filters?.country_id?.value ? Number(filters?.country_id?.value) : null,
        currency: filters?.currency?.label,
        date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
        date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
      })
    } else {
      fetctDefaultPricingList({
        ...quickfilters,
        title: quickfilters?.title || null,
        country_id: filters?.country_id?.value ? Number(filters?.country_id?.value) : null,
        currency: filters?.currency?.label,
        date_from: filters?.date_from ? moment(filters?.date_from).format('YYYY-MM-DD') : null,
        date_to: filters?.date_from ? moment(filters?.date_to).format('YYYY-MM-DD') : null,
      })
    }
  }, [reload, quickfilters])

  // console.log(quickfilters)

  // ---------- JSX Code --------
  return (
    <div className='safaVisa-wrapper'>
      <div>
        {/* Default Pricing Header */}
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="main-title mb-0">{safaVisa.defaultPricing}</h4>

          <div className='d-flex gap-2 align-items-center'>
            <ShowForPermission permission="Tourism-Company-Manage">
              <button
                className="btn export-btn py-2"
                onClick={exportExcel}
              >
                <ExportIcon color='#57A866' width='24' height='24' />
                {safaVisa.exportExcel}
              </button>
            </ShowForPermission>
            <ShowForPermission permission="Tourism-Company-Manage">
              <button
                className="btn  add-btn py-2"
                onClick={() => history.push("/safaVisa-defaultPricing/add")}
              >
                <i className="fas fa-plus text-white fa-md"></i>{" "}
                {safaVisa.add}
              </button>
            </ShowForPermission>
          </div>
        </div>

        <hr />

        {/* Default Pricing Filter */}
        <div className='safa-visa-filter'>
          <QuickFilter
            quickfilters={quickfilters}
            onQuickFilter={handleQuickFilter}
            toggleFilter={handleToggleFilter}
            filterIsOpen={filterIsOpen}
          />

          {filterIsOpen &&
            <DefaultPricingFilter
              filters={filters}
              onFilter={handleFilter}
              onApplyFilter={handleApplyFilter}
            />
          }

        </div>

        {/* Default Pricing List */}
        <DefaultPricingList
          pricingList={defaultPricing?.list}
          reload={reload}
          onReload={setReload}
        />

        {/* Default Pricing Pagination */}
        <Pagination info={defaultPricing?.meta} goTo={goTo} items={10} />
      </div>
    </div>
  )
}

export default DefaultPricing